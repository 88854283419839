import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PrivacyPolicyService from "../../../../services/assetManagement/system/privacyPolicy/PrivacyPolicy.service";


interface InitialStateInterface {
    PrivacyPolicyContent: any
}

const initialState = {
    PrivacyPolicyContent: {},
} as InitialStateInterface;

export const getPrivacyPolicy = createAsyncThunk(
    "getPrivacyPolicy",
    async () => {
        try {
            return await PrivacyPolicyService.getPrivacyPolicyService().then(response => response);
        } catch (error) { }
    }
);

export const postPrivacyPolicy = createAsyncThunk(
    "postPrivacyPolicy",
    async (req: any, thunkAPI) => {
        try {
            return await PrivacyPolicyService.postPrivacyPolicyService(req).then(response => response);
        } catch (error) { }
    }
);

export const putPrivacyPolicy = createAsyncThunk(
    "putPrivacyPolicy",
    async (req: any, thunkAPI) => {
        try {
            return await PrivacyPolicyService.putPrivacyPolicyService(req).then(response => response);
        } catch (error) { }
    }
);

const PrivacyPolicySlice = createSlice({
    name: "PrivacyPolicySlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPrivacyPolicy.fulfilled, (state, action) => {
            state.PrivacyPolicyContent = action.payload?.result ? action.payload.result?.privacy_policy : {};
        });
    },
});

export default PrivacyPolicySlice;
