/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTCard } from "../../../../_metronic/helpers"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC, useEffect, useState } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"
import CustomSelect from "../../../components/customModal/CustomSelect"
import Styles from "../../../../_metronic/assets/sass/pages/frpPurchaseRateList.module.scss"
import { AppDispatch } from "../../../store/store"
import { useDispatch } from "react-redux"
import { addFpBalance, exportTransactions, getFpBalance, getTransactions } from "../../../slices/frpTransaction/transactionSlice"
import moment from "moment"
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"
import CustomFilterNumberRange from "../../../components/customFilter/CustomFilterNumberRange"
import CustomFilterDateRange from "../../../components/customFilter/CustomFilterDateRange"
import { useFormik } from "formik"
import * as Yup from 'yup'
import { showToast } from "../../../components/custom-toasts/custom-toasts"

interface Props {
  transactionList: any,
  userList: any,
  fpBalance: any
}
export enum ClientType {
  None,
  Merchant,
  Consumer,
  BackOffice,
  FreedomPay
}

const Transaction: FC<Props> = ({ transactionList, userList, fpBalance }) => {
  const [userType, setuserType] = useState<any>()
  const [user, setUser] = useState<any>()
  const [userSelectList, setUserSelectList] = useState<any>()
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const dispatch: AppDispatch = useDispatch();
  const [filterParams, setfilterParams] = useState<any>([])
  const [filterQuery, setfilterQuery] = useState<any>([])

  const userTypeData = [
    {
      value: ClientType.Merchant,
      label: "Merchant"
    },
    {
      value: ClientType.Consumer,
      label: "Consumer"
    },
    {
      value: ClientType.FreedomPay,
      label: "FreedomPay"
    }
  ]

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .required('FRP Amount is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        let req = {
          amount: values.amount,
        }
        dispatch(addFpBalance(req)).then((response) => {
          if (response?.payload?.is_success) {
            dispatch(getFpBalance())
            showToast.success("Saved Successfully")
          }
        })
      } catch (ex) {
        console.error(ex)
      } finally {
        actions.resetForm();
      }
    },
  })



  useEffect(() => {
    setUser(null)
    if (userType?.value === ClientType.Merchant) {
      setUserSelectList(userList?.merchants)
    } else if (userType?.value === ClientType.Consumer) {
      setUserSelectList(userList?.consumers)
    } else if (userType?.value === ClientType.FreedomPay) {
      setUserSelectList([{ id: "00000000-0000-0000-0000-000000000000", name: "FreedomPay" }])
    } else {
      setUserSelectList([])
    }
  }, [userType])

  useEffect(() => {
    setdataTableisBusy(false)
  }, [transactionList])

  const handlePageChange = (page: any) => {
    setdataTableisBusy(true);
    setPage(page - 1)
    setdataTableisBusy(false);
  };
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setdataTableisBusy(true);
    setPerPage(newPerPage);
    setdataTableisBusy(false);
  };

  useEffect(() => {
    if (user && userType) {
      dispatch(getTransactions({ page_index: page, page_size: perPage, filters: filterParams, id: user?.id, type: userType?.value }));
    }
  }, [page, perPage, user, filterParams])

  const customFilter = [
    {
      component: (
        <>
          <CustomFilterNumberRange
            filterName='Amount'
            filterTitle='Frp Amount'
            customFunction={setfilterQuery}
            customQuery={filterQuery}
            onlyPositive={false}
          ></CustomFilterNumberRange>
        </>
      ),
    },
    {
      component: <>
        <CustomFilterDateRange filterName={"CreateDateUtc"} filterTitle={"Date"} customQuery={filterQuery} customFunction={setfilterQuery}></CustomFilterDateRange>
      </>
    },
    {
      component: <>
        <label className='fw-bold fs-6 mb-2'>Description</label>
        <CustomTextbox
          onChange={
            (e) => isNotEmpty(e) ?
              setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Description"), { "name": "Description", "comparator": "Contains", "value": e }])
              : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Description")])
          }
          placeHolder={"Type a Description"}
          name='Description'
          value={filterQuery.find((x: any) => x.name === "Description") !== undefined ? filterQuery.find((x: any) => x.name === "Description")?.value : ""}
          customKeypressEvent={() => setfilterParams(filterQuery)}
        ></CustomTextbox>
      </>
    },
  ]

  function isNotEmpty(obj: unknown) {
    return obj !== undefined && obj !== null && obj !== ''
  }

  const columns = [
    {
      name: 'FRP Amount',
      selector: (row: any) => <div style={{ color: row?.amount > 0 ? "green" : "red" }}>{Number(row?.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>,
      right: true,
      width: "150px"

    },
    {
      name: 'Date',
      selector: (row: any) => <div>{moment(row.date_created).format("MM/DD/YYYY")}</div>,
      width: "250px"
    },

    {
      name: 'Description',
      selector: (row: any) => <div>{row?.description}</div>,
    },

  ];
  return (
    <>
      <div className={`${Styles.container}`}>
        <KTCard>
          <form id='frp_form' className='form' onSubmit={formik.handleSubmit}>
            <div style={{ paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "20px" }}>
              <div className="row" >
                <div className="col-6">
                  <label className='fw-bold fs-6 mb-2'> {"FreedomPay Balance: " + Number(fpBalance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " FRP"}</label>
                </div>
                <div className="col-3">
                  <label className='required fw-bold fs-6 mb-2'> Add FRP (For FreedomPay) </label>
                  <CustomTextbox
                    {...formik.getFieldProps('amount')}
                    onChange={() => null}
                    placeHolder={"Amount"}
                    name='amount'
                    formik={formik}
                    formikName={"amount"}
                    onlyPositive
                    type={"number"}
                  ></CustomTextbox>
                </div>
                <div className="col">
                  <button
                    type='submit'
                    className={`btn btn-primary ${Styles.saveButton}`}
                    data-kt-users-modal-action='submit'
                    onClick={() => {}}
                  >
                    <span className='indicator-label'>Save</span>
                  </button>
                </div>
              </div>
              <hr></hr>
              <div className="row mt-6" >
                <div className="col-6">
                  <label className='required fw-bold fs-6 mb-2'>User Type</label>
                  <CustomSelect
                    onChange={setuserType}
                    data={userTypeData}
                    isMulti={false}
                  ></CustomSelect>
                </div>
                <div className="col-6">
                  <label className='required fw-bold fs-6 mb-2'>User</label>
                  <CustomSelect
                    onChange={setUser}
                    data={userSelectList}
                    selectedValue={user}
                    isMulti={false}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                  ></CustomSelect>
                </div>
              </div>
              <CustomDatatable
                columns={columns}
                data={transactionList?.items}
                pagination
                paginationServer
                paginationTotalRows={transactionList?.total_count}
                isBusy={dataTableisBusy}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                showFilter={true}
                filterOptions={customFilter}
                searchButtonClickEvent={() => setfilterParams(filterQuery)}
                isExport
                onExport={() => {
                  dispatch(exportTransactions({ page_index: 0, page_size: 0, filters: filterParams, id: user?.id, type: userType?.value }));

                }}
                clearButtonClickEvent={() => {
                  setfilterParams([])
                  setfilterQuery([])
                }}
              ></CustomDatatable>
              <br />
            </div>
          </form>
        </KTCard>
      </div>
    </>
  )
}

const TransactionWrapper: FC<Props> = ({ transactionList, userList, fpBalance }) => <QueryRequestProvider>
  <ListViewProvider>
    <Transaction transactionList={transactionList} userList={userList} fpBalance={fpBalance} />
  </ListViewProvider>
</QueryRequestProvider>

export { TransactionWrapper }
