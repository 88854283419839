/* eslint-disable no-template-curly-in-string */
import { callApi, HttpMethods } from "../../../../utils/api-util";
import { ResponseType } from "../../../../utils/enums/ResponseType.enum";

const getVideoService = async (req: any) => {
  let _options = {
    controller: "/video/get",
    method: HttpMethods.POST,
    data: req,
  };
  const result = await callApi(_options);
  return result;
}

const exportVideoService = async (req: any) => {
  let _options = {
    controller: "/video/export",
    method: HttpMethods.POST,
    data: req,
    responseType: ResponseType.File
  };
  const result = await callApi(_options);
  return result;
}



const postVideoService = async (req: any) => {
  const formData = new FormData();
  formData.append("FileStream", req?.file_stream);
  formData.append("ContainerName", req?.container_name);
  formData.append("PublishedDate", req?.published_date);
  formData.append("IsActive", req?.is_active);

  let _options = {
    controller: "/video",
    method: HttpMethods.POST,
    data: formData,
    contentType: 'multipart/form-data; boundary=${form._boundary}'
  };
  const result = await callApi(_options);
  return result;
};

const putVideoService = async (req: any) => {
  const formData = new FormData();
  formData.append("FileStream", req?.file_stream);
  formData.append("ContainerName", req?.container_name);
  formData.append("PublishedDate", req?.published_date);
  formData.append("IsActive", req?.is_active);
  formData.append("Id", req?.id);


  let _options = {
    controller: "/video",
    method: HttpMethods.PUT,
    data: formData,
    contentType: 'multipart/form-data; boundary=${form._boundary}'
  };
  const result = await callApi(_options);
  return result;
};

const deleteVideoService = async (req: any) => {
  let _options = {
    controller: "/video",
    method: HttpMethods.DELETE,
    data: { Id: req }
  };
  return await callApi(_options);
};

const getVideoWithIdService = async (req: any) => {
  let _options = {
    controller: "/video/get-video-by-id/" + req,
    method: HttpMethods.GET,
  };
  const result = await callApi(_options);
  return result;
};

const videoService = {
  getVideoService,
  exportVideoService,
  deleteVideoService,
  postVideoService,
  putVideoService,
  getVideoWithIdService
};

export default videoService;
