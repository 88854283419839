import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DataSourcesService from "../../../services/credentialManagement/dataSources/DataSources.service";


interface InitialStateInterface {
    DataSourcesListSlice: any
    DataSourcesSlice: any[]
    DataProviderTypes: any[]
}

const initialState = {
    DataSourcesListSlice: null,
    DataSourcesSlice: [],
    DataProviderTypes: []
} as InitialStateInterface;

export const getDataSources = createAsyncThunk(
    "getDataSourcesList",
    async (req: any) => {
        try {
            return await DataSourcesService.getDataSourcesService(req).then(response => response);
        } catch (error) { }
    }
);

export const getDataProviderType = createAsyncThunk(
    "getDataProviderType",
    async () => {
        try {
            return await DataSourcesService.getDataProviderType().then(response => response);
        } catch (error) { }
    }
);

export const exportDataSources = createAsyncThunk(
    "exportDataSources",
    async (req: any) => {
        try {
            return await DataSourcesService.exportDataSourcesService(req).then(response => response);
        } catch (error) { }
    }
);




export const postDataSources = createAsyncThunk(
    "postDataSources",
    async (req: any, thunkAPI) => {
        try {
            return await DataSourcesService.postDataSourcesService(req).then(response => response);
        } catch (error) { }
    }
);

export const putDataSources = createAsyncThunk(
    "putDataSources",
    async (req: any, thunkAPI) => {
        try {
            return await DataSourcesService.putDataSourcesService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteDataSourcesWithId = createAsyncThunk(
    "deleteDataSources",
    async (req: any, thunkAPI) => {
        try {
            return await DataSourcesService.deleteDataSourcesService(req).then(response => response);
        } catch (error) { }
    }
);

export const getDataSourcesWithId = createAsyncThunk(
    "getDataSourcesWithId",
    async (req: any) => {
        try {
            return await DataSourcesService.getDataSourcesWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const DataSourcesSlice = createSlice({
    name: "DataSourcesSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDataSources.fulfilled, (state, action) => {
            state.DataSourcesListSlice = action.payload?.result ? action.payload.result?.data_provider : null;
        });
        builder.addCase(getDataSourcesWithId.fulfilled, (state, action) => {
            state.DataSourcesSlice = action.payload?.result ? action.payload.result?.data_provider : [];
        });
        builder.addCase(getDataProviderType.fulfilled, (state, action) => {
            state.DataProviderTypes = action.payload?.result ? action.payload.result?.data_provider_types : [];
        });
    },
});

export default DataSourcesSlice;
