/* eslint-disable react-hooks/exhaustive-deps */
import Style from '../../../_metronic/assets/sass/components/percentage.module.scss'

interface CustomProps {
    title?: string;
    children?: React.ReactNode;
}

const CustomParentPercentageCard: React.FC<CustomProps> = ({
    title,
    children,
}) => {


    return (
        <>

            <div className={`${Style.container}`}>
                <div className={`${Style.header}`}>
                    {title}
                </div>
                
                {children}
            </div>
        </>
    )
}

export { CustomParentPercentageCard }
