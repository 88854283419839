import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TinkWebHookService from "../../../../services/assetManagement/system/tinkWebHook/TinkWebHook.service";


interface InitialStateInterface {
    TinkWebHookListSlice: any
}

const initialState = {
    TinkWebHookListSlice: null,
} as InitialStateInterface;

export const getTinkWebHook = createAsyncThunk(
    "getTinkWebHookList",
    async (req: any) => {
        try {
            return await TinkWebHookService.getTinkWebHookService(req).then((response: any) => response);
        } catch (error) { }
    }
);

export const exportTinkWebHook = createAsyncThunk(
    "exportTinkWebHookList",
    async (req: any) => {
        try {
            return await TinkWebHookService.exportTinkWebHookService(req).then((response: any) => response);
        } catch (error) { }
    }
);
export const postTinkWebHook = createAsyncThunk(
    "postTinkWebHook",
    async (req: any, thunkAPI) => {
        try {
            return await TinkWebHookService.postTinkWebHookService(req).then((response: any) => response);
        } catch (error) { }
    }
);

export const putTinkWebHook = createAsyncThunk(
    "putTinkWebHook",
    async (req: any, thunkAPI) => {
        try {
            return await TinkWebHookService.putTinkWebHookService(req).then((response: any) => response);
        } catch (error) { }
    }
);

export const deleteTinkWebHookWithId = createAsyncThunk(
    "deleteTinkWebHook",
    async (req: any, thunkAPI) => {
        try {
            return await TinkWebHookService.deleteTinkWebHookService(req).then((response: any) => response);
        } catch (error) { }
    }
);

const TinkWebHookSlice = createSlice({
    name: "TinkWebHookSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTinkWebHook.fulfilled, (state, action) => {
            state.TinkWebHookListSlice = action.payload?.result ? action.payload.result?.webhook_endpoints : null;
        });
    },
});

export default TinkWebHookSlice;
