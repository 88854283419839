/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTCard, KTSVG } from "../../../../_metronic/helpers"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC, useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"
import Styles from "../../../../_metronic/assets/sass/pages/frpPurchaseRateList.module.scss"
import { useDispatch } from "react-redux"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { AppDispatch, useAppSelector } from "../../../store/store"
import { CustomModal } from "../../../components/customModal/CustomModal"
import componentsSlice from "../../../slices/components/componentsSlice"
import clsx from "clsx"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"

interface Props {
  vasEpConfigurationList: any[]
}
const VasEpConfiguration: FC<Props> = ({ vasEpConfigurationList }) => {

  const [totalRowCountVal, settotalRowCountVal] = useState(0)
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const [resetDataTable, setresetDataTable] = useState(false)
  const { modalIsOpen } = useAppSelector((state) => state.components);
  const dispatch: AppDispatch = useDispatch();
  const [processName, setprocessName] = useState("Insert")
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null)

  const formik = useFormik({
    initialValues: {
      id: '',
      title: '',
      content: '',
      sliderType: [] as any,
      url: '',
      priority: '',
      video: ''
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Title is required'),
      content: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(250, 'A maximum of 250 symbols can be displayed on mobile screens.')
        .required('Content is required'),
      url: Yup.string()
        .required('Url is required'),
      priority: Yup.string()
        .required('Priority is required'),
      sliderType: Yup.array()
        .nullable()
        .min(1, "Slider Type required")
    }),
    onSubmit: (values, actions) => {
      actions.resetForm();
    },
  })

  useEffect(() => {
    setdataTableisBusy(false)
    settotalRowCountVal(vasEpConfigurationList?.length)
    setresetDataTable(!resetDataTable)
  }, [vasEpConfigurationList])

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => <div>{row?.name}</div>,
    },
    {
      name: 'POS Template',
      selector: (row: any) => <div>{row?.pos_template}</div>,
    },
    {
      name: 'POS System',
      selector: (row: any) => <div>{row?.pos_system}</div>,
    },
    {
      name: 'Product Lists',
      selector: (row: any) => <div>{row?.product_lists}</div>,
    },
    {
      name: 'Last Updated',
      selector: (row: any) => <div>{row?.last_updated}</div>,
      width: "auto",
    },
    {
      name: 'Actions',
      selector: (row: any) =>
        <>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => { }}
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </div>
          </div>
        </>
      ,
      width: "100px",
    },
  ];

  useEffect(() => {
    //setdataTableisBusy(true) Temp closed until api ready
    //dispatch(getStorage());  Temp closed until api ready
  }, [activePage, pageSize])


  useEffect(() => {
    if (modalIsOpen === undefined) {
      setprocessName("Insert")
      setSelectedRowId(null)
      formik.resetForm()
    }
  }, [modalIsOpen])

  const onChangePage = (page: any) => {
    setActivePage(page);
  };
  const onChangeRowsPerPage = (size: any) => {
    setPageSize(size);
  };
  // For DataTable Event End

  function findProcessScreen(processName: string) {
    switch (processName) {
      case "Insert":
        return <>
          <CustomModal title={selectedRowId !== null ? "Update" : "Create"}>
            <>
              <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Title</label>
                    <input
                      placeholder='Type a Title'
                      type='text'
                      {...formik.getFieldProps('title')}
                      name='title'
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      autoComplete='off'
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.title}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Content</label>
                    <textarea
                      placeholder='Type a Content'
                      {...formik.getFieldProps('content')}
                      name='content'
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      autoComplete='off'
                      data-kt-element='input'
                      style={{ height: "100px" }}
                    />
                    {formik.touched.content && formik.errors.content && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.content}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Priority</label>
                    <input
                      placeholder='Priority'
                      type='number'
                      {...formik.getFieldProps('priority')}
                      name='priority'
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      autoComplete='off'
                    />
                    {formik.touched.priority && formik.errors.priority && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.priority}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                  >
                    {<span className='indicator-label'>Submit</span>}
                  </button>
                </div>
                {/* end::Actions */}
              </form>
            </>
          </CustomModal>
        </>

      case "Update":
        return <>

        </>

      case "Delete":
        return <>
          <CustomModal title="Are You Sure?">
            <>
              {/* begin::Actions */}
              <div className='text-center pt-15'>
                <button
                  type='reset'
                  onClick={() => { }
                  }
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Discard
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={() => {
                  }
                  }
                >
                  <span className='indicator-label'>Delete</span>
                </button>
              </div>
              {/* end::Actions */}
            </>
          </CustomModal>
        </>

      default: return null;
    }
  }


  return (
    <>
      <KTCard>
        <form id='kt_modal_add_user_form' className='form' style={{ height: "70vh" }}>
          <Tabs
            defaultActiveKey="configuration"
            id="tab"
            className="mb-3"
          >
            <Tab eventKey="configuration" title="Configuration">
              <div className={`${Styles.container}`} style={{ marginRight: "1.2rem" }}>
                <div className="row">
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Es Key</label>
                    <CustomTextbox
                      onChange={() => null}
                      placeHolder={"Es Key"}
                    ></CustomTextbox>

                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Terminal ID</label>
                    <CustomTextbox
                      onChange={() => null}
                      placeHolder={"Terminal ID"}
                    ></CustomTextbox>

                  </div>

                  <div className="col-2">
                    <button
                      type='submit'
                      className={`btn btn-primary ${Styles.saveButton}`}
                      data-kt-users-modal-action='submit'
                      onClick={() => { }}
                    >
                      <span className='indicator-label'>Save</span>
                    </button>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="pos" title="PoS">
              <>
                <KTCard>
                  <CustomDatatable
                    columns={columns}
                    data={vasEpConfigurationList}
                    title={"PoS List"}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRowCountVal}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    isBusy={dataTableisBusy}

                    showTitle
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport

                  ></CustomDatatable>
                </KTCard>
                {modalIsOpen !== undefined ?
                  findProcessScreen(processName)
                  : null
                }
              </>
            </Tab>
          </Tabs>

        </form>
      </KTCard>
    </>
  )
}

const VasEpConfigurationWrapper: FC<Props> = ({ vasEpConfigurationList }) => <QueryRequestProvider>
  <ListViewProvider>
    <VasEpConfiguration vasEpConfigurationList={vasEpConfigurationList} />
  </ListViewProvider>
</QueryRequestProvider>

export { VasEpConfigurationWrapper }
