
import { callApi, HttpMethods } from "../../utils/api-util";
import { ResponseType } from "../../utils/enums/ResponseType.enum";

const getCredentialMerchantLogoService = async (req: any) => {
    let _options = {
        controller: "/credential-merchant-logo/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}


const exportCredentialMerchantLogoService = async (req: any) => {
    let _options = {
        controller: "/credential-merchant-logo/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}

const postCredentialMerchantLogoService = async (req: any) => {
    let _options = {
        controller: "/credential-merchant-logo",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putCredentialMerchantLogoService = async (req: any) => {
    let _options = {
        controller: "/credential-merchant-logo",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteCredentialMerchantLogoService = async (req: any) => {
    let _options = {
        controller: "/credential-merchant-logo",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getCredentialMerchantLogoWithIdService = async (req:any) => {
    let _options = {
        controller: "/credential-merchant-logo/get-logo-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const credentialMerchantLogoService = {
    getCredentialMerchantLogoService,
    exportCredentialMerchantLogoService,
    postCredentialMerchantLogoService,
    putCredentialMerchantLogoService,
    deleteCredentialMerchantLogoService,
    getCredentialMerchantLogoWithIdService,
};

export default credentialMerchantLogoService;
