/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { CustomModalHeader } from './CustomModalHeader'
import { AppDispatch } from '../../store/store';
import { useDispatch } from 'react-redux';
import componentsSlice from '../../slices/components/componentsSlice';

interface CustomProps {
    title?: string;
    children?: React.ReactNode;
    footer?: any;
    maxWidth?: number;
    setHeight?: boolean;
}

const CustomModal: React.FC<CustomProps> = ({
    title,
    children,
    footer,
    maxWidth,
    setHeight
}) => {
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        document.body.classList.add('modal-open')
        const close = (e:any) => {
            if(e.keyCode === 27){
                dispatch(componentsSlice.actions.closeModal({}))
            }
          }
          window.addEventListener('keydown', close)
        return () => {
            window.removeEventListener('keydown', close)
            document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
                onKeyDown={() => document.body.classList.remove('modal-close')}
            >
                {/* begin::Modal dialog */}
                <div className={`modal-dialog modal-dialog-centered ${maxWidth === undefined ? "mw-650px" : 'mw-' + maxWidth + 'px'}`}>
                    {/* begin::Modal content */}
                    <div className='modal-content' style={{ height: setHeight ? '95vh' : '' }}>
                        <CustomModalHeader title={title} />
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-1'>
                            {children}
                        </div>
                        {/* end::Modal body */}
                        {footer ? <div className='modal-footer justify-content-center pt-0'>
                            {footer}
                        </div> : <></>}
                    </div>

                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export { CustomModal }
