/* eslint-disable no-template-curly-in-string */
import {callApi, HttpMethods} from '../utils/api-util'

const postPicture = async (file: File, isSat: boolean = false) => {
  const formData = new FormData()
  formData.append('FileStream', file)
  if (isSat) {
    formData.append('ContainerName', 'saticons')
  } else {
    formData.append('ContainerName', 'merchanticons')
  }
  formData.append('FileType', '1')

  let _options = {
    controller: '/storage',
    method: HttpMethods.POST,
    data: formData,
    contentType: 'multipart/form-data; boundary=${form._boundary}',
  }
  const result = await callApi(_options)
  return result
}

const postPDF = async (file: File, isSat: boolean = false) => {
  const formData = new FormData()
  formData.append('FileStream', file)
  if (isSat) {
    formData.append('ContainerName', 'saticons')
  } else {
    formData.append('ContainerName', 'merchanticons')
  }
  formData.append('FileType', '0')

  let _options = {
    controller: '/storage',
    method: HttpMethods.POST,
    data: formData,
    contentType: 'multipart/form-data; boundary=${form._boundary}',
  }
  const result = await callApi(_options)
  return result
}

const getdownloadableLink = async (req: any) => {
  let _options = {
    controller: '/storage/private-file',
    method: HttpMethods.POST,
    data: JSON.stringify(req),
  }
  return await callApi(_options)
}

const pictureAndFileService = {
  postPicture,
  postPDF,
  getdownloadableLink,
}

export default pictureAndFileService
