
import { callApi, HttpMethods } from "../../../utils/api-util";
import { ResponseType } from "../../../utils/enums/ResponseType.enum";

const getIZoneSystemMessagesService = async (req: any) => {
    let _options = {
        controller: "/izone-system-message/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}
const exportIZoneSystemMessagesService = async (req: any) => {
    let _options = {
        controller: "/izone-system-message/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}


const postIZoneSystemMessagesService = async (req: any) => {
    let _options = {
        controller: "/izone-system-message",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putIZoneSystemMessagesService = async (req: any) => {
    let _options = {
        controller: "/izone-system-message",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteIZoneSystemMessagesService = async (req: any) => {
    let _options = {
        controller: "/izone-system-message",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getIZoneSystemMessagesWithIdService = async (req:any) => {
    let _options = {
        controller: "/izone-system-message/get-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const IZoneSystemMessagesService = {
    getIZoneSystemMessagesService,
    exportIZoneSystemMessagesService,
    postIZoneSystemMessagesService,
    putIZoneSystemMessagesService,
    deleteIZoneSystemMessagesService,
    getIZoneSystemMessagesWithIdService
};

export default IZoneSystemMessagesService;
