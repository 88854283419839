/* eslint-disable react-hooks/exhaustive-deps */
// import { useEffect } from 'react'
// import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
// import { getCredentials, getShoutback } from '../../slices/dataSourceIntegrationSlice'
// import { AppDispatch, useAppSelector } from '../../store/store'
import { MemberManagementListWrapper } from './MemberManagementList'

const MemberManagementBreadcrumbs: Array<PageLink> = [
    {
        title: `Member Management`,
        path: '/MemberManagement/MemberManagement',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const MemberManagement = () => {

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={MemberManagementBreadcrumbs}>{`Member Management`}</PageTitle>
                            <MemberManagementListWrapper memberManagementList={
                                [
                                    { "name": "Batman", "partner_name" :"Citibank", "status": "Active", "country": "US", "date": "07/07/2022" },
                                    { "name": "Paul", "country": "UK", "date": "05/07/2022" },
                                    { "name": "Poppye","country": "US", "date": "03/09/2022" },
                                ]
                                } />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default MemberManagement
