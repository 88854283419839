import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BrandAssetService from "../../../services/assetManagement/system/BrandAsset.service";


interface InitialStateInterface {
    BrandAssetSliceList: any[]
}

const initialState = {
    BrandAssetSliceList: [],
} as InitialStateInterface;

export const getBrandAsset = createAsyncThunk(
    "getBrandAssetList",
    async () => {
        try {
            return await BrandAssetService.getBrandAssetService().then(response => response);
        } catch (error) { }
    }
);

export const postBrandAsset = createAsyncThunk(
    "postBrandAsset",
    async (req: any, thunkAPI) => {
        try {
            return await BrandAssetService.postBrandAssetService(req).then(response => response);
        } catch (error) { }
    }
);

export const putBrandAsset = createAsyncThunk(
    "putBrandAsset",
    async (req: any, thunkAPI) => {
        try {
            return await BrandAssetService.putBrandAssetService(req).then(response => response);
        } catch (error) { }
    }
);

const BrandAssetSlice = createSlice({
    name: "BrandAssetSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBrandAsset.fulfilled, (state, action) => {
            state.BrandAssetSliceList = action.payload?.result ? action.payload.result?.brand_asset : [];
        });
    },
});

export default BrandAssetSlice;
