/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from 'react'
import CustomDatatable from '../../../../components/customDatatable/CustomDatatable'
import { CustomModal } from '../../../../components/customModal/CustomModal'
import { AppDispatch, useAppSelector } from '../../../../store/store'
import { useDispatch } from 'react-redux'
import componentsSlice from '../../../../slices/components/componentsSlice'
import { showToast } from '../../../../components/custom-toasts/custom-toasts'
import { isNotEmpty, KTCard, KTSVG } from '../../../../../_metronic/helpers'
import { QueryRequestProvider } from '../../../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import { ListViewProvider } from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import HelperSlice, {
  deleteHelperWithId,
  exportHelper,
  getHelper,
} from '../../../../slices/assetManagement/console/HelperSlice'
import { useNavigate } from 'react-router-dom'
import CustomTextbox from '../../../../components/customTextbox/CustomTextbox'
import CustomSelect from '../../../../components/customModal/CustomSelect'

interface Props {
  HelperListSlice: any
}

export const projectList =
  [
    {
      value: 0,
      label: "Business Console"
    },
    {
      value: 1,
      label: "Backoffice"
    },
    {
      value: 2,
      label: "Sales Assistant"
    }
  ]
  
const HelperList: FC<Props> = ({ HelperListSlice }) => {
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const { modalIsOpen } = useAppSelector((state) => state.components)
  const dispatch: AppDispatch = useDispatch()
  const [processName, setprocessName] = useState('Insert')
  const [selectedRowId, setSelectedRowId] = useState(undefined)
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [filterQuery, setfilterQuery] = useState<any>([])
  const [sortData, setsortData] = useState<any>([])
  const [filterParams, setfilterParams] = useState<any>([])

  useEffect(() => {
    setdataTableisBusy(false)
  }, [HelperListSlice])

  useEffect(() => {
    if (modalIsOpen === undefined) {
      setprocessName('Insert')
      setSelectedRowId(undefined)
    }
  }, [modalIsOpen])

  //#region DataTable Methods

  const columns = useMemo(
    () => [
      {
        name: 'Project Name',
        selector: (row: any) => <div>{projectList.find(x=>x.value === row.project_name)?.label}</div>,
      },
      {
        name: 'Page Url',
        selector: (row: any) => <div>{row?.helper_url}</div>,
      },
      {
        name: 'Page Name',
        selector: (row: any) => <div>{row?.name}</div>,
      },
      {
        name: 'Action',
        selector: (row: any) => (
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {
                <>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      dispatch(HelperSlice.actions.changeSelectedId({ id: row?.id }))
                      navigate('/Helper/CreateHelper')
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </div>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Delete')
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </div>
                </>
              }
            </div>
          </>
        ),
        width: '150px',
      },
    ],
    []
  )

  const customFilter = [
    {
      component: <>
        <label className='fw-bold fs-6 mb-2'>Project</label>
        <CustomSelect
          onChange={
            (e: any) =>
              e.value !== -1 ?
                setfilterQuery([...filterQuery.filter((x: any) => x.name !== "ProjectName"), { "name": "ProjectName", "comparator": "==", "value": e.value }])
                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "ProjectName")])
          }
          data={projectList}
          isMulti={false}
          selectedValue={projectList?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "ProjectName")?.value) !== undefined ? projectList?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "ProjectName")?.value) : -1}
        ></CustomSelect>
      </>
    },
    {
      component:
        <>
          <label className='fw-bold fs-6 mb-2'>Name</label>
          <CustomTextbox
            onChange={(e) =>
              isNotEmpty(e)
                ? setfilterQuery([
                  ...filterQuery.filter((x: any) => x.name !== 'Name'),
                  { name: 'Name', comparator: 'Contains', value: e },
                ])
                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== 'Name')])
            }
            placeHolder={'Type a Name'}
            name='name'
            value={
              filterQuery.find((x: any) => x.name === 'Name') !== undefined
                ? filterQuery.find((x: any) => x.name === 'Name')?.value
                : ''
            }
          ></CustomTextbox>
        </>
    }
  ]

  const handlePageChange = (page: any) => {
    setdataTableisBusy(true)
    setPage(page - 1)
    setdataTableisBusy(false)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setdataTableisBusy(true)
    setPerPage(newPerPage)
    setdataTableisBusy(false)
  }
  const handleSort = async (column: any, sortDirection: any) => {
    setsortData([{ property: column?.name, direction: sortDirection === 'asc' ? 0 : 1 }])
  }
  useEffect(() => {
    dispatch(
      getHelper({
        page_index: page,
        page_size: perPage,
        filters: filterParams,
        sort_filters: sortData,
      })
    )
  }, [page, perPage, sortData, filterParams])

  //#endregion DataTable Methods End

  function deleteWithId() {
    if (selectedRowId !== undefined) {
      dispatch(deleteHelperWithId(selectedRowId)).then((s: any) => {
        if (s?.payload?.is_success) {
          dispatch(
            getHelper({
              page_index: page,
              page_size: perPage,
              filters: filterParams,
              sort_filters: sortData,
            })
          )
          showToast.success('Item Deleted')
          dispatch(componentsSlice.actions.closeModal({}))
        } else {
          dispatch(
            getHelper({
              page_index: page,
              page_size: perPage,
              filters: filterParams,
              sort_filters: sortData,
            })
          )
          showToast.error(s?.payload?.response_exception?.exception_message)
          dispatch(componentsSlice.actions.closeModal({}))
        }
      })
    }
  }

  function findProcessScreen(processName: string) {
    if (processName === 'Delete') {
      return (
        <>
          <CustomModal title='Are You Sure?'>
            <>
              {/* begin::Actions */}
              <div className='text-center pt-15'>
                <button
                  type='reset'
                  onClick={() => {
                    dispatch(componentsSlice.actions.closeModal({}))
                  }}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Discard
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={() => {
                    deleteWithId()
                  }}
                >
                  <span className='indicator-label'>Delete</span>
                </button>
              </div>
              {/* end::Actions */}
            </>
          </CustomModal>
        </>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={HelperListSlice?.items}
          title={'User Manual List'}
          pagination
          paginationServer
          paginationTotalRows={HelperListSlice?.total_count}
          isBusy={dataTableisBusy}
          isNewItem
          isNewItemString={'New Item'}
          isExport
          onExport={() => {
            dispatch(
              exportHelper({
                page_index: 0,
                page_size: 0,
                filters: filterParams,
                sort_filters: sortData,
              })
            )
          }}
          newItemClickEvent={() => {
            dispatch(HelperSlice.actions.changeSelectedId({ id: '' }))
            navigate('/Helper/CreateHelper')
          }}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          showTitle
          showFilter={true}
          filterOptions={customFilter}
          searchButtonClickEvent={() => setfilterParams(filterQuery)}
          sortHandle={handleSort}
          clearButtonClickEvent={() => {
            setfilterParams([])
            setfilterQuery([])
          }}
        ></CustomDatatable>
      </KTCard>
      {modalIsOpen !== undefined ? findProcessScreen(processName) : null}
    </>
  )
}

const HelperWrapper: FC<Props> = ({ HelperListSlice }) => (
  <QueryRequestProvider>
    <ListViewProvider>
      <HelperList HelperListSlice={HelperListSlice} />
    </ListViewProvider>
  </QueryRequestProvider>
)

export { HelperWrapper }
