import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import ProductionRequestService from '../../services/merchantManagement/ProductionRequest.service'

interface InitialStateInterface {
  ProductionRequestListSlice: any
  RequestSlice: any
  ActiveMerchantsListSlice: any
  ActiveMerchantSlice: any
  AllActiveMerchantsListSlice: any
}

const initialState = {
  ProductionRequestListSlice: null,
  RequestSlice: null,
  ActiveMerchantsListSlice: null,
  ActiveMerchantSlice: null,
  AllActiveMerchantsListSlice: null,
} as InitialStateInterface

export const getProductionRequestWithStatus = createAsyncThunk(
  'getProductionRequestWithStatus',
  async (req: any) => {
    try {
      return await ProductionRequestService.getProductionRequestWithStatusService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

export const exportProductionRequestWithStatus = createAsyncThunk(
  'exportProductionRequestWithStatus',
  async (req: any) => {
    try {
      return await ProductionRequestService.exportProductionRequestWithStatusService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)


export const postProductionRequest = createAsyncThunk(
  'postProductionRequest',
  async (req: any, thunkAPI) => {
    try {
      return await ProductionRequestService.postProductionRequestService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

export const putProductionRequest = createAsyncThunk(
  'putProductionRequest',
  async (req: any, thunkAPI) => {
    try {
      return await ProductionRequestService.putProductionRequestService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

export const deleteProductionRequestWithId = createAsyncThunk(
  'deleteProductionRequest',
  async (req: any, thunkAPI) => {
    try {
      return await ProductionRequestService.deleteProductionRequestService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

export const getProductionRequestWithId = createAsyncThunk(
  'getProductionRequestWithId',
  async (req: any) => {
    try {
      return await ProductionRequestService.getProductionRequestWithIdService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

export const getActiveMerchants = createAsyncThunk('getActiveMerchants', async (req: any) => {
  try {
    return await ProductionRequestService.getActiveMerchantsService(req).then(
      (response) => response
    )
  } catch (error) {}
})

export const exportActiveMerchants = createAsyncThunk('exportActiveMerchants', async (req: any) => {
  try {
    return await ProductionRequestService.exportActiveMerchants(req).then(
      (response) => response
    )
  } catch (error) {}
})

export const getActiveMerchantsWithId = createAsyncThunk(
  'getActiveMerchantsWithId',
  async (req: any) => {
    try {
      return await ProductionRequestService.getActiveMerchantWithIdService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

export const postSendEmailToAdmin = createAsyncThunk(
  'postSendEmailToAdmin',
  async (req: any, thunkAPI) => {
    try {
      return await ProductionRequestService.postProductionRequestSendEmailToAdmin(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

export const postSendEmailDirector = createAsyncThunk(
  'postSendEmailDirector',
  async (req: any, thunkAPI) => {
    try {
      return await ProductionRequestService.postSendEmailDirectorService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

export const putPartner = createAsyncThunk('putPartner', async (req: any, thunkAPI) => {
  try {
    return await ProductionRequestService.putPartnerService(req).then((response) => response)
  } catch (error) {}
})

const ProductionRequestSlice = createSlice({
  name: 'ProductionRequestSlice',
  initialState,
  reducers: {
    clearItems: (state, action) => {
      state.RequestSlice = null
      state.ActiveMerchantSlice = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductionRequestWithStatus.fulfilled, (state, action) => {
      state.ProductionRequestListSlice = action.payload?.result
        ? action.payload.result?.production_request_list
        : null
    })
    builder.addCase(getProductionRequestWithId.fulfilled, (state, action) => {
      state.RequestSlice = action.payload?.result ? action.payload.result : []
    })
    builder.addCase(getActiveMerchants.fulfilled, (state, action) => {
      if (action.payload.result.merchant_list.page_size > 0) {
        state.ActiveMerchantsListSlice = action.payload?.result
          ? action.payload.result?.merchant_list
          : null
      } else {
        state.AllActiveMerchantsListSlice = action.payload?.result
          ? action.payload.result?.merchant_list
          : null
      }
    })
    builder.addCase(getActiveMerchantsWithId.fulfilled, (state, action) => {
      state.ActiveMerchantSlice = action.payload?.result ? action.payload.result : []
    })
  },
})

export default ProductionRequestSlice
