/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { SchemaSubCategory } from '../../../model/CredentialModule'
import { getAttributeFormat } from '../../../slices/credentialManagement/attributeFormat/AttributeFormatSlice'
import credentialsSlice from '../../../slices/credentialManagement/credentials/CredentialsSlice'
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../store/store'
import { CredentialSchemaSubCategoryWrapper } from './CredentialSchemaSubCategory'

interface Props {
}
// This data needs to be pulled by the API
const AttributeFormatsEnumData = [
    {
        value: 0,
        label: "Decimal"
    },
    {
        value: 1,
        label: "Boolean"
    },
    {
        value: 2,
        label: "Int"
    },
    {
        value: 3,
        label: "Selection"
    },
    {
        value: 4,
        label: "Date"
    },
    {
        value: 5,
        label: "Percentage"
    },
    {
        value: 6,
        label: "Merchant Label Pair"
    },
]

const CredentialSchemaDetail: FC<Props> = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const { TempCredentialCategortList } = useAppSelector((state) => state.credentials);
    const { AttributeFormatListSlice } = useAppSelector((state) => state.attributeFormat);
    const [AttributeFormatList, setAttributeFormatList] = useState<any>()
    const [credentialSub, setCredentialSub] = useState<any>()

    useEffect(() => {
        dispatch(getAttributeFormat({page_index:0, page_size: 0}));
    }, [])

    useEffect(() => {
        let tempArray = AttributeFormatListSlice?.items?.map((x: any) => ({ value: x?.id, index: AttributeFormatsEnumData.find(y => y.value === x.type)?.value, label: AttributeFormatsEnumData.find(y => y.value === x.type)?.label }))
        setAttributeFormatList(tempArray)
    }, [AttributeFormatListSlice])

    useEffect(() => {
        if(TempCredentialCategortList?.length > 0){
            let credentialSub = [...TempCredentialCategortList];
            let index = credentialSub?.findIndex((s:any) => s.is_main_category);
            credentialSub?.splice(0, 0, credentialSub?.splice(index, 1)[0]);
            setCredentialSub(credentialSub);
        }
  
    }, [TempCredentialCategortList])

   

    function addNewSubCategory() {

        let newSchemaSubCategory: SchemaSubCategory = {
            icon: '',
            id: generateUUID(),
            name: '',
            open_banking_categories: [],
            schema: '',
            schema_attributes: [],
            schema_id: '',
            is_main_category: false
        }
        if(TempCredentialCategortList?.length === 0){
            newSchemaSubCategory.name = 'Category Total';
            newSchemaSubCategory.is_main_category = true;
        }
        dispatch(credentialsSlice.actions.addCredentialSubCategoryList({ sub: newSchemaSubCategory }))
    }

    function generateUUID() { // Public Domain/MIT
        let d = new Date().getTime();//Timestamp
        let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const array = new Uint32Array(1);
            window.crypto.getRandomValues(array)
            let r = array[0] * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    return (
        <>
            <div>
                <div className='row mb-4'>
                    <div className='col-10'>
                        <h3 style={{ marginTop: "9px" }}>Schema Sub Categories</h3>
                    </div>
                    <div className='col-2'>
                        <button type='button' className='btn btn-primary'
                            style={{ float: "right" }}
                            onClick={() => addNewSubCategory()}>
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                            {`Add Sub Category`}
                        </button>
                    </div>
                </div>
                <div>
                    <div className="accordion" id="accordionSubCategory">
                        {
                            credentialSub?.map((item: any, index: number) => {
                                return (
                                    <>
                                        <CredentialSchemaSubCategoryWrapper SchemaSubCategory={item} index={index} key={item.id} AttributeFormatList={AttributeFormatList}></CredentialSchemaSubCategoryWrapper>
                                    </>
                                )
                            }
                            )
                        }
                    </div>
                </div>

            </div>
        </>
    )
}

export default CredentialSchemaDetail