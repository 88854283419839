/* eslint-disable no-template-curly-in-string */

import { callApi, HttpMethods } from "../../../utils/api-util";

const getBrandAssetService = async () => {
    let _options = {
        controller: "/brand-asset",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}

const postBrandAssetService = async (req: any) => {
    let _options = {
        controller: "/brand-asset",
        method: HttpMethods.POST,
        data: req,
        contentType: 'multipart/form-data; boundary=${form._boundary}'
    };
    return await callApi(_options);
};

const putBrandAssetService = async (req: any) => {
    let _options = {
        controller: "/brand-asset",
        method: HttpMethods.PUT,
        data: req,
        contentType: 'multipart/form-data; boundary=${form._boundary}'
    };
    return await callApi(_options);
};

const BrandAssetService = {
    getBrandAssetService,
    postBrandAssetService,
    putBrandAssetService,
};

export default BrandAssetService;
