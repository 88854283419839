/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import componentsSlice from '../../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../../store/store'
import { WelcomeScreenListWrapper } from './WelcomeScreenList'
import { getWelcomeScreen } from '../../../../slices/assetManagement/sdk/welcomeScreen/WelcomeScreenSlice'

const WelcomeScreenBreadcrumbs: Array<PageLink> = [
    {
        title: `Asset Management`,
        path: '/WelcomeScreen/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const WelcomeScreen = () => {
    const dispatch: AppDispatch = useDispatch();

    const { WelcomeScreenListSlice } = useAppSelector(
        (state) => state.welcomeScreen
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getWelcomeScreen({ page_index: 0, page_size: 10, filters: [], sort_filters: [] }));
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={WelcomeScreenBreadcrumbs}>{`Welcome Screen`}</PageTitle>
                            <WelcomeScreenListWrapper welcomeScreenList={WelcomeScreenListSlice} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default WelcomeScreen
