import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: window.__RUNTIME_CONFIG__.REACT_APP_SSO_CLIENTID, // This is the ONLY mandatory field that you need to supply.
        authority: `https://login.microsoftonline.com/${window.__RUNTIME_CONFIG__.REACT_APP_SSO_TENANTID}`, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: "/auth", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: "/auth", // Indicates the page to navigate after logout.
        clientCapabilities: ["CP1"] // this lets the resource owner know that this client is capable of handling claims challenge.
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

export const loginRequest = {
    scopes: ["offline_access", "openid", "email", "profile", `api://${window.__RUNTIME_CONFIG__.REACT_APP_SSO_CLIENTID}/email`]
};