import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";
import "react-phone-input-2/lib/style.css";

interface Props {
    height?: number | string;
    series: ApexOptions['series'];
    options: ApexOptions;
    type?: string;
}

const CustomChart = React.forwardRef<HTMLInputElement, Props>(
    (
        {
            series,
            options,
            type,
            height,
        },
    ) => {
        return (
            <>
                {type === "bar" ?
                    <ReactApexChart options={options} series={series} type="bar" height={height} width={"100%"} /> :
                    type === "line" ?
                        <ReactApexChart options={options} series={series} type="line" height={height} width={"100%"} /> :
                        type === "pie" ?
                            <ReactApexChart options={options} series={series} type="pie" height={height} width={"100%"} /> :
                            <ReactApexChart options={options} series={series} type="bar" height={height} width={"100%"} />
                }
            </>
        );
    }
);

CustomChart.defaultProps = {
    height: "350px",
    type: "bar",
};

export default CustomChart;
