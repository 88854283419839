/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {FC, useEffect, useMemo, useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import ReactQuill from 'react-quill'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import Styles from '../../../../_metronic/assets/sass/pages/videoList.module.scss'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import {showToast} from '../../../components/custom-toasts/custom-toasts'
import CustomDatatable from '../../../components/customDatatable/CustomDatatable'
import CustomFileUpload from '../../../components/customFileUpload/CustomImageUpload'
import {CustomModal} from '../../../components/customModal/CustomModal'
import {ListViewProvider} from '../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {QueryRequestProvider} from '../../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import componentsSlice from '../../../slices/components/componentsSlice'
import {
  deleteProductFBWithId,
  deleteProductTSWithId,
  deleteProductVideoWithId,
  deleteProductWithId,
  exportProductList,
  getProduct,
  getProductWithId,
  postProduct,
  postProductFB,
  postProductTs,
  postProductVideo,
} from '../../../slices/salesAssistant/product/productSlice'
import {AppDispatch, useAppSelector} from '../../../store/store'
import {uploadPicture} from '../../../utils/upload-image-file'
import CustomRichTextbox from '../../../components/customTextbox/CustomRichTextbox'
import CustomTextbox from '../../../components/customTextbox/CustomTextbox'

interface Props {
  ProductsListSlice: any
}

const ProductsList: FC<Props> = ({ProductsListSlice}) => {
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const {modalIsOpen} = useAppSelector((state) => state.components)
  const dispatch: AppDispatch = useDispatch()
  const [processName, setprocessName] = useState('Insert')
  const [selectedRowId, setSelectedRowId] = useState(undefined)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [filterQuery, setfilterQuery] = useState<any>([])
  const [sortData, setsortData] = useState<any>([])
  const [filterParams, setfilterParams] = useState<any>([])
  const [selectedProduct, setSelectedProduct] = useState<any>()
  const [merchantLogo, setMerchantLogo] = useState('')
  const {urlAfterImageUpload} = useAppSelector((state) => state.utils)
  const [isFBModalOpen, setFBModalOpen] = useState(false)
  const [isVideoModalOpen, setVideoModalOpen] = useState(false)
  const [isTSModalOpen, setTSModalOpen] = useState(false)

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      code: '',
      description: '',
      userGuide: '',
      merchantLogoFormik: '',
      fpCheck: false,
      partnerCheck: false,
      merchantCheck: false,
      consumerCheck: false,
      bankMerchantCheck: false,
      bankRetailCheck: false,
      mobileCheck: false,
      isComingSoon: false,
      showBWDesignTool: false,
      showEPDesignTool: false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(1, 'Minimum 1 symbols').required('Name is required'),
      code: Yup.string().min(1, 'Minimum 1 symbols').required('Code is required'),
      merchantLogoFormik: Yup.string().min(1, 'Minimum 1 symbols').required('Icon URL is required'),
      description: Yup.string().min(1, 'Minimum 1 symbols').required('Description is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        createWithRequest(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm()
      }
    },
  })

  const formikFB = useFormik({
    initialValues: {
      title: '',
      feature: '',
      benefit: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().min(1, 'Minimum 1 symbols').required('Title is required'),
      feature: Yup.string().min(1, 'Minimum 1 symbols').required('Feature is required'),
      benefit: Yup.string().min(1, 'Minimum 1 symbols').required('Benefit is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        createWithRequestFB(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        setFBModalOpen(false)
        //dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm()
      }
    },
  })

  const formikVideo = useFormik({
    initialValues: {
      title: '',
      url: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().min(1, 'Minimum 1 symbols').required('Title is required'),
      url: Yup.string().min(1, 'Minimum 1 symbols').required('URL is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        createWithRequestVideo(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        setVideoModalOpen(false)
        //dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm()
      }
    },
  })

  const formikTS = useFormik({
    initialValues: {
      question: '',
      solution: '',
    },
    validationSchema: Yup.object().shape({
      question: Yup.string().min(1, 'Minimum 1 symbols').required('Question is required'),
      solution: Yup.string().min(1, 'Minimum 1 symbols').required('Solution is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        createWithRequestTS(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        //dispatch(componentsSlice.actions.closeModal({}))
        setTSModalOpen(false)
        actions.resetForm()
      }
    },
  })

  useEffect(() => {
    setdataTableisBusy(false)
  }, [ProductsList])

  useEffect(() => {
    setMerchantLogo('')
    if (urlAfterImageUpload !== undefined && urlAfterImageUpload !== '') {
      setMerchantLogo(urlAfterImageUpload)
    }
  }, [urlAfterImageUpload])

  useEffect(() => {
    if (modalIsOpen === undefined) {
      setprocessName('Insert')
      setMerchantLogo('')
      setSelectedRowId(undefined)
      formik.resetForm()
    }
  }, [modalIsOpen])

  //#region DataTable Methods

  const quillRef = React.useRef<ReactQuill>(null)
  let modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{header: [1, 2, false]}, 'bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}, {align: []}],
          [{color: []}, {background: []}, 'link'],
        ],
      },
    }),
    []
  )

  const columns = useMemo(
    () => [
      {
        name: 'Code',
        width: '10%',
        selector: (row: any) => (
          <div className='text-overflow' dangerouslySetInnerHTML={{__html: row?.code}} />
        ),
      },
      {
        name: 'Title',
        selector: (row: any) => <div>{row?.name}</div>,
        width: '15%',
      },
      {
        name: 'Description',
        selector: (row: any) => (
          <div className='text-overflow' dangerouslySetInnerHTML={{__html: row?.description}} />
        ),
      },
      {
        name: 'Coming Soon',
        selector: (row: any) =>
          row?.is_coming_soon ? (
            <span className='badge badge-light-success fs-5 fw-semibold'>Yes</span>
          ) : (
            <span className='badge badge-light-danger fs-5 fw-semibold'>No</span>
          ),
        width: '10%',
      },
      {
        name: 'Action',
        selector: (row: any) => (
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {
                <>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Insert')
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                      ProductWithId(row.id, 'Insert')
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </div>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Delete')
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </div>
                </>
              }
            </div>
          </>
        ),
        width: '100px',
      },
    ],
    []
  )

  const columnsFB = [
    {
      name: 'Title',
      selector: (row: any) => <div>{row?.title}</div>,
      width: '25%',
    },
    {
      name: 'Feature',
      selector: (row: any) => <div>{row?.feature}</div>,
      width: '30%',
    },
    {
      name: 'Benefit',
      selector: (row: any) => <div className='text-overflow'>{row?.benefit}</div>,
      width: '30%',
    },
    {
      name: 'Action',
      selector: (row: any) => (
        <>
          <div className='d-flex justify-content-end flex-shrink-0'>
            {
              <>
                <div
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  onClick={() => {
                    deleteWithIdFB(row.id)
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                </div>
              </>
            }
          </div>
        </>
      ),
      width: '13%',
    },
  ]

  const columnsVideo = [
    {
      name: 'Title',
      selector: (row: any) => <div>{row?.title}</div>,
      width: 'auto',
    },
    {
      name: 'URL',
      selector: (row: any) => (
        <div>
          <a href='' target='_blank' rel='noopener noreferrer'>
            {row?.url}
          </a>
        </div>
      ),
      width: '300px',
    },
    {
      name: 'Action',
      selector: (row: any) => (
        <>
          <div className='d-flex justify-content-end flex-shrink-0'>
            {
              <>
                <div
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  onClick={() => {
                    //setprocessName('Delete')
                    //setSelectedRowId(row.id)
                    //dispatch(componentsSlice.actions.openModal({}))
                    deleteWithIdVideo(row.id)
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                </div>
              </>
            }
          </div>
        </>
      ),
      width: '100px',
    },
  ]

  const columnsTech = [
    {
      name: 'Question',
      selector: (row: any) => <div>{row?.question}</div>,
      width: 'Auto',
    },
    {
      name: 'Solution',
      selector: (row: any) => <div>{row?.solution}</div>,
      width: '300px',
    },
    {
      name: 'Action',
      selector: (row: any) => (
        <>
          <div className='d-flex justify-content-end flex-shrink-0'>
            {
              <>
                <div
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  onClick={() => {
                    //setprocessName('Delete')
                    //setSelectedRowId(row.id)
                    //dispatch(componentsSlice.actions.openModal({}))
                    deleteWithIdTS(row.id)
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                </div>
              </>
            }
          </div>
        </>
      ),
      width: '100px',
    },
  ]

  const handlePageChange = (page: any) => {
    setdataTableisBusy(true)
    setPage(page - 1)
    setdataTableisBusy(false)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setdataTableisBusy(true)
    setPerPage(newPerPage)
    setdataTableisBusy(false)
  }
  const handleSort = async (column: any, sortDirection: any) => {
    setsortData([{property: column?.name, direction: sortDirection === 'asc' ? 0 : 1}])
  }
  useEffect(() => {
    dispatch(
      getProduct({
        page_index: page,
        page_size: perPage,
        filters: filterParams,
        sort_filters: sortData,
      })
    )
  }, [page, perPage, sortData, filterParams])

  //#endregion DataTable Methods End

  function ProductWithId(rowId: any, process: string) {
    if (rowId !== undefined) {
      dispatch(getProductWithId(rowId)).then((s: any) => {
        formik.setValues({
          id: s?.payload?.result?.product?.id,
          code: s?.payload?.result?.product?.code,
          name: s?.payload?.result?.product?.name,
          description: s?.payload?.result?.product?.description,
          userGuide: s?.payload?.result?.product?.user_guide,
          merchantLogoFormik: s?.payload?.result?.product?.icon_url,
          fpCheck: s?.payload?.result?.product?.party?.includes(0) ? true : false,
          partnerCheck: s?.payload?.result?.product?.party?.includes(1) ? true : false,
          merchantCheck: s?.payload?.result?.product?.party?.includes(2) ? true : false,
          consumerCheck: s?.payload?.result?.product?.party?.includes(3) ? true : false,
          bankMerchantCheck: s?.payload?.result?.product?.party?.includes(4) ? true : false,
          bankRetailCheck: s?.payload?.result?.product?.party?.includes(5) ? true : false,
          mobileCheck: s?.payload?.result?.product?.party?.includes(6) ? true : false,
          isComingSoon: s?.payload?.result?.product?.is_coming_soon,
          showBWDesignTool: s?.payload?.result?.product?.show_bw_design_tool,
          showEPDesignTool: s?.payload?.result?.product?.show_ep_design_tool,
        })
        setSelectedProduct(s?.payload?.result?.product)
        setMerchantLogo(s?.payload?.result?.product?.icon_url)
      })
    }
  }

  function deleteWithId() {
    if (selectedRowId !== undefined) {
      dispatch(deleteProductWithId(selectedRowId)).then((s: any) => {
        dispatch(
          getProduct({
            page_index: page,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        if (s?.payload?.is_success) {
          showToast.success('Item Deleted Successfully')
        } else {
          showToast.error(s?.payload?.response_exception?.exception_message)
        }
        dispatch(componentsSlice.actions.closeModal({}))
      })
    }
  }

  function deleteWithIdFB(id: string) {
    dispatch(deleteProductFBWithId(id)).then((s: any) => {
      if (s?.payload?.result.response) {
        dispatch(getProductWithId(selectedProduct.id)).then((s: any) => {
          setSelectedProduct(s?.payload?.result?.product)
          formik.setValues({
            id: s?.payload?.result?.product?.id,
            code: s?.payload?.result?.product?.code,
            name: s?.payload?.result?.product?.name,
            description: s?.payload?.result?.product?.description,
            userGuide: s?.payload?.result?.product?.user_guide,
            merchantLogoFormik: s?.payload?.result?.product?.icon_url,
            fpCheck: s?.payload?.result?.product?.party?.includes(0) ? true : false,
            partnerCheck: s?.payload?.result?.product?.party?.includes(1) ? true : false,
            merchantCheck: s?.payload?.result?.product?.party?.includes(2) ? true : false,
            consumerCheck: s?.payload?.result?.product?.party?.includes(3) ? true : false,
            bankMerchantCheck: s?.payload?.result?.product?.party?.includes(4) ? true : false,
            bankRetailCheck: s?.payload?.result?.product?.party?.includes(5) ? true : false,
            mobileCheck: s?.payload?.result?.product?.party?.includes(6) ? true : false,
            isComingSoon: s?.payload?.result?.product?.is_coming_soon,
            showBWDesignTool: s?.payload?.result?.product?.show_bw_design_tool,
            showEPDesignTool: s?.payload?.result?.product?.show_ep_design_tool,
          })
        })
        setprocessName('Insert')
        setSelectedRowId(selectedProduct.id)
        dispatch(componentsSlice.actions.openModal({}))
        showToast.success('Item Deleted Successfully')
      } else {
        showToast.error(s?.payload?.response_exception?.exception_message)
      }
    })
  }

  function deleteWithIdVideo(id: string) {
    dispatch(deleteProductVideoWithId(id)).then((s: any) => {
      if (s?.payload?.result.response) {
        dispatch(getProductWithId(selectedProduct.id)).then((s: any) => {
          setSelectedProduct(s?.payload?.result?.product)
          formik.setValues({
            id: s?.payload?.result?.product?.id,
            code: s?.payload?.result?.product?.code,
            name: s?.payload?.result?.product?.name,
            description: s?.payload?.result?.product?.description,
            userGuide: s?.payload?.result?.product?.user_guide,
            merchantLogoFormik: s?.payload?.result?.product?.icon_url,
            fpCheck: s?.payload?.result?.product?.party?.includes(0) ? true : false,
            partnerCheck: s?.payload?.result?.product?.party?.includes(1) ? true : false,
            merchantCheck: s?.payload?.result?.product?.party?.includes(2) ? true : false,
            consumerCheck: s?.payload?.result?.product?.party?.includes(3) ? true : false,
            bankMerchantCheck: s?.payload?.result?.product?.party?.includes(4) ? true : false,
            bankRetailCheck: s?.payload?.result?.product?.party?.includes(5) ? true : false,
            mobileCheck: s?.payload?.result?.product?.party?.includes(6) ? true : false,
            isComingSoon: s?.payload?.result?.product?.is_coming_soon,
            showBWDesignTool: s?.payload?.result?.product?.show_bw_design_tool,
            showEPDesignTool: s?.payload?.result?.product?.show_ep_design_tool,
          })
        })
        setprocessName('Insert')
        setSelectedRowId(selectedProduct.id)
        dispatch(componentsSlice.actions.openModal({}))
        showToast.success('Item Deleted Successfully')
      } else {
        showToast.error(s?.payload?.response_exception?.exception_message)
      }
    })
  }

  function deleteWithIdTS(id: string) {
    dispatch(deleteProductTSWithId(id)).then((s: any) => {
      if (s?.payload?.result.response) {
        dispatch(getProductWithId(selectedProduct.id)).then((s: any) => {
          setSelectedProduct(s?.payload?.result?.product)
          formik.setValues({
            id: s?.payload?.result?.product?.id,
            code: s?.payload?.result?.product?.code,
            name: s?.payload?.result?.product?.name,
            description: s?.payload?.result?.product?.description,
            userGuide: s?.payload?.result?.product?.user_guide,
            merchantLogoFormik: s?.payload?.result?.product?.icon_url,
            fpCheck: s?.payload?.result?.product?.party?.includes(0) ? true : false,
            partnerCheck: s?.payload?.result?.product?.party?.includes(1) ? true : false,
            merchantCheck: s?.payload?.result?.product?.party?.includes(2) ? true : false,
            consumerCheck: s?.payload?.result?.product?.party?.includes(3) ? true : false,
            bankMerchantCheck: s?.payload?.result?.product?.party?.includes(4) ? true : false,
            bankRetailCheck: s?.payload?.result?.product?.party?.includes(5) ? true : false,
            mobileCheck: s?.payload?.result?.product?.party?.includes(6) ? true : false,
            isComingSoon: s?.payload?.result?.product?.is_coming_soon,
            showBWDesignTool: s?.payload?.result?.product?.show_bw_design_tool,
            showEPDesignTool: s?.payload?.result?.product?.show_ep_design_tool,
          })
        })
        setprocessName('Insert')
        setSelectedRowId(selectedProduct.id)
        dispatch(componentsSlice.actions.openModal({}))
        showToast.success('Item Deleted Successfully')
      } else {
        showToast.error(s?.payload?.response_exception?.exception_message)
      }
    })
  }

  function createWithRequest(request: any) {
    const partyMapping: {[key: string]: number} = {
      fpCheck: 0,
      partnerCheck: 1,
      merchantCheck: 2,
      consumerCheck: 3,
      bankMerchantCheck: 4,
      bankRetailCheck: 5,
      mobileCheck: 6,
    }

    const partiesFromRequest = Object.keys(partyMapping)
      .filter((check) => request[check])
      .map((check) => partyMapping[check])

    let req = {
      product: {
        id: request.id !== null ? request.id : undefined,
        code: request.code,
        name: request.name,
        icon_url: merchantLogo,
        description: request.description,
        user_guide: request.userGuide,
        party: partiesFromRequest,
        is_coming_soon: request.isComingSoon,
        show_bw_design_tool: request.showBWDesignTool,
        show_ep_design_tool: request.showEPDesignTool,
      },
    }

    dispatch(postProduct(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(
          getProduct({
            page_index: 0,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        showToast.success('Item Saved Successfully')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function createWithRequestFB(request: any) {
    let req = {
      product_fb: {
        product_id: selectedProduct.id,
        title: request.title,
        feature: request.feature,
        benefit: request.benefit,
      },
    }
    dispatch(postProductFB(req)).then((response) => {
      if (response?.payload?.is_success) {
        //dispatch(componentsSlice.actions.closeModal({}))
        setFBModalOpen(false)
        dispatch(getProductWithId(selectedProduct.id)).then((s: any) => {
          setSelectedProduct(s?.payload?.result?.product)
          formik.setValues({
            id: s?.payload?.result?.product?.id,
            code: s?.payload?.result?.product?.code,
            name: s?.payload?.result?.product?.name,
            description: s?.payload?.result?.product?.description,
            userGuide: s?.payload?.result?.product?.user_guide,
            merchantLogoFormik: s?.payload?.result?.product?.icon_url,
            fpCheck: s?.payload?.result?.product?.party?.includes(0) ? true : false,
            partnerCheck: s?.payload?.result?.product?.party?.includes(1) ? true : false,
            merchantCheck: s?.payload?.result?.product?.party?.includes(2) ? true : false,
            consumerCheck: s?.payload?.result?.product?.party?.includes(3) ? true : false,
            bankMerchantCheck: s?.payload?.result?.product?.party?.includes(4) ? true : false,
            bankRetailCheck: s?.payload?.result?.product?.party?.includes(5) ? true : false,
            mobileCheck: s?.payload?.result?.product?.party?.includes(6) ? true : false,
            isComingSoon: s?.payload?.result?.product?.is_coming_soon,
            showBWDesignTool: s?.payload?.result?.product?.show_bw_design_tool,
            showEPDesignTool: s?.payload?.result?.product?.show_ep_design_tool,
          })
        })
        showToast.success('Item Saved Successfully')
        setprocessName('Insert')
        setSelectedRowId(selectedProduct.id)
        dispatch(componentsSlice.actions.openModal({}))
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function createWithRequestVideo(request: any) {
    let req = {
      product_video: {
        product_id: selectedProduct.id,
        title: request.title,
        url: request.url,
      },
    }
    dispatch(postProductVideo(req)).then((response) => {
      if (response?.payload?.is_success) {
        //dispatch(componentsSlice.actions.closeModal({}))
        setVideoModalOpen(false)
        dispatch(getProductWithId(selectedProduct.id)).then((s: any) => {
          setSelectedProduct(s?.payload?.result?.product)
          formik.setValues({
            id: s?.payload?.result?.product?.id,
            code: s?.payload?.result?.product?.code,
            name: s?.payload?.result?.product?.name,
            description: s?.payload?.result?.product?.description,
            userGuide: s?.payload?.result?.product?.user_guide,
            merchantLogoFormik: s?.payload?.result?.product?.icon_url,
            fpCheck: s?.payload?.result?.product?.party?.includes(0) ? true : false,
            partnerCheck: s?.payload?.result?.product?.party?.includes(1) ? true : false,
            merchantCheck: s?.payload?.result?.product?.party?.includes(2) ? true : false,
            consumerCheck: s?.payload?.result?.product?.party?.includes(3) ? true : false,
            bankMerchantCheck: s?.payload?.result?.product?.party?.includes(4) ? true : false,
            bankRetailCheck: s?.payload?.result?.product?.party?.includes(5) ? true : false,
            mobileCheck: s?.payload?.result?.product?.party?.includes(6) ? true : false,
            isComingSoon: s?.payload?.result?.product?.is_coming_soon,
            showBWDesignTool: s?.payload?.result?.product?.show_bw_design_tool,
            showEPDesignTool: s?.payload?.result?.product?.show_ep_design_tool,
          })
        })
        showToast.success('Item Saved Successfully')
        setprocessName('Insert')
        setSelectedRowId(selectedProduct.id)
        dispatch(componentsSlice.actions.openModal({}))
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function createWithRequestTS(request: any) {
    let req = {
      product_ts: {
        product_id: selectedProduct.id,
        question: request.question,
        solution: request.solution,
      },
    }
    dispatch(postProductTs(req)).then((response) => {
      if (response?.payload?.is_success) {
        //dispatch(componentsSlice.actions.closeModal({}))
        setTSModalOpen(false)
        dispatch(getProductWithId(selectedProduct.id)).then((s: any) => {
          setSelectedProduct(s?.payload?.result?.product)
          formik.setValues({
            id: s?.payload?.result?.product?.id,
            code: s?.payload?.result?.product?.code,
            name: s?.payload?.result?.product?.name,
            description: s?.payload?.result?.product?.description,
            userGuide: s?.payload?.result?.product?.user_guide,
            merchantLogoFormik: s?.payload?.result?.product?.icon_url,
            fpCheck: s?.payload?.result?.product?.party?.includes(0) ? true : false,
            partnerCheck: s?.payload?.result?.product?.party?.includes(1) ? true : false,
            merchantCheck: s?.payload?.result?.product?.party?.includes(2) ? true : false,
            consumerCheck: s?.payload?.result?.product?.party?.includes(3) ? true : false,
            bankMerchantCheck: s?.payload?.result?.product?.party?.includes(4) ? true : false,
            bankRetailCheck: s?.payload?.result?.product?.party?.includes(5) ? true : false,
            mobileCheck: s?.payload?.result?.product?.party?.includes(6) ? true : false,
            isComingSoon: s?.payload?.result?.product?.is_coming_soon,
            showBWDesignTool: s?.payload?.result?.product?.show_bw_design_tool,
            showEPDesignTool: s?.payload?.result?.product?.show_ep_design_tool,
          })
        })
        showToast.success('Item Saved Successfully')
        setprocessName('Insert')
        setSelectedRowId(selectedProduct.id)
        dispatch(componentsSlice.actions.openModal({}))
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function postPicture(file: File) {
    dispatch(uploadPicture(file)).then((s: any) => {
      setMerchantLogo(s?.payload?.result?.url)
      // Upload Picture for sat change
    })
  }

  function findProcessScreen(processName: string) {
    switch (processName) {
      case 'Insert':
        return (
          <>
            <CustomModal
              maxWidth={1100}
              title={selectedRowId !== undefined ? 'Update' : 'New Item'}
            >
              <>
                <Tabs defaultActiveKey='merchant' id='tab' className='mb-3'>
                  <Tab eventKey='merchant' title='Product General'>
                    <form
                      id='kt_modal_add_user_form'
                      className='form'
                      onSubmit={formik.handleSubmit}
                    >
                      <div className='mt-2'>
                        <div className='d-flex flex-stack'>
                          <div className={`list-group-item list-group-item-action my-1`}>
                            <div className='d-flex'>
                              <div className='d-flex flex-column col-12'>
                                <label className='required fw-bold fs-6 mb-2'>Product Code</label>
                                <CustomTextbox
                                {...formik.getFieldProps('code')}
                                onChange={() => null}
                                placeHolder={'Type a Code'}
                                name='code'
                                value={formik.values.code}
                                formik={formik}
                                formikName={'code'}
                                ></CustomTextbox>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='separator separator-dashed my-2'></div>
                      </div>
                      <div className='mt-2'>
                        <div className='d-flex flex-stack'>
                          <div className={`list-group-item list-group-item-action my-1`}>
                            <div className='d-flex'>
                              <div className='d-flex flex-column col-12'>
                                <label className='required fw-bold fs-6 mb-2'>Product Name</label>
                                <CustomTextbox
                                {...formik.getFieldProps('name')}
                                onChange={() => null}
                                placeHolder={'Type a Name'}
                                name='name'
                                value={formik.values.name}
                                formik={formik}
                                formikName={'name'}
                                ></CustomTextbox>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='separator separator-dashed my-2'></div>
                      </div>
                      <div className='mt-2'>
                        <div className='d-flex flex-stack'>
                          <div className={`list-group-item list-group-item-action my-1`}>
                            <div className='d-flex'>
                              <div className='d-flex flex-column col-12'>
                                <label className='required fw-bold fs-6 mb-2'>Description</label>
                                <CustomRichTextbox
                                  {...formik.getFieldProps('description')}
                                  onChange={() => null}
                                  placeHolder={'Type a description'}
                                  name='description'
                                  value={formik.values.description}
                                  formik={formik}
                                  formikName={'description'}
                                  height={'23vh'}
                                ></CustomRichTextbox>
                                {formik.touched.description && formik.errors.description && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{formik.errors.description}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='separator separator-dashed mt-20'></div>
                      </div>
                      <div className='mt-2'>
                        <div className='d-flex flex-stack'>
                          <div className={`list-group-item list-group-item-action my-1`}>
                            <div className='d-flex'>
                              <div className='d-flex flex-column col-12'>
                                <label className='required fw-bold fs-6 mb-2'>
                                  Product Icon URL
                                </label>
                                <CustomFileUpload
                                  uploadFile={(file: FileList) => {
                                    postPicture(file?.[0])
                                  }}
                                  imageString={merchantLogo}
                                  formik={formik}
                                  formikName={'merchantLogoFormik'}
                                ></CustomFileUpload>
                                {formik.touched.merchantLogoFormik &&
                                  formik.errors.merchantLogoFormik && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.merchantLogoFormik}</span>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='separator separator-dashed my-2'></div>
                      </div>
                      <div className='mt-2'>
                        <div className='d-flex text-dark fw-bold fs-6 '>{`Associated Parties`}</div>
                        <div className='fs-6 fw-bold text-gray-400 row d-flex justify-content-between mt-2'>
                          <div className='form-check form-check-solid col-4 d-flex justify-content-start'>
                            <input
                              {...formik.getFieldProps('partnerCheck')}
                              name='partnerCheck'
                              type='checkbox'
                              id='partnerCheck'
                              className='form-check-input'
                              value=''
                              defaultChecked={formik.values.partnerCheck}
                              checked={formik.values.partnerCheck}
                              onChange={(event) =>
                                formik?.setValues({
                                  ...formik?.values,
                                  partnerCheck: event.target.checked,
                                })
                              }
                            />
                            <label className='form-check-label mx-2' htmlFor='partnerCheck'>
                              Partner
                            </label>
                          </div>
                          <div className='form-check form-check-solid col-4 d-flex justify-content-start'>
                            <input
                              {...formik.getFieldProps('merchantCheck')}
                              name='merchantCheck'
                              type='checkbox'
                              id='merchantCheck'
                              className='form-check-input'
                              value=''
                              defaultChecked={formik.values.merchantCheck}
                              checked={formik.values.merchantCheck}
                              onChange={(event) =>
                                formik?.setValues({
                                  ...formik?.values,
                                  merchantCheck: event.target.checked,
                                })
                              }
                            />
                            <label className='form-check-label mx-2' htmlFor='merchantCheck'>
                              Merchant
                            </label>
                          </div>
                          <div className='form-check form-check-solid col-4 d-flex justify-content-start'>
                            <input
                              {...formik.getFieldProps('consumerCheck')}
                              name='consumerCheck'
                              type='checkbox'
                              id='consumerCheck'
                              className='form-check-input'
                              value=''
                              defaultChecked={formik.values.consumerCheck}
                              checked={formik.values.consumerCheck}
                              onChange={(event) =>
                                formik?.setValues({
                                  ...formik?.values,
                                  consumerCheck: event.target.checked,
                                })
                              }
                            />
                            <label className='form-check-label mx-2' htmlFor='consumerCheck'>
                              Consumer
                            </label>
                          </div>
                        </div>
                        <div className='fs-6 fw-bold text-gray-400 row mt-2 d-flex'>
                          <div className='form-check form-check-solid col-4 d-flex justify-content-start'>
                            <input
                              {...formik.getFieldProps('bankMerchantCheck')}
                              name='bankMerchantCheck'
                              type='checkbox'
                              id='bankMerchantCheck'
                              className='form-check-input'
                              value=''
                              defaultChecked={formik.values.bankMerchantCheck}
                              checked={formik.values.bankMerchantCheck}
                              onChange={(event) =>
                                formik?.setValues({
                                  ...formik?.values,
                                  bankMerchantCheck: event.target.checked,
                                })
                              }
                            />
                            <label className='form-check-label mx-2' htmlFor='bankCheck'>
                              Bank
                            </label>
                          </div>
                          <div className='form-check form-check-solid col-4 d-flex justify-content-start'>
                            <input
                              {...formik.getFieldProps('fpCheck')}
                              name='fpCheck'
                              type='checkbox'
                              id='fpCheck'
                              className='form-check-input'
                              value=''
                              defaultChecked={formik.values.fpCheck}
                              checked={formik.values.fpCheck}
                              onChange={(event) =>
                                formik?.setValues({
                                  ...formik?.values,
                                  fpCheck: event.target.checked,
                                })
                              }
                              //onChange={(event) => setIsPartner(event.target.checked)}
                            />
                            <label className='form-check-label mx-2' htmlFor='fpCheck'>
                              Freedompay
                            </label>
                          </div>
                        </div>
                        <div className='separator separator-dashed my-2'></div>
                      </div>
                      <div className='mt-2'>
                        <div className='d-flex'>
                          <div className={`list-group-item list-group-item-action my-1`}>
                            <div className='d-flex justify-content-between'>
                              <div className='fs-5 d-flex text-dark fw-bolder'>{`Coming Soon`}</div>
                              <div className='form-check d-flex form-switch'>
                                <input
                                  {...formik.getFieldProps('isComingSoon')}
                                  name='isComingSoon'
                                  type='checkbox'
                                  role='switch'
                                  id='isComingSoon'
                                  className='form-check-input'
                                  value=''
                                  defaultChecked={formik.values.isComingSoon}
                                  checked={formik.values.isComingSoon}
                                  onChange={(event) =>
                                    formik?.setValues({
                                      ...formik?.values,
                                      isComingSoon: event.target.checked,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='separator separator-dashed my-2'></div>
                      </div>

                      <div className='mt-2'>
                        <div className='d-flex'>
                          <div className={`list-group-item list-group-item-action my-1`}>
                            <div className='d-flex justify-content-between'>
                              <div className='fs-5 d-flex text-dark fw-bolder'>{`Show BW Design Tool`}</div>
                              <div className='form-check d-flex form-switch'>
                                <input
                                  {...formik.getFieldProps('showBWDesignTool')}
                                  name='showBWDesignTool'
                                  type='checkbox'
                                  role='switch'
                                  id='showBWDesignTool'
                                  className='form-check-input'
                                  value=''
                                  defaultChecked={formik.values.showBWDesignTool}
                                  checked={formik.values.showBWDesignTool}
                                  onChange={(event) =>
                                    formik?.setValues({
                                      ...formik?.values,
                                      showBWDesignTool: event.target.checked,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='separator separator-dashed my-2'></div>
                      </div>

                      <div className='mt-2'>
                        <div className='d-flex'>
                          <div className={`list-group-item list-group-item-action my-1`}>
                            <div className='d-flex justify-content-between'>
                              <div className='fs-5 d-flex text-dark fw-bolder'>{`Show EP Design Tool`}</div>
                              <div className='form-check d-flex form-switch'>
                                <input
                                  {...formik.getFieldProps('showEPDesignTool')}
                                  name='showEPDesignTool'
                                  type='checkbox'
                                  role='switch'
                                  id='showEPDesignTool'
                                  className='form-check-input'
                                  value=''
                                  defaultChecked={formik.values.showEPDesignTool}
                                  checked={formik.values.showEPDesignTool}
                                  onChange={(event) =>
                                    formik?.setValues({
                                      ...formik?.values,
                                      showEPDesignTool: event.target.checked,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='separator separator-dashed my-2'></div>
                      </div>

                      <div className='mt-4'>
                        <div className={`list-group-item list-group-item-action my-1`}>
                          <label className='fw-bold fs-6 mb-2'>User Guide</label>
                          <ReactQuill
                            theme='snow'
                            ref={quillRef}
                            modules={modules}
                            className='h-100px'
                            preserveWhitespace
                            value={formik?.values?.userGuide}
                            onChange={(i: any) => {
                              formik.setFieldValue('userGuide', i)
                            }}
                          />
                        </div>
                        <div className='separator separator-dashed mt-20'></div>
                      </div>
                      {/* begin::Actions */}
                      <div className='text-center pt-15'>
                        <button
                          type='reset'
                          onClick={() => {
                            dispatch(componentsSlice.actions.closeModal({}))
                          }}
                          className='btn btn-light me-3'
                          data-kt-users-modal-action='cancel'
                        >
                          Discard
                        </button>

                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-users-modal-action='submit'
                        >
                          <span className='indicator-label'>Submit Product</span>
                        </button>
                      </div>
                      {/* end::Actions */}
                    </form>
                  </Tab>
                  <Tab
                    disabled={selectedRowId !== undefined ? false : true}
                    tabClassName={selectedRowId !== undefined ? '' : 'text-muted'}
                    eventKey='partner'
                    title='Feature & Benefits'
                  >
                    <div className='fv-row mb-7'>
                      <KTCard>
                        <CustomDatatable
                          key={'FB-DT'}
                          columns={columnsFB}
                          data={selectedProduct?.feature_and_benefits}
                          title={'Feature & Benefit List'}
                          isBusy={dataTableisBusy}
                          isNewItem
                          isNewItemString={'New Item'}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          showTitle
                          showFilter={false}
                          newItemClickEvent={() => {
                            //setprocessName('FbAdd')
                            //dispatch(componentsSlice.actions.openModal({}))
                            setFBModalOpen(true)
                          }}
                        ></CustomDatatable>
                      </KTCard>
                    </div>
                  </Tab>
                  <Tab
                    disabled={selectedRowId !== undefined ? false : true}
                    tabClassName={selectedRowId !== undefined ? '' : 'text-muted'}
                    eventKey='video'
                    title='Videos'
                  >
                    <div className='fv-row mb-7'>
                      <KTCard>
                        <CustomDatatable
                          key={'Video-DT'}
                          columns={columnsVideo}
                          data={selectedProduct?.videos}
                          title={'Video Links'}
                          isBusy={dataTableisBusy}
                          isNewItem
                          isNewItemString={'New Item'}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          showTitle
                          showFilter={false}
                          newItemClickEvent={() => {
                            //setprocessName('VideoAdd')
                            setVideoModalOpen(true)
                            //dispatch(componentsSlice.actions.openModal({}))
                          }}
                        ></CustomDatatable>
                      </KTCard>
                    </div>
                  </Tab>
                  <Tab
                    disabled={selectedRowId !== undefined ? false : true}
                    tabClassName={selectedRowId !== undefined ? '' : 'text-muted'}
                    eventKey='tech'
                    title='Technical Specificiation'
                  >
                    <div className='fv-row mb-7'>
                      <KTCard>
                        <CustomDatatable
                          key={'TS-DT'}
                          columns={columnsTech}
                          data={selectedProduct?.technical_spesifications}
                          title={'Technical Specification'}
                          isBusy={dataTableisBusy}
                          isNewItem
                          isNewItemString={'New Item'}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          showTitle
                          showFilter={false}
                          newItemClickEvent={() => {
                            //setprocessName('TsAdd')
                            //dispatch(componentsSlice.actions.openModal({}))
                            setTSModalOpen(true)
                          }}
                        ></CustomDatatable>
                      </KTCard>
                    </div>
                  </Tab>
                  {/*<Tab
                    disabled={selectedRowId !== undefined ? false : true}
                    tabClassName={selectedRowId !== undefined ? '' : 'text-muted'}
                    eventKey='user'
                    title='User Guide'
                  >
                    <div className='fv-row mb-7'>
                      <div className='row mb-10'>
                        
                      </div>
                    </div>
                  </Tab>*/}
                </Tabs>
              </>
            </CustomModal>
          </>
        )

      case 'Delete':
        return (
          <>
            <CustomModal title='Are You Sure?'>
              <>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => {
                      dispatch(componentsSlice.actions.closeModal({}))
                    }}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    onClick={() => {
                      deleteWithId()
                    }}
                  >
                    <span className='indicator-label'>Delete</span>
                  </button>
                </div>
                {/* end::Actions */}
              </>
            </CustomModal>
          </>
        )
      case 'UgAdd':
        return <></>

      default:
        return null
    }
  }
  return (
    <>
      {isFBModalOpen && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
            style={{
              zIndex: 9999,
            }}
          >
            <div className={`modal-dialog modal-dialog-centered mw-650px`}>
              <div className='modal-content' style={{maxHeight: '95vh', border: '2px solid #000'}}>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>{'Add new F&B'}</h2>

                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => setFBModalOpen(false)}
                    style={{cursor: 'pointer'}}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-1 mh-750px'>
                  <form
                    id='kt_modal_add_user_form'
                    className='form'
                    onSubmit={formikFB.handleSubmit}
                  >
                    <div
                      className='d-flex flex-column scroll-y me-n7 pe-7'
                      id='kt_modal_add_user_scroll'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-max-height='auto'
                      data-kt-scroll-dependencies='#kt_modal_add_user_header'
                      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                      data-kt-scroll-offset='300px'
                    >
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Title</label>
                        <input
                          placeholder='Type a title'
                          type='text'
                          {...formikFB.getFieldProps('title')}
                          title='title'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                          autoComplete='off'
                        />
                        {formikFB.touched.title && formikFB.errors.title && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formikFB.errors.title}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Feature</label>
                        <CustomRichTextbox
                          {...formikFB.getFieldProps('feature')}
                          onChange={() => null}
                          placeHolder={'Type a feature'}
                          name='feature'
                          value={formikFB.values.feature}
                          formik={formikFB}
                          formikName={'feature'}
                          height={'15vh'}
                        ></CustomRichTextbox>
                      </div>
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Benefit</label>
                        <CustomRichTextbox
                          {...formikFB.getFieldProps('benefit')}
                          onChange={() => null}
                          placeHolder={'Type a benefit'}
                          name='benefit'
                          value={formikFB.values.benefit}
                          formik={formikFB}
                          formikName={'benefit'}
                          height={'15vh'}
                        ></CustomRichTextbox>
                      </div>
                    </div>
                    {/* begin::Actions */}
                    <div className='text-center pt-15'>
                      <button
                        type='reset'
                        onClick={() => setFBModalOpen(false)}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                      >
                        Discard
                      </button>

                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                      >
                        <span className='indicator-label'>Submit</span>
                      </button>
                    </div>
                    {/* end::Actions */}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
      {isVideoModalOpen && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
            style={{
              zIndex: 9999,
            }}
          >
            <div className={`modal-dialog modal-dialog-centered mw-650px`}>
              <div className='modal-content' style={{maxHeight: '95vh', border: '2px solid #000'}}>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>{'Add Video'}</h2>

                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => setVideoModalOpen(false)}
                    style={{cursor: 'pointer'}}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-1 mh-750px'>
                  <form
                    id='kt_modal_add_user_form'
                    className='form'
                    onSubmit={formikVideo.handleSubmit}
                  >
                    <div
                      className='d-flex flex-column scroll-y me-n7 pe-7'
                      id='kt_modal_add_user_scroll'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-max-height='auto'
                      data-kt-scroll-dependencies='#kt_modal_add_user_header'
                      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                      data-kt-scroll-offset='300px'
                    >
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Title</label>
                        <input
                          placeholder='Type a title'
                          type='text'
                          {...formikVideo.getFieldProps('title')}
                          title='title'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                          autoComplete='off'
                        />
                        {formikVideo.touched.title && formikVideo.errors.title && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formikVideo.errors.title}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>URL</label>
                        <input
                          placeholder='Type a URL'
                          type='text'
                          {...formikVideo.getFieldProps('url')}
                          title='url'
                          className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                          autoComplete='off'
                        />
                        {formikVideo.touched.url && formikVideo.errors.url && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formikVideo.errors.url}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* begin::Actions */}
                    <div className='text-center pt-15'>
                      <button
                        type='reset'
                        onClick={() => setVideoModalOpen(false)}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                      >
                        Discard
                      </button>

                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                      >
                        <span className='indicator-label'>Submit</span>
                      </button>
                    </div>
                    {/* end::Actions */}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
      {isTSModalOpen && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
            style={{
              zIndex: 9999,
            }}
          >
            <div className={`modal-dialog modal-dialog-centered mw-650px`}>
              <div className='modal-content' style={{maxHeight: '95vh', border: '2px solid #000'}}>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>{'Add Technical Spesification'}</h2>

                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => setTSModalOpen(false)}
                    style={{cursor: 'pointer'}}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-1 mh-750px'>
                  <form
                    id='kt_modal_add_user_form'
                    className='form'
                    onSubmit={formikTS.handleSubmit}
                  >
                    <div
                      className='d-flex flex-column scroll-y me-n7 pe-7'
                      id='kt_modal_add_user_scroll'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-max-height='auto'
                      data-kt-scroll-dependencies='#kt_modal_add_user_header'
                      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                      data-kt-scroll-offset='300px'
                    >
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Question</label>
                        <CustomRichTextbox
                          {...formikTS.getFieldProps('question')}
                          onChange={() => null}
                          placeHolder={'Type a question'}
                          name='question'
                          value={formikTS.values.question}
                          formik={formikTS}
                          formikName={'question'}
                          height={'15vh'}
                        ></CustomRichTextbox>
                      </div>
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Solution</label>
                        <CustomRichTextbox
                          {...formikTS.getFieldProps('solution')}
                          onChange={() => null}
                          placeHolder={'Type a solution'}
                          name='solution'
                          value={formikTS.values.solution}
                          formik={formikTS}
                          formikName={'solution'}
                          height={'15vh'}
                        ></CustomRichTextbox>
                      </div>
                    </div>
                    {/* begin::Actions */}
                    <div className='text-center pt-15'>
                      <button
                        type='reset'
                        onClick={() => setTSModalOpen(false)}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                      >
                        Discard
                      </button>

                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                      >
                        <span className='indicator-label'>Submit</span>
                      </button>
                    </div>
                    {/* end::Actions */}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
      <KTCard>
        <CustomDatatable
          key={'ProductsTable'}
          columns={columns}
          data={ProductsListSlice?.items}
          title={'Products List'}
          pagination
          paginationServer
          paginationTotalRows={ProductsListSlice?.total_count}
          isBusy={dataTableisBusy}
          isNewItem
          isNewItemString={'New Item'}
          isExport
          onExport={() => {
            dispatch(
              exportProductList({
                page_index: 0,
                page_size: 0,
                filters: filterParams,
                sort_filters: sortData,
              })
            );
          }}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          showTitle
          showFilter={false}
          searchButtonClickEvent={() => setfilterParams(filterQuery)}
          sortHandle={handleSort}
          clearButtonClickEvent={() => {
            setfilterParams([])
            setfilterQuery([])
          }}
        ></CustomDatatable>
      </KTCard>
      {modalIsOpen !== undefined ? findProcessScreen(processName) : null}
    </>
  )
}

const ProductsWrapper: FC<Props> = ({ProductsListSlice}) => (
  <QueryRequestProvider>
    <ListViewProvider>
      <ProductsList ProductsListSlice={ProductsListSlice} />
    </ListViewProvider>
  </QueryRequestProvider>
)

export {ProductsWrapper}
