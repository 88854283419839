import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import incentiveService from "../../services/incentives/Incentive.service";


interface InitialStateInterface {
    incentive: any,
    distiributionRules: any
}

const initialState = {
    incentive: {},
} as InitialStateInterface;

export const getIncentive = createAsyncThunk(
    "getIncentive",
    async () => {
        try {
            return await incentiveService.getIncentiveService().then(response => response);
        } catch (error) { }
    }
);

export const postIncentive = createAsyncThunk(
    "postIncentive",
    async (req: any, thunkAPI) => {
        try {
            return await incentiveService.postIncentiveService(req).then(response => response);
        } catch (error) { }
    }
);

export const getDistributionRules = createAsyncThunk(
    "getDistributionRules",
    async () => {
        try {
            return await incentiveService.getDistributionRules().then(response => response);
        } catch (error) { }
    }
);

export const postDistributionRules = createAsyncThunk(
    "postDistributionRules",
    async (req: any, thunkAPI) => {
        try {
            return await incentiveService.postDistributionRules(req).then(response => response);
        } catch (error) { }
    }
);
export const putDistributionRules = createAsyncThunk(
    "postDistributionRules",
    async (req: any, thunkAPI) => {
        try {
            return await incentiveService.putDistributionRules(req).then(response => response);
        } catch (error) { }
    }
);



export const putIncentive = createAsyncThunk(
    "putIncentive",
    async (req: any, thunkAPI) => {
        try {
            return await incentiveService.putIncentiveService(req).then(response => response);
        } catch (error) { }
    }
);

const incentiveSlice = createSlice({
    name: "incentiveSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getIncentive.fulfilled, (state, action) => {
            state.incentive = action.payload?.result ? action.payload.result?.incentive : {};
        });
        builder.addCase(getDistributionRules.fulfilled, (state, action) => {
            state.distiributionRules = action.payload?.result ? action.payload.result?.distribution_rule : {};
        });
    },
});

export default incentiveSlice;
