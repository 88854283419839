import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import frpPurchaseRateService from "../../services/frpManagement/FrpPurchaseRate.service";


interface InitialStateInterface {
    frpPurchaseRateListSlice: any[]
}

const initialState = {
    frpPurchaseRateListSlice: [],
} as InitialStateInterface;

export const getFrpPurchaseRateWithCountryId = createAsyncThunk(
    "getFrpPurchaseRateList",
    async (req: any, thunkAPI) => {
        try {
            return await frpPurchaseRateService.getFrpPurchaseRateWithIdService(req).then(response => response);
        } catch (error) { }
    }
);
export const exportFrpTransaction = createAsyncThunk(
    "exportFrpTransaction",
    async (req: any, thunkAPI) => {
        try {
            return await frpPurchaseRateService.exportFrpTransactionService(req).then(response => response);
        } catch (error) { }
    }
);

export const postFrpPurchaseRate = createAsyncThunk(
    "postFrpPurchaseRate",
    async (req: any, thunkAPI) => {
        try {
            return await frpPurchaseRateService.postFrpPurchaseRateService(req).then(response => response);
        } catch (error) { }
    }
);

export const putFrpPurchaseRate = createAsyncThunk(
    "putFrpPurchaseRate",
    async (req: any, thunkAPI) => {
        try {
            return await frpPurchaseRateService.putFrpPurchaseRateService(req).then(response => response);
        } catch (error) { }
    }
);

export const postFrpPrice = createAsyncThunk(
    "postFrpPrice",
    async (req: any, thunkAPI) => {
        try {
            return await frpPurchaseRateService.postFrpPriceService(req).then(response => response);
        } catch (error) { }
    }
);

export const putFrpPrice = createAsyncThunk(
    "putFrpPrice",
    async (req: any, thunkAPI) => {
        try {
            return await frpPurchaseRateService.putFrpPriceService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteFrpPriceWithId = createAsyncThunk(
    "deleteFrpPriceWithId",
    async (req: any, thunkAPI) => {
        try {
            return await frpPurchaseRateService.deleteFrpPriceService(req).then(response => response);
        } catch (error) { }
    }
);

const frpPurchaseRateSlice = createSlice({
    name: "frpPurchaseRateSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFrpPurchaseRateWithCountryId.fulfilled, (state, action) => {
            state.frpPurchaseRateListSlice = action.payload?.result ? action.payload.result?.frp_prices : [];
        });
    },
});

export default frpPurchaseRateSlice;
