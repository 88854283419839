/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { TplConfigurationWrapper } from './tplConfigurationList'

const tplConfigurationCrumbs: Array<PageLink> = [
    {
        title: `TplConfiguration`,
        path: '/TplConfiguration/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const TplConfiguration = () => {

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={tplConfigurationCrumbs}>{`Tpl Configuration`}</PageTitle>
                            <TplConfigurationWrapper tplConfigurationList={[]}  />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default TplConfiguration
