/* eslint-disable no-template-curly-in-string */

import { callApi, HttpMethods } from "../../../../utils/api-util";

const getPrivacyPolicyService = async () => {
    let _options = {
        controller: "/privacy-policy",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}

const postPrivacyPolicyService = async (req: any) => {
    let _options = {
        controller: "/privacy-policy",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putPrivacyPolicyService = async (req: any) => {
    let _options = {
        controller: "/privacy-policy",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const PrivacyPolicyService = {
    getPrivacyPolicyService,
    postPrivacyPolicyService,
    putPrivacyPolicyService,
};

export default PrivacyPolicyService;
