import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";

const axiosOptions: AxiosRequestConfig = {
    responseEncoding: "",
    url: "",
    data: "",
    params: "",
    method: undefined,
};

export const HttpMethods = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
};

function axiosOptionBuilder(options: any) {
    let URL = window.__RUNTIME_CONFIG__.REACT_APP_AUTH_API_URL;
    try {
        const _url =
            options.url === undefined ||
                options.url === "" ||
                options.url === "" ||
                options.url === null
                ? URL
                : options.url;
        axiosOptions.url = _url + options.controller;
        axiosOptions.method = options.method;
        axiosOptions.data = options.controller === "/connect/token" ? options.data : JSON.stringify(options.data);
        axiosOptions.params = options.params;

        let header: AxiosRequestHeaders = {
            "Content-Type": options.controller === "/connect/token" ? "application/x-www-form-urlencoded" : "application/json",
            "Access-Control-Expose-Headers": "interactionid",
        };
        axiosOptions.headers = header;

        return axiosOptions;
    } catch (error) {
        throw new Error();
    }
}

export const callApi = async (options: any) => {
    let apiOptions = axiosOptionBuilder(options);
    const resp = await axios(apiOptions)
        .then(function (response) {
            if (response.status === 200) {
                return response.data;
            } else if (response.status === 401 || response.status === 403 || response.status === 500 || response.status === 503) {
                return response;
            } else {
                return response.statusText;
            }
        })
        .catch(function (response) {
            // i will edit this part. First we will edit api response from 
            // if(response?.message == "Network Error")
            // {
            //   localStorage.removeItem("user");
            //   Router.reload()
            // }
            return response;
        });
    return resp;
};

const apiUtils = {
    callApi,
};
export default apiUtils;
