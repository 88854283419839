import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FPMerchantsService from "../../../services/fpConnections/fpMerchants/FPMerchants.service";


interface InitialStateInterface {
    FPMerchantsListSlice: any[]
    FPMerchantSlice: any[]
}

const initialState = {
    FPMerchantsListSlice: [],
    FPMerchantSlice: [],
} as InitialStateInterface;

export const getFPMerchants = createAsyncThunk(
    "getFPMerchantsList",
    async () => {
        try {
            return await FPMerchantsService.getFPMerchantsService().then(response => response);
        } catch (error) { }
    }
);

export const postFPMerchants = createAsyncThunk(
    "postFPMerchants",
    async (req: any, thunkAPI) => {
        try {
            return await FPMerchantsService.postFPMerchantsService(req).then(response => response);
        } catch (error) { }
    }
);

export const putFPMerchants = createAsyncThunk(
    "putFPMerchants",
    async (req: any, thunkAPI) => {
        try {
            return await FPMerchantsService.putFPMerchantsService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteFPMerchantsWithId = createAsyncThunk(
    "deleteFPMerchants",
    async (req: any, thunkAPI) => {
        try {
            return await FPMerchantsService.deleteFPMerchantsService(req).then(response => response);
        } catch (error) { }
    }
);

export const getFPMerchantsWithId = createAsyncThunk(
    "getFPMerchantsWithId",
    async (req: any) => {
        try {
            return await FPMerchantsService.getFPMerchantsWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const FPMerchantsSlice = createSlice({
    name: "FPMerchantsSlice",
    initialState,
    reducers: {
        tempAddList: (state, action) => {
            state.FPMerchantsListSlice = []
            state.FPMerchantsListSlice.push(action.payload.item)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFPMerchants.fulfilled, (state, action) => {
            state.FPMerchantsListSlice = action.payload?.result ? action.payload.result?.fpMerchants : [];
        });
        builder.addCase(getFPMerchantsWithId.fulfilled, (state, action) => {
            state.FPMerchantSlice = action.payload?.result ? action.payload.result?.fpMerchant : [];
        });
    },
});

export default FPMerchantsSlice;
