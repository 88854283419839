/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { lazy, useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import Styles from '../../../_metronic/assets/sass/components/custom-datatable.module.scss'
import { KTSVG } from '../../../_metronic/helpers'
import { AppDispatch, useAppDispatch } from '../../store/store'
import componentsSlice from '../../slices/components/componentsSlice'
import CustomAccordion from '../custom-accordion/custom-accordion'
const DataTable = lazy(() => import('react-data-table-component-footer'))
interface CustomProps {
  title?: any
  subTitle?: any
  columns: any
  data: any
  pagination?: boolean
  paginationServer?: boolean
  paginationTotalRows?: number
  onChangePage?: any
  onChangeRowsPerPage?: any
  isBusy?: boolean
  footer?: any
  noHeader?: boolean
  showTitle?: boolean
  isExport?: boolean
  isNewItem?: boolean
  isNewItemString?: string
  filterOptions?: any
  showFilter?: boolean
  newItemClickEvent?: any
  formik?: any
  formikName?: any
  noMargin?: boolean
  searchButtonClickEvent?: any
  sortHandle?: any
  clearButtonClickEvent?: any
  customFilterIsOpen?: boolean
  customPaginationDefaultPage?: any
  customPaginationPerPage?: any
  disablePaginationToggle?: any
  onExport?: Function
}

const CustomDatatable: React.FC<CustomProps> = ({
  title,
  subTitle,
  columns,
  data,
  pagination,
  paginationServer,
  paginationTotalRows,
  onChangePage,
  onChangeRowsPerPage,
  isBusy,
  footer,
  noHeader,
  showTitle,
  isExport,
  isNewItem,
  isNewItemString,
  filterOptions,
  showFilter,
  newItemClickEvent,
  formik,
  formikName,
  noMargin,
  searchButtonClickEvent,
  sortHandle,
  clearButtonClickEvent,
  customFilterIsOpen,
  customPaginationDefaultPage,
  customPaginationPerPage,
  disablePaginationToggle,
  onExport,
}) => {

  const [IsDataGridBusy, SetisDataGridBusy] = useState(false)
  const dispatch: AppDispatch = useAppDispatch()
  const [ResetPaginationToggle, SetResetPaginationToggle] = useState(false)

  useEffect(() => {
    SetisDataGridBusy(isBusy !== undefined ? isBusy : false)
  }, [isBusy])

  useEffect(() => {
    if (!disablePaginationToggle) {
      SetResetPaginationToggle(!ResetPaginationToggle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationTotalRows])

  let filter = showFilter ? '47vh' : '49vh'
  const customDatatableStyle = {
    table: { style: { height: filter, 'max-height': filter, overflowX: 'hidden', 'table-layout': 'fixed', 'width':'100%', 'display':'table'} },
    cells: {
      style: {
        fontFamily: 'Inter,Helvetica,"sans-serif"',
        fontSize: '1.075rem !important',
        fontWeight: '600 !important',
        fontStyle: 'normal',
        justifyContent: 'start',
        color: '#181c32',
      },
    },
    headCells: {
      style: {
        fontFamily: 'Inter,Helvetica,"sans-serif"',
        fontSize: '1.085rem',
        fontWeight: '600',
        fontStyle: 'normal',
        justifyContent: 'start',
        color: '#979797',
      },
    },
  }

  const DataFilter = [
    {
      id: 'filter',
      header: `Filter`,
      content: (
        <>
          <div className={`row ${Styles.filterBar}`}>
            <div className={`row justify-content-md-start ${Styles.filterBarRow}`}>
                <div className='row'>
                  {filterOptions?.map((x: any, index:any) => (
                    <div key={index} className={`col-4 mt-2`}>
                      {x.component}
                    </div>
                  ))}
                </div>
            </div>
            <div className={`${Styles.filterBarButtomColInline} mb-2`}>
              <button
                type='button'
                className={`btn btn-success me-3 ${Styles.filterBarButtomColInlineButton}`}
                onClick={searchButtonClickEvent != null ? searchButtonClickEvent : () => null}
              >
                <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-2' />
                {`Search`}
              </button>
            </div>
            <div className={`${Styles.filterBarButtomColInline} mb-2`}>
              <button
                type='button'
                className={`btn btn-primary me-3 ${Styles.filterBarButtomColInlineButton}`}
                onClick={clearButtonClickEvent != null ? clearButtonClickEvent : () => null}
              >
                <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon-2' />
                {`Clear`}
              </button>
            </div>
          </div>
        </>
      ),
    },
  ]

  return (
    <>
      <div className={Styles.area}>
        <div className={noMargin ? '' : Styles.content}>
          <div className='row mb-4'>
            <div className='col-6'>
              {showTitle ? (
                <>
                  <div className='row mt-2'>
                    <div className='mt-2'>
                      <div className='row'>
                        <div className='col-8'>
                          <div className={Styles.title}>{!noHeader ? `${title}` : null}</div>
                        </div>
                        {formik?.touched?.[formikName] && formik?.errors?.[formikName] && (
                          <div
                            className='fv-plugins-message-container'
                            style={{ marginLeft: '1.3rem' }}
                          >
                            <div className='fv-help-block'>
                              <span role='alert'>{formik?.errors?.[formikName]}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='row'>
                        <div className='col-8'>
                          <div className={Styles.subtitle}>
                            {!noHeader && subTitle !== undefined ? `${subTitle}` : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className='col-6'>
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                {isExport ? (
                  <>
                    <button type='button' className='btn btn-light-primary me-3' onClick={() => { if (onExport) onExport(); }}>
                      <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                      Export
                    </button>
                  </>
                ) : null}

                {isNewItem ? (
                  <>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={
                        newItemClickEvent != null
                          ? newItemClickEvent
                          : () => dispatch(componentsSlice.actions.openModal({}))
                      }
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                      {isNewItemString}
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {showFilter ? (
            <CustomAccordion
              isAlwaysOpen={true}
              list={DataFilter}
              noAccordionHeaderStyle
              customHeaderStyle={Styles.title}
              customId={title ? 'filter' : title}
              isOpen={customFilterIsOpen}
            ></CustomAccordion>
          ) : null}
          {
            <DataTable
              columns={columns}
              data={data}
              customStyles={customDatatableStyle}
              pagination={pagination}
              paginationServer={paginationServer}
              paginationTotalRows={paginationTotalRows}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              highlightOnHover={false}
              striped={true}
              responsive={true}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              progressPending={IsDataGridBusy}
              progressComponent={
                isBusy ? (
                  <ThreeDots
                    height='80'
                    width='80'
                    radius='9'
                    color='black'
                    ariaLabel='three-dots-loading'
                  />
                ) : <></>
              }
              noDataComponent={<div style={{ padding: '10rem 0rem' }}>{'No Data'}</div>}
              footer={footer !== undefined ? footer : null}
              footerBold
              noHeader={true}
              onSort={sortHandle}
              sortServer={true}
              paginationResetDefaultPage={ResetPaginationToggle}
              paginationDefaultPage={customPaginationDefaultPage}
              paginationPerPage={customPaginationPerPage}
            />
          }

        </div>
      </div>
    </>
  )
}

export default CustomDatatable
