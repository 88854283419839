
import { callApi, HttpMethods } from "../../utils/api-util";

const getIncentiveService = async () => {
    let _options = {
        controller: "/incentive",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}

const getDistributionRules = async () => {
    let _options = {
        controller: "/distribution-rule",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}


const postIncentiveService = async (req: any) => {
    let _options = {
        controller: "/incentive",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};
const postDistributionRules = async (req: any) => {
    let _options = {
        controller: "/distribution-rule",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};



const putIncentiveService = async (req: any) => {
    let _options = {
        controller: "/incentive",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};
const putDistributionRules = async (req: any) => {
    let _options = {
        controller: "/distribution-rule",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};


const IncentiveService = {
    getIncentiveService,
    postIncentiveService,
    putIncentiveService,
    postDistributionRules,
    getDistributionRules,
    putDistributionRules
};

export default IncentiveService;
