import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import VeracityContactInformationService from "../../../../services/assetManagement/system/veracityContactInformation/VeracityContactInformation.service";


interface InitialStateInterface {
    VeracityContactInformationSlice: any
}

const initialState = {
    VeracityContactInformationSlice: {},
} as InitialStateInterface;

export const getVeracityContactInformation = createAsyncThunk(
    "getVeracityContactInformation",
    async () => {
        try {
            return await VeracityContactInformationService.getVeracityContactInformationService().then(response => response);
        } catch (error) { }
    }
);

export const postVeracityContactInformation = createAsyncThunk(
    "postVeracityContactInformation",
    async (req: any, thunkAPI) => {
        try {
            return await VeracityContactInformationService.postVeracityContactInformationService(req).then(response => response);
        } catch (error) { }
    }
);

export const putVeracityContactInformation = createAsyncThunk(
    "putVeracityContactInformation",
    async (req: any, thunkAPI) => {
        try {
            return await VeracityContactInformationService.putVeracityContactInformationService(req).then(response => response);
        } catch (error) { }
    }
);

const VeracityContactInformationSlice = createSlice({
    name: "VeracityContactInformationSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVeracityContactInformation.fulfilled, (state, action) => {
            state.VeracityContactInformationSlice = action.payload?.result ? action.payload.result?.veracity_contact_information : {};
        });
    },
});

export default VeracityContactInformationSlice;
