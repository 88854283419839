/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import componentsSlice from '../../../slices/components/componentsSlice'
import {AppDispatch, useAppSelector} from '../../../store/store'
import {ContractsWrapper} from './ContractsList'

const ContractsContentBreadcrumbs: Array<PageLink> = [
  {
    title: `Sales Asistant Management`,
    path: '/Contracts/List',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Contracts = (props: any) => {
  const dispatch: AppDispatch = useDispatch()

  const {ContractListSlice} = useAppSelector((state) => state.contact)

  useEffect(() => {
    dispatch(componentsSlice.actions.closeModal({}))
  }, [])
  

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/List'
          element={
            <>
              <PageTitle breadcrumbs={ContractsContentBreadcrumbs}>{`Contracts`}</PageTitle>
              <ContractsWrapper ContractList={ContractListSlice}/>
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default Contracts
