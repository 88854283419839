type Props = {
  description: string
  count: string
  backgroudColor?: string
  titleColor?: string
  fontColor?: string
}

const DashboardCard = ({ description, count, backgroudColor, titleColor, fontColor }: Props) => (
  <div
    className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end `}
    style={{
      backgroundColor: backgroudColor,
    }}
  >
    <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        <span className='fs-2 fw-bold me-2 lh-1 ls-n2' style={{ color: fontColor }}>{count}</span>
        <span className='opacity-75 pt-1 fw-semibold fs-2' style={{ color: titleColor }}>{description}</span>
      </div>
    </div>
  </div>
)

DashboardCard.defaultProps = {
  backgroudColor: "#F1416C",
  titleColor: "white",
  fontColor: "white"
};


export { DashboardCard }
