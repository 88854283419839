export const CountryArray = [
    {
      value: 0,
      label: "UK"
    },
    {
      value: 1,
      label: "USA"
    },
  ]
