/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { getIZonePollingSettings } from '../../../slices/campaignManagament/iZonePollingSettings/iZonePollingSettingsSlice'
import componentsSlice from '../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { IZonePollingSettingsWrapper } from './IZonePollingSettingsList'

const IZonePollingSettingsContentBreadcrumbs: Array<PageLink> = [
    {
        title: `Campaign Management`,
        path: '/IZonePollingSettings/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const IZonePollingSettings = (props:any) => {
    const dispatch: AppDispatch = useDispatch();

    const { IZonePollingSettingsSlice } = useAppSelector(
        (state) => state.iZonePollingSettings
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getIZonePollingSettings({Key: ["QueryPollingFrequency", "CheckinPollingFrequency", "CheckinRefreshTime", "EpQrExpireTime", "EpCampaignExpireTime", "SocialReplyAutomationFrequency"]}));
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={IZonePollingSettingsContentBreadcrumbs}>{`iZone Polling Settings`}</PageTitle>
                            <IZonePollingSettingsWrapper IZonePollingSettingsSlice={IZonePollingSettingsSlice} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default IZonePollingSettings
