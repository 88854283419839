import { ApexOptions } from "apexcharts";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import "react-phone-input-2/lib/style.css";
import Style from '../../../_metronic/assets/sass/components/percentage.module.scss'
import CustomTextbox from "../customTextbox/CustomTextbox";

interface Props {

    formik?: any;
    formikName?: any;
    color?: string;
    textColor?: string;
    value: string | number;
    name?: string;
    subName?: string;
    isButtonVisible?: boolean;
    height?: string;
    isLeftButtonVisible?: boolean;
    isTitleBorderVisible?: boolean;
    leftValue?: string | number | undefined;
}

const CustomPercentageCard = React.forwardRef<HTMLInputElement, Props>(
    (
        {   formik,
            formikName,
            color,
            textColor,
            value,
            name,
            subName,
            isButtonVisible,
            height,
            isLeftButtonVisible,
            isTitleBorderVisible,
            leftValue
        },
    ) => {

        return (
            <div className={`${Style.card}`} style={{
                backgroundColor: color, color: textColor, height: height
            }}>
                {name ? <div className={`${Style.cardTitle}`} style={isTitleBorderVisible ? { borderBottom: '0.5px solid #CFCFCF ' } : {}}>{name}</div> : <></>}
                <div className={`${Style.cardContent}`}>{subName}</div>
                {isLeftButtonVisible ? <div className={`${Style.leftButtonGroup}`}>
                    <div className={`${Style.percentageDisplay}`}>{'%' + leftValue}</div>
                </div> : <></>}

                <div className={`${Style.buttonGroup}`}>
                    {isButtonVisible ? <button onClick={(e => {
                         formik?.setValues({
                            ...formik?.values,
                            [formikName]: +value - 1
                          })
                    })}>-</button> : <></>}
                    <div className={`${Style.textBoxFont}`}>
                        <CustomTextbox
                            onChange={() => null}
                            value={'%' + +value}
                            formik={formik}
                            formikName={formikName}
                        ></CustomTextbox>
                    </div>

                    {isButtonVisible ? <button onClick={(e) => {
                       formik?.setValues({
                        ...formik?.values,
                        [formikName]: +value + 1
                      })
                    }}>+</button> : <></>}
                </div>
            </div>
        );
    }
);

CustomPercentageCard.defaultProps = {

};

export default CustomPercentageCard;
