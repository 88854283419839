/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import componentsSlice from '../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { UseCasesWrapper } from './UseCasesList'

const UseCasesContentBreadcrumbs: Array<PageLink> = [
    {
        title: `Sales Asistant Management`,
        path: '/UseCases/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const UseCases = (props: any) => {
    const dispatch: AppDispatch = useDispatch();

    const { UsecaseListSlice } = useAppSelector(
        (state) => state.useCase
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={UseCasesContentBreadcrumbs}>{`Use Cases`}</PageTitle>
                            <UseCasesWrapper UsecaseList={UsecaseListSlice} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default UseCases
