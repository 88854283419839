
import { callApi, HttpMethods } from "../../../utils/api-util";

const getIZonePollingSettingsService = async (req: any) => {
    let _options = {
        controller: "/system-settings/get",
        data: JSON.stringify(req),
        method: HttpMethods.POST,
    };
    const result = await callApi(_options);
    return result;
}

const postIZonePollingSettingsService = async (req: any) => {
    let _options = {
        controller: "/system-settings",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putIZonePollingSettingsService = async (req: any) => {
    let _options = {
        controller: "/system-settings",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const IZonePollingSettingsService = {
    getIZonePollingSettingsService,
    postIZonePollingSettingsService,
    putIZonePollingSettingsService,
};

export default IZonePollingSettingsService;
