/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import componentsSlice from '../../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../../store/store'
import { HelperWrapper } from './HelperList'

const HelperContentBreadcrumbs: Array<PageLink> = [
    {
        title: `Asset Management`,
        path: '/Helper/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Helper = () => {
    const dispatch: AppDispatch = useDispatch();

    const { HelperListSlice } = useAppSelector(
        (state) => state.helper
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={HelperContentBreadcrumbs}>{`User Manual`}</PageTitle>
                            <HelperWrapper HelperListSlice={HelperListSlice} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default Helper
