import {callApi, HttpMethods} from '../../utils/api-util'
import { ResponseType } from '../../utils/enums/ResponseType.enum'

const getReferralManagementWithUserTypeService = async (req: any) => {
  let _options = {
    controller: `/partner/get`,
    method: HttpMethods.POST,
    data: JSON.stringify(req),
  }
  const result = await callApi(_options)
  return result
}

const exportReferralManagementWithUserTypeService = async (req: any) => {
  let _options = {
    controller: `/partner/export`,
    method: HttpMethods.POST,
    data: JSON.stringify(req),
    responseType: ResponseType.File
  }
  const result = await callApi(_options)
  return result
}
const postReferralManagementService = async (req: any) => {
  let _options = {
    controller: '/partner/partner',
    method: HttpMethods.POST,
    data: JSON.stringify(req),
  }
  return await callApi(_options)
}

const putReferralManagementService = async (req: any) => {
  let _options = {
    controller: '/partner',
    method: HttpMethods.PUT,
    data: JSON.stringify(req),
  }
  return await callApi(_options)
}

const getReferralManagementWithIdService = async (req: any) => {
  let _options = {
    controller: '/partner/get-by-id/' + req,
    method: HttpMethods.GET,
  }
  const result = await callApi(_options)
  return result
}

const getReferralManagementDetailWithIdService = async (req: any) => {
  let _options = {
    controller: '/partner/detail/' + req,
    method: HttpMethods.GET,
  }
  const result = await callApi(_options)
  return result
}

const getReferralUsersWithIdService = async (req: any) => {
  let _options = {
    controller: `/partner/users${req !== undefined ? '?UserType=' + req : ''}`,
    method: HttpMethods.GET,
  }
  const result = await callApi(_options)
  return result
}

const getAllReferralUsersWithUserTypeService = async (req: any) => {
  let _options = {
    controller: `/partner/users${req !== undefined ? '?UserType=' + req + '&' : ''}GetAll=true`,
    method: HttpMethods.GET,
  }
  const result = await callApi(_options)
  return result
}

const postReferralRemittanceService = async (req: any) => {
  let _options = {
    controller: '/partner/remittance',
    method: HttpMethods.POST,
    data: JSON.stringify(req),
  }
  return await callApi(_options)
}

const ReferralManagementService = {
  getReferralManagementWithUserTypeService,
  exportReferralManagementWithUserTypeService,
  postReferralManagementService,
  putReferralManagementService,
  getReferralManagementWithIdService,
  getReferralManagementDetailWithIdService,
  getReferralUsersWithIdService,
  postReferralRemittanceService,
  getAllReferralUsersWithUserTypeService,
}

export default ReferralManagementService
