import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SalesAssistantService from "../../../services/salesAssistant/SalesAssistant.service";


interface InitialStateInterface {
    UsecaseListSlice: any
    UsecaseSlice: any[]
}

const initialState = {
    UsecaseListSlice: null,
    UsecaseSlice: [],
} as InitialStateInterface;


export const postUsecase = createAsyncThunk(
    "postUsecase",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.postUseCaseService(req).then(response => response);
        } catch (error) { }
    }
);

export const getUsecase = createAsyncThunk(
    "getUsecaseList",
    async (req: any) => {
        try {
            return await SalesAssistantService.getUseCaseService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportUseCases = createAsyncThunk('exportUseCases', async (req: any) => {
    try {
      return await SalesAssistantService.exportUseCases(req).then(
        response => response
      )
    } catch (error) {}
  })

export const deleteUsecaseWithId = createAsyncThunk(
    "deleteUsecase",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.deleteUseCaseService(req).then(response => response);
        } catch (error) { }
    }
);



const UsecaseSlice = createSlice({
    name: "UsecaseSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUsecase.fulfilled, (state, action) => {
            state.UsecaseListSlice = action.payload?.result ? action.payload.result?.use_case : null;
        });
    },
});

export default UsecaseSlice;
