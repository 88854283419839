
import { callApi, HttpMethods } from "../../utils/api-util";
import { ResponseType } from "../../utils/enums/ResponseType.enum";

const getDataSourceIntegrationService = async (req: any) => {
    let _options = {
        controller: "/campaign/get-shoutback",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}



const exportDataSourceIntegrationService = async (req: any) => {
    let _options = {
        controller: "/campaign/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File

    };
    const result = await callApi(_options);
    return result;
}

const postDataSourceIntegrationService = async (req: any) => {
    let _options = {
        controller: "/campaign/shoutback",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putDataSourceIntegrationService = async (req: any) => {
    let _options = {
        controller: "/campaign/shoutback",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteDataSourceIntegrationService = async (req: any) => {
    let _options = {
        controller: "/campaign/shoutback",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getCredentialListService = async (req: any) => {
    let _options = {
        controller: "/credential/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}

const getDataSourceIntegrationWithIdService = async (req:any) => {
    let _options = {
        controller: "/campaign/get-shoutback-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}

const storageService = {
    getDataSourceIntegrationService,
    exportDataSourceIntegrationService,
    postDataSourceIntegrationService,
    putDataSourceIntegrationService,
    deleteDataSourceIntegrationService,
    getCredentialListService,
    getDataSourceIntegrationWithIdService
};

export default storageService;
