/* eslint-disable jsx-a11y/alt-text */
import React, { FC } from 'react'
import Style from '../../../_metronic/assets/sass/pages/sdkWelcomeScreenPreview.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { isObject } from 'formik';

interface Props {
    content?: any;
}

const SdkWelcomeScreenPreview: FC<Props> = ({ content }) => {

    function findFileContent() {
        const result = isObject(content?.fileUrl);
        if (content?.sdkIntroType?.value === 0) {
            if (result) {
                return (
                    <img
                        src={URL.createObjectURL(content?.fileUrl[0])} className={Style.sdkImageContent} alt="img"
                    />
                )
            }
            else {
                return (
                    <img
                        src={content?.fileUrl} className={Style.sdkImageContent} alt="img"
                    />
                )
            }

        }
        else {
            return (
                <div className={Style.sdkVideoContentDiv}>
                    <img
                        src={toAbsoluteUrl("/media/icons/duotune/social/soc007.svg")} className={Style.sdkVideoContent} alt="img"
                    />
                </div>
            )
        }
    }
    return (
        <>
            <div className={Style.container}>
                <div className={Style.blankDiv}></div>
                <div className={Style.mainDiv}>
                    <div className={Style.sdkHeader}>
                        <div className={Style.sdkCustomRow}>
                            <div className='col-10'></div>
                            <div className={`col-2 ${Style.sdkCustomRow}`}>
                                <div className={Style.sdkCloseButtonDiv}>
                                    <FontAwesomeIcon icon={faX} className={Style.sdkCloseButton}></FontAwesomeIcon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={Style.sdkContent}>
                        {findFileContent()}
                    </div>
                    <div className={Style.sdkFooter}>
                        <div className={Style.sdkCustomRow}>
                            <div className={Style.sdkContentTitle}>{content.title}</div>
                        </div>
                        <div className={Style.sdkCustomRow}>
                            <div className={Style.sdkContentDiv}>{content.content}</div>
                        </div>
                        <div className={Style.sdkCustomRow}>
                            <div className='col-3'></div>
                            <div className='col-6'>
                                <div className={`${Style.sdkCustomRow} ${Style.sdkCustomRowWithMargin}`}>
                                    <div className='col-4'>
                                        <div className={Style.sdkDotBlack}></div>
                                    </div>
                                    <div className='col-4'>
                                        <div className={Style.sdkDotGray}></div>
                                    </div>
                                    <div className='col-4'>
                                        <div className={Style.sdkDotGray}></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-3'></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SdkWelcomeScreenPreview