import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showToast } from "../../../components/custom-toasts/custom-toasts";
import { SchemaSubCategory } from "../../../model/CredentialModule";
import CredentialsService from "../../../services/credentialManagement/credentials/Credentials.service";


interface InitialStateInterface {
    CredentialsListSlice: any
    credentialSlice: any
    TempCredentialCategortList: SchemaSubCategory[]
    CredentialCategoryList: SchemaSubCategory[]
    selectedCredentialId: string
    schemaJson: any
    SchemaDefId: string;
    isSubCatValid: boolean;
    subCatValidList: any;
    CategoryFromTink: any[];
}

const initialState = {
    CredentialsListSlice: null,
    credentialSlice: null,
    TempCredentialCategortList: [],
    selectedCredentialId: "",
    schemaJson: null,
    SchemaDefId: "",
    isSubCatValid: false,
    CredentialCategoryList: [],
    subCatValidList: [],
    CategoryFromTink: []
} as InitialStateInterface;

export const getCredentials = createAsyncThunk(
    "getCredentials",
    async (req: any) => {
        try {
            return await CredentialsService.getCredentialsService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportCredentials = createAsyncThunk(
    "exportCredentials",
    async (req: any) => {
        try {
            return await CredentialsService.exportCredentialsService(req).then(response => response);
        } catch (error) { }
    }
);

export const getCalculationFormula = createAsyncThunk(
    "getCalculationFormula",
    async () => {
        try {
            return await CredentialsService.getCalculationFormula().then(response => response);
        } catch (error) { }
    }
);

export const postCredential = createAsyncThunk(
    "postCredentials",
    async (req: any, thunkAPI) => {
        try {
            return await CredentialsService.postCredentialsService(req).then(response => response);
        } catch (error) { }
    }
);

export const putCredential = createAsyncThunk(
    "putCredentials",
    async (req: any, thunkAPI) => {
        try {
            return await CredentialsService.putCredentialsService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteCredentialsWithId = createAsyncThunk(
    "deleteCredentialssWithId",
    async (req: any, thunkAPI) => {
        try {
            return await CredentialsService.deleteCredentialsService(req).then(response => response);
        } catch (error) { }
    }
);

export const getCredentialsWithId = createAsyncThunk(
    "getCredentialsWithId",
    async (req: any) => {
        try {
            return await CredentialsService.getCredentialsWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

export const getSchemaJsonWithReq = createAsyncThunk(
    "getSchemaJsonWithReq",
    async (req: any) => {
        try {
            return await CredentialsService.getSchemaJsonWithReqService(req).then(response => response);
        } catch (error) { }
    }
);

export const saveSchemaJson = createAsyncThunk(
    "saveSchemaJson",
    async () => {
        try {
            return await CredentialsService.saveSchemaJson().then(response => response);
        } catch (error) { }
    }
);

export const publishCDS = createAsyncThunk(
    "publishCDS",
    async () => {
        try {
            return await CredentialsService.publishCDS().then(response => response);
        } catch (error) { }
    }
);

export const getCategoryOfTinkReq = createAsyncThunk(
    "getCategoryOfTinkReq",
    async () => {
        try {
            return await CredentialsService.getCategoryOfTinkService().then(response => response);
        } catch (error) { }
    }
);


const credentialsSlice = createSlice({
    name: "credentialsSlice",
    initialState,
    reducers: {
        addCredentialSubCategoryList: (state, action) => {
            state.TempCredentialCategortList.push(action?.payload?.sub)
        },
        updateSubCategoryName: (state, action) => {
            let item = state.TempCredentialCategortList.find(x => x.id === action?.payload?.id)
            if (item !== undefined) {
                item.name = action?.payload?.name
            }
        },
        updateSubCategoryIcon: (state, action) => {
            let item = state.TempCredentialCategortList.find(x => x.id === action?.payload?.id)
            if (item !== undefined) {
                item.icon = action?.payload?.icon
            }
        },
        updateCredentialSubCategory: (state, action) => {
            let item = state.TempCredentialCategortList.find(x => x.id === action?.payload?.SchemaSubCategory?.id)
            if (item !== undefined) {
                item.id = action?.payload?.SchemaSubCategory?.id
                item.name = action?.payload?.SchemaSubCategory?.name
                item.icon = action?.payload?.SchemaSubCategory?.icon
            }
            showToast.success("Item Saved Successfully")
        },
        deleteCredentialSubCategory: (state, action) => {
            let item = state.TempCredentialCategortList.find(x => x.id === action?.payload?.SchemaSubCategory?.id)
            if (item !== null && item !== undefined) {
                let index = state.TempCredentialCategortList.indexOf(item)
                state.TempCredentialCategortList.splice(index, 1)
            }
            let newSubCatValidList = state.subCatValidList.filter((x: any) => x.id !== action?.payload?.SchemaSubCategory?.id);
            state.subCatValidList = newSubCatValidList;
            state.isSubCatValid = newSubCatValidList.find((x: any) => x.value === false) === undefined ? true : false
        },
        addCredentialSubSchemaAttributes: (state, action) => {

            let SchemaAttributesListWithId = state.TempCredentialCategortList.find(x => x.id === action?.payload?.subId)
            SchemaAttributesListWithId?.schema_attributes.push(action?.payload?.schemaAttribute)
        },
        updateCredentialSubSchemaAttributes: (state, action) => {

            let SchemaAttributeWithId = state.TempCredentialCategortList.find(x => x.id === action?.payload?.subId)?.schema_attributes.find(x => x.id === action?.payload?.schemaAttributeId)
            if (SchemaAttributeWithId !== undefined) {
                SchemaAttributeWithId.id = action?.payload?.schemaAttribute?.id
                SchemaAttributeWithId.name = action?.payload?.schemaAttribute?.name
                SchemaAttributeWithId.attribute_contents = action?.payload?.schemaAttribute?.attribute_contents
                SchemaAttributeWithId.display_name = action?.payload?.schemaAttribute?.display_name
                SchemaAttributeWithId.is_query_criteria = action?.payload?.schemaAttribute?.is_query_criteria
                SchemaAttributeWithId.is_mobile_sum_field = action?.payload?.schemaAttribute?.is_mobile_sum_field
                SchemaAttributeWithId.calculation_formula = action?.payload?.schemaAttribute?.calculation_formula
                SchemaAttributeWithId.attribute_format_id = action?.payload?.schemaAttribute?.attribute_format_id
            }
        },
        deleteCredentialSubSchemaAttributes: (state, action) => {

            let SchemaAttributeWithId = state.TempCredentialCategortList.find(x => x.id === action?.payload?.subId)?.schema_attributes.find(x => x.id === action?.payload?.schemaAttributeId)
            if (SchemaAttributeWithId !== null && SchemaAttributeWithId !== undefined) {
                let index = state.TempCredentialCategortList.find(x => x.id === action?.payload?.subId)?.schema_attributes.indexOf(SchemaAttributeWithId)
                if (index !== null && index !== undefined) {
                    state.TempCredentialCategortList.find(x => x.id === action?.payload?.subId)?.schema_attributes.splice(index, 1)
                }
            }
        },
        addCredentialSubOpenBankingCategory: (state, action) => {
            let opeBankingCategoryListWithId = state.TempCredentialCategortList.find(x => x.id === action?.payload?.subId)
            opeBankingCategoryListWithId?.open_banking_categories.push(action?.payload?.openBankingCategory)
        },
        subCatIsValid: (state, action) => {
            state.isSubCatValid = action?.payload?.valid;
        },
        subCatValidList: (state, action) => {
            state.subCatValidList = action?.payload?.validList;
        },
        updateCredentialOpenBankingCategory: (state, action) => {
            let openBankingCategoryWithId = state.TempCredentialCategortList.find(x => x.id === action?.payload?.subId)?.open_banking_categories.find(x => x.id === action?.payload?.openBankingCategoryId)
            if (openBankingCategoryWithId !== undefined) {
                openBankingCategoryWithId.id = action?.payload?.openBankingCategory?.id
                openBankingCategoryWithId.open_banking_definition = action?.payload?.openBankingCategory?.open_banking_definition
                openBankingCategoryWithId.open_banking_category_map_info = action?.payload?.openBankingCategory?.open_banking_category_map_info
            }
        },
        deleteCredentialSubOpenBankingCategory: (state, action) => {
            let openBankingCategoryWithId = state.TempCredentialCategortList.find(x => x.id === action?.payload?.subId)?.open_banking_categories.find(x => x.id === action?.payload?.openBankingCategoryId)
            if (openBankingCategoryWithId !== null && openBankingCategoryWithId !== undefined) {
                let index = state.TempCredentialCategortList.find(x => x.id === action?.payload?.subId)?.open_banking_categories.indexOf(openBankingCategoryWithId)
                if (index !== null && index !== undefined) {
                    state.TempCredentialCategortList.find(x => x.id === action?.payload?.subId)?.open_banking_categories.splice(index, 1)
                }
            }
        },
        changeSelectedCredentialId: (state, action) => {
            state.selectedCredentialId = action.payload?.credentialId
        },
        clearItems: (state, action) => {
            state.credentialSlice = null
            state.TempCredentialCategortList = [];
            state.selectedCredentialId = "";
            state.schemaJson = null;
            state.selectedCredentialId = "";
            state.isSubCatValid = false;
        },
        changeSchema: (state, action) => {
            state.schemaJson = action.payload?.schemaJson;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCredentials.fulfilled, (state, action) => {
            state.CredentialsListSlice = action.payload?.result ? action.payload.result?.credential_list : null;
        });
        builder.addCase(getCredentialsWithId.fulfilled, (state, action) => {
            state.credentialSlice = action.payload?.result ? action.payload.result?.credential_definition : [];
            state.TempCredentialCategortList = action.payload?.result ? action.payload.result?.credential_definition?.schema?.schema_sub_categories : [];
            state.CredentialCategoryList = action.payload?.result ? action.payload.result?.credential_definition?.schema?.schema_sub_categories : [];

        });
        builder.addCase(getSchemaJsonWithReq.fulfilled, (state, action) => {
            state.schemaJson = action.payload?.result ? action.payload.result?.attributes : null;
        });
        builder.addCase(getCategoryOfTinkReq.fulfilled, (state, action) => {
            state.CategoryFromTink = action.payload?.result ? action.payload.result?.categories : null;
        });
    },
});

export default credentialsSlice;
