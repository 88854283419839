/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../../slices/components/componentsSlice"
import { showToast } from "../../../../components/custom-toasts/custom-toasts"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTCard, KTSVG } from "../../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import CustomTextbox from "../../../../components/customTextbox/CustomTextbox"
import CustomRichTextbox from "../../../../components/customTextbox/CustomRichTextbox"
import { deleteNotificationWithId, exportNotification, getMerchants, getNotification, getNotificationWithId, postNotification, postSendNotification, putNotification } from "../../../../slices/assetManagement/console/NotificationSlice"
import CustomSelect from "../../../../components/customModal/CustomSelect"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import CopyToClipboard from "react-copy-to-clipboard"

interface Props {
    NotificationListSlice: any
}

const NotificationList: FC<Props> = ({ NotificationListSlice }) => {
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [contentCopied, setContentCopied] = useState(false)
    const [selectedRowId, setSelectedRowId] = useState(undefined)
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([{ "property": "CreateDateUtc", "direction": 1 }])
    const [merchantList, setMerchantList] = useState<any>([])
    const [filterParams, setfilterParams] = useState<any>([])
    const [flag, setflag] = useState<boolean>(false) // true = submit, false = send

    const formik = useFormik({
        initialValues: {
            id: '',
            content: '',
            merchants: [],
        },
        validationSchema: Yup.object().shape({
            content: Yup.string()
                .required('Content is required').max(1000, "Max 1000 character"),
            merchants: flag ? Yup.array() : Yup.array().min(1, "Merchant is required"),
        }),
        onSubmit: (values, actions) => {
            try {
                if (flag) {
                    if (isNotEmpty(values.id)) {
                        updateWithId(values)
                    }
                    else {
                        addNotification(values)
                    }
                } else {
                    sendNotification(values)
                }

            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    function addNotification(request: any) {
        let req = {
            content: request?.content,
        }
        dispatch(postNotification(req)).then((response) => {
            dispatch(getNotification({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                showToast.success("Item Saved Successfully")
            } else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }
    function updateWithId(request: any) {
        let req = {
            id: selectedRowId,
            content: request?.content,
        }
        dispatch(putNotification(req)).then((response) => {
            dispatch(getNotification({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                showToast.success("Item Updated Successfully")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }
    function sendNotification(request: any) {
        let req = {
            notification_id: request?.id,
            merchant_ids: request?.merchants?.map((x: any) => x.id),
            content: request?.content,
        }
        dispatch(postSendNotification(req)).then((response) => {
            dispatch(getNotification({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                showToast.success("Notification Has Been Sent Successfully")
            } else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    useEffect(() => {
        dispatch(getMerchants()).then(x => {
            setMerchantList(x?.payload?.result?.merchants)
        });

    }, [])

    useEffect(() => {
        if (!contentCopied) {
          return
        }
    
        setTimeout(() => {
          setContentCopied(false)
        }, 1500)
      }, [contentCopied])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(undefined)
            formik.resetForm()
        }
    }, [modalIsOpen])

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.name, "direction": sortDirection === "asc" ? 0 : 1 }])
    };
    useEffect(() => {
        dispatch(getNotification({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, filterParams])

    const columns = useMemo(
        () => [
            {
                name: 'Notification',
                selector: (row: any) => <div>{row?.content}</div>,
            },
            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                            {
                                <>
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setprocessName("Preview")
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                            NotificationWithId(row.id)
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/files/fil024.svg' className='svg-icon-2' />
                                    </div>
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setprocessName("Delete")
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                    </div>
                                </>
                            }

                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const customFilter = [
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Content</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Content"), { "name": "Content", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Content")])
                    }
                    placeHolder={"Type a Content"}
                    name='Content'
                    value={filterQuery.find((x: any) => x.name === "Content") !== undefined ? filterQuery.find((x: any) => x.name === "Content")?.value : ""}
                    customKeypressEvent={() => setfilterParams(filterQuery)}
                ></CustomTextbox>
            </>
        },
    ]


    function NotificationWithId(rowId: any) {
        if (rowId !== undefined) {
            dispatch(getNotificationWithId(rowId)).then((s: any) => {
                formik.setValues({
                    id: s?.payload?.result?.id,
                    content: s?.payload?.result?.notification,
                    merchants: s?.payload?.result?.merchants,
                })
            })
        }
    }

    function deleteWithId() {
        if (selectedRowId !== undefined) {
            dispatch(deleteNotificationWithId(selectedRowId)).then((s: any) => {
                dispatch(getNotification({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                if (s?.payload?.is_success) {
                    showToast.success("Item Deleted Successfully")
                }
                else {
                    showToast.error(s?.payload?.response_exception?.exception_message)
                }
                dispatch(componentsSlice.actions.closeModal({}))
            })
        }
    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Update Notification" : "Create Notification"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Content</label>
                                        <CustomRichTextbox
                                            {...formik.getFieldProps('content')}
                                            onChange={() => null}
                                            placeHolder={"Type a Content"}
                                            name='content'
                                            value={formik.values.content}
                                            formik={formik}
                                            formikName={"content"}
                                            height={"23vh"}
                                        ></CustomRichTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Merchants</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={merchantList}
                                            isMulti={true}
                                            formik={formik}
                                            formikName={"merchants"}
                                            selectedValue={formik?.values?.merchants}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                        ></CustomSelect>
                                    </div>
                                </div>
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        onClick={() => {
                                            setflag(false)
                                        }}
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Send</span>
                                    </button>
                                </div>
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Preview":
                return <>
                    <CustomModal title={"Preview Notification"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <div className='d-flex justify-content-between mb-2'>
                                        <label className='fw-bold fs-6'>Content</label>

                                        <OverlayTrigger
                                            key='copy-to-clipboard'
                                            placement='top'
                                            overlay={<Tooltip id='tooltip-copy-to-clipboard'>Copy Content</Tooltip>}
                                            >
                                            <CopyToClipboard text={formik.values.content} onCopy={() => setContentCopied(true)}>
                                                <a className='highlight-copy btn p-0 px-2'>{contentCopied ? 'copied' : <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-2' />}</a>
                                            </CopyToClipboard>
                                        </OverlayTrigger>
                                    </div>
                                        <CustomRichTextbox
                                            {...formik.getFieldProps('content')}
                                            onChange={() => null}
                                            placeHolder={"Content"}
                                            name='content'
                                            readonly
                                            value={formik.values.content}
                                            formik={formik}
                                            formikName={"content"}
                                            height={"23vh"}
                                        ></CustomRichTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className=' fw-bold fs-6 mb-2'>Merchants</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={merchantList}
                                            isMulti={true}
                                            formik={formik}
                                            disable={true}
                                            formikName={"merchants"}
                                            selectedValue={formik?.values?.merchants}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                        ></CustomSelect>
                                    </div>
                                </div>
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Close
                                    </button>
                                </div>
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => { dispatch(componentsSlice.actions.closeModal({})) }}
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => { deleteWithId() }}
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                        </>
                    </CustomModal>
                </>
            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={NotificationListSlice?.items}
                    title={"Notification List"}
                    pagination
                    paginationServer
                    paginationTotalRows={NotificationListSlice?.total_count}
                    isBusy={dataTableisBusy}
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    onExport={() => {
                        dispatch(exportNotification({ page_index: 0, page_size: 0, filters: filterParams, sort_filters: sortData }));

                    }}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    showTitle
                    showFilter={true}
                    filterOptions={customFilter}
                    searchButtonClickEvent={() => setfilterParams(filterQuery)}
                    sortHandle={handleSort}
                    clearButtonClickEvent={() => {
                        setfilterParams([])
                        setfilterQuery([])
                    }}
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const NotificationWrapper: FC<Props> = ({ NotificationListSlice }) => <QueryRequestProvider>
    <ListViewProvider>
        <NotificationList NotificationListSlice={NotificationListSlice} />
    </ListViewProvider>
</QueryRequestProvider>

export { NotificationWrapper }
