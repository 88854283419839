/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useMemo, useState} from 'react'
import CustomDatatable from '../../components/customDatatable/CustomDatatable'
import {CustomModal} from '../../components/customModal/CustomModal'
import {AppDispatch, useAppSelector} from '../../store/store'
import {useDispatch} from 'react-redux'
import componentsSlice from '../../slices/components/componentsSlice'
import {showToast} from '../../components/custom-toasts/custom-toasts'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, KTCard, KTSVG} from '../../../_metronic/helpers'
import {QueryRequestProvider} from '../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import {ListViewProvider} from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import CustomTextbox from '../../components/customTextbox/CustomTextbox'
import {
  exportReferral,
  getReferral,
  getReferralDetailWithId,
  getReferralUsersWithId,
  getReferralWithId,
  postReferral,
  postReferralRemittance,
  putReferral,
} from '../../slices/referralManagement/referralManagementSlice'
import CustomSelect from '../../components/customModal/CustomSelect'
import {Tab, Tabs} from 'react-bootstrap'
import {ClientUserTypeEnum} from '../../utils/enums/ClientUserTypeEnum.enum'
import CustomFilterNumberRange from '../../components/customFilter/CustomFilterNumberRange'
import moment from 'moment'

interface Props {
  ReferralManagementListSlice: any
}

const ClientUserType = [
  {
    value: 0,
    label: 'None',
  },
  {
    value: 1,
    label: 'Merchant',
  },
  {
    value: 2,
    label: 'Consumer',
  },
  {
    value: 3,
    label: 'Back Office',
  },
  {
    value: 4,
    label: 'FreedomPay',
  },
  {
    value: 5,
    label: 'Employee Portal',
  },
]

const ReferralManagementList: FC<Props> = ({ReferralManagementListSlice}) => {
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const {modalIsOpen} = useAppSelector((state) => state.components)
  const dispatch: AppDispatch = useDispatch()
  const [processName, setprocessName] = useState('Insert')
  const [selectedRowId, setSelectedRowId] = useState(undefined)
  const {ReferralUserSlice, ReferralDetailSlice, ReferralListOnlyIdName} = useAppSelector(
    (state) => state.referralManagement
  )
  const [UserId, setUserId] = useState(undefined)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [filterQuery, setfilterQuery] = useState<any>([])
  const [sortData, setsortData] = useState<any>([])
  const [filterParams, setfilterParams] = useState<any>([])

  const formik = useFormik({
    initialValues: {
      id: '',
      user: undefined,
      shareRate: '',
    },
    validationSchema: Yup.object().shape({
      user: Yup.object().required('User is required').nullable(),
      shareRate: Yup.string().min(1, 'Minimum 1 symbols').required('Share Rate is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        if (isNotEmpty(values.id)) {
          updateWithId(values)
        } else {
          createWithRequest(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm()
      }
    },
  })

  useEffect(() => {
    setdataTableisBusy(false)
  }, [ReferralManagementListSlice])

  useEffect(() => {
    if (modalIsOpen === undefined) {
      setprocessName('Insert')
      setSelectedRowId(undefined)
      setUserId(undefined)
      formik.resetForm()
    }
  }, [modalIsOpen])

  const walletColumns = [
    {
      name: 'Name',
      selector: (row: any) => <div>{row?.user_name}</div>,
      width: 'auto',
    },
  ]

  const earnedFrpTransactionsColumns = [
    {
      name: 'Description',
      selector: (row: any) => <div>{row?.description}</div>,
      width: '240px',
    },
    {
      name: 'Amount',
      selector: (row: any) => <div>{Number(row?.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' FRP'}</div>,
      width: '110px',
      right: true,
    },
    {
      name: 'Date',
      selector: (row: any) => <div>{moment(row?.date_created).format('MM/DD/YYYY')}</div>,
      width: '130px',
    },
  ]

  //#region DataTable Methods

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row: any) => <div>{row?.user_name}</div>,
        width: 'auto',
      },
      {
        name: 'Share Rate',
        label: "ShareRate",
        selector: (row: any) => <div>{row?.share_rate + '%'}</div>,
        width: '150px',
        sortable: true
      },
      {
        name: 'Action',
        selector: (row: any) => (
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {
                <>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Detail')
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                      ReferralDetailWithId(row.id)
                      setUserId(row.user_id)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/coding/cod002.svg' className='svg-icon-3' />
                  </div>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Update')
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                      ReferralManagementWithId(row.id)
                      setUserId(row.user_id)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </div>
                </>
              }
            </div>
          </>
        ),
        width: '100px',
      },
    ],
    []
  )

  const customFilter = [
    {
      component: (
        <>
          <label className='fw-bold fs-6 mb-2'>Merchant</label>
          <CustomSelect
            onChange={(e: any) =>
              e.value !== -1
                ? setfilterQuery([
                    ...filterQuery.filter((x: any) => x.name !== 'UserID'),
                    {name: 'UserID', comparator: 'Equals', value: e.value},
                  ])
                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== 'UserID')])
            }
            data={ReferralListOnlyIdName}
            isMulti={false}
            selectedValue={
              ReferralListOnlyIdName?.find(
                (x: any) => x.value === filterQuery.find((x: any) => x.name === 'UserID')?.value
              ) !== undefined
                ? ReferralListOnlyIdName?.find(
                    (x: any) => x.value === filterQuery.find((x: any) => x.name === 'UserID')?.value
                  )
                : -1
            }
          ></CustomSelect>
        </>
      ),
    },
    {
      component: (
        <>
          <CustomFilterNumberRange
            filterName='ShareRate'
            filterTitle='Share Rate'
            customFunction={setfilterQuery}
            customQuery={filterQuery}
          ></CustomFilterNumberRange>
        </>
      ),
    },
  ]

  const handlePageChange = (page: any) => {
    setdataTableisBusy(true)
    setPage(page - 1)
    setdataTableisBusy(false)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setdataTableisBusy(true)
    setPerPage(newPerPage)
    setdataTableisBusy(false)
  }
  const handleSort = async (column: any, sortDirection: any) => {
    setsortData([{property: column?.label, direction: sortDirection === 'asc' ? 0 : 1}])
  }
  useEffect(() => {
    dispatch(
      getReferral({
        user_type: ClientUserTypeEnum.Merchant,
        page_index: page,
        page_size: perPage,
        filters: filterParams,
        sort_filters: sortData,
      })
    )
  }, [page, perPage, sortData, filterParams])

  //#endregion DataTable Methods End

  function ReferralManagementWithId(rowId: any) {
    if (rowId !== undefined) {
      dispatch(getReferralWithId(rowId)).then((s: any) => {
        formik.setValues({
          id: s?.payload?.result?.partner?.id,
          user: {
            label: s?.payload?.result?.partner?.user_name,
            value: s?.payload?.result?.partner?.user_id,
          } as any,
          shareRate: s?.payload?.result?.partner?.share_rate,
        })
      })
    }
  }

  function ReferralDetailWithId(rowId: any) {
    if (rowId !== undefined) {
      dispatch(getReferralDetailWithId(rowId)).then((s: any) => {})
    }
  }

  function updateWithId(request: any) {
    let req = {
      id: request.id,
      share_rate: request.shareRate,
    }
    dispatch(putReferral(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(
          getReferral({
            user_type: ClientUserTypeEnum.Merchant,
            page_index: page,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        dispatch(getReferralUsersWithId(ClientUserTypeEnum.Merchant))
        showToast.success('Item Updated Successfully')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function createWithRequest(request: any) {
    let req = {
      user_id: request.user?.value,
      user_type: ClientUserType[1].value,
      share_rate: request.shareRate,
    }
    dispatch(postReferral(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(
          getReferral({
            user_type: ClientUserTypeEnum.Merchant,
            page_index: 0,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        dispatch(getReferralUsersWithId(ClientUserTypeEnum.Merchant))
        showToast.success('Item Saved Successfully')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function postRemittance() {
    if (UserId !== undefined) {
      let req = {
        referral_id: UserId,
      }
      dispatch(postReferralRemittance(req)).then((response) => {
        if (response?.payload?.is_success) {
          showToast.success('Remittance Successfully')
          ReferralDetailWithId(selectedRowId)
        } else {
          showToast.error(response?.payload?.response_exception?.exception_message)
        }
      })
    }
  }

  function findProcessScreen(processName: string) {
    switch (processName) {
      case 'Insert':
        return (
          <>
            <CustomModal title={'Create Referral'}>
              <>
                <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                  <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                  >
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>User</label>
                      <CustomSelect
                        onChange={() => null}
                        data={ReferralUserSlice}
                        isMulti={false}
                        selectedValue={formik?.values?.user}
                        formik={formik}
                        formikName={'user'}
                      ></CustomSelect>
                    </div>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Share Rate</label>
                      <CustomTextbox
                        {...formik.getFieldProps('shareRate')}
                        onChange={() => null}
                        placeHolder={'Share Rate'}
                        name='shareRate'
                        formik={formik}
                        formikName={'shareRate'}
                        mask={'99%'}
                        prefix={'%'}
                        value={formik.values.shareRate}
                      ></CustomTextbox>
                    </div>
                  </div>
                  {/* begin::Actions */}
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                      className='btn btn-light me-3'
                      data-kt-users-modal-action='cancel'
                    >
                      Discard
                    </button>

                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                    >
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </div>
                  {/* end::Actions */}
                </form>
              </>
            </CustomModal>
          </>
        )

      case 'Update':
        return (
          <>
            <CustomModal title={'Update Referral'}>
              <>
                <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                  <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                  >
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>User</label>
                      <CustomSelect
                        onChange={() => null}
                        data={ReferralUserSlice}
                        isMulti={false}
                        selectedValue={formik?.values?.user}
                        formik={formik}
                        formikName={'user'}
                        disable={true}
                      ></CustomSelect>
                    </div>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Share Rate</label>
                      <CustomTextbox
                        {...formik.getFieldProps('shareRate')}
                        onChange={() => null}
                        placeHolder={'Share Rate'}
                        name='shareRate'
                        formik={formik}
                        formikName={'shareRate'}
                        mask={'99%'}
                        prefix={'%'}
                        value={formik.values.shareRate}
                      ></CustomTextbox>
                    </div>
                  </div>
                  {/* begin::Actions */}
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                      className='btn btn-light me-3'
                      data-kt-users-modal-action='cancel'
                    >
                      Discard
                    </button>

                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                    >
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </div>
                  {/* end::Actions */}
                </form>
              </>
            </CustomModal>
          </>
        )

      case 'Detail':
        return (
          <>
            <CustomModal title={'Referral Detail'}>
              <>
                <Tabs defaultActiveKey='wallets' id='tab' className='mb-3'>
                  <Tab eventKey='wallets' title='Wallets'>
                    <CustomDatatable
                      columns={walletColumns}
                      data={ReferralDetailSlice?.wallets}
                      isBusy={dataTableisBusy}
                      noHeader
                      noMargin
                    ></CustomDatatable>
                  </Tab>
                  <Tab eventKey='earnedFrpTransactions' title='Earned Frp Transactions'>
                    <CustomDatatable
                      columns={earnedFrpTransactionsColumns}
                      data={ReferralDetailSlice?.earned_frp_transactions}
                      isBusy={dataTableisBusy}
                      noHeader
                      noMargin
                    ></CustomDatatable>
                  </Tab>
                </Tabs>
              </>
            </CustomModal>
          </>
        )

      default:
        return null
    }
  }

  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={ReferralManagementListSlice?.items}
          title={'Referral List'}
          pagination
          paginationServer
          paginationTotalRows={ReferralManagementListSlice?.total_count}
          isBusy={dataTableisBusy}
          isNewItem
          isNewItemString={'New Item'}
          isExport
          onExport={() => {
            dispatch(
              exportReferral({
                user_type: ClientUserTypeEnum.Merchant,
                page_index: 0,
                page_size: 0,
                filters: filterParams,
                sort_filters: sortData,
              })
            )
          }}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          showTitle
          showFilter={true}
          filterOptions={customFilter}
          searchButtonClickEvent={() => setfilterParams(filterQuery)}
          sortHandle={handleSort}
          clearButtonClickEvent={() => {
            setfilterParams([])
            setfilterQuery([])
          }}
        ></CustomDatatable>
      </KTCard>
      {modalIsOpen !== undefined ? findProcessScreen(processName) : null}
    </>
  )
}

const ReferralManagementWrapper: FC<Props> = ({ReferralManagementListSlice}) => (
  <QueryRequestProvider>
    <ListViewProvider>
      <ReferralManagementList ReferralManagementListSlice={ReferralManagementListSlice} />
    </ListViewProvider>
  </QueryRequestProvider>
)

export {ReferralManagementWrapper}
