/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../../slices/components/componentsSlice"
import { showToast } from "../../../../components/custom-toasts/custom-toasts"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTCard, KTSVG } from "../../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { deleteFaqWithId, exportFaq, getFaq, getFaqWithId, postFaq, putFaq } from "../../../../slices/assetManagement/console/FaqSlice"
import CustomTextbox from "../../../../components/customTextbox/CustomTextbox"
import CustomRichTextbox from "../../../../components/customTextbox/CustomRichTextbox"

interface Props {
    FaqListSlice: any,
    type: any
}

const FaqList: FC<Props> = ({ FaqListSlice, type }) => {
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [selectedRowId, setSelectedRowId] = useState(undefined)
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([{ "property": "IsActive", "direction": 1 }])
    const [filterParams, setfilterParams] = useState<any>([])

    const formik = useFormik({
        initialValues: {
            id: '',
            questions: '',
            detail: '',
            isActive: false
        },
        validationSchema: Yup.object().shape({
            detail: Yup.string()
                .min(10, 'Minimum 10 symbols.')
                .max(1000, 'Maximum 1000 symbols')
                .required('Detail is required'),
            questions: Yup.string()
                .min(5, 'Minimum 5 symbols.')
                .max(400, 'Maximum 400 symbols')
                .required('Question is required'),
        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateWithId(values)
                }
                else {
                    createWithRequest(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    useEffect(() => {
        setdataTableisBusy(false)
    }, [FaqListSlice])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(undefined)
            formik.resetForm()
        }
    }, [modalIsOpen])

    //#region DataTable Methods

    const columns = useMemo(
        () => [
            {
                name: 'Question',
                selector: (row: any) => <div>{row?.question}</div>,
                width: "280px",
            },
            {
                name: 'Detail',
                selector: (row: any) => <div>{row?.detail}</div>,
                width: "650px",
            },
            {
                width: "auto",
            },
            {
                name: 'Status',
                selector: (row: any) => row?.is_active ?
                    <span className='badge badge-light-success fs-5 fw-semibold'>{row?.is_active.toString()}</span> :
                    <span className='badge badge-light-danger fs-5 fw-semibold'>{row?.is_active.toString()}</span>
                ,
                width: "80px",
            },
            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                            {
                                <>
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setprocessName("Insert")
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                            FaqWithId(row.id, "Insert")
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                    </div>
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setprocessName("Delete")
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                    </div>
                                </>
                            }

                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const customFilter = [
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Question</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Question"), { "name": "Question", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Question")])
                    }
                    placeHolder={"Type a Question"}
                    name='Question'
                    value={filterQuery.find((x: any) => x.name === "Question") !== undefined ? filterQuery.find((x: any) => x.name === "Question")?.value : ""}
                    customKeypressEvent={() => setfilterParams(filterQuery)}
                ></CustomTextbox>
            </>
        },
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Detail</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Detail"), { "name": "Detail", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Detail")])
                    }
                    placeHolder={"Type a Detail"}
                    name='Detail'
                    value={filterQuery.find((x: any) => x.name === "Detail") !== undefined ? filterQuery.find((x: any) => x.name === "Detail")?.value : ""}
                    customKeypressEvent={() => setfilterParams(filterQuery)}
                ></CustomTextbox>
            </>
        },
        {
            component: <>
                <div className='form-check form-check-solid' style={{ marginTop: "2.9rem" }}>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        id='is_active'
                        checked={filterQuery.find((x: any) => x.name === "IsActive") !== undefined ? filterQuery.find((x: any) => x.name === "IsActive")?.value : false}
                        onChange={(e) => setfilterQuery([...filterQuery.filter((x: any) => x.name !== "IsActive"), { "name": "IsActive", "comparator": "Equal", "value": e.target.checked }])}
                    />
                    <label
                        className='form-check-label ps-2'
                        htmlFor='is_active'
                    >
                        Is Active
                    </label>
                </div>
            </>
        },
    ]

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.name, "direction": sortDirection === "asc" ? 0 : 1 }])
    };
    useEffect(() => {
        dispatch(getFaq({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, filterParams])


    //#endregion DataTable Methods End

    function FaqWithId(rowId: any, process: string) {
        if (rowId !== undefined) {
            dispatch(getFaqWithId(rowId)).then((s: any) => {
                formik.setValues({
                    id: s?.payload?.result?.faq?.id,
                    questions: s?.payload?.result?.faq?.question,
                    detail: s?.payload?.result?.faq?.detail,
                    isActive: s?.payload?.result?.faq?.is_active,
                })
            })
        }
    }

    function updateWithId(request: any) {
        let req = {
            id: selectedRowId,
            question: request.questions,
            detail: request.detail,
            is_active: request.isActive,
        }
        dispatch(putFaq(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getFaq({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function deleteWithId() {
        if (selectedRowId !== undefined) {
            dispatch(deleteFaqWithId(selectedRowId)).then((s: any) => {
                if (s?.payload?.is_success) {
                    dispatch(getFaq({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.success("Item Saved Deleted")
                    dispatch(componentsSlice.actions.closeModal({}))
                }
                else {
                    dispatch(getFaq({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.error(s?.payload?.response_exception?.exception_message)
                    dispatch(componentsSlice.actions.closeModal({}))
                }
            })
        }
    }

    function createWithRequest(request: any) {
        let req = {
            question: request.questions,
            detail: request.detail,
            is_active: request.isActive,
        }
        dispatch(postFaq(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getFaq({ page_index: 0, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
            }
        })
    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Update FAQ" : "Create FAQ"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Question</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('questions')}
                                            onChange={() => null}
                                            placeHolder={"Type a Question"}
                                            name='questions'
                                            value={formik.values.questions}
                                            formik={formik}
                                            formikName={"questions"}
                                            type='text'
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Detail</label>
                                        <CustomRichTextbox
                                            {...formik.getFieldProps('detail')}
                                            onChange={() => null}
                                            placeHolder={"Type a Detail"}
                                            name='detail'
                                            value={formik.values.detail}
                                            formik={formik}
                                            formikName={"detail"}
                                            height={"23vh"}
                                        ></CustomRichTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <div className='form-check form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value=''
                                                id='kt_settings_notification_phone'
                                                defaultChecked={formik.values.isActive}
                                                checked={formik.values.isActive}
                                                onChange={(event) =>
                                                    formik?.setValues({
                                                        ...formik?.values,
                                                        isActive: event.target.checked
                                                    })
                                                }
                                            />
                                            <label
                                                className='form-check-label ps-2'
                                                htmlFor='kt_settings_notification_phone'
                                            >
                                                Is Active
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteWithId()
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={FaqListSlice?.items}
                    title={"FAQ List"}
                    pagination
                    paginationServer
                    paginationTotalRows={FaqListSlice?.total_count}
                    isBusy={dataTableisBusy}
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    onExport={() => {

                        dispatch(exportFaq({ page_index: 0, page_size: 0, filters: filterParams, sort_filters: sortData }));

                    }}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    showTitle
                    showFilter={true}
                    filterOptions={customFilter}
                    searchButtonClickEvent={() => setfilterParams(filterQuery)}
                    sortHandle={handleSort}
                    clearButtonClickEvent={() => {
                        setfilterParams([])
                        setfilterQuery([])
                    }}
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const FaqWrapper: FC<Props> = ({ FaqListSlice, type }) => <QueryRequestProvider>
    <ListViewProvider>
        <FaqList FaqListSlice={FaqListSlice} type={type} />
    </ListViewProvider>
</QueryRequestProvider>

export { FaqWrapper }
