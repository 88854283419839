/* eslint-disable no-duplicate-case */
import { faAdn, faAndroid, faApple, faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faAddressCard,
  faArrowDown,
  faArrowDownToLine,
  faArrowRightArrowLeft,
  faBaby,
  faBagsShopping,
  faBaguette,
  faBeer,
  faBicycle,
  faBolt,
  faBone,
  faBooks,
  faBridge,
  faBriefcase,
  faBurger,
  faBus,
  faCalendarAlt,
  faCalendarDays,
  faCameraMovie,
  faCandy,
  faCar,
  faCarBus,
  faCards,
  faCarGarage,
  faCarMirrors,
  faCarWrench,
  faChartBar,
  faChartColumn,
  faChartGantt,
  faChartLine,
  faCheeseburger,
  faChildren,
  faCoffee,
  faCoins,
  faCreditCard,
  faDice,
  faDroplet,
  faDryer,
  faDumbbell,
  faEllipsis,
  faFerrisWheel,
  faFileInvoice,
  faFileSignature,
  faFireFlameCurved,
  faFireFlameSimple,
  faForkKnife,
  faGamepadModern,
  faGasPump,
  faGift,
  faGlasses,
  faGraduationCap,
  faHandHoldingDollar,
  faHandshake,
  faHotel,
  faHouseBuilding,
  faHouseHeart,
  faHouseTree,
  faIdBadge,
  faIdCard,
  faIdCardAlt,
  faIdCardClip,
  faKey,
  faKitMedical,
  faLandmark,
  faMapLocationDot,
  faMapMarkedAlt,
  faMasksTheater,
  faMobile,
  faMobileButton,
  faMoneyBill1Wave,
  faMoneyCheck,
  faMoneyCheckDollar,
  faMoneyCheckDollarPen,
  faMusic,
  faPaintRoller,
  faPassport,
  faPaw,
  faPenAlt,
  faPenClip,
  faPercent,
  faPersonChalkboard,
  faPiggyBank,
  faPlane,
  faPlug,
  faPrescriptionBottleMedical,
  faRacquet,
  faReceipt,
  faRibbon,
  faScaleBalanced,
  faShieldCheck,
  faShip,
  faShoppingBag,
  faSmoking,
  faSpa,
  faSuitcaseRolling,
  faTaxi,
  faTennisBall,
  faTicket,
  faTicketAlt,
  faTimes,
  faTooth,
  faToothbrush,
  faTrain,
  faTshirt,
  faTvRetro,
  faUser,
  faUserCheck,
  faUserDoctor,
  faUsers,
  faUtensils,
  faWifi,
  IconDefinition,
  faWallet,
  faCartCircleArrowDown,
  faCartCircleArrowUp,
  faCircleCheck,
  faCircleQuestion,
  faDownToBracket,
  faUpFromBracket,
  faPlaneDeparture,
  faBed,
  faBagShopping,
  faShop,
  faPersonPinball,
  faTruckPlane,
  faMobileSignalOut,
  faUserHelmetSafety,
  faUsersRays,
  faHandsHoldingDollar,
  faMoneyBillSimpleWave,
} from "@fortawesome/pro-light-svg-icons";
import { faX } from "@fortawesome/pro-solid-svg-icons";

function fontAwesomeHelper(iconText: IconDefinition | string) {
  switch (iconText) {
    case 'facebook':
      return faFacebook;
    case 'adn':
      return faAdn;
    case 'instagram':
      return faInstagram;
    case 'linkedin':
      return faLinkedin;
    case 'arrow-down-to-line':
      return faArrowDownToLine;
    case 'arrow-down-to-line':
      return faArrowDownToLine;
    case 'landmark':
      return faLandmark;
    case 'piggy-bank':
      return faPiggyBank;
    case 'coins':
      return faCoins;
    case 'arrow-right-arrow-left':
      return faArrowRightArrowLeft;
    case 'ellipsis':
      return faEllipsis;
    case 'hand-holding-dollar':
      return faHandHoldingDollar;
    case 'money-check-dollar':
      return faMoneyCheckDollar;
    case 'wifi':
      return faWifi;
    case 'car-mirrors':
      return faCarMirrors;
    case 'file-signature':
      return faFileSignature;
    case 'car-garage':
      return faCarGarage;
    case 'gas-pump':
      return faGasPump;
    case 'car-wrench':
      return faCarWrench;
    case 'graduation-cap':
      return faGraduationCap;
    case 'money-check-dollar-pen':
      return faMoneyCheckDollarPen;
    case 'books':
      return faBooks;
    case 'person-chalkboard':
      return faPersonChalkboard;
    case 'masks-theater':
      return faMasksTheater;
    case 'music':
      return faMusic;
    case 'users':
      return faUsers;
    case 'gamepad-modern':
      return faGamepadModern;
    case 'camera-movie':
      return faCameraMovie;
    case 'ticket':
      return faTicket;
    case 'handshake':
      return faHandshake;
    case 'credit-card':
      return faCreditCard;
    case 'house-heart':
      return faHouseHeart;
    case 'shield-check':
      return faShieldCheck;
    case 'car':
      return faCar;
    case 'fork-knife':
      return faForkKnife;
    case 'beer':
      return faBeer;
    case 'utensils':
      return faUtensils;
    case 'coffee':
      return faCoffee;
    case 'burger':
      return faBurger;
    case 'candy':
      return faCandy;
    case 'money-bill-1-wave':
      return faMoneyBill1Wave;
    case 'cards':
      return faCards;
    case 'ferris-wheel':
      return faFerrisWheel;
    case 'dice':
      return faDice;
    case 'money-check':
      return faMoneyCheck;
    case 'ribbon':
      return faRibbon;
    case 'gift':
      return faGift;
    case 'kit-medical':
      return faKitMedical;
    case 'user-doctor':
      return faUserDoctor;
    case 'tooth':
      return faTooth;
    case 'glasses':
      return faGlasses;
    case 'prescription-bottle-medical':
      return faPrescriptionBottleMedical;
    case 'dumbbell':
      return faDumbbell;
    case 'paw':
      return faPaw;
    case 'racquet':
      return faRacquet;
    case 'baby':
      return faBaby;
    case 'mobile-button':
      return faMobileButton;
    case 'apple':
      return faApple;
    case 'android':
      return faAndroid;
    case 'toothbrush':
      return faToothbrush;
    case 'spa':
      return faSpa;
    case 'dryer':
      return faDryer;
    case 'bolt':
      return faBolt;
    case 'fire-flame-curved':
      return faFireFlameCurved;
    case 'fire-flame-simple':
      return faFireFlameSimple;
    case 'children':
      return faChildren;
    case 'receipt':
      return faReceipt;
    case 'pen-clip':
      return faPenClip;
    case 'key':
      return faKey;
    case 'smoking':
      return faSmoking;
    case 'bone':
      return faBone;
    case 'tennis-ball':
      return faTennisBall;
    case 'times':
      return faTimes;
    case 'plug':
      return faPlug;
    case 'paint-roller':
      return faPaintRoller;
    case 't-shirt':
      return faTshirt;
    case 'baguette':
      return faBaguette;
    case 'bag-shopping':
      return faBagsShopping;
    case 'chart-column':
      return faChartColumn;
    case 'briefcase':
      return faBriefcase;
    case 'chart-line':
      return faChartLine;
    case 'chart-gantt':
      return faChartGantt;
    case 'house-building':
      return faHouseBuilding;
    case 'user-check':
      return faUserCheck;
    case 'tv-retro':
      return faTvRetro;
    case 'house-tree':
      return faHouseTree;
    case 'percent':
      return faPercent;
    case 'hotel':
      return faHotel;
    case 'ship':
      return faShip;
    case 'train':
      return faTrain;
    case 'plane':
      return faPlane;
    case 'suitcase-rolling':
      return faSuitcaseRolling;
    case 'taxi':
      return faTaxi;
    case 'bridge':
      return faBridge;
    case 'bicycle':
      return faBicycle;
    case 'bus':
      return faBus;
    case 'car-bus':
      return faCarBus;
    case 'droplet':
      return faDroplet;
    case 'scale-balanced':
      return faScaleBalanced;
    case 'map-location-dot':
      return faMapLocationDot;
    case 'calendar-days':
      return faCalendarDays;
    case 'id-badge':
      return faIdBadge;
    case 'user':
      return faUser;
    case 'id-card':
      return faIdCard;
    case 'passport':
      return faPassport;
    case 'id-card-clip':
      return faIdCardClip;
    case 'id-card-clip':
      return faIdCardClip;
    case 'mobile':
      return faMobile;
    case 'ticket-alt':
      return faTicketAlt;
    case 'file-invoice':
      return faFileInvoice;
    case 'pen-alt':
      return faPenAlt;
    case 'shopping-bag':
      return faShoppingBag;
    case 'chart-bar':
      return faChartBar;
    case 'map-marked-alt':
      return faMapMarkedAlt;
    case 'calendar-alt':
      return faCalendarAlt;
    case 'address-card':
      return faAddressCard;
    case 'id-card-alt':
      return faIdCardAlt;
    case 'arrow-turn-down':
      return faArrowDown;
    case 'cheeseburger':
      return faCheeseburger;
    case 'wallet':
      return faWallet;
    case 'up-from-bracket':
      return faUpFromBracket;
    case 'down-to-bracket':
      return faDownToBracket;
    case 'cart-circle-arrow-up':
      return faCartCircleArrowUp;
    case 'cart-circle-arrow-down':
      return faCartCircleArrowDown;
    case 'circle-check':
      return faCircleCheck;
    case 'circle-question':
      return faCircleQuestion;
    case 'plane-departure':
      return faPlaneDeparture;
    case 'bed':
      return faBed;
    case 'bags-shopping':
      return faBagShopping;
    case 'shop':
      return faShop;
    case 'person-pinball':
      return faPersonPinball;
    case 'truck-plane':
      return faTruckPlane;
    case 'mobile-signal-out':
      return faMobileSignalOut;
    case 'user-helmet-safety':
      return faUserHelmetSafety;
    case 'users-rays':
      return faUsersRays;
    case 'hand-holding-dollar':
      return faHandsHoldingDollar;
    case 'money-bill-simple-wave':
      return faMoneyBillSimpleWave;
    default:
      return faX;
  }
}

export default fontAwesomeHelper