/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTSVG } from "../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import fontAwesomeHelper from "../../../utils/font-awesome-helper"
import { IconDefinition } from "@fortawesome/free-brands-svg-icons"
import CustomSelect from "../../../components/customModal/CustomSelect"
import Style from "../../../../_metronic/assets/sass/components/custom-modal.module.scss"
import { SchemaSubCategory, SchemaAttribute, OpenBankingCategory, AttributeContent } from "../../../model/CredentialModule"
import { faXmark } from "@fortawesome/pro-light-svg-icons"
import credentialsSlice, { getCalculationFormula, getCategoryOfTinkReq } from "../../../slices/credentialManagement/credentials/CredentialsSlice"

interface Props {
    SchemaSubCategory: SchemaSubCategory,
    index: number,
    AttributeFormatList: any[];
}

// This data needs to be pulled by the API
enum AttributeFormats {
    Decimal,
    Boolean,
    Int,
    Selection,
    Date,
    Percentage,
    MerchantLabelPair = "Merchant Label Pair"
}

const CredentialSchemaSubCategoryList: FC<Props> = ({ SchemaSubCategory, index, AttributeFormatList }) => {
    const [totalRowCountValSchemaAttribute, settotalRowCountValSchemaAttribute] = useState(0)
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const [resetDataTable, setresetDataTable] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("")
    const [selectedRowId, setSelectedRowId] = useState("")
    const [attributeContent, setAttributeContent] = useState("")
    const [editAttributeContent, setEditAttributeContent] = useState(false)
    const { subCatValidList } = useAppSelector((state) => state.credentials);
    const [AttributeCalculationFormulaTypes, setAttributeCalculationFormulaTypes] = useState<any>();
    const [OpenBankingList, setOpenBankingList] = useState<any>()

    const schemaSubCategoryFormik = useFormik({
        initialValues: {
            id: '',
            name: '',
            icon: '',
            schemaAttributes: [],
            OpenBankingGroupCategories: [],
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(150, 'Maximum 150 symbols')
                .required('Name is required'),
            icon: Yup.string()
                .max(50, 'Maximum 50 symbols'),
            schemaAttributes: Yup.array()
                .min(1, "Schema Attribute is required")
                .nullable(),
        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    SchemaAdd(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
            }
        },
    })

    const attributeFormik = useFormik({
        initialValues: {
            id: '',
            attributeName: '',
            attributeFormatEnum: null,
            attributeContents: [] as AttributeContent[],
            attributeDisplayName: '',
            attributeIsQueryCriteria: false,
            attributeIsMobileSumField: false,
            attributeCalculationFormula: [],
        },
        validationSchema: Yup.object().shape({
            attributeName: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(150, 'Maximum 150 symbols')
                .required('Name is required'),
            attributeDisplayName: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(100, 'Maximum 100 symbols')
                .required('Display Name is required'),
            attributeFormatEnum: Yup.mixed()
                .required("Attribute Format is required").nullable(false),
            attributeCalculationFormula: Yup.array()
                .min(1, "Calculation Formula is required")
                .nullable(),
            attributeContents:
                Yup.array().when('attributeFormatEnum', (attributeFormatEnum, schema) => {
                    if (attributeFormatEnum?.index === AttributeFormats.Selection) { return schema.required('Attribute Content is required').min(1, 'Attribute Content is required').max(300, 'Maximum 300 symbols'); }
                    return schema;
                })

        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateSchemaAttribute(values)
                }
                else {
                    createSchemaAttribute(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    const openBankingCategoryFormik = useFormik({
        initialValues: {
            id: '',
            openBankingDefinition: undefined,
            openBankingCategoryMapInfo: undefined,
        },
        validationSchema: Yup.object().shape({
            openBankingCategoryMapInfo: Yup.object().required('Category map info is required'),
            openBankingDefinition: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(150, 'Maximum 150 symbols')
                .required('Open Banking Category Definition is required'),
        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateOpenBanking(values)
                }
                else {
                    createOpenBankingGroup(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    useEffect(() => {
        setdataTableisBusy(false)
        settotalRowCountValSchemaAttribute(SchemaSubCategory?.schema_attributes?.length)
        setresetDataTable(!resetDataTable)
        updateschemaSubCategoryFormik(SchemaSubCategory?.schema_attributes.length > 0 ? SchemaSubCategory?.schema_attributes : [])
        dispatch(getCategoryOfTinkReq()).then(response => {
            let tempArray = response?.payload?.result?.categories.map((x: any) => { return ({ value: x.id, label: x.code }) })
            setOpenBankingList(tempArray)
        })
    }, [SchemaSubCategory?.schema_attributes])

    useEffect(() => {
        schemaSubCategoryFormik.setValues({
            icon: SchemaSubCategory.icon,
            id: SchemaSubCategory.id,
            name: SchemaSubCategory.name,
            schemaAttributes: SchemaSubCategory.schema_attributes as [],
            OpenBankingGroupCategories: SchemaSubCategory.open_banking_categories as []
        })
    }, [SchemaSubCategory?.name, SchemaSubCategory?.icon])

    useEffect(() => {
        if (schemaSubCategoryFormik.values.id !== "") {
            let newSubCatValidList;
            if (subCatValidList.find((x: any) => x.id === schemaSubCategoryFormik.values.id)) {
                newSubCatValidList = subCatValidList.filter((x: any) => x.id !== schemaSubCategoryFormik.values.id);
                newSubCatValidList = [...newSubCatValidList, { id: schemaSubCategoryFormik.values.id, value: schemaSubCategoryFormik.isValid }];
            } else {
                newSubCatValidList = [...subCatValidList, { id: schemaSubCategoryFormik.values.id, value: schemaSubCategoryFormik.isValid }]
            }
            dispatch(credentialsSlice.actions.subCatValidList({ validList: newSubCatValidList }))
            dispatch(credentialsSlice.actions.subCatIsValid({ valid: newSubCatValidList.find((x: any) => x.value === false) === undefined ? true : false }))
        }


    }, [schemaSubCategoryFormik.isValid])

    useEffect(() => {

        dispatch(getCalculationFormula()).then(s => {
            if (s.payload.result.calculation_formula_list) {

                setAttributeCalculationFormulaTypes(Object.keys(s.payload.result.calculation_formula_list).map(x => ({ value: +x, label: s.payload.result.calculation_formula_list[x] })))
            }
        })
    }, [])


    const columnsAttribute = [
        {
            name: 'Name',
            selector: (row: any) => <div>{row?.name}</div>,
            width: "240px",
        },
        {
            name: 'Attribute Format',
            selector: (row: any) => <div>{AttributeFormatList?.find(x => x.value === row?.attribute_format_id)?.label}</div>,
        },
        {
            name: 'Attribute Contents',
            selector: (row: any) => <div>{row?.attribute_contents?.map((x: any) => x.value).join(',')}</div>,
            width: "100px",
        },
        {
            name: 'Display Name',
            selector: (row: any) => <div>{row?.display_name}</div>,
        },
        {
            name: 'Calculation Formula',
            selector: (row: any) => <div>{AttributeCalculationFormulaTypes?.find((x: any) => x.value === row?.calculation_formula)?.label}</div>,
        },
        {
            name: 'Is Query Criteria',
            selector: (row: any) => <div>{row?.is_query_criteria.toString()}</div>,
            width: "100px",
        },
        {
            name: 'Is Mobile Sum Field',
            selector: (row: any) => <div>{row?.is_mobile_sum_field.toString()}</div>,
            width: "100px",
        },
        {
            name: 'Action',
            selector: (row: any) =>
                <>
                    <div className='d-flex justify-content-end flex-shrink-0'>

                        {
                            <>
                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => {
                                        setprocessName("SchemaAttributeInsert")
                                        setSelectedRowId(row.id)
                                        schemaAttirbuteWithId(row)
                                        dispatch(componentsSlice.actions.openModal({}))
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                </div>
                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => {
                                        setprocessName("SchemaAttributeDelete")
                                        setSelectedRowId(row.id)
                                        dispatch(componentsSlice.actions.openModal({}))
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                </div>
                            </>
                        }

                    </div>
                </>
            ,
            width: "100px",
        },
    ];

    const attContCol = [
        {
            name: 'Attribute',
            selector: (row: any) => <div>{row?.value}</div>,
        },
        {
            name: 'Action',
            selector: (row: any) =>
                <>
                    <div className='d-flex justify-content-end flex-shrink-0'>

                        {
                            <>
                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => {
                                        setAttributeContent(row.value)
                                        setSelectedRowId(row.id)
                                        setEditAttributeContent(true)
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                </div>
                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => {
                                        let attCntList = attributeFormik?.values?.attributeContents as AttributeContent[];
                                        let att = attCntList.find(x => x.id === row.id) as AttributeContent;
                                        let ind = attCntList.indexOf(att)
                                        attCntList.splice(ind, 1);
                                        attributeFormik.setValues({
                                            ...attributeFormik?.values,
                                            attributeContents: attCntList
                                        });
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                </div>
                            </>
                        }

                    </div>
                </>
            ,
            width: "100px",
        },
    ];

    const columnsOpenBankingCategory = [
        {
            name: 'Open Banking Category Id',
            selector: (row: any) => <div>{row?.open_banking_definition !== "" ? row?.open_banking_category_map_info : row?.open_banking_definition}</div>,
            width: "auto",
        },
        {
            name: 'Open Banking Category Definition',
            selector: (row: any) => <div>{row?.open_banking_definition === "" ? row?.open_banking_category_map_info : row?.open_banking_definition}</div>,
            width: "auto",
        },
        {
            name: 'Action',
            selector: (row: any) =>
                <>
                    <div className='d-flex justify-content-end flex-shrink-0'>

                        {
                            <>
                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => {
                                        setprocessName("OpenBankingDelete")
                                        setSelectedRowId(row.id)
                                        dispatch(componentsSlice.actions.openModal({}))
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                </div>
                            </>
                        }

                    </div>
                </>
            ,
            width: "100px",
        },
    ];

    useEffect(() => {
        //setdataTableisBusy(true) Temp closed until api ready
        //dispatch(getStorage());  Temp closed until api ready
    }, [activePage, pageSize])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("")
            setSelectedRowId("")
            attributeFormik.setValues({
                id: '',
                attributeName: '',
                attributeFormatEnum: null,
                attributeContents: [] as AttributeContent[],
                attributeDisplayName: '',
                attributeIsQueryCriteria: false,
                attributeIsMobileSumField: false,
                attributeCalculationFormula: [],
            }
            )
            openBankingCategoryFormik.resetForm()
            setAttributeContent("");
            setEditAttributeContent(false)
        }
    }, [modalIsOpen])

    const onChangePage = (page: any) => {
        setActivePage(page);
    };
    const onChangeRowsPerPage = (size: any) => {
        setPageSize(size);
    };
    // For DataTable Event End

    function generateUUID() {
        let d = new Date().getTime();
        let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const array = new Uint32Array(1);
            window.crypto.getRandomValues(array)
            let r = array[0] * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    // Schema Attribute Process
    function createSchemaAttribute(values: any) {
        let newItem: SchemaAttribute = {
            name: values?.attributeName,
            attribute_contents: values?.attributeContents,
            display_name: values?.attributeDisplayName,
            is_query_criteria: values?.attributeIsQueryCriteria,
            is_mobile_sum_field: values?.attributeIsMobileSumField,
            calculation_formula: values?.attributeCalculationFormula?.value,
            active_credential_value: [],
            attribute_format_id: values?.attributeFormatEnum?.value,
            id: generateUUID(),
            schema_sub_category: '',
            schema_sub_category_id: '',
            attribute_format: {
                format: '',
                id: '',
                type: 0
            },
        }
        dispatch(credentialsSlice.actions.addCredentialSubSchemaAttributes({ schemaAttribute: newItem, subId: SchemaSubCategory.id }))
        updateschemaSubCategoryFormik(newItem)
    }

    function schemaAttirbuteWithId(row: any) {
        attributeFormik.setValues({
            id: row?.id,
            attributeName: row?.name,
            attributeContents: row?.attribute_contents?.map((x: AttributeContent) => ({ id: x.id, value: x.value } as AttributeContent)),
            attributeDisplayName: row?.display_name,
            attributeIsQueryCriteria: row?.is_query_criteria,
            attributeIsMobileSumField: row?.is_mobile_sum_field,
            attributeCalculationFormula: AttributeCalculationFormulaTypes?.find((x: any) => x.value === row?.calculation_formula),
            attributeFormatEnum: AttributeFormatList.find(x => x.value === row?.attribute_format_id),
        })
    }

    function updateSchemaAttribute(values: any) {
        let newItem: SchemaAttribute = {
            name: values?.attributeName,
            attribute_contents: values?.attributeContents,
            display_name: values?.attributeDisplayName,
            is_query_criteria: values?.attributeIsQueryCriteria,
            is_mobile_sum_field: values?.attributeIsMobileSumField,
            calculation_formula: values?.attributeCalculationFormula?.value,
            id: values.id,
            active_credential_value: [],
            attribute_format_id: values?.attributeFormatEnum?.value,
            schema_sub_category: '',
            schema_sub_category_id: '',
            attribute_format: {
                format: '',
                id: '',
                type: 0
            },
        }
        dispatch(credentialsSlice.actions.updateCredentialSubSchemaAttributes({ schemaAttribute: newItem, subId: SchemaSubCategory.id, schemaAttributeId: values.id }))
        updateschemaSubCategoryFormik(newItem)
    }

    function schemaDeleteWithId(rowId: any) {
        dispatch(credentialsSlice.actions.deleteCredentialSubSchemaAttributes({ subId: SchemaSubCategory.id, schemaAttributeId: rowId }))
    }

    function updateschemaSubCategoryFormik(values: any) {
        schemaSubCategoryFormik.setValues({
            icon: schemaSubCategoryFormik.values.icon,
            id: schemaSubCategoryFormik.values.id,
            name: schemaSubCategoryFormik.values.name,
            schemaAttributes: (values) as [],
            //it will develop
            OpenBankingGroupCategories: (values) as []
        })
    }
    // Schema Attribute Process End

    // Open Banking Process
    function createOpenBankingGroup(values: any) {
        let newItem: OpenBankingCategory = {
            id: generateUUID(),
            open_banking_definition: values?.openBankingDefinition,
            open_banking_category_map_info: values?.openBankingCategoryMapInfo?.value == null ? values?.openBankingCategoryMapInfo : values?.openBankingCategoryMapInfo?.value,
            schema_sub_category: '',
            schema_sub_category_id: ''
        }
        dispatch(credentialsSlice.actions.addCredentialSubOpenBankingCategory({ openBankingCategory: newItem, subId: SchemaSubCategory.id }))
        updateOpenBankingFormik(newItem)
    }

    function updateOpenBanking(values: any) {
        let newItem: OpenBankingCategory = {
            id: values.id,
            open_banking_definition: values?.openBankingDefinition,
            open_banking_category_map_info: values?.openBankingCategoryMapInfo,
            schema_sub_category: '',
            schema_sub_category_id: ''
        }
        dispatch(credentialsSlice.actions.updateCredentialOpenBankingCategory({ openBankingCategory: newItem, subId: SchemaSubCategory.id, openBankingCategoryId: values.id }))
        updateOpenBankingFormik(newItem)
    }

    function openBankingDeleteWithId(rowId: any) {
        dispatch(credentialsSlice.actions.deleteCredentialSubOpenBankingCategory({ subId: SchemaSubCategory.id, openBankingCategoryId: rowId }))
        updateOpenBankingFormik([])
    }

    function updateOpenBankingFormik(values: any) {
        schemaSubCategoryFormik.setValues({
            icon: schemaSubCategoryFormik.values.icon,
            id: schemaSubCategoryFormik.values.id,
            name: schemaSubCategoryFormik.values.name,
            schemaAttributes: schemaSubCategoryFormik.values.schemaAttributes,
            OpenBankingGroupCategories: (values) as []
        })
    }

    // Schema Process

    function SchemaAdd(values: any) {
        dispatch(credentialsSlice.actions.updateCredentialSubCategory({ SchemaSubCategory: values }))
    }

    function SchemaDeleteWithIndex() {
        dispatch(credentialsSlice.actions.deleteCredentialSubCategory({ SchemaSubCategory: SchemaSubCategory }))
    }
    // Schema End

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "SchemaAttributeInsert":
                return <>
                    <CustomModal title={attributeFormik.values.id !== "" ? "Update Attribute" : "New Attribute"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={attributeFormik.handleSubmit}>
                                <div
                                    className={`d-flex flex-column scroll-y me-n7 pe-7 ${Style.insideDiv}`}
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Name</label>
                                        <CustomTextbox
                                            {...attributeFormik.getFieldProps('attributeName')}
                                            onChange={() => null}
                                            placeHolder={"Type a Name"}
                                            name='attributeName'
                                            value={attributeFormik.values.attributeName}
                                            formik={attributeFormik}
                                            formikName={"attributeName"}
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Attribute Format</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={AttributeFormatList}
                                            isMulti={false}
                                            selectedValue={attributeFormik?.values?.attributeFormatEnum}
                                            formik={attributeFormik}
                                            formikName={"attributeFormatEnum"}
                                        ></CustomSelect>
                                    </div>
                                    {
                                        attributeFormik?.values?.attributeFormatEnum !== null &&
                                            ((attributeFormik?.values?.attributeFormatEnum) as any).index === AttributeFormats.Selection
                                            ?
                                            <div className='fv-row mb-7'>
                                                <label className={`required fw-bold fs-6 mb-2`}>Attribute Contents</label>
                                                <div className="row">
                                                    <div className="col">
                                                        <CustomTextbox
                                                            onChange={(e) => { setAttributeContent(e) }}
                                                            placeHolder={"Type an Attribute Content"}
                                                            name='attributeContent'
                                                            value={attributeContent}
                                                        ></CustomTextbox>
                                                    </div>
                                                    <div className="col">
                                                        <button
                                                            type="button"
                                                            className='btn btn-primary btn-sm'
                                                            onClick={() => {
                                                                if (!(attributeContent == null || attributeContent === undefined || attributeContent === "")) {
                                                                    let attCntList = attributeFormik?.values?.attributeContents as AttributeContent[];
                                                                    if (editAttributeContent) {
                                                                        let att = attCntList.find(x => x.id === selectedRowId) as AttributeContent;
                                                                        let ind = attCntList.indexOf(att)
                                                                        attCntList[ind] = { value: attributeContent, id: selectedRowId } as AttributeContent;
                                                                        setEditAttributeContent(false);
                                                                    } else {
                                                                        attCntList.push({ value: attributeContent, id: generateUUID() } as AttributeContent)
                                                                    }
                                                                    attributeFormik.setValues({
                                                                        ...attributeFormik?.values,
                                                                        attributeContents: attCntList
                                                                    });
                                                                    setAttributeContent("");
                                                                }
                                                            }}
                                                        >
                                                            <span className='indicator-label'>{editAttributeContent ? "Edit" : "Add"}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <CustomDatatable
                                                    noMargin
                                                    noHeader
                                                    columns={attContCol}
                                                    data={attributeFormik?.values?.attributeContents}
                                                    formik={attributeFormik}
                                                    formikName={"attributeContents"}
                                                ></CustomDatatable>
                                            </div>
                                            :
                                            ""
                                    }
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Display Name</label>
                                        <CustomTextbox
                                            {...attributeFormik.getFieldProps('attributeDisplayName')}
                                            onChange={() => null}
                                            placeHolder={"Type a Display Name"}
                                            name='attributeDisplayName'
                                            value={attributeFormik.values.attributeDisplayName}
                                            formik={attributeFormik}
                                            formikName={"attributeDisplayName"}
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Calculation Formula</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={AttributeCalculationFormulaTypes}
                                            isMulti={false}
                                            selectedValue={attributeFormik?.values?.attributeCalculationFormula}
                                            formik={attributeFormik}
                                            formikName={"attributeCalculationFormula"}
                                        ></CustomSelect>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='fv-row mb-7'>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        id='is_query_criteria'
                                                        defaultChecked={attributeFormik.values.attributeIsQueryCriteria}
                                                        checked={attributeFormik.values.attributeIsQueryCriteria}
                                                        onChange={(event) =>
                                                            attributeFormik?.setValues({
                                                                ...attributeFormik?.values,
                                                                attributeIsQueryCriteria: event.target.checked
                                                            })
                                                        }
                                                    />
                                                    <label
                                                        className='form-check-label ps-2'
                                                        htmlFor='is_query_criteria'
                                                    >
                                                        Is Query Criteria
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='fv-row mb-7'>
                                                <div className='form-check form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        id='is_mobile_sum'
                                                        defaultChecked={attributeFormik.values.attributeIsMobileSumField}
                                                        checked={attributeFormik.values.attributeIsMobileSumField}
                                                        onChange={(event) =>
                                                            attributeFormik?.setValues({
                                                                ...attributeFormik?.values,
                                                                attributeIsMobileSumField: event.target.checked
                                                            })
                                                        }
                                                    />
                                                    <label
                                                        className='form-check-label ps-2'
                                                        htmlFor='is_mobile_sum'
                                                    >
                                                        Is Mobile Sum Field
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>
            case "SchemaAttributeDelete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }}
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        schemaDeleteWithId(selectedRowId)
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }}
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>
            case "OpenBankingInsert":
                return <>
                    <CustomModal title={openBankingCategoryFormik.values.id !== "" ? "Open Banking Category Update" : "New Open Banking Category"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={openBankingCategoryFormik.handleSubmit} style={{ height: "40vh" }}>
                                <div className='fv-row mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>Open Banking Category</label>
                                    <CustomSelect
                                        onChange={() => null}
                                        data={OpenBankingList}
                                        isMulti={false}
                                        selectedValue={openBankingCategoryFormik?.values?.openBankingCategoryMapInfo}
                                        formik={openBankingCategoryFormik}
                                        formikName={"openBankingCategoryMapInfo"}
                                    ></CustomSelect>
                                </div>

                                <div className='fv-row mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>Open Banking Category Definition</label>
                                    <CustomTextbox
                                        {...openBankingCategoryFormik.getFieldProps('openBankingDefinition')}
                                        onChange={() => null}
                                        placeHolder={"Type a Open Banking Category Definition"}
                                        name='openBankingDefinition'
                                        value={openBankingCategoryFormik.values.openBankingDefinition}
                                        formik={openBankingCategoryFormik}
                                        formikName={"openBankingDefinition"}
                                    ></CustomTextbox>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>
            case "OpenBankingDelete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        openBankingDeleteWithId(selectedRowId)
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }}
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>



            default: return null;
        }
    }

    return (
        <>
            <div className="accordion-item" style={{ borderColor: "black" }}>
                <h2 className="accordion-header" id={`heading${index}`}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`} style={{ backgroundColor: "#f0f0f0", color: schemaSubCategoryFormik.isValid ? "black" : "red" }}>
                        <div className="row">
                            <div className="col-auto">{schemaSubCategoryFormik.values.name}</div>
                            <div className="col-auto px-0 text-danger">{`${index === 0 ? "This Subcategory will be the 'Main Category' of the Credential" : ""}`}</div>
                        </div>
                    </button>
                </h2>
                <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <form id='kt_modal_add_user_form' className='form' onSubmit={schemaSubCategoryFormik.handleSubmit}>
                            <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Name</label>
                                <CustomTextbox
                                    {...schemaSubCategoryFormik.getFieldProps('name')}
                                    onChange={(e) => {
                                        dispatch(credentialsSlice.actions.updateSubCategoryName({ id: schemaSubCategoryFormik.values.id, name: e.trimStart() }));
                                    }}
                                    placeHolder={"Type a Name"}
                                    name='name'
                                    value={schemaSubCategoryFormik.values.name}
                                    formik={schemaSubCategoryFormik}
                                    formikName={"name"}
                                ></CustomTextbox>
                            </div>
                            <div className='fv-row mb-7'>
                                <label className='fw-bold fs-6 mb-2'>Icon</label>
                                <div className="row">
                                    <div className="col-11">
                                        <CustomTextbox
                                            {...schemaSubCategoryFormik.getFieldProps('icon')}
                                            onChange={(e) => {
                                                dispatch(credentialsSlice.actions.updateSubCategoryIcon({ id: schemaSubCategoryFormik.values.id, icon: e.trimStart() }));
                                            }}
                                            placeHolder={"Type an Icon"}
                                            name='icon'
                                            value={schemaSubCategoryFormik.values.icon}
                                            formik={schemaSubCategoryFormik}
                                            formikName={"icon"}
                                        ></CustomTextbox>
                                    </div>
                                    <div className="col-1">
                                        <FontAwesomeIcon
                                            icon={schemaSubCategoryFormik.values.icon !== '' ? fontAwesomeHelper(schemaSubCategoryFormik.values.icon) as IconDefinition : faXmark} size={"2x"} style={{ marginTop: "0.5rem" }}
                                        ></FontAwesomeIcon>
                                    </div>
                                </div>
                            </div>
                            <CustomDatatable
                                columns={columnsAttribute}
                                data={SchemaSubCategory?.schema_attributes}
                                title={"Schema Attribute List"}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRowCountValSchemaAttribute}
                                onChangePage={onChangePage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                isBusy={dataTableisBusy}

                                showTitle


                                isNewItem
                                isNewItemString={"New Item"}
                                newItemClickEvent={
                                    () => {
                                        setprocessName("SchemaAttributeInsert")
                                        dispatch(componentsSlice.actions.openModal({}))
                                    }
                                }
                                formik={schemaSubCategoryFormik}
                                formikName={"schemaAttributes"}
                            ></CustomDatatable>
                            <>
                                <CustomDatatable
                                    columns={columnsOpenBankingCategory}
                                    data={SchemaSubCategory?.open_banking_categories}
                                    title={"Open Banking Category List"}
                                    onChangePage={onChangePage}
                                    onChangeRowsPerPage={onChangeRowsPerPage}
                                    isBusy={dataTableisBusy}
                                    showTitle
                                    isNewItem
                                    isNewItemString={"New Item"}
                                    newItemClickEvent={
                                        () => {
                                            setprocessName("OpenBankingInsert")
                                            dispatch(componentsSlice.actions.openModal({}))
                                        }
                                    }
                                    formik={schemaSubCategoryFormik}
                                    formikName={"OpenBankingCategories"}
                                ></CustomDatatable>
                            </>
                            {
                                index > 0 ? <div className="row">
                                    <div className="col-11"></div>
                                    <div className="col-1">
                                        <button
                                            type='reset'
                                            className='btn btn-secondary'
                                            data-kt-users-modal-action='reset'
                                            onClick={() => {
                                                SchemaDeleteWithIndex()
                                            }}
                                        >
                                            <span className='indicator-label'>Delete</span>
                                        </button>
                                    </div>
                                    {/* <div className="col-1">
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Save</span>
                                    </button>
                                </div> */}
                                </div> : <></>
                            }

                        </form>
                    </div>
                </div>
            </div>


            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const CredentialSchemaSubCategoryWrapper: FC<Props> = ({ SchemaSubCategory, index, AttributeFormatList }) => <QueryRequestProvider>
    <ListViewProvider>
        <CredentialSchemaSubCategoryList SchemaSubCategory={SchemaSubCategory} index={index} AttributeFormatList={AttributeFormatList} />
    </ListViewProvider>
</QueryRequestProvider>

export { CredentialSchemaSubCategoryWrapper }
