import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashboardService from "../../services/dashboard/dashboard.service";

interface InitialStateInterface {
}

const initialState = {
} as InitialStateInterface;

export const getActiveCounts = createAsyncThunk(
    "getActiveCounts",
    async () => {
        try {
            return await dashboardService.getActiveCountsService().then(response => response);
        } catch (error) { }
    }
);
export const getFrpData = createAsyncThunk(
    "getFrpData",
    async () => {
        try {
            return await dashboardService.getFrpDataService().then(response => response);
        } catch (error) { }
    }
);

export const getHealthyStatus = createAsyncThunk(
    "getHealthyStatus",
    async () => {
        try {
            return await dashboardService.getHealthyStatusService().then(response => response);
        } catch (error) { }
    }
);

export const getHybridHealthyStatus = createAsyncThunk(
    "getHybridHealthyStatus",
    async () => {
        try {
            return await dashboardService.getHybridHealthyStatusService().then(response => response);
        } catch (error) { }
    }
);

const DashboardSlice = createSlice({
    name: "DashboardSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
    },
});

export default DashboardSlice;
