/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { getVideo } from '../../../../slices/assetManagement/mobile/introVideos/IntroVideosSlice'
import componentsSlice from '../../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../../store/store'
import { IntroVideosListWrapper } from './IntroVideosList'


const IntroVideosAssetBreadcrumbs: Array<PageLink> = [
  {
    title: `Asset Management`,
    path: '/Videos/Videos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const IntroVideos = () => {
  const dispatch: AppDispatch = useDispatch();

  const { videoList } = useAppSelector(
    (state) => state.video
  );

  useEffect(() => {
    dispatch(componentsSlice.actions.closeModal({}))
    dispatch(getVideo({ page_index: 0, page_size: 10, filters: [], sort_filters: [] }));
  }, [])



  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/Videos'
          element={
            <>
              <PageTitle breadcrumbs={IntroVideosAssetBreadcrumbs}>{`Videos`}</PageTitle>
              <IntroVideosListWrapper videoList={videoList} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default IntroVideos
