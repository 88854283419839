/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react"
import { AppDispatch } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import { showToast } from "../../../components/custom-toasts/custom-toasts"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTCard, KTCardBody } from "../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { getIZonePollingSettings, postIZonePollingSettings, putIZonePollingSettings } from "../../../slices/campaignManagament/iZonePollingSettings/iZonePollingSettingsSlice"
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"

interface Props {
    IZonePollingSettingsSlice: any
}

const IZonePollingSettings: FC<Props> = ({ IZonePollingSettingsSlice }) => {
    const dispatch: AppDispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            queryPollingFrequency: '',
            checkinPollingFrequency: '',
            checkinRefreshTime: '',
            epQrExpireTime: '',
            epCampaignExpireTime: '',
            socialReplyAutomationFrequency: '',
        },
        validationSchema: Yup.object().shape({
            queryPollingFrequency: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Query Polling Frequency is required'),
            checkinPollingFrequency: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Checkin Polling Frequency is required'),
            checkinRefreshTime: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Checkin Refresh Time is required'),
            epQrExpireTime: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Ep Qr Code Expire Time is required'),
            epCampaignExpireTime: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Ep Campaign Expire Time is required'),
            socialReplyAutomationFrequency: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Social Reply Automation Frequency'),
        }),
        onSubmit: (values, actions) => {
            try {
                if (IZonePollingSettingsSlice?.items?.length !== undefined && IZonePollingSettingsSlice?.items?.length > 0) {
                    updateWithId(values)
                }
                else {
                    createWithRequest(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    useEffect(() => {
        formik.setValues({
            queryPollingFrequency: IZonePollingSettingsSlice?.items?.filter((x: { key: string }) => x.key === "QueryPollingFrequency")?.[0]?.value,
            checkinPollingFrequency: IZonePollingSettingsSlice?.items?.filter((x: { key: string }) => x.key === "CheckinPollingFrequency")?.[0]?.value,
            checkinRefreshTime: IZonePollingSettingsSlice?.items?.filter((x: { key: string }) => x.key === "CheckinRefreshTime")?.[0]?.value,
            epQrExpireTime: IZonePollingSettingsSlice?.items?.filter((x: { key: string }) => x.key === "EpQrExpireTime")?.[0]?.value,
            epCampaignExpireTime: IZonePollingSettingsSlice?.items?.filter((x: { key: string }) => x.key === "EpCampaignExpireTime")?.[0]?.value,
            socialReplyAutomationFrequency: IZonePollingSettingsSlice?.items?.filter((x: { key: string }) => x.key === "SocialReplyAutomationFrequency")?.[0]?.value,
        })
    }, [IZonePollingSettingsSlice])


    function updateWithId(request: any) {
        let req = {
            "system_setting_list": [
                {
                    "key": "QueryPollingFrequency",
                    "value": request.queryPollingFrequency
                },
                {
                    "key": "CheckinPollingFrequency",
                    "value": request.checkinPollingFrequency
                },
                {
                    "key": "CheckinRefreshTime",
                    "value": request.checkinRefreshTime
                },
                {
                    "key": "EpQrExpireTime",
                    "value": request.epQrExpireTime
                },
                {
                    "key": "EpCampaignExpireTime",
                    "value": request.epCampaignExpireTime
                },
                {
                    "key": "SocialReplyAutomationFrequency",
                    "value": request.socialReplyAutomationFrequency
                }
            ]
        }
        dispatch(putIZonePollingSettings(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getIZonePollingSettings({ Key: ["QueryPollingFrequency", "CheckinPollingFrequency", "CheckinRefreshTime", "EpQrExpireTime", "EpCampaignExpireTime", "SocialReplyAutomationFrequency"] }))
                showToast.success("Item Saved Successfully")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function createWithRequest(request: any) {
        let req = {
            "system_setting_list": [
                {
                    "key": "QueryPollingFrequency",
                    "value": request.queryPollingFrequency
                },
                {
                    "key": "CheckinPollingFrequency",
                    "value": request.checkinPollingFrequency
                },
                {
                    "key": "CheckinRefreshTime",
                    "value": request.checkinRefreshTime
                },
                {
                    "key": "EpQrExpireTime",
                    "value": request.epQrExpireTime
                },
                {
                    "key": "EpCampaignExpireTime",
                    "value": request.epCampaignExpireTime
                },
                {
                    "key": "SocialReplyAutomationFrequency",
                    "value": request.socialReplyAutomationFrequency
                }
            ]
        }
        dispatch(postIZonePollingSettings(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getIZonePollingSettings({ Key: ["QueryPollingFrequency", "CheckinPollingFrequency", "CheckinRefreshTime", "EpQrExpireTime", "EpCampaignExpireTime", "SocialReplyAutomationFrequency"] }))
                showToast.success("Item Saved Successfully")
            }
        })
    }

    return (
        <>
            <KTCard>
                <KTCardBody>
                    <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                        <div className='fv-row mb-7'>
                            <div className="row">
                                <div className="col-3">
                                    <label className='required fw-bold fs-6 mb-2'>Query Polling Frequency</label>
                                    <CustomTextbox
                                        {...formik.getFieldProps('queryPollingFrequency')}
                                        onChange={() => null}
                                        placeHolder={"Type a Query Polling Frequency"}
                                        name='queryPollingFrequency'
                                        value={formik.values.queryPollingFrequency}
                                        formik={formik}
                                        formikName={"queryPollingFrequency"}
                                        type={"number"}
                                        onlyPositive
                                    ></CustomTextbox>
                                </div>
                                <div className="col" style={{ padding: 0, margin: 0 }}>
                                    <label className='fw-bold fs-6 mt-12'>Seconds</label>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-3">
                                    <label className='required fw-bold fs-6 mb-2'>CheckIn Polling Frequency</label>
                                    <CustomTextbox
                                        {...formik.getFieldProps('checkinPollingFrequency')}
                                        onChange={() => null}
                                        placeHolder={"Type a CheckIn Polling Frequency"}
                                        name='checkinPollingFrequency'
                                        value={formik.values.checkinPollingFrequency}
                                        formik={formik}
                                        formikName={"checkinPollingFrequency"}
                                        type={"number"}
                                        onlyPositive
                                    ></CustomTextbox>
                                </div>
                                <div className="col" style={{ padding: 0, margin: 0 }}>
                                    <label className='fw-bold fs-6 mt-12'>Seconds</label>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-3">
                                    <label className='required fw-bold fs-6 mb-2'>CheckIn Refresh Time</label>
                                    <CustomTextbox
                                        {...formik.getFieldProps('checkinRefreshTime')}
                                        onChange={() => null}
                                        placeHolder={"Type a CheckIn Refresh Time"}
                                        name='checkinRefreshTime'
                                        value={formik.values.checkinRefreshTime}
                                        formik={formik}
                                        formikName={"checkinRefreshTime"}
                                        type={"number"}
                                        onlyPositive
                                        min={900}
                                    ></CustomTextbox>
                                </div>
                                <div className="col" style={{ padding: 0, margin: 0 }}>
                                    <label className='fw-bold fs-6 mt-12'>Seconds</label>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-3">
                                    <label className='required fw-bold fs-6 mb-2'>Ep Qr Code Expire Time</label>
                                    <CustomTextbox
                                        {...formik.getFieldProps('epQrExpireTime')}
                                        onChange={() => null}
                                        placeHolder={"Type an Ep Qr Code Expire Time"}
                                        name='epQrExpireTime'
                                        value={formik.values.epQrExpireTime}
                                        formik={formik}
                                        formikName={"epQrExpireTime"}
                                        type={"number"}
                                        onlyPositive
                                    ></CustomTextbox>
                                </div>
                                <div className="col" style={{ padding: 0, margin: 0 }}>
                                    <label className='fw-bold fs-6 mt-12'>Seconds</label>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-3">
                                    <label className='required fw-bold fs-6 mb-2'>Ep Campaign Expire Time </label>
                                    <CustomTextbox
                                        {...formik.getFieldProps('epCampaignExpireTime')}
                                        onChange={() => null}
                                        placeHolder={"Type an Ep Campaign Expire Time"}
                                        name='epCampaignExpireTime'
                                        value={formik.values.epCampaignExpireTime}
                                        formik={formik}
                                        formikName={"epCampaignExpireTime"}
                                        type={"number"}
                                        onlyPositive
                                    ></CustomTextbox>
                                </div>
                                <div className="col" style={{ padding: 0, margin: 0 }}>
                                    <label className='fw-bold fs-6 mt-12'>Seconds</label>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-3">
                                    <label className='required fw-bold fs-6 mb-2'>Social Reply Automation Frequency</label>
                                    <CustomTextbox
                                        {...formik.getFieldProps('socialReplyAutomationFrequency')}
                                        onChange={() => null}
                                        placeHolder={"Type an Social Reply Automation Frequency"}
                                        name='socialReplyAutomationFrequency'
                                        value={formik.values.socialReplyAutomationFrequency}
                                        formik={formik}
                                        formikName={"socialReplyAutomationFrequency"}
                                        type={"number"}
                                        onlyPositive
                                    ></CustomTextbox>
                                </div>
                                <div className="col" style={{ padding: 0, margin: 0 }}>
                                    <label className='fw-bold fs-6 mt-12'>Seconds</label>
                                </div>
                            </div>
                        </div>
                        {/* begin::Actions */}
                        <div className='text-left'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                            >
                                <span className='indicator-label'>Submit</span>
                            </button>
                        </div>
                        {/* end::Actions */}
                    </form>
                </KTCardBody>
            </KTCard>
        </>
    )
}

const IZonePollingSettingsWrapper: FC<Props> = ({ IZonePollingSettingsSlice }) => <QueryRequestProvider>
    <ListViewProvider>
        <IZonePollingSettings IZonePollingSettingsSlice={IZonePollingSettingsSlice} />
    </ListViewProvider>
</QueryRequestProvider>

export { IZonePollingSettingsWrapper }
