import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TransactionService from "../../services/frpTransaction/transaction.service";

interface InitialStateInterface {
    TransactionSlice: any,
    UserSlice: any,
    FpBalance: any

}

const initialState = {
    TransactionSlice: null,
    UserSlice: null,
    FpBalance: null

} as InitialStateInterface;

export const getTransactions = createAsyncThunk(
    "getTransactions",
    async (req: any, thunkAPI) => {
        try {
            return await TransactionService.getTransactionsService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportTransactions = createAsyncThunk(
    "exportTransactions",
    async (req: any, thunkAPI) => {
        try {
            return await TransactionService.exportTransactionsService(req).then(response => response);
        } catch (error) { }
    }
);
export const getUsers = createAsyncThunk(
    "getUsers",
    async (thunkAPI) => {
        try {
            return await TransactionService.getUsersService().then(response => response);
        } catch (error) { }
    }
);
export const getFpBalance = createAsyncThunk(
    "getFpBalance",
    async (thunkAPI) => {
        try {
            return await TransactionService.getFpBalanceService().then(response => response);
        } catch (error) { }
    }
);

export const addFpBalance = createAsyncThunk(
    "addFpBalance",
    async (req: any, thunkAPI) => {
        try {
            return await TransactionService.addFpBalanceService(req).then(response => response);
        } catch (error) { }
    }
);

const FrpTransactionSlice = createSlice({
    name: "FrpTransactionSlice",
    initialState,
    reducers: {
        resetData: (state) => {
            state.TransactionSlice = null
            state.UserSlice = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTransactions.fulfilled, (state, action) => {
            state.TransactionSlice = action.payload?.result ? action.payload.result?.transactions : null;
        });
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.UserSlice = action.payload?.result;
        });
        builder.addCase(getFpBalance.fulfilled, (state, action) => {
            state.FpBalance = action.payload?.result?.balance;
        });
    },
});

export default FrpTransactionSlice;
