/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from 'react'
import CustomDatatable from '../../../components/customDatatable/CustomDatatable'
import { CustomModal } from '../../../components/customModal/CustomModal'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { useDispatch } from 'react-redux'
import componentsSlice from '../../../slices/components/componentsSlice'
import { showToast } from '../../../components/custom-toasts/custom-toasts'
import { isNotEmpty, KTCard, KTSVG } from '../../../../_metronic/helpers'
import { QueryRequestProvider } from '../../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import { ListViewProvider } from '../../../modules/apps/user-management/users-list/core/ListViewProvider'
import ProductionRequestSlice, {
  exportActiveMerchants,
  getActiveMerchants,
  getActiveMerchantsWithId,
  putPartner,
} from '../../../slices/merchantManagement/productionRequestSlice'
import { Tab, Tabs } from 'react-bootstrap'
import moment from 'moment'
import Style from '../../../../_metronic/assets/sass/pages/activeMerchants.module.scss'
import CustomTextbox from '../../../components/customTextbox/CustomTextbox'
import CustomAccordion from '../../../components/custom-accordion/custom-accordion'
import { postFRPRequest } from '../../../slices/frpRequest/frpRequestSlice'
import CustomSelect from '../../../components/customModal/CustomSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBadgeCheck,
  faBuilding,
  faEllipsisStroke,
  faFile,
  faUsers,
} from '@fortawesome/pro-light-svg-icons'
import levenshteinAlgorithm from '../../../utils/levenshtein-algorithm'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { getdownloadableLinkWithUrl } from '../../../utils/upload-image-file'
import { CountryArray } from '../../../utils/constants/Country'
interface Props {
  activeMerchantList: any
}

const ActiveMerchantList: FC<Props> = ({ activeMerchantList }) => {
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const { modalIsOpen } = useAppSelector((state) => state.components)
  const dispatch: AppDispatch = useDispatch()
  const [processName, setprocessName] = useState('Detail')
  const [selectedRowId, setSelectedRowId] = useState(undefined)
  const { ActiveMerchantSlice } = useAppSelector((state) => state.productionRequest)
  const [giftFrp, setGiftFrp] = useState(0)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [filterQuery, setfilterQuery] = useState<any>([])
  const [sortData, setsortData] = useState<any>([])
  const [filterParams, setfilterParams] = useState<any>([])
  const [filterPartnerQuery, setfilterPartnerQuery] = useState<any>([])
  const [selectPartnerMerchantList, setSelectPartnerMerchantList] = useState<any[]>()
  const [partnerMerchantList, setPartnerMerchantList] = useState<any[]>([])
  const [tempPartnerMerchantList, setTempPartnerMerchantList] = useState<any[]>([])
  const [selectedPartner, setSelectedPartner] = useState<any>()
  const [isPartner, setIsPartner] = useState(false)
  const [historyDetailList, sethistoryDetailList] = useState([])

  const { AllActiveMerchantsListSlice } = useAppSelector((state) => state.productionRequest)

  useEffect(() => {
    dispatch(ProductionRequestSlice.actions.clearItems({}))
  }, [])

  useEffect(() => {
    setPartnerMerchantList(ActiveMerchantSlice?.merchant?.partner_list)
    setTempPartnerMerchantList(ActiveMerchantSlice?.merchant?.partner_list)
    setdataTableisBusy(false)
    updateSelectPartner()
  }, [ActiveMerchantSlice])

  useEffect(() => {
    updateSelectPartner()
  }, [partnerMerchantList])

  function updateSelectPartner() {
    let array: any[] = []
    AllActiveMerchantsListSlice?.items
      ?.filter(
        (s: any) =>
          !s.is_partner &&
          ActiveMerchantSlice?.merchant?.id !== s.id &&
          ActiveMerchantSlice?.merchant?.parent_id !== s.id &&
          (partnerMerchantList?.length === 0 ||
            !partnerMerchantList?.map((m) => m.id).includes(s.id))
      )
      .map((x: any) => {
        let optionsData = {
          value: x.id,
          label: x.name,
          isFixed: true,
        }
        array.push(optionsData)
      })

    setSelectPartnerMerchantList(array)
  }

  useEffect(() => {
    if (modalIsOpen === undefined) {
      setprocessName('Detail')
      setSelectedRowId(undefined)
    }
  }, [modalIsOpen])

  const columnsTransaction = [
    {
      name: 'Amount',
      selector: (row: any) => (
        <div>
          {Number(row?.amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + ' FRP'}
        </div>
      ),
      width: '150px',
      right: true,
    },
    {
      name: 'Date',
      selector: (row: any) => <div>{moment(row.date_created).format('MM/DD/YYYY')}</div>,
      width: '150px',
    },
    {
      name: 'Description',
      selector: (row: any) => <div>{row?.description}</div>,
      width: 'auto',
    },
  ]

  const partnersColumns = [
    {
      name: 'Country',
      selector: (row: any) => <div>{row?.country}</div>,
      width: '150px',
    },
    {
      name: 'Name',
      selector: (row: any) => <div>{row?.name}</div>,
      width: 'auto',
    },
    {
      name: 'Action',
      selector: (row: any) => (
        <>
          <div className='d-flex justify-content-end flex-shrink-0'>
            {
              <>
                <div
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  onClick={() => {
                    deletePartnerToList(row)
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                </div>
              </>
            }
          </div>
        </>
      ),
      width: '100px',
    },
  ]
  //#region DataTable Methods

  const columns = useMemo(
    () => [
      {
        name: 'Country',
        selector: (row: any) => <div>{row?.country}</div>,
        width: '11%',
      },
      {
        name: 'Name',
        selector: (row: any) => <div>{row?.name}</div>,
        width: '33%',
      },
      {
        name: 'Date Created',
        label: "CreateDateUtc",
        selector: (row: any) => <div>{moment(row.create_date_utc).format('MM/DD/YYYY')}</div>,
        width: '18%',
        sortable: true
      },
      {
        name: 'Partner',
        selector: (row: any) => <div>{row.partner}</div>,
        width: '28%',
      },
      {
        name: 'Action',
        selector: (row: any) => (
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {
                <>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setSelectedPartner(null)
                      setprocessName('Detail')
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                      ActiveMerchantWithId(row.id)
                      dispatch(
                        getActiveMerchants({
                          page_index: 0,
                          page_size: 0,
                          filters: filterParams,
                          sort_filters: sortData,
                        })
                      )
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/coding/cod002.svg' className='svg-icon-3' />
                  </div>
                </>
              }
            </div>
          </>
        ),
        width: '100px',
      },
    ],
    []
  )

  const customFilter = [
    {
      component: (
        <>
          <label className='fw-bold fs-6 mb-2'>Country</label>
          <CustomSelect
            onChange={(e: any) =>
              e.value !== -1
                ? setfilterQuery([
                  ...filterQuery.filter((x: any) => x.name !== 'Country'),
                  { name: 'Country', comparator: 'Contains', value: e.label },
                ])
                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== 'Country')])
            }
            data={CountryArray}
            isMulti={false}
            selectedValue={
              CountryArray?.find(
                (x: any) => x.label === filterQuery.find((x: any) => x.name === 'Country')?.value
              ) !== undefined
                ? CountryArray?.find(
                  (x: any) => x.label === filterQuery.find((x: any) => x.name === 'Country')?.value
                )
                : -1
            }
          ></CustomSelect>
        </>
      ),
    },
    {
      component: (
        <>
          <label className='fw-bold fs-6 mb-2'>Name</label>
          <CustomTextbox
            onChange={(e) =>
              isNotEmpty(e)
                ? setfilterQuery([
                  ...filterQuery.filter((x: any) => x.name !== 'Name'),
                  { name: 'Name', comparator: 'Contains', value: e },
                ])
                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== 'Name')])
            }
            placeHolder={'Type a Name'}
            name='name'
            value={
              filterQuery.find((x: any) => x.name === 'Name') !== undefined
                ? filterQuery.find((x: any) => x.name === 'Name')?.value
                : ''
            }
            customKeypressEvent={() => setfilterParams(filterQuery)}
          ></CustomTextbox>
        </>
      ),
    },
  ]

  const customPartnerFilter = [
    {
      component: (
        <>
          <label className='fw-bold fs-6 mb-2'>Name</label>
          <CustomTextbox
            onChange={(e) =>
              isNotEmpty(e)
                ? setfilterPartnerQuery([
                  ...filterPartnerQuery.filter((x: any) => x.name !== 'Name'),
                  { name: 'Name', comparator: 'Contains', value: e },
                ])
                : setfilterPartnerQuery([
                  ...filterPartnerQuery.filter((x: any) => x.name !== 'Name'),
                ])
            }
            placeHolder={'Type a Name'}
            name='name'
            value={
              filterPartnerQuery.find((x: any) => x.name === 'Name') !== undefined
                ? filterPartnerQuery.find((x: any) => x.name === 'Name')?.value
                : ''
            }
          ></CustomTextbox>
        </>
      ),
    },
  ]

  function fileDownload(url: string, name: string) {
    let req = {
      url: url,
      file_name: 'production-request',
    }
    dispatch(getdownloadableLinkWithUrl(req)).then((response) => {
      if (response?.payload?.is_success) {
        let a = document.createElement('a')
        a.href = response?.payload?.result?.original_url
        a.download = name
        a.click()
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function redirectSocialMedia(url: string, type: number) {
    if (url.includes('http')) {
      window.open(url)
    } else {
      switch (type) {
        case 0:
          window.open(`https://www.facebook.com/${url}`)
          break
        case 1:
          window.open(`https://www.instagram.com/${url}`)
          break
        case 2:
          window.open(`https://twitter.com/${url}`)
          break
        case 3:
          if (url.includes("company/")) {
            window.open(`https://www.linkedin.com/${url}`)
          }
          else {
            window.open(`https://www.linkedin.com/company/${url}`)
          }
          break

        default:
          break
      }
    }
  }

  useEffect(() => {
    if (ActiveMerchantSlice?.history !== null || ActiveMerchantSlice?.history !== undefined) {
      let tempArray = ActiveMerchantSlice?.history?.map((x: any) => {
        return {
          id: x.id,
          header: `${x?.status === 1 ? 'Approved' : 'Rejected'}  ${moment(
            x?.onboarding_date
          ).format('MM/DD/YYYY')}`,
          content: (
            <>
              <div className='row'>
                <div className='col-12 border rounded d-flex justify-content-between'>
                  <div className='row d-flex mx-2' style={{ width: '100%' }}>
                    <div className='col-1 mx-2 d-flex flex-column justify-content-center align-items-center'>
                      <div className='row fs-5 fw-bolder d-flex justify-content-center text-center'>{`Company Detail`}</div>
                      <div className='row d-flex justify-content-center'>
                        <FontAwesomeIcon
                          icon={faBuilding}
                          size='2xl'
                          className='mt-2'
                        ></FontAwesomeIcon>
                      </div>
                    </div>
                    <div className='col-auto d-flex flex-column justify-content-center align-items-center'>
                      <div
                        className='row opacity-50'
                        style={{
                          borderLeft: '2px solid #CCCCCC',
                          height: '80%',
                        }}
                      ></div>
                    </div>
                    <div className='col'>
                      <div className='row d-flex justify-content-start mt-4'>
                        <div className='col-7'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`Country :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  {x?.temp_address_definition?.country}
                                </div>
                                <div
                                  className='col'
                                  style={{ marginLeft: '0.2rem', paddingLeft: '0.2rem' }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-5'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`Bank Name :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  <div className='row'>
                                    <div
                                      className='col-auto'
                                      style={{
                                        marginRight: '0px',
                                        paddingRight: '0px',
                                      }}
                                    >
                                      {x?.account_name}
                                    </div>
                                    {levenshteinAlgorithm(x?.company_name, x?.account_name) <= 5 ? (
                                      <div
                                        className='col-1'
                                        style={{ marginLeft: '2px', paddingLeft: '2px' }}
                                      >
                                        <FontAwesomeIcon icon={faBadgeCheck}></FontAwesomeIcon>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row d-flex justify-content-start mt-2'>
                        <div className='col-7'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`Company Name :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  {x?.company_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-5'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`MCC Code :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  {x?.mcc_code}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row d-flex justify-content-start mt-2'>
                        <div className='col-7'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`Company Number :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  {x?.company_number}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-5'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`Building name :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  {x?.temp_address_definition?.building_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row d-flex justify-content-start mt-2'>
                        <div className='col-7'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`Company Mobile Number :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  {`+${x?.company_phone_number}`}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-5'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`Street name :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  {x?.temp_address_definition?.street_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row d-flex justify-content-start mt-2'>
                        <div className='col-7'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`Company Email :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  {x?.company_email}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-5'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`Postcode :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  {x?.temp_address_definition?.post_code}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row d-flex justify-content-start mt-2 mb-4'>
                        <div className='col-7'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`Website :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  {
                                    x?.temp_social_channels?.find(
                                      (x: any) => x.social_channel_type === 4
                                    )?.social_channel_url
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-5'>
                          <div className='row'>
                            <div
                              className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                              style={{ marginRight: '0px', paddingRight: '0px' }}
                            >{`City :`}</div>
                            <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                              <div className='row'>
                                <div
                                  className='col'
                                  style={{ marginRight: '0px', paddingRight: '0px' }}
                                >
                                  {x?.temp_address_definition?.city}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {x?.temp_officers.length > 0 ? (
                <div className='row mt-5'>
                  <div className='col-12 border rounded d-flex justify-content-between'>
                    <div className='row d-flex mx-2' style={{ width: '100%' }}>
                      <div className='col-1 mx-2 d-flex flex-column justify-content-center align-items-center'>
                        <div className='row fs-5 fw-bolder d-flex justify-content-center text-center'>{`Registered Directors`}</div>
                        <div className='row d-flex justify-content-center'>
                          <FontAwesomeIcon
                            icon={faUsers}
                            size='2xl'
                            className='mt-2'
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                      <div className='col-auto d-flex flex-column justify-content-center align-items-center'>
                        <div
                          className='row opacity-50'
                          style={{
                            borderLeft: '2px solid #CCCCCC',
                            height: '80%',
                          }}
                        ></div>
                      </div>
                      <div className='col'>
                        <table className='table mt-2' style={{ width: '95%' }}>
                          <thead>
                            <tr>
                              <th
                                scope={`col`}
                                className={`${Style.merchantsRegistredDirectorsTableHeaderFont}`}
                              >
                                Name
                              </th>
                              <th
                                scope='col'
                                className={`${Style.merchantsRegistredDirectorsTableHeaderFont}`}
                              >
                                Nationality
                              </th>
                              <th
                                scope='col'
                                className={`${Style.merchantsRegistredDirectorsTableHeaderFont}`}
                              >
                                Occupation
                              </th>
                              <th
                                scope='col'
                                className={`${Style.merchantsRegistredDirectorsTableHeaderFont}`}
                              >
                                E-Mail
                              </th>
                              <th
                                scope='col'
                                className={`${Style.merchantsRegistredDirectorsTableHeaderFont}`}
                              >
                                Mobile Number
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {x?.temp_officers?.map((item: any, index: any) => (
                              <>
                                <tr>
                                  <td>{item?.name}</td>
                                  <td>{item?.nationality}</td>
                                  <td>{item?.occupation}</td>
                                  <td>{item?.email}</td>
                                  <td>{`+${item?.phone_number}`}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {x?.temp_social_channels.filter((x: any) => x.social_channel_type !== 4).length >
                0 ? (
                <div className='row mt-5' style={{ height: '8rem' }}>
                  <div className='col-12 border rounded d-flex justify-content-between'>
                    <div className='row d-flex mx-2' style={{ width: '100%' }}>
                      <div className='col-1 mx-2 d-flex flex-column justify-content-center align-items-center'>
                        <div className='row fs-5 fw-bolder d-flex justify-content-center text-center'>{`Social Verification`}</div>
                        <div className='row d-flex justify-content-center'>
                          <FontAwesomeIcon
                            icon={faBadgeCheck}
                            size='2xl'
                            className='mt-2'
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                      <div className='col-auto d-flex flex-column justify-content-center align-items-center'>
                        <div
                          className='row opacity-50'
                          style={{
                            borderLeft: '2px solid #CCCCCC',
                            height: '80%',
                          }}
                        ></div>
                      </div>
                      <div className='col-10 align-self-center'>
                        <div className='row d-flex justify-content-start'>
                          {x?.temp_social_channels?.find(
                            (x: any) => x.social_channel_type === 0
                          ) !== undefined ? (
                            <div className='col-3'>
                              <div className='row d-flex justify-content-start align-items-center'>
                                <div className='col-auto d-flex justify-content-center align-items-center'>
                                  <FontAwesomeIcon icon={faFacebook} size='3x'></FontAwesomeIcon>
                                </div>
                                <div
                                  className={`col-auto mx-1 text-decoration-underline ${Style.pendingSocialMediaFont}`}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className='row'>
                                    {x?.temp_social_channels?.find(
                                      (x: any) => x.social_channel_type === 0
                                    )?.username !== undefined ? (
                                      <>
                                        <div
                                          className={`col-auto ${Style.pendingSocialMediaVerfiedFont}`}
                                          onClick={() =>
                                            redirectSocialMedia(
                                              x?.temp_social_channels
                                                ?.find((x: any) => x.social_channel_type === 0)
                                                ?.username?.split(',')[1],
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 0
                                              )?.social_channel_type
                                            )
                                          }
                                        >
                                          {`/${x?.temp_social_channels
                                            ?.find((x: any) => x.social_channel_type === 0)
                                            ?.username?.split(',')[0]
                                            }`}
                                        </div>
                                        {x?.temp_social_channels?.find(
                                          (x: any) => x.social_channel_type === 0
                                        )?.is_verified ? (
                                          <div
                                            className='col-1'
                                            style={{ marginLeft: '2px', paddingLeft: '2px' }}
                                          >
                                            <FontAwesomeIcon icon={faBadgeCheck}></FontAwesomeIcon>
                                          </div>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </div>
                                  {
                                    x?.temp_social_channels?.find(
                                      (x: any) => x.social_channel_type === 0
                                    )?.social_channel_url !== undefined ?
                                      <div className={`row`}>
                                        <div className={`col ${Style.treeDotsforSocialMediaUrl}`}
                                          onClick={() =>
                                            redirectSocialMedia(
                                              x?.temp_social_channels
                                                ?.find((x: any) => x.social_channel_type === 0)
                                                ?.social_channel_url,
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 0
                                              )?.social_channel_type
                                            )
                                          }
                                        >
                                          {`/${x?.temp_social_channels?.find(
                                            (x: any) => x.social_channel_type === 0
                                          )?.social_channel_url
                                            }`}
                                        </div>
                                      </div>
                                      : ''
                                  }
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {x?.temp_social_channels?.find(
                            (x: any) => x.social_channel_type === 1
                          ) !== undefined ? (
                            <div className='col-3'>
                              <div className='row d-flex justify-content-start align-items-center'>
                                <div className='col-4 d-flex justify-content-center align-items-center'>
                                  <FontAwesomeIcon icon={faInstagram} size='3x'></FontAwesomeIcon>
                                </div>
                                <div
                                  className={`col-auto mx-1 text-decoration-underline ${Style.pendingSocialMediaFont}`}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className='row'>
                                    {x?.temp_social_channels?.find(
                                      (x: any) => x.social_channel_type === 1
                                    )?.username !== undefined ? (
                                      <>
                                        <div
                                          className={`col-auto ${Style.pendingSocialMediaVerfiedFont}`}
                                          onClick={() =>
                                            redirectSocialMedia(
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 1
                                              )?.username,
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 1
                                              )?.social_channel_type
                                            )
                                          }
                                        >
                                          {`/${x?.temp_social_channels?.find(
                                            (x: any) => x.social_channel_type === 1
                                          )?.username
                                            }`}
                                        </div>
                                        {x?.temp_social_channels?.find(
                                          (x: any) => x.social_channel_type === 1
                                        )?.is_verified ? (
                                          <div
                                            className='col-1'
                                            style={{ marginLeft: '2px', paddingLeft: '2px' }}
                                          >
                                            <FontAwesomeIcon icon={faBadgeCheck}></FontAwesomeIcon>
                                          </div>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </div>
                                  {
                                    x?.temp_social_channels?.find(
                                      (x: any) => x.social_channel_type === 1
                                    )?.social_channel_url !== undefined ?
                                      <div className={`row`}>
                                        <div className={`col ${Style.treeDotsforSocialMediaUrl}`}
                                          onClick={() =>
                                            redirectSocialMedia(
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 1
                                              )?.social_channel_url,
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 1
                                              )?.social_channel_type
                                            )
                                          }
                                        >
                                          {`/${x?.temp_social_channels?.find(
                                            (x: any) => x.social_channel_type === 1
                                          )?.social_channel_url
                                            }`}
                                        </div>
                                      </div>
                                      : ''
                                  }

                                </div>
                              </div>
                            </div>
                          ) : null}

                          {x?.temp_social_channels?.find(
                            (x: any) => x.social_channel_type === 3
                          ) !== undefined ? (
                            <div className='col-3'>
                              <div className='row d-flex justify-content-start align-items-center'>
                                <div className='col-4 d-flex justify-content-center align-items-center'>
                                  <FontAwesomeIcon icon={faLinkedin} size='3x'></FontAwesomeIcon>
                                </div>
                                <div
                                  className={`col-auto mx-1 text-decoration-underline ${Style.pendingSocialMediaFont}`}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className='row'>
                                    {x?.temp_social_channels?.find(
                                      (x: any) => x.social_channel_type === 3
                                    )?.username !== undefined ? (
                                      <>
                                        <div
                                          className={`col-auto ${Style.pendingSocialMediaVerfiedFont}`}
                                          onClick={() =>
                                            redirectSocialMedia(
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 3
                                              )?.username,
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 3
                                              )?.social_channel_type
                                            )
                                          }
                                        >
                                          {`/${x?.temp_social_channels?.find(
                                            (x: any) => x.social_channel_type === 3
                                          )?.username
                                            }`}
                                        </div>
                                        {x?.temp_social_channels?.find(
                                          (x: any) => x.social_channel_type === 3
                                        )?.is_verified ? (
                                          <div
                                            className='col-1'
                                            style={{ marginLeft: '2px', paddingLeft: '2px' }}
                                          >
                                            <FontAwesomeIcon icon={faBadgeCheck}></FontAwesomeIcon>
                                          </div>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </div>
                                  {
                                    x?.temp_social_channels?.find(
                                      (x: any) => x.social_channel_type === 3
                                    )?.social_channel_url !== undefined ?
                                      <div className={`row`}>
                                        <div className={`col ${Style.treeDotsforSocialMediaUrl}`}
                                          onClick={() =>
                                            redirectSocialMedia(
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 3
                                              )?.social_channel_url,
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 3
                                              )?.social_channel_type
                                            )
                                          }
                                        >
                                          {`/${x?.temp_social_channels?.find(
                                            (x: any) => x.social_channel_type === 3
                                          )?.social_channel_url
                                            }`}
                                        </div>
                                      </div>
                                      : ''
                                  }
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {x?.temp_social_channels?.find(
                            (x: any) => x.social_channel_type === 2
                          ) !== undefined ? (
                            <div className='col-3'>
                              <div className='row d-flex justify-content-start align-items-center'>
                                <div className='col-4 d-flex justify-content-center align-items-center'>
                                  <FontAwesomeIcon icon={faTwitter} size='3x'></FontAwesomeIcon>
                                </div>
                                <div
                                  className={`col-auto mx-1 text-decoration-underline ${Style.pendingSocialMediaFont}`}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className='row'>
                                    {x?.temp_social_channels?.find(
                                      (x: any) => x.social_channel_type === 2
                                    )?.username !== undefined ? (
                                      <>
                                        <div
                                          className={`col-auto ${Style.pendingSocialMediaVerfiedFont}`}
                                          onClick={() =>
                                            redirectSocialMedia(
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 2
                                              )?.username,
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 2
                                              )?.social_channel_type
                                            )
                                          }
                                        >
                                          {`/${x?.temp_social_channels?.find(
                                            (x: any) => x.social_channel_type === 2
                                          )?.username
                                            }`}
                                        </div>
                                        {x?.temp_social_channels?.find(
                                          (x: any) => x.social_channel_type === 2
                                        )?.is_verified ? (
                                          <div
                                            className='col-1'
                                            style={{ marginLeft: '2px', paddingLeft: '2px' }}
                                          >
                                            <FontAwesomeIcon icon={faBadgeCheck}></FontAwesomeIcon>
                                          </div>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </div>
                                  {
                                    x?.temp_social_channels?.find(
                                      (x: any) => x.social_channel_type === 2
                                    )?.social_channel_url !== undefined ?
                                      <div className={`row`}>
                                        <div className={`col ${Style.treeDotsforSocialMediaUrl}`}
                                          onClick={() =>
                                            redirectSocialMedia(
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 2
                                              )?.social_channel_url,
                                              x?.temp_social_channels?.find(
                                                (x: any) => x.social_channel_type === 2
                                              )?.social_channel_type
                                            )
                                          }
                                        >
                                          {`/${x?.temp_social_channels?.find(
                                            (x: any) => x.social_channel_type === 2
                                          )?.social_channel_url
                                            }`}
                                        </div>
                                      </div>
                                      : ''
                                  }
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {x?.temp_production_request_files.length > 0 ? (
                <div className='row mt-5' style={{ height: '10rem' }}>
                  <div className='col-12 border rounded d-flex justify-content-between'>
                    <div className='row d-flex mx-2' style={{ width: '100%' }}>
                      <div className='col-1 mx-2 d-flex flex-column justify-content-center align-items-center'>
                        <div className='row fs-5 fw-bolder d-flex justify-content-center text-center'>{`Document Verification`}</div>
                        <div className='row d-flex justify-content-center'>
                          <FontAwesomeIcon
                            icon={faFile}
                            size='2xl'
                            className='mt-2'
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                      <div className='col-auto d-flex flex-column justify-content-center align-items-center'>
                        <div
                          className='row opacity-50'
                          style={{
                            borderLeft: '2px solid #CCCCCC',
                            height: '80%',
                          }}
                        ></div>
                      </div>
                      <div className='col-6 align-self-center'>
                        <div className='row'>
                          {x?.temp_production_request_files?.find((x: any) => x.file_type === 0) !==
                            undefined ? (
                            <div className='col-5'>
                              <div className='row fs-6 fw-bolder d-flex justify-content-start text-start'>
                                <div className='col fs-5 fw-bolder d-flex justify-content-start text-start'>
                                  {`Company Registration`}
                                </div>
                              </div>
                              <div className='row mt-3 fs-6 fw-bolder d-flex justify-content-center text-center'>
                                <FontAwesomeIcon
                                  icon={faFile}
                                  size='2x'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    fileDownload(
                                      x?.temp_production_request_files?.find(
                                        (x: any) => x.file_type === 0
                                      ).file_url,
                                      'Company Registration'
                                    )
                                  }
                                ></FontAwesomeIcon>
                              </div>
                              <div className='row mt-3 fs-6 d-flex justify-content-center text-center'>
                                <div className={`col-5 ${Style.pendingDocumentVerificationArea}`}>
                                  {
                                    x?.temp_production_request_files?.find(
                                      (x: any) => x.file_type === 0
                                    ).file_url
                                  }
                                </div>
                                <div
                                  className='col-1'
                                  style={{ marginLeft: '0px', paddingLeft: '0px' }}
                                >{`.pdf`}</div>
                              </div>
                            </div>
                          ) : null}
                          {x?.temp_production_request_files?.find((x: any) => x.file_type === 1) !==
                            undefined ? (
                            <div className='col-5'>
                              <div className='row fs-6 fw-bolder d-flex justify-content-center text-center'>
                                {`Bank Statement`}
                              </div>
                              <div className='row mt-3 fs-6 fw-bolder d-flex justify-content-center text-center'>
                                <FontAwesomeIcon
                                  icon={faFile}
                                  size='2x'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    fileDownload(
                                      x?.temp_production_request_files?.find(
                                        (x: any) => x.file_type === 1
                                      ).file_url,
                                      'Bank Statement'
                                    )
                                  }
                                ></FontAwesomeIcon>
                              </div>
                              <div className='row mt-3 fs-6 d-flex justify-content-center text-center'>
                                <div className={`col-5 ${Style.pendingDocumentVerificationArea}`}>
                                  {
                                    x?.temp_production_request_files?.find(
                                      (x: any) => x.file_type === 1
                                    ).file_url
                                  }
                                </div>
                                <div
                                  className='col-1'
                                  style={{ marginLeft: '0px', paddingLeft: '0px' }}
                                >{`.pdf`}</div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {x?.note !== '' && x?.note !== null && x?.note !== undefined ? (
                <div className='row mt-5'>
                  <div className='col-12 border rounded d-flex justify-content-between'>
                    <div className='row d-flex mx-2' style={{ width: '100%' }}>
                      <div className='col-1 mx-2 d-flex flex-column justify-content-center align-items-center'>
                        <div className='row fs-5 fw-bolder d-flex justify-content-center text-center'>{`Others`}</div>
                        <div className='row d-flex justify-content-center'>
                          <FontAwesomeIcon
                            icon={faEllipsisStroke}
                            size='2xl'
                            className='mt-2'
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                      <div className='col-auto d-flex flex-column justify-content-center align-items-center'>
                        <div
                          className='row opacity-50'
                          style={{
                            borderLeft: '2px solid #CCCCCC',
                            height: '80%',
                          }}
                        ></div>
                      </div>
                      <div className='col-10 align-self-center'>
                        <div className='row mt-3'>
                          <div className='col-2 fs-5 fw-bolder d-flex justify-content-start text-start'>
                            {`Note :`}
                          </div>
                        </div>
                        <div className='row mt-3 mb-5 d-flex justify-content-center text-center'>
                          <div className='col-11 border rounded d-flex justify-content-start text-start'>
                            {x?.note}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ),
        }
      })
      sethistoryDetailList(tempArray)
    }
  }, [ActiveMerchantSlice])

  const handlePageChange = (page: any) => {
    setdataTableisBusy(true)
    setPage(page - 1)
    setdataTableisBusy(false)
  }
  const handlePerRowsChange = async (newPerPage: any) => {
    setdataTableisBusy(true)
    setPerPage(newPerPage)
    setdataTableisBusy(false)
  }
  const handleSort = async (column: any, sortDirection: any) => {
    setsortData([{ property: column?.label, direction: sortDirection === 'asc' ? 0 : 1 }])
  }
  useEffect(() => {
    dispatch(
      getActiveMerchants({
        page_index: page,
        page_size: perPage,
        filters: filterParams,
        sort_filters: sortData,
      })
    )
  }, [page, perPage, sortData, filterParams])

  function addPartnerToList() {
    if (selectedPartner) {
      let currentList = [...partnerMerchantList]
      currentList?.push(
        ...AllActiveMerchantsListSlice?.items?.filter((s: any) => s.id === selectedPartner?.value)
      )
      setPartnerMerchantList(currentList)
      setTempPartnerMerchantList(currentList)
      setSelectedPartner(null)
    }
  }
  function deletePartnerToList(value: any) {
    let currentList = [...partnerMerchantList]
    currentList = currentList?.filter((s) => s.id !== value.id)
    setPartnerMerchantList(currentList)
    setTempPartnerMerchantList(currentList)
  }
  function savePartner() {
    let ids = partnerMerchantList.map((s) => s.id)
    let merchantId = ActiveMerchantSlice?.merchant?.id
    let req = { partner_list: ids, merchant_id: merchantId, is_partner: isPartner }
    dispatch(putPartner(req)).then(() => {
      dispatch(componentsSlice.actions.closeModal({}))
      dispatch(
        getActiveMerchants({
          page_index: page,
          page_size: perPage,
          filters: filterParams,
          sort_filters: sortData,
        })
      )
    })
  }
  //#endregion DataTable Methods End

  function ActiveMerchantWithId(rowId: any) {
    if (rowId !== undefined) {
      dispatch(getActiveMerchantsWithId(rowId)).then((s: any) => {
        setIsPartner(s?.payload?.result?.merchant?.is_partner)
      })
    }
  }

  function sendFrp() {
    if (giftFrp) {
      let req = {
        merchant_id: ActiveMerchantSlice?.merchant?.id,
        amount: giftFrp,
        description: 'Gift FRP',
      }
      dispatch(postFRPRequest(req)).then((response) => {
        if (response?.payload?.is_success) {
          showToast.success('FRP Sent')
          setGiftFrp(0)
          dispatch(getActiveMerchantsWithId(selectedRowId))
          setIsPartner(response?.payload?.result?.merchant?.is_partner)
        } else {
          showToast.error(response?.payload?.response_exception?.exception_message)
          setGiftFrp(0)
        }
      })
    } else {
      showToast.warning('Please enter FRP value')
    }
  }

  function filterMerchant(filter: any) {
    if (filter?.length > 0) {
      let value = filter[0].value
      let currentList = [...partnerMerchantList]
      currentList = currentList?.filter((s) => s.name.toLowerCase().includes(value.toLowerCase()))
      setPartnerMerchantList(currentList)
    } else {
      setPartnerMerchantList(tempPartnerMerchantList)
    }
  }

  function findProcessScreen(processName: string) {
    if (processName === 'Detail') {
      return (
        <>
          <CustomModal
            title={selectedRowId !== undefined ? 'Merchant Detail' : 'New Active Merchant'}
            maxWidth={1100}
          >
            <>
              <Tabs defaultActiveKey='merchant' id='tab' className='mb-3'>
                <Tab eventKey='merchant' title='Merchant'>
                  <div className='mt-2'>
                    <div className='d-flex flex-stack'>
                      <div className={`list-group-item list-group-item-action my-1`}>
                        <div className='d-flex'>
                          <div className='d-flex flex-column'>
                            <div className='fs-5 text-dark fw-bolder'>{`Name`}</div>
                            <div className='fs-6 fw-bold text-gray-400'>
                              {ActiveMerchantSlice?.merchant?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='separator separator-dashed my-2'></div>
                  </div>
                  <div className='mt-2'>
                    <div className='d-flex flex-stack'>
                      <div className={`list-group-item list-group-item-action my-1`}>
                        <div className='d-flex'>
                          <div className='d-flex flex-column'>
                            <div className='fs-5 text-dark fw-bolder'>{`Country`}</div>
                            <div className='fs-6 fw-bold text-gray-400'>
                              {ActiveMerchantSlice?.merchant?.country}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='separator separator-dashed my-2'></div>
                  </div>
                  <div className='mt-2'>
                    <div className='d-flex flex-stack'>
                      <div className={`list-group-item list-group-item-action my-1`}>
                        <div className='d-flex'>
                          <div className='d-flex flex-column'>
                            <div className='fs-5 text-dark fw-bolder'>{`Contact Email`}</div>
                            <div className='fs-6 fw-bold text-gray-400'>
                              {ActiveMerchantSlice?.merchant?.business_directory_entity?.email}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='separator separator-dashed my-2'></div>
                  </div>
                  <div className='mt-2'>
                    <div className='d-flex flex-stack'>
                      <div className={`list-group-item list-group-item-action my-1`}>
                        <div className='d-flex'>
                          <div className='d-flex flex-column'>
                            <div className='fs-5 text-dark fw-bolder'>
                              {`Contact Phone Number`}
                            </div>
                            <div className='fs-6 fw-bold text-gray-400'>
                              {ActiveMerchantSlice?.merchant?.business_directory_entity
                                ?.phone_number
                                ? `+${ActiveMerchantSlice?.merchant?.business_directory_entity?.phone_number}`
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='separator separator-dashed my-2'></div>
                  </div>
                  <div className='mt-2'>
                    <div className='d-flex flex-stack'>
                      <div className={`list-group-item list-group-item-action my-1`}>
                        <div className='d-flex'>
                          <div className='d-flex flex-column'>
                            <div className='fs-5 text-dark fw-bolder'>{`Partner`}</div>
                            <div className='fs-6 fw-bold text-gray-400'>
                              {ActiveMerchantSlice?.merchant?.parent?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='separator separator-dashed my-2'></div>
                  </div>
                </Tab>
                <Tab eventKey='frp' title='FRP'>
                  <div style={{ height: "35rem" }}>
                    <div className='row'>
                      <div className={`row ${Style.title}`}>{`FRP Balance: ${Number(
                        ActiveMerchantSlice?.frp_balance
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} FRP`}</div>
                    </div>
                    <div className='row mb-5'>
                      <div className={`row mt-2 ${Style.title}`}>{`Gift FRP`}</div>
                      <div className='row'>
                        <div className='col-8'>
                          <CustomTextbox
                            onChange={setGiftFrp}
                            placeHolder={'Gift FRP'}
                            name='questions'
                            value={giftFrp}
                            type='number'
                            onlyPositive
                          ></CustomTextbox>
                        </div>
                        <div className='col-4'>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-users-modal-action='submit'
                            onClick={() => sendFrp()}
                          >
                            <span className='indicator-label'>Send</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className={`row`}>
                      <div
                        className={`row ${Style.title} ${Style.centerDiv}`}
                      >{`Transactions`}</div>
                      <div>
                        <CustomDatatable
                          columns={columnsTransaction}
                          data={ActiveMerchantSlice?.transactions}
                          isBusy={dataTableisBusy}
                          showTitle
                          noHeader
                          noMargin
                        ></CustomDatatable>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='employees' title='Employees'>
                  <div className={`${Style.tabContent}`}>
                    {ActiveMerchantSlice?.merchant?.merchant_users?.map((item: any) => (
                      <>
                        <div className='mt-2'>
                          <div className='d-flex flex-stack'>
                            <div
                              key={item?.email}
                              className={`list-group-item list-group-item-action my-1`}
                            >
                              <div className='d-flex'>
                                <div className='d-flex flex-column'>
                                  <div className='fs-5 text-dark fw-bolder'>{item?.full_name}</div>
                                  <div className='fs-6 fw-bold text-gray-400'>{item?.email}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='separator separator-dashed my-2'></div>
                        </div>
                      </>
                    ))}
                  </div>
                </Tab>
                <Tab eventKey='locations' title='Locations'>
                  <div className={`${Style.tabContent}`}>
                    {ActiveMerchantSlice?.merchant?.locations?.map((item: any) => (
                      <>
                        <div className='mt-2'>
                          <div className='d-flex flex-stack'>
                            <div
                              key={item?.name}
                              className={`list-group-item list-group-item-action my-1`}
                            >
                              <div className=''>
                                <div className='d-flex flex-column'>
                                  <div className='fs-5 text-dark fw-bolder'>{item?.name}</div>
                                  <div className='row mt-2'>
                                    <div className='col-6'>
                                      <div className='fs-6 fw-bold text-gray-400'>{`Lat: ${item?.latitude}`}</div>
                                    </div>
                                    <div className='col-6'>
                                      <div className='fs-6 fw-bold text-gray-400'>{`Long: ${item?.longditude}`}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='separator separator-dashed my-2'></div>
                        </div>
                      </>
                    ))}
                  </div>
                </Tab>
                <Tab eventKey='history' title='History'>
                  <div className={`${Style.tabContent}`}>
                    <CustomAccordion
                      hasIcon={true}
                      isAlwaysOpen={true}
                      list={historyDetailList}
                      noAccordionHeaderStyle
                      customHeaderStyle={Style.customHeaderStyle}
                    ></CustomAccordion>
                  </div>
                </Tab>
                <Tab eventKey='partner' title='Partner Management'>
                  <div className='fv-row mb-7'>
                    <div className='col-3 mt-4'>
                      <div className='form-check form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='is_partner'
                          defaultChecked={isPartner}
                          checked={isPartner}
                          onChange={(event) => setIsPartner(event.target.checked)}
                        />
                        <label className='form-check-label ps-2' htmlFor='is_partner'>
                          Is Partner
                        </label>
                      </div>
                    </div>
                    {isPartner ? (
                      <>
                        <div className='row mt-6'>
                          <div className='col-9'>
                            <CustomSelect
                              onChange={(e: any) => {
                                setSelectedPartner(e)
                              }}
                              data={selectPartnerMerchantList}
                              isMulti={false}
                              formikName={'partnerList'}
                              selectedValue={selectedPartner}
                            ></CustomSelect>
                          </div>
                          <div className='col-3'>
                            <button
                              // type='submit'
                              className='btn btn-primary'
                              onClick={() => addPartnerToList()}
                            >
                              <span className='indicator-label'>Add Selected</span>
                            </button>
                          </div>
                        </div>
                        <div className={`row mt-4`}>
                          <div className={`row ${Style.title}`}>{`Merchant List`}</div>
                          <div>
                            <CustomDatatable
                              columns={partnersColumns}
                              data={partnerMerchantList}
                              isBusy={dataTableisBusy}
                              showTitle
                              noHeader
                              noMargin
                              showFilter={true}
                              filterOptions={customPartnerFilter}
                              searchButtonClickEvent={() => filterMerchant(filterPartnerQuery)}
                              clearButtonClickEvent={() => {
                                setfilterPartnerQuery([])
                                filterMerchant([])
                              }}
                            ></CustomDatatable>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className='row mt-6'>
                      <div className='col-10'></div>
                      <div className='col-1'>
                        <button className='btn btn-primary' onClick={() => savePartner()}>
                          <span className='indicator-label'>Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </>
          </CustomModal>
        </>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={activeMerchantList?.items}
          title={'Active Merchant List'}
          pagination
          paginationServer
          paginationTotalRows={activeMerchantList?.total_count}
          isBusy={dataTableisBusy}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          showTitle
          showFilter={true}
          filterOptions={customFilter}
          searchButtonClickEvent={() => setfilterParams(filterQuery)}
          sortHandle={handleSort}
          isExport
          onExport={() => {
            dispatch(
              exportActiveMerchants({
                page_index: 0,
                page_size: 0,
                filters: filterParams,
                sort_filters: sortData,
              })
            );
          }}
          clearButtonClickEvent={() => {
            setfilterParams([])
            setfilterQuery([])
          }}
        ></CustomDatatable>
      </KTCard>
      {modalIsOpen !== undefined ? findProcessScreen(processName) : null}
    </>
  )
}

const ActiveMerchantWrapper: FC<Props> = ({ activeMerchantList }) => (
  <QueryRequestProvider>
    <ListViewProvider>
      <ActiveMerchantList activeMerchantList={activeMerchantList} />
    </ListViewProvider>
  </QueryRequestProvider>
)

export { ActiveMerchantWrapper }
