/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { isNotEmpty, KTCard, KTCardBody } from '../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import * as Yup from 'yup'
import CustomTextbox from '../../../components/customTextbox/CustomTextbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fontAwesomeHelper from '../../../utils/font-awesome-helper'
import { IconDefinition } from '@fortawesome/free-brands-svg-icons'
import { faX } from '@fortawesome/pro-solid-svg-icons'
import CredentialSchemaDetail from './CredentialSchemaDetail'
import { Tab, Tabs } from 'react-bootstrap'
import CustomSelect from '../../../components/customModal/CustomSelect'
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../store/store'
import credentialsSlice, { getCredentialsWithId, postCredential, putCredential } from '../../../slices/credentialManagement/credentials/CredentialsSlice'
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from '../../../components/custom-toasts/custom-toasts'
import Style from '../../../../_metronic/assets/sass/pages/credentialDetail.module.scss'

const CredentialsContentBreadcrumbs: Array<PageLink> = [
    {
        title: `Credentials`,
        path: '/Credentials/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const GroupNameEnum = [
    {
        value: "Income",
        label: "Income"
    },
    {
        value: "Expenditure",
        label: "Expenditure"
    },
    {
        value: "Social / Behaviour",
        label: "Social / Behaviour"
    },
    {
        value: "Natural Identity",
        label: "Natural Identity"
    },
]

const DataProviderTypesEnum = [
    {
        value: 0,
        label: "OpenBanking"
    },
    {
        value: 1,
        label: "Facebook"
    },
    {
        value: 2,
        label: "Instagram"
    },
    {
        value: 3,
        label: "LinkedIn"
    },
    {
        value: 4,
        label: "Device"
    },
    {
        value: 5,
        label: "None"
    },
    {
        value: 6,
        label: "FreedomPay BI"
    },
    {
        value: 7,
        label: "X"
    },

]
const SchemaClassEnum = [
    {
        value: 0,
        label: "NaturalIdentity"
    },
    {
        value: 1,
        label: "Expenditure"
    },
    {
        value: 2,
        label: "Income"
    },
    {
        value: 3,
        label: "SocialBehaviour"
    },

]
function CredentialDetail() {
    const dispatch: AppDispatch = useAppDispatch();
    const { selectedCredentialId, credentialSlice, TempCredentialCategortList, isSubCatValid, CredentialCategoryList } = useAppSelector((state) => state.credentials);
    const navigate = useNavigate();
    const [sdkAboutRequired, setsdkAboutRequired] = useState<any>();
    const location = useLocation();
    let customPageName = location.pathname.substring(0, location.pathname.indexOf("/", 2))

    const credentialFormik = useFormik({
        initialValues: {
            id: '',
            name: '',
            credentialDefinitionId: '',
            version: '',
            credPrice: '',
            dataProviderType: null,
            isActive: false,
            isActiveOnHybridService: false,
            icon: '',
            about: '',
            sdkAbout: '',
            schema: 0,
            schemaId: '',
            schemaName: '',
            jsonldUrl: '',
            schemaClass: null,
            schemaObjectType: '',
            schemaJsonString: '',
            groupName: null,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(50, 'Maximum 50 symbols')
                .required('Name is required'),
            credPrice: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Cred Price is required'),
            icon: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(100, 'Maximum 100 symbols')
                .required('Icon is required'),
            about: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(300, 'Maximum 300 symbols')
                .required('About is required'),
            sdkAbout: sdkAboutRequired ? Yup.string().min(1, 'Minimum 1 symbols')
                .max(300, 'Maximum 300 symbols')
                .required('SDK About is required') : Yup.string(),
            schemaName: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(300, 'Maximum 300 symbols')
                .required('Schema Name is required'),
            schemaClass: Yup.object().typeError("Schema Class is required").required("Schema Class is required").nullable(false),
            schemaObjectType: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(50, 'Maximum 50 symbols')
                .required('Schema Object Type is required'),
            dataProviderType: Yup.object().typeError("Data Provider Type is required").required("Data Provider Type is required").nullable(false),
            schema: Yup.number().min(1),
            groupName: Yup.object().typeError("Group Name is required")
                .required("Group Name is required").nullable(false),
        }),
        onSubmit: (values, actions) => {
            try {
                if (isSubCatValid === false) {
                    showToast.warning("Sub Category is not valid")
                } else {
                    if (isNotEmpty(values.id)) {
                        updateCredentail(values)
                    }
                    else {
                        createCredential(values)
                    }
                }
            } catch (ex) {
                console.error(ex)
            }
        },
    })

    useEffect(() => {
        if (selectedCredentialId !== "" && isNotEmpty(selectedCredentialId) && selectedCredentialId !== undefined) {
            dispatch(getCredentialsWithId(selectedCredentialId));
        }
        else {
            credentialFormik.resetForm();
        }
    }, [])

    useEffect(() => {
        if (credentialSlice !== undefined && credentialSlice !== null) {
            credentialFormik.setValues({
                id: credentialSlice?.id,
                name: credentialSlice?.name,
                about: credentialSlice?.about,
                sdkAbout: credentialSlice?.sdk_about,
                credentialDefinitionId: credentialSlice?.credential_definition_id,
                credPrice: credentialSlice?.cred_price,
                dataProviderType: DataProviderTypesEnum.find(x => x.value === credentialSlice?.data_provider_type) as any,
                icon: credentialSlice?.icon,
                isActive: credentialSlice?.is_active,
                isActiveOnHybridService: credentialSlice?.is_active_on_hybrid_service,
                version: credentialSlice?.version,
                schemaId: credentialSlice?.schema_id,
                schema: credentialSlice?.schema?.length,
                schemaObjectType: credentialSlice?.schema?.schema_object_type,
                schemaName: credentialSlice?.schema?.name,
                jsonldUrl: credentialSlice?.schema?.jsonld_url,
                schemaClass: SchemaClassEnum.find(x => x.value === credentialSlice?.schema?.schema_class) as any,
                schemaJsonString: credentialSlice?.schema?.schemaJsonString, // Will be edit
                groupName: GroupNameEnum.find(x => x.value === credentialSlice?.group_name) as any
            })
        }
    }, [credentialSlice])

    useEffect(() => {
        credentialFormik.setFieldValue('schema', TempCredentialCategortList.length);
    }, [TempCredentialCategortList.length])

    function createCredential(values: any) {
        if (TempCredentialCategortList.flatMap((x) => x?.schema_attributes).filter((x) => x?.is_query_criteria === true).length === 0) {
            showToast.error("At least one query criteria is required.")
            return
        }
        let schemaSubCategoriesArray = TempCredentialCategortList.map((x: any) => ({
            name: x?.name,
            icon: x?.icon,
            is_main_category: x?.is_main_category,
            schema_attributes: x?.schema_attributes?.map((y: any) => ({
                name: y?.name,
                attribute_format_id: y?.attribute_format_id,
                attribute_contents: y?.attribute_contents,
                display_name: y?.display_name,
                is_query_criteria: y?.is_query_criteria,
                is_mobile_sum_field: y?.is_mobile_sum_field,
                calculation_formula: y?.calculation_formula
            })),
            open_banking_categories: x?.open_banking_categories?.map((y: any) => ({
                open_banking_definition: y?.open_banking_definition,
                open_banking_category_map_info: y?.open_banking_category_map_info,
            })),
        }))

        let req = {
            name: values?.name,
            cred_price: values?.credPrice,
            data_provider_type: values?.dataProviderType?.value,
            is_active: values?.isActive,
            is_active_on_hybrid_service: values?.isActiveOnHybridService,
            icon: values?.icon,
            about: values?.about,
            sdk_about: values?.sdkAbout,
            schema: {
                name: values?.schemaName,
                schema_class: values?.schemaClass?.value,
                schema_object_type: values?.schemaObjectType,
                schema_sub_categories: schemaSubCategoriesArray
            },
            group_name: values?.groupName?.value
        }

        dispatch(postCredential(req)).then((response) => {
            if (response?.payload?.is_success) {
                localStorage.removeItem(customPageName)
                showToast.success("Item Saved Successfully")
                credentialFormik.resetForm()
                dispatch(credentialsSlice.actions.clearItems({}))
                navigate("/Credentials/List")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function updateCredentail(values: any) {

        let oldAtts = CredentialCategoryList.flatMap(m => m.schema_attributes.map(s => s.name));
        let newAtts = TempCredentialCategortList.flatMap(m => m.schema_attributes.map(s => s.name));
        let isDeactive = false;

        if ((!credentialSlice?.is_active && values?.isActive) || newAtts
            .filter(x => !oldAtts.includes(x))
            .concat(oldAtts.filter(x => !newAtts.includes(x)))?.length > 0) {
            isDeactive = true;
            showToast.warning("The changes made will cause the campaigns to be deactivated.")
        }
        let schemaSubCategoriesArray = TempCredentialCategortList.map((x: any) => ({
            id: x?.id,
            name: x?.name,
            icon: x?.icon,
            is_main_category: x?.is_main_category,
            schema_attributes: x?.schema_attributes?.map((y: any) => ({
                id: y?.id,
                name: y?.name,
                attribute_format_id: y?.attribute_format_id,
                attribute_contents: y?.attribute_contents,
                display_name: y?.display_name,
                is_query_criteria: y?.is_query_criteria,
                is_mobile_sum_field: y?.is_mobile_sum_field,
                calculation_formula: y?.calculation_formula
            })),
            open_banking_categories: x?.open_banking_categories?.map((y: any) => ({
                open_banking_definition: y?.open_banking_definition,
                open_banking_category_map_info: y?.open_banking_category_map_info,
            })),
        }))

        let req = {
            id: values?.id,
            is_deactive: isDeactive,
            name: values?.name,
            cred_price: values?.credPrice,
            data_provider_type: values?.dataProviderType?.value,
            is_active: values?.isActive,
            is_active_on_hybrid_service: values?.isActiveOnHybridService,
            icon: values?.icon,
            about: values?.about,
            sdk_about: values?.sdkAbout,
            schema: {
                name: values?.schemaName,
                schema_class: values?.schemaClass?.value,
                schema_object_type: values?.schemaObjectType,
                schema_sub_categories: schemaSubCategoriesArray
            },
            group_name: values?.groupName?.value
        }

        dispatch(putCredential(req)).then((response) => {
            if (response?.payload?.is_success) {
                showToast.success("Item Updated Successfully")
                credentialFormik.resetForm()
                dispatch(credentialsSlice.actions.clearItems({}))
                navigate("/Credentials/List")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    // This function temporarily closed 
    // function getSchemaJson() {
    //     let req = {
    //         attributes: TempCredentialCategortList.map((x: any) => ({ name: x?.name }))
    //     }
    //     dispatch(getSchemaJsonWithReq(req)).then((response) => {
    //         if (response?.payload?.is_success) {
    //             if (response?.payload?.result?.json_attributes !== null) {
    //                 credentialFormik.setFieldValue('schemaJsonString', response?.payload?.result?.json_attributes);
    //             }
    //         }
    //         else {
    //             showToast.error(response?.payload?.response_exception?.exception_message)
    //         }
    //     })
    // }


    return (
        <>
            <PageTitle breadcrumbs={CredentialsContentBreadcrumbs}>{credentialSlice?.name ? `Update Credential` : `Create Credential`}</PageTitle>
            <KTCard>
                <KTCardBody>

                    <Tabs
                        defaultActiveKey="details"
                        id="tab"
                        className="mb-3"
                    >
                        <Tab eventKey="details" title="Detail"
                        //tabClassName={`${!credentialFormik.isValid ? Style.colorRed : Style.colorBlack}`} This side temproray closed.
                        >
                            <form id='kt_modal_add_user_form' className='form' onSubmit={credentialFormik.handleSubmit}>
                                <div>
                                    <div>
                                        <h3>Credential Detail</h3>
                                    </div>
                                    <hr></hr>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='fv-row mb-7'>
                                                <label className='required fw-bold fs-6 mb-2'>Name</label>
                                                <CustomTextbox
                                                    {...credentialFormik.getFieldProps('name')}
                                                    onChange={() => null}
                                                    placeHolder={"Type a Name"}
                                                    name='name'
                                                    value={credentialFormik.values.name}
                                                    formik={credentialFormik}
                                                    formikName={"name"}
                                                ></CustomTextbox>
                                            </div>
                                            <div className='fv-row mb-7'>
                                                <label className='fw-bold fs-6 mb-2'>Credential Definition Id</label>
                                                <CustomTextbox
                                                    {...credentialFormik.getFieldProps('credentialDefinitionId')}
                                                    onChange={() => null}
                                                    name='credentialDefinitionId'
                                                    value={credentialFormik.values.credentialDefinitionId}
                                                    formik={credentialFormik}
                                                    formikName={"credentialDefinitionId"}
                                                    disabled
                                                ></CustomTextbox>
                                            </div>
                                            <div className='fv-row mb-7'>
                                                <label className=' fw-bold fs-6 mb-2'>Version</label>
                                                <CustomTextbox
                                                    {...credentialFormik.getFieldProps('version')}
                                                    onChange={() => null}
                                                    name='version'
                                                    value={credentialFormik.values.version}
                                                    formik={credentialFormik}
                                                    formikName={"version"}
                                                    type={"number"}
                                                    onlyPositive
                                                    disabled
                                                ></CustomTextbox>
                                            </div>
                                            <div className='fv-row mb-7'>
                                                <label className='required fw-bold fs-6 mb-2'>Group Name</label>
                                                <CustomSelect
                                                    onChange={() => null}
                                                    data={GroupNameEnum}
                                                    isMulti={false}
                                                    selectedValue={credentialFormik?.values?.groupName}
                                                    formik={credentialFormik}
                                                    formikName={"groupName"}
                                                ></CustomSelect>
                                            </div>
                                            <div className='fv-row mb-7 d-flex' style={{ marginTop: "4rem" }}>
                                                <div className='col-3'>
                                                    <div className='form-check form-check-solid'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            id='is_active'
                                                            defaultChecked={credentialFormik.values.isActive}
                                                            checked={credentialFormik.values.isActive}
                                                            onChange={(event) =>
                                                                credentialFormik?.setValues({
                                                                    ...credentialFormik?.values,
                                                                    isActive: event.target.checked
                                                                })
                                                            }
                                                        />
                                                        <label
                                                            className='form-check-label ps-2'
                                                            htmlFor='is_active'
                                                        >
                                                            Is Active
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className='col-3'>
                                                    <div className='form-check form-check-solid' >
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            id='is_active_on_hybrid_service'
                                                            defaultChecked={credentialFormik.values.isActiveOnHybridService}
                                                            checked={credentialFormik.values.isActiveOnHybridService}
                                                            onChange={(event) =>
                                                               {
                                                                credentialFormik?.setValues({
                                                                    ...credentialFormik?.values,
                                                                    isActiveOnHybridService: event.target.checked
                                                                })
                                                                setsdkAboutRequired(event.target.checked)
                                                               }
                                                            }
                                                        />
                                                        <label
                                                            className='form-check-label ps-2'
                                                            htmlFor='is_active_on_hybrid_service'
                                                        >
                                                            Is Active on Hybrid Service
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='fv-row mb-7'>
                                                <label className='required fw-bold fs-6 mb-2'>Cred Price</label>
                                                <CustomTextbox
                                                    type={"number"}
                                                    {...credentialFormik.getFieldProps('credPrice')}
                                                    onChange={() => null}
                                                    placeHolder={"Type a Cred Price"}
                                                    name='credPrice'
                                                    value={credentialFormik.values.credPrice}
                                                    formik={credentialFormik}
                                                    formikName={"credPrice"}
                                                    onlyPositive
                                                ></CustomTextbox>
                                            </div>
                                            <div className='fv-row mb-7'>
                                                <label className='required fw-bold fs-6 mb-2'>Icon</label>
                                                <div className="row">
                                                    <div className="col-11">
                                                        <CustomTextbox
                                                            {...credentialFormik.getFieldProps('icon')}
                                                            onChange={() => null}
                                                            placeHolder={"Type an Icon"}
                                                            name='icon'
                                                            value={credentialFormik.values.icon}
                                                            formik={credentialFormik}
                                                            formikName={"icon"}
                                                        ></CustomTextbox>
                                                    </div>
                                                    <div className="col-1">
                                                        <FontAwesomeIcon
                                                            icon={credentialFormik.values.icon !== undefined ? fontAwesomeHelper(credentialFormik.values.icon) as IconDefinition : faX} size={"2x"} style={{ marginTop: "0.5rem" }}
                                                        ></FontAwesomeIcon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='fv-row mb-7'>
                                                <label className='required fw-bold fs-6 mb-2'>About</label>
                                                <CustomTextbox
                                                    {...credentialFormik.getFieldProps('about')}
                                                    onChange={() => null}
                                                    placeHolder={"Type an About"}
                                                    name='about'
                                                    value={credentialFormik.values.about}
                                                    formik={credentialFormik}
                                                    formikName={"about"}
                                                ></CustomTextbox>
                                            </div>
                                            <div className='fv-row mb-7'>
                                                <label className='required fw-bold fs-6 mb-2'>Data Provider Type</label>
                                                <CustomSelect
                                                    onChange={() => null}
                                                    data={DataProviderTypesEnum}
                                                    isMulti={false}
                                                    selectedValue={credentialFormik?.values?.dataProviderType}
                                                    formik={credentialFormik}
                                                    formikName={"dataProviderType"}
                                                ></CustomSelect>
                                            </div>
                                            {credentialFormik?.values?.isActiveOnHybridService ?
                                                <div className='fv-row mb-7'>
                                                    <label className='required fw-bold fs-6 mb-2'>SDK About</label>
                                                    <CustomTextbox
                                                        {...credentialFormik.getFieldProps('sdkAbout')}
                                                        onChange={() => null}
                                                        placeHolder={"Type an SDK About"}
                                                        name='sdkAbout'
                                                        value={credentialFormik.values.sdkAbout}
                                                        formik={credentialFormik}
                                                        formikName={"sdkAbout"}
                                                    ></CustomTextbox>
                                                </div> : <></>
                                            }

                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div>
                                        <h3>Schema</h3>
                                    </div>
                                    <hr></hr>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='fv-row mb-7'>
                                                <label className='required fw-bold fs-6 mb-2'>Name</label>
                                                <CustomTextbox
                                                    {...credentialFormik.getFieldProps('schemaName')}
                                                    onChange={() => null}
                                                    placeHolder={"Type a Name"}
                                                    name='schemaName'
                                                    value={credentialFormik.values.schemaName}
                                                    formik={credentialFormik}
                                                    formikName={"schemaName"}
                                                ></CustomTextbox>
                                            </div>

                                            {/* <div className='fv-row mb-7'>
                                                <label className='required fw-bold fs-6 mb-2'>Schema Class</label>
                                                <CustomTextbox
                                                    {...credentialFormik.getFieldProps('schemaClass')}
                                                    onChange={() => null}
                                                    placeHolder={"Type a Schema Class"}
                                                    name='schemaClass'
                                                    value={credentialFormik.values.schemaClass}
                                                    formik={credentialFormik}
                                                    formikName={"schemaClass"}
                                                ></CustomTextbox>
                                            </div> */}
                                            <div className='fv-row mb-7'>
                                                <label className='fw-bold fs-6 mb-2'>Jsonld Url</label>
                                                <CustomTextbox
                                                    {...credentialFormik.getFieldProps('jsonldUrl')}
                                                    onChange={() => null}
                                                    name='jsonldUrl'
                                                    value={credentialFormik.values.jsonldUrl}
                                                    formik={credentialFormik}
                                                    formikName={"jsonldUrl"}
                                                    disabled
                                                ></CustomTextbox>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='fv-row mb-7'>
                                                <label className='required fw-bold fs-6 mb-2'>Schema Class</label>
                                                <CustomSelect
                                                    onChange={() => null}
                                                    data={SchemaClassEnum}
                                                    isMulti={false}
                                                    selectedValue={credentialFormik?.values?.schemaClass}
                                                    formik={credentialFormik}
                                                    formikName={"schemaClass"}
                                                ></CustomSelect>
                                            </div>
                                            <div className='fv-row mb-7'>
                                                <label className='required fw-bold fs-6 mb-2'>Schema Object Type</label>
                                                <CustomTextbox
                                                    {...credentialFormik.getFieldProps('schemaObjectType')}
                                                    onChange={() => null}
                                                    placeHolder={"Type a Schema Object Type"}
                                                    name='schemaObjectType'
                                                    value={credentialFormik.values.schemaObjectType}
                                                    formik={credentialFormik}
                                                    formikName={"schemaObjectType"}
                                                ></CustomTextbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-11"></div>
                                    <div className="col-1">
                                        <button
                                            type='submit'
                                            className='btn btn-primary'
                                            data-kt-users-modal-action='submit'
                                        >
                                            <span className='indicator-label'>Save</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Tab>
                        <Tab eventKey="sub" title="Sub Category" tabClassName={`${isSubCatValid === false ? Style.colorRed : Style.colorBlack}`}>
                            <CredentialSchemaDetail></CredentialSchemaDetail>
                        </Tab>
                        {/* <Tab eventKey="json" title="Schema Json"
                        //tabClassName={`${schemaJson === null ? Style.colorRed : Style.colorBlack}`} This part temproray closed.
                        >
                            <div>
                                <div className='row'>
                                    <div className='col-10'>
                                        <h3 style={{ marginTop: "9px" }}>Schema Json</h3>
                                    </div>
                                    <div className='col-2'>
                                        <button type='button' className='btn btn-primary'
                                            style={{ float: "right" }}
                                            onClick={() => getSchemaJson()}>
                                            <KTSVG path='' className='svg-icon-2' />
                                            {`Generate Json`}
                                        </button>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className='fv-row mb-7'>
                                    <label className={`required fw-bold fs-6 mb-2`}>String</label>
                                    <CustomRichTextbox
                                        {...credentialFormik.getFieldProps('schemaJsonString')}
                                        onChange={() => null}
                                        placeHolder={""}
                                        name='schemaJsonString'
                                        value={credentialFormik.values.schemaJsonString}
                                        formik={credentialFormik}
                                        formikName={"schemaJsonString"}
                                        height={"300px"}
                                    ></CustomRichTextbox>
                                </div>
                            </div>
                        </Tab> */}

                    </Tabs>
                    <hr></hr>
                </KTCardBody>
            </KTCard>
        </>
    )
}

export default CredentialDetail