/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { isNotEmpty, KTCard, KTSVG } from "../../../../../_metronic/helpers"
import { ListViewProvider } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../../slices/components/componentsSlice"
import { showToast } from "../../../../components/custom-toasts/custom-toasts"
import CustomSelect from "../../../../components/customModal/CustomSelect"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { deleteTenderWithId, exportTender, getExistTender, getTender, getTenderWithId, postTender, putTender } from "../../../../slices/assetManagement/sdk/tender/TenderSlice"

interface Props {
    TenderListSlice: any,
    ExistTenderListSlice: any
}


export const sdkTenderTypeData = [
    {
        value: 0,
        label: "GooglePay"
    },
    {
        value: 1,
        label: "ApplePay"
    },
    {
        value: 2,
        label: "Merchant"
    },
]

export const sdkTenderTypeDataForFilter =
    [
        {
            value: -1,
            label: "Select..."
        },
        ...sdkTenderTypeData
    ]

const TenderList: FC<Props> = ({ TenderListSlice, ExistTenderListSlice }) => {
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { modalIsOpen } = useAppSelector((state: any) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [selectedRowId, setSelectedRowId] = useState<string | null>(null)
    const [loading, setloading] = useState(false)
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([])
    const [filterParams, setfilterParams] = useState<any>([])

    const formik = useFormik({
        initialValues: {
            id: '',
            sdkTenderType: undefined,
            isActive: false,
        },
        validationSchema: Yup.object().shape({
            sdkTenderType: Yup.object().required('Type is required'),

        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateWithId(values)
                } else {
                    createTender(values);
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                // dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    useEffect(() => {
        setdataTableisBusy(false)
    }, [TenderList])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(null)
            formik.resetForm()
        }
    }, [modalIsOpen])

    //#region DataTable Methods

    const columns = useMemo(
        () => [
            {
                name: 'Type',
                selector: (row: any) => <div>{sdkTenderTypeData.find(x => x.value === row?.sdk_tender_type)?.label}</div>,
            },
            {
                name: 'Visibility',
                selector: (row: any) => <div>{row?.visibility ?
                    <span className='badge badge-light-success fs-5 fw-semibold'>{row?.visibility.toString()}</span> :
                    <span className='badge badge-light-danger fs-5 fw-semibold'>{row?.visibility.toString()}</span>
                }</div>,
            },
            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={() => {
                                    customgetTenderWithId(row.id)
                                    setprocessName("Insert")
                                    setSelectedRowId(row.id)
                                    dispatch(componentsSlice.actions.openModal({}))
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </div>
                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={() => {
                                    setprocessName("Delete")
                                    setSelectedRowId(row.id)
                                    dispatch(componentsSlice.actions.openModal({}))
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                            </div>
                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const customFilter = [
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Type</label>
                <CustomSelect
                    onChange={
                        (e: any) =>
                            e.value !== -1 ?
                                setfilterQuery([...filterQuery.filter((x: any) => x.name !== "SdkTenderType"), { "name": "SdkTenderType", "comparator": "==", "value": e.value }])
                                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "SdkTenderType")])
                    }
                    data={sdkTenderTypeDataForFilter}
                    isMulti={false}
                    selectedValue={sdkTenderTypeDataForFilter?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "SdkTenderType")?.value) !== undefined ? sdkTenderTypeDataForFilter?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "SdkTenderType")?.value) : -1}
                ></CustomSelect>
            </>
        },
    ]

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.name, "direction": sortDirection === "asc" ? 0 : 1 }])
    };
    useEffect(() => {
        dispatch(getTender({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, filterParams])


    //#endregion DataTable Methods End

    function deleteFunc() {
        dispatch(deleteTenderWithId(selectedRowId)).then((response: any) => {
            if (response?.payload?.is_success) {
                showToast.success("Deleted successfully")
                dispatch(getTender({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
                dispatch(getExistTender());
            }
            else {
                showToast.error("Delete process failed")
                dispatch(getTender({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
            }
        })
    }

    function createTender(request: any) {
        setloading(true)
        let req = {
            sdk_tender_type: request.sdkTenderType?.value,
            visibility: request.isActive,
        }

        dispatch(postTender(req)).then((response: any) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getTender({ page_index: 0, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
                setloading(false)
                dispatch(getExistTender());
            }
            else {
                setloading(false)
            }
        })
    }

    function updateWithId(request: any) {
        setloading(true)
        let req = {
            id: selectedRowId,
            sdk_tender_type: request.sdkTenderType?.value,
            visibility: request.isActive,
        }

        dispatch(putTender(req)).then((response: any) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getTender({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
                setloading(false)
                dispatch(getExistTender());
            }
            else {
                setloading(false)
            }
        })
    }

    function customgetTenderWithId(rowId: any) {
        if (rowId !== undefined) {
            dispatch(getTenderWithId(rowId)).then((s: any) => {
                formik.setValues({
                    id: s?.payload?.result?.sdk_tender?.id,
                    isActive: s?.payload?.result?.sdk_tender?.visibility,
                    sdkTenderType: (sdkTenderTypeData.find(x => x.value === s?.payload?.result?.sdk_tender?.sdk_tender_type) as any),
                })
            })
        }
    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== null ? "Update Tender" : "Create Tender"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Tender Type</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={sdkTenderTypeData.filter((x: any) => !ExistTenderListSlice.some((y: any) => y === x.value))}
                                            isMulti={false}
                                            selectedValue={formik?.values?.sdkTenderType}
                                            formik={formik}
                                            formikName={"sdkTenderType"}
                                        ></CustomSelect>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <div className='form-check form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value=''
                                                id='kt_settings_notification_phone'
                                                defaultChecked={formik.values.isActive}
                                                checked={formik.values.isActive}
                                                onChange={(event) =>
                                                    formik?.setValues({
                                                        ...formik?.values,
                                                        isActive: event.target.checked
                                                    })
                                                }
                                            />
                                            <label
                                                className='form-check-label ps-2'
                                                htmlFor='kt_settings_notification_phone'
                                            >
                                                Is Active
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        {loading ? (
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        ) :
                                            <span className='indicator-label'>Submit</span>
                                        }
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteFunc()
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={TenderListSlice?.items}
                    title={"Tender List"}
                    pagination
                    paginationServer
                    paginationTotalRows={TenderListSlice?.total_count}
                    isBusy={dataTableisBusy}
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    onExport={() => {
                        dispatch(exportTender({ page_index: 0, page_size: 0, filters: filterParams, sort_filters: sortData }));

                    }}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    showTitle
                    showFilter={true}
                    filterOptions={customFilter}
                    searchButtonClickEvent={() => setfilterParams(filterQuery)}
                    sortHandle={handleSort}
                    clearButtonClickEvent={() => {
                        setfilterParams([])
                        setfilterQuery([])
                    }}
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const TenderListWrapper: FC<Props> = ({ TenderListSlice, ExistTenderListSlice }) => <QueryRequestProvider>
    <ListViewProvider>
        <TenderList TenderListSlice={TenderListSlice}  ExistTenderListSlice={ExistTenderListSlice} />
    </ListViewProvider>
</QueryRequestProvider>

export { TenderListWrapper }
