import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SalesAssistantService from "../../../services/salesAssistant/SalesAssistant.service";


interface InitialStateInterface {
    PrepareListSlice: any
    PrepareSlice: any[]
    selectedPrepare: any
}

const initialState = {
    PrepareListSlice: null,
    PrepareSlice: [],
    selectedPrepare: null,
} as InitialStateInterface;


export const postPrepare = createAsyncThunk(
    "postPrepare",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.postPrepareService(req).then(response => response);
        } catch (error) { }
    }
);

export const getPrepare = createAsyncThunk(
    "getPrepareList",
    async (req: any) => {
        try {
            return await SalesAssistantService.getPrepareService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportPrepareMeetingList = createAsyncThunk('exportPrepareMeetingList', async (req: any) => {
    try {
      return await SalesAssistantService.exportPrepareMeetingList(req).then(
        response => response
      )
    } catch (error) {}
  })



export const deletePrepareWithId = createAsyncThunk(
    "deletePrepare",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.deletePrepareService(req).then(response => response);
        } catch (error) { }
    }
);

export const postPrepareDetail = createAsyncThunk(
    "postPrepareDetail",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.postPrepareDetailService(req).then(response => response);
        } catch (error) { }
    }
);

export const deletePrepareDetailWithId = createAsyncThunk(
    "deletePrepareDetail",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.deletePrepareDetailService(req).then(response => response);
        } catch (error) { }
    }
);


const PrepareSlice = createSlice({
    name: "PrepareSlice",
    initialState,
    reducers: {
        changeSelectedPrepareId: (state, action) => {
            state.selectedPrepare = action.payload?.prepareData
        },
        clearItems: (state, action) => {
            state.selectedPrepare = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPrepare.fulfilled, (state, action) => {
            state.PrepareListSlice = action.payload?.result ? action.payload.result?.prepare_meeting : null;
        });
    },
});

export default PrepareSlice;
