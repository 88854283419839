/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import componentsSlice from '../../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../../store/store'
import { ContentWrapper } from './contentList'

const ContentBreadcrumbs: Array<PageLink> = [
    {
        title: `SDK`,
        path: '/Content/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Contents = (props:any) => {
    const dispatch: AppDispatch = useDispatch();

    const { ContentListSlice } = useAppSelector(
        (state) => state.content
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={ContentBreadcrumbs}>{`Content`}</PageTitle>
                            <ContentWrapper ContentListSlice={ContentListSlice} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default Contents
