import React from "react";
import clsx from 'clsx';
import Styles from "../../../_metronic/assets/sass/pages/frpPurchaseRateList.module.scss"

interface Props {
  formik?: any;
  formikName?: any;
  type?: any;
  name?: any;
  onChange: (e: any) => void;
  value?: any;
  disabled?: any;
  readonly?: any;
  mask?: string;
  placeHolder?: any;
  prefix?: any;
  onlyPositive?: boolean;
  height?: any;
}

const CustomRichTextbox = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      formik,
      formikName,
      type,
      name,
      onChange,
      value,
      disabled,
      readonly,
      mask,
      placeHolder,
      prefix,
      onlyPositive,
      height
    },
    ref
  ) => {


    return (
      <>
        <textarea
          inputRef={ref}
          placeholder={placeHolder}
          type={"textarea"}
          {...formik?.getFieldProps([formikName])}
          name={name}
          className={`${clsx('form-control form-control-solid mb-3 mb-lg-0')} ${Styles.richTextbox}`}
          style={{height:`${height}`}}
          autoComplete='off'
          value={value?.toString()}
          disabled={disabled}
          readOnly={readonly}
          mask={mask}
          onChange={(event) => {
            onChange(mask === undefined ?
              onlyPositive ?
                Math.abs(parseInt(event.target.value))
                : event.target.value
              : event.target.value.replaceAll(prefix, '').replaceAll('_', ''));
            formik?.setValues({
              ...formik?.values,
              [formikName]: mask === undefined ?
                onlyPositive ?
                  Math.abs(parseInt(event.target.value))
                  : event.target.value.trimStart()
                : event.target.value.replaceAll(prefix, '').replaceAll('_', '')
            })
          }}
        />
        {formik?.touched?.[formikName] && formik?.errors?.[formikName] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik?.errors?.[formikName]}</span>
            </div>
          </div>
        )}
      </>
    );
  }
);

CustomRichTextbox.defaultProps = {
};

export default CustomRichTextbox;
