import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SliderContentService from "../../../../services/assetManagement/mobile/sliderContent/SliderContent.service";


interface InitialStateInterface {
    SliderContentListSlice: any
}

const initialState = {
    SliderContentListSlice: null,
} as InitialStateInterface;

export const getSliderContent = createAsyncThunk(
    "getSliderContentList",
    async (req: any) => {
        try {
            return await SliderContentService.getSliderContentService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportSliderContent = createAsyncThunk(
    "exportSliderContentList",
    async (req: any) => {
        try {
            return await SliderContentService.exportSliderContentService(req).then(response => response);
        } catch (error) { }
    }
);

export const postSliderContent = createAsyncThunk(
    "postSliderContent",
    async (req: any, thunkAPI) => {
        try {
            return await SliderContentService.postSliderContentService(req).then(response => response);
        } catch (error) { }
    }
);

export const putSliderContent = createAsyncThunk(
    "putSliderContent",
    async (req: any, thunkAPI) => {
        try {
            return await SliderContentService.putSliderContentService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteSliderContentWithId = createAsyncThunk(
    "deleteSliderContent",
    async (req: any, thunkAPI) => {
        try {
            return await SliderContentService.deleteSliderContentService(req).then(response => response);
        } catch (error) { }
    }
);

export const getSliderContentWithId = createAsyncThunk(
    "getSliderContentWithId",
    async (req: any) => {
        try {
            return await SliderContentService.getSliderContentWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const sliderContentSlice = createSlice({
    name: "sliderContentSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSliderContent.fulfilled, (state, action) => {
            state.SliderContentListSlice = action.payload?.result ? action.payload.result?.sliders : null;
        });
    },
});

export default sliderContentSlice;
