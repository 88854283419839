import React, { useState, FC } from 'react'
import Style from '../../../_metronic/assets/sass/pages/previewPages.module.scss'
import SliderContentPreview from './SliderContentPreview';
import SdkWelcomeScreen from './SdkWelcomeScreenPreview';

interface Props {
    screenName?: any;
    content?: any;
}

const PreviewPages: FC<Props> = ({ screenName, content }) => {
    const [buttonTypeVal, setbuttonTypeVal] = useState("iOS")

    function FindScreen() {
        switch (screenName) {
            case "SliderContent":
                return <SliderContentPreview content={content}></SliderContentPreview>
            case "SdkWelcomeScreen":
                return <SdkWelcomeScreen content={content}></SdkWelcomeScreen>
            default:
                break;
        }
    }

    return (
        <>
            <div className={`${Style.mainSwitch}`}>
                <div className={`${Style.rowSwitch} row`}>
                    <div className='col-4'></div>
                    <div className={`${Style.colSwitch} col-4`}>
                        <button
                            className={`${buttonTypeVal === "iOS" ? Style.iosSwitchEnable : Style.iosSwitchDisabled} col-6`}
                            onClick={() => { setbuttonTypeVal("iOS") }}
                        >
                            <span className='indicator-label'>IOS</span>
                        </button>
                        <button
                            className={`${buttonTypeVal === "Android" ? Style.androidSwitchEnable : Style.androidSwitchDisable} col-6`}
                            onClick={() => { setbuttonTypeVal("Android") }}
                        >
                            <span className='indicator-label'>Android</span>
                        </button>
                    </div>
                    <div className='col-4'></div>
                </div>
            </div>
            <div className={`${Style.mainContainer}`}>
                <div className={`${Style.phoneContainer}`} style={{ backgroundImage: `url(${(buttonTypeVal === "iOS" ? '/phoneImages/GeneralphoneDevice.png' : '/phoneImages/GeneraAndroidDevice.png')})` }}>
                    {
                        FindScreen()
                    }
                </div>
            </div>
        </>
    )
}

export default PreviewPages