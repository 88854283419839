/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { ReportWrapper } from './reportList'

const reportCrumbs: Array<PageLink> = [
    {
        title: `Report`,
        path: '/Report/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Report = () => {

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={reportCrumbs}>{`Report`}</PageTitle>
                            <ReportWrapper reportList={[]}  />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default Report
