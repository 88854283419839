
import { callApi, HttpMethods } from "../../../utils/api-util";

const getFPMerchantsService = async () => {
    let _options = {
        controller: "/?",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}

const postFPMerchantsService = async (req: any) => {
    let _options = {
        controller: "/?",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putFPMerchantsService = async (req: any) => {
    let _options = {
        controller: "/?",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteFPMerchantsService = async (req: any) => {
    let _options = {
        controller: "/?",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getFPMerchantsWithIdService = async (req:any) => {
    let _options = {
        controller: "?"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const FPMerchantsService = {
    getFPMerchantsService,
    postFPMerchantsService,
    putFPMerchantsService,
    deleteFPMerchantsService,
    getFPMerchantsWithIdService
};

export default FPMerchantsService;
