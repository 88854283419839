/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import { showToast } from "../../../components/custom-toasts/custom-toasts"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTCard, KTSVG } from "../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { deleteEndpointsWithId, getEndpoints, getEndpointsWithId, postEndpoints, putEndpoints } from "../../../slices/fpConnections/endpoints/endpointsSlice"
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"

interface Props {
    EndpointsListSlice: any
}

const EndpointsList: FC<Props> = ({ EndpointsListSlice }) => {
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [selectedRowId, setSelectedRowId] = useState(undefined)
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([])
    const [filterParams, setfilterParams] = useState<any>([])

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            value: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Name is required'),
            value: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Value is required'),
        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateWithId(values)
                }
                else {
                    createWithRequest(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    useEffect(() => {
        setdataTableisBusy(false)
    }, [EndpointsListSlice])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(undefined)
            formik.resetForm()
        }
    }, [modalIsOpen])

    //#region DataTable Methods

    const columns = useMemo(
        () => [
            {
                name: 'Name',
                selector: (row: any) => <div>{row?.name}</div>,
                width: "auto"
            },
            {
                name: 'Value',
                selector: (row: any) => <div>{row?.value}</div>,
                width: "auto"
            },
            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                            {
                                <>
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setprocessName("Insert")
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                            EndpointsWithId(row.id)
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                    </div>
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setprocessName("Delete")
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                    </div>
                                </>
                            }

                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const customFilter = [
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Name</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Name"), { "name": "Name", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Name")])
                    }
                    placeHolder={"Type a Name"}
                    name='name'
                    value={filterQuery.find((x: any) => x.name === "Name") !== undefined ? filterQuery.find((x: any) => x.name === "Name")?.value : ""}
                    customKeypressEvent={() => setfilterParams(filterQuery)}
                ></CustomTextbox>
            </>
        },
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Value</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Value"), { "name": "Value", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Value")])
                    }
                    placeHolder={"Type a Value"}
                    name='Value'
                    value={filterQuery.find((x: any) => x.name === "Value") !== undefined ? filterQuery.find((x: any) => x.name === "Value")?.value : ""}
                    customKeypressEvent={() => setfilterParams(filterQuery)}
                ></CustomTextbox>
            </>
        },
    ]

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.name, "direction": sortDirection === "asc" ? 0 : 1 }])
    };
    useEffect(() => {
        dispatch(getEndpoints({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, filterParams])


    //#endregion DataTable Methods End

    function EndpointsWithId(rowId: any) {
        if (rowId !== undefined) {
            dispatch(getEndpointsWithId(rowId)).then((s: any) => {
                formik.setValues({
                    id: s?.payload?.result?.endpoint?.id,
                    name: s?.payload?.result?.endpoint?.name,
                    value: s?.payload?.result?.endpoint?.value
                })
            })
        }
    }

    function updateWithId(request: any) {
        let req = {
            id: request.id,
            name: request.name,
            value: request.value,
        }
        dispatch(putEndpoints(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getEndpoints({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Updated Successfully")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function deleteWithId() {
        if (selectedRowId !== undefined) {
            dispatch(deleteEndpointsWithId(selectedRowId)).then((s: any) => {
                if (s?.payload?.is_success) {
                    dispatch(getEndpoints({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.success("Item Deleted Successfully")
                    dispatch(componentsSlice.actions.closeModal({}))
                }
                else {
                    dispatch(getEndpoints({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.error(s?.payload?.response_exception?.exception_message)
                    dispatch(componentsSlice.actions.closeModal({}))
                }
            })
        }
    }

    function createWithRequest(request: any) {
        let req = {
            name: request.name,
            value: request.value,
        }
        dispatch(postEndpoints(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getEndpoints({ page_index: 0, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Update Endpoint" : "Create Endpoint"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Name</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('name')}
                                            onChange={() => null}
                                            placeHolder={"Type a Name"}
                                            name='name'
                                            value={formik.values.name}
                                            formik={formik}
                                            formikName={"name"}
                                            type='text'
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Value</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('value')}
                                            onChange={() => null}
                                            placeHolder={"Type a Value"}
                                            name='value'
                                            value={formik.values.value}
                                            formik={formik}
                                            formikName={"value"}
                                            type='text'
                                        ></CustomTextbox>
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteWithId()
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={EndpointsListSlice?.items}
                    title={"Endpoint List"}
                    pagination
                    paginationServer
                    paginationTotalRows={EndpointsListSlice?.total_count}
                    isBusy={dataTableisBusy}
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    showTitle
                    showFilter={true}
                    filterOptions={customFilter}
                    searchButtonClickEvent={() => setfilterParams(filterQuery)}
                    sortHandle={handleSort}
                    clearButtonClickEvent={() => {
                        setfilterParams([])
                        setfilterQuery([])
                    }}
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const EndpointsWrapper: FC<Props> = ({ EndpointsListSlice }) => <QueryRequestProvider>
    <ListViewProvider>
        <EndpointsList EndpointsListSlice={EndpointsListSlice} />
    </ListViewProvider>
</QueryRequestProvider>

export { EndpointsWrapper }
