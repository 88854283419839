/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {AppDispatch} from '../../../../store/store'
import {useDispatch} from 'react-redux'
import componentsSlice from '../../../../slices/components/componentsSlice'
import {showToast} from '../../../../components/custom-toasts/custom-toasts'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import {QueryRequestProvider} from '../../../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import {ListViewProvider} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {
  getVeracityContactInformation,
  postVeracityContactInformation,
  putVeracityContactInformation,
} from '../../../../slices/assetManagement/system/veracityContactInformation/VeracityContactInformationSlice'
import CustomTextbox from '../../../../components/customTextbox/CustomTextbox'
import CustomPhoneTextbox from '../../../../components/customTextbox/CustomPhoneTextbox'
import CustomEmailTextbox from '../../../../components/customTextbox/CustomEmailTextbox'
import CustomRichTextbox from '../../../../components/customTextbox/CustomRichTextbox'

interface Props {
  VeracityContactInformationSlice: any
}

const VeracityContactInformation: FC<Props> = ({VeracityContactInformationSlice}) => {
  const dispatch: AppDispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      id: '',
      description: '',
      phoneNumber: '',
      email: '',
      address: '',
    },
    validationSchema: Yup.object().shape({
      description: Yup.string()
        .min(1, 'Minimum 1 symbols')
        .max(150, 'Maximum 150 symbols')
        .required('Description is required'),
      phoneNumber: Yup.string().min(1, 'Minimum 1 symbols').max(50, 'Maximum 50 symbols').required('Phone Number is required'),
      email: Yup.string().min(1, 'Minimum 1 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
      address: Yup.string().min(1, 'Minimum 1 symbols').max(150, 'Maximum 150 symbols').required('Address is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        if (formik.values.id) {
          updateWithId(values)
        } else {
          createWithRequest(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm()
      }
    },
  })

  useEffect(() => {
    formik.setValues({
      id: VeracityContactInformationSlice?.id,
      description: VeracityContactInformationSlice?.description,
      phoneNumber: VeracityContactInformationSlice?.phone_number,
      email: VeracityContactInformationSlice?.email,
      address: VeracityContactInformationSlice?.address,
    })
  }, [VeracityContactInformationSlice])

  function updateWithId(request: any) {
    let req = {
      id: request.id,
      description: request.description,
      phone_number: request.phoneNumber,
      email: request.email,
      address: request.address,
    }
    dispatch(putVeracityContactInformation(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getVeracityContactInformation())
        showToast.success('Item Saved Successfully')
      }
    })
  }

  function createWithRequest(request: any) {
    let req = {
      id: request.id,
      description: request.description,
      phone_number: request.phoneNumber,
      email: request.email,
      address: request.address,
    }
    dispatch(postVeracityContactInformation(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getVeracityContactInformation())
        showToast.success('Item Saved Successfully')
      }
    })
  }

  return (
    <>
      <KTCard>
        <KTCardBody>
          <form
            id='kt_modal_add_user_form'
            className='form'
            onSubmit={formik.handleSubmit}
            style={{height: '65vh'}}
          >
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              <div className='row'>
                <div className='col-6'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Phone Number</label>
                    <CustomPhoneTextbox
                      {...formik.getFieldProps('phoneNumber')}
                      onChange={() => null}
                      name='phoneNumber'
                      value={formik.values.phoneNumber}
                      formik={formik}
                      formikName={'phoneNumber'}
                    ></CustomPhoneTextbox>
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Email</label>
                    <CustomEmailTextbox
                      {...formik.getFieldProps('email')}
                      onChange={() => null}
                      name='email'
                      value={formik.values.email}
                      formik={formik}
                      formikName={'email'}
                    ></CustomEmailTextbox>
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Address</label>
                    <CustomTextbox
                      {...formik.getFieldProps('address')}
                      onChange={() => null}
                      placeHolder={'Type an Address'}
                      name='address'
                      value={formik.values.address}
                      formik={formik}
                      formikName={'address'}
                    ></CustomTextbox>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Description</label>
                    <CustomRichTextbox
                      {...formik.getFieldProps('description')}
                      onChange={() => null}
                      placeHolder={'Type a Description'}
                      name='description'
                      value={formik.values.description}
                      formik={formik}
                      formikName={'description'}
                      height={'23vh'}
                    ></CustomRichTextbox>
                  </div>
                </div>
              </div>
            </div>
            {/* begin::Actions */}
            <div className='text-left'>
              <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit'>
                <span className='indicator-label'>Submit</span>
              </button>
            </div>
            {/* end::Actions */}
          </form>
        </KTCardBody>
      </KTCard>
    </>
  )
}

const VeracityContactInformationWrapper: FC<Props> = ({VeracityContactInformationSlice}) => (
  <QueryRequestProvider>
    <ListViewProvider>
      <VeracityContactInformation
        VeracityContactInformationSlice={VeracityContactInformationSlice}
      />
    </ListViewProvider>
  </QueryRequestProvider>
)

export {VeracityContactInformationWrapper}
