import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import clsx from 'clsx';
import Styles from "../../../_metronic/assets/sass/pages/frpPurchaseRateList.module.scss"

interface Props {
  startDate?: Date | null;
  endDate?: Date | null;
  maxDate?: Date;
  minDate?: Date;
  onChange?: (e: Date | null) => void | undefined;
  placeHolder?: string;
  selected?: Date | null;
  disabled?: boolean;
  value?: Date | null;
  showTimeInput?: boolean;
  formik?: any;
  formikName?: any;
}

const CustomDate: React.FC<Props> = ({
  startDate,
  endDate,
  onChange,
  placeHolder,
  selected,
  maxDate,
  minDate,
  disabled,
  value,
  showTimeInput,
  formik,
  formikName
}) => {

  function findDateValue() {
    if (value !== undefined && value !== null) {
      if (value) {
        return moment(value).format("MM/DD/YYYY hh:mm a")
      }
      else {
        return moment(value).format("MM/DD/YYYY")
      }
    }
    else if (selected !== undefined && selected !== null) {
      if (showTimeInput) {
        return moment(selected).format("MM/DD/YYYY hh:mm a")
      }
      else {
        return moment(selected).format("MM/DD/YYYY")
      }
    }
    else {
      return placeHolder
    }
  }
  return (
    <>
      <DatePicker
        popperPlacement="top-start"
        showTimeInput={showTimeInput}
        timeFormat={"HH:mm"}
        startDate={startDate}
        endDate={endDate}
        maxDate={maxDate}
        minDate={minDate}
        dateFormat={!showTimeInput ? "MM/DD/YYYY" : "MM/DD/YYYY h:mm aa"}
        className={`${clsx('form-control form-control-solid mb-3 mb-lg-0')} ${Styles.textbox}`}
        onChange={(event: any) => {
          if (onChange !== undefined) {
            onChange(event)
          }
          formik?.setValues({
            ...formik?.values,
            [formikName]: event
          })
        }}
        placeholderText={placeHolder}
        disabledKeyboardNavigation
        selected={selected}
        disabled={disabled}
        shouldCloseOnSelect={!showTimeInput}
        value={findDateValue()}
      />
      {formik?.touched?.[formikName] && formik?.errors?.[formikName] && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik?.errors?.[formikName]}</span>
          </div>
        </div>
      )}
    </>

  );
};

CustomDate.defaultProps = {
  startDate: null,
  endDate: null,
  placeHolder: "Select",
  selected: new Date(),
  maxDate: new Date(3000, 12, 31, 23, 59, 59, 0),
  minDate: new Date(1950, 12, 31, 23, 59, 59, 0),
  disabled: false,
  showTimeInput: true
};

export default CustomDate;
