/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react"
import { AppDispatch } from "../../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../../slices/components/componentsSlice"
import { showToast } from "../../../../components/custom-toasts/custom-toasts"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import CustomRichTextbox from "../../../../components/customTextbox/CustomRichTextbox"
import { getPrivacyPolicy, postPrivacyPolicy, putPrivacyPolicy } from "../../../../slices/assetManagement/system/privacyPolicy/privacyPolicySlice"

interface Props {
    PrivacyPolicySlice: any
}

const PrivacyPolicy: FC<Props> = ({ PrivacyPolicySlice }) => {
    const dispatch: AppDispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            id: '',
            content: '',
        },
        validationSchema: Yup.object().shape({
            content: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Content is required'),
        }),
        onSubmit: (values, actions) => {
            try {
                if (formik.values.id) {
                    updateWithId(values)
                }
                else {
                    createWithRequest(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    useEffect(() => {
        formik.setValues({
            id: PrivacyPolicySlice?.id,
            content: PrivacyPolicySlice?.content,
        })
    }, [PrivacyPolicySlice])


    function updateWithId(request: any) {
        let req = {
            id: request.id,
            content: request.content,
        }
        dispatch(putPrivacyPolicy(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getPrivacyPolicy())
                showToast.success("Item Saved Successfully")
            }
        })
    }

    function createWithRequest(request: any) {
        let req = {
            id: request.id,
            content: request.content,
        }
        dispatch(postPrivacyPolicy(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getPrivacyPolicy())
                showToast.success("Item Saved Successfully")
            }
        })
    }

    return (
        <>
            <KTCard>
                <KTCardBody>
                    <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} style={{ height: "65vh" }}>
                        <div
                            className='d-flex flex-column scroll-y me-n7 pe-7'
                            id='kt_modal_add_user_scroll'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#kt_modal_add_user_header'
                            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                            data-kt-scroll-offset='300px'
                        >
                            <div className="row">
                                <div className="col-6">
                                    <div className='fv-row mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>Content</label>
                                        <CustomRichTextbox
                                            {...formik.getFieldProps('content')}
                                            onChange={() => null}
                                            placeHolder={"Type a Content"}
                                            name='description'
                                            value={formik.values.content}
                                            formik={formik}
                                            formikName={"content"}
                                            height={"23vh"}
                                        ></CustomRichTextbox>
                                    </div>
                                </div>
                            </div>


                        </div>
                        {/* begin::Actions */}
                        <div className='text-left'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                            >
                                <span className='indicator-label'>Submit</span>
                            </button>
                        </div>
                        {/* end::Actions */}
                    </form>
                </KTCardBody>
            </KTCard>
        </>
    )
}

const PrivacyPolicyWrapper: FC<Props> = ({ PrivacyPolicySlice }) => <QueryRequestProvider>
    <ListViewProvider>
        <PrivacyPolicy PrivacyPolicySlice={PrivacyPolicySlice} />
    </ListViewProvider>
</QueryRequestProvider>

export { PrivacyPolicyWrapper }
