import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IZonePollingSettingsService from "../../../services/campaignManagament/iZonePollingSettings/iZonePollingSettings.service";


interface InitialStateInterface {
    IZonePollingSettingsSlice: any
}

const initialState = {
    IZonePollingSettingsSlice: null,
} as InitialStateInterface;

export const getIZonePollingSettings = createAsyncThunk(
    "getIZonePollingSettingsList",
    async (req: any, thunkAPI) => {
        try {
            return await IZonePollingSettingsService.getIZonePollingSettingsService(req).then(response => response);
        } catch (error) { }
    }
);

export const postIZonePollingSettings = createAsyncThunk(
    "postIZonePollingSettings",
    async (req: any, thunkAPI) => {
        try {
            return await IZonePollingSettingsService.postIZonePollingSettingsService(req).then(response => response);
        } catch (error) { }
    }
);

export const putIZonePollingSettings = createAsyncThunk(
    "putIZonePollingSettings",
    async (req: any, thunkAPI) => {
        try {
            return await IZonePollingSettingsService.putIZonePollingSettingsService(req).then(response => response);
        } catch (error) { }
    }
);

const IZonePollingSettingsSlice = createSlice({
    name: "IZonePollingSettingsSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getIZonePollingSettings.fulfilled, (state, action) => {
            state.IZonePollingSettingsSlice = action.payload?.result ? action.payload.result?.system_setting : null;
        });
    },
});

export default IZonePollingSettingsSlice;
