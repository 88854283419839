import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SalesAssistantService from "../../../services/salesAssistant/SalesAssistant.service";


interface InitialStateInterface {
    ProductListSlice: any
    ProductSlice: any[]
}

const initialState = {
    ProductListSlice: null,
    ProductSlice: [],
} as InitialStateInterface;


export const postProduct = createAsyncThunk(
    "postProduct",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.postProductService(req).then(response => response);
        } catch (error) { }
    }
);

export const getProduct = createAsyncThunk(
    "getProductList",
    async (req: any) => {
        try {
            return await SalesAssistantService.getProductService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportProductList = createAsyncThunk('exportProductList', async (req: any) => {
    try {
      return await SalesAssistantService.exportProductList(req).then(
        response => response
      )
    } catch (error) {}
  })

export const getProductWithId = createAsyncThunk(
    "getProductWithId",
    async (req: any) => {
        try {
            return await SalesAssistantService.getProductWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

export const postProductFB = createAsyncThunk(
    "postProductFB",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.postProductFBService(req).then(response => response);
        } catch (error) { }
    }
);

export const postProductVideo = createAsyncThunk(
    "postProductVideo",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.postProductVideoService(req).then(response => response);
        } catch (error) { }
    }
);

export const postProductTs = createAsyncThunk(
    "postProductTs",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.postProductTsService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteProductWithId = createAsyncThunk(
    "deleteProduct",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.deleteProductService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteProductFBWithId = createAsyncThunk(
    "deleteProductFB",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.deleteProductFBService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteProductTSWithId = createAsyncThunk(
    "deleteProductTS",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.deleteProductTSService(req).then(response => response);
        } catch (error) { }
    }
);
export const deleteProductVideoWithId = createAsyncThunk(
    "deleteProductVideo",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.deleteProductVideoService(req).then(response => response);
        } catch (error) { }
    }
);
const ProductSlice = createSlice({
    name: "ProductSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProduct.fulfilled, (state, action) => {
            state.ProductListSlice = action.payload?.result ? action.payload.result?.products : null;
        });
    },
});


export default ProductSlice;
