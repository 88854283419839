import React from "react";
import InputMask from "react-input-mask"
import clsx from 'clsx';
import Styles from "../../../_metronic/assets/sass/pages/frpPurchaseRateList.module.scss"

interface Props {
  formik?: any;
  formikName?: any;
  type?: any;
  name?: any;
  onChange: (e: any) => void;
  value?: any;
  disabled?: any;
  readonly?: any;
  mask?: string;
  placeHolder?: any;
  prefix?: any;
  onlyPositive?: boolean;
  customKeypressEvent?: any;
  min?: number;
  maxLength?: number;
}

const CustomTextbox = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      formik,
      formikName,
      type,
      name,
      onChange,
      value,
      disabled,
      readonly,
      mask,
      placeHolder,
      prefix,
      onlyPositive,
      customKeypressEvent,
      min,
      maxLength,
    },
    ref
  ) => {


    return (
      <>
        <InputMask
          onKeyPress={(event) => {
            if (onlyPositive && !/[0-9.,]/.test(event.key)) {
              event.preventDefault();
            }
            if (event.key === "Enter") {
              customKeypressEvent();
            }
          }}
          inputRef={ref}
          placeholder={placeHolder}
          type={type ?? "text"}
          {...formik?.getFieldProps([formikName])}
          name={name}
          className={`${clsx('form-control form-control-solid mb-3 mb-lg-0')} ${Styles.textbox}`}
          autoComplete='off'
          value={value?.toString()}
          disabled={disabled}
          readOnly={readonly}
          mask={mask}
          step="any"
          maxLength={maxLength}
          min={min ?? (onlyPositive && type === "number" ? 0.0 : null)}
          onChange={(event) => {
            onChange(mask === undefined ?
              onlyPositive ?
                Math.abs(parseInt(event.target.value))
                : event.target.value
              : event.target.value.replaceAll(prefix, '').replaceAll('_', ''));
            formik?.setValues({
              ...formik?.values,
              [formikName]: mask === undefined ?
                onlyPositive ?
                  (type === "number" ? event.target.value :
                    Math.abs(parseInt(event.target.value)))
                  : event.target.value.trimStart()
                : event.target.value.replaceAll(prefix, '').replaceAll('_', '')
            })
          }}
        />
        {formik?.touched?.[formikName] && formik?.errors?.[formikName] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik?.errors?.[formikName]}</span>
            </div>
          </div>
        )}
      </>
    );
  }
);

CustomTextbox.defaultProps = {
};

export default CustomTextbox;
