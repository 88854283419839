
import { callApi, HttpMethods } from "../../../utils/api-util";
import { ResponseType } from "../../../utils/enums/ResponseType.enum";

const getFaqService = async (req: any) => {
    let _options = {
        controller: "/faq/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}
const exportFaqService = async (req: any) => {
    let _options = {
        controller: "/faq/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}

const postFaqService = async (req: any) => {
    let _options = {
        controller: "/faq",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putFaqService = async (req: any) => {
    let _options = {
        controller: "/faq",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteFaqService = async (req: any) => {
    let _options = {
        controller: "/faq",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getFaqWithIdService = async (req:any) => {
    let _options = {
        controller: "/faq/get-faq-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const FaqService = {
    getFaqService,
    exportFaqService,
    postFaqService,
    putFaqService,
    deleteFaqService,
    getFaqWithIdService
};

export default FaqService;
