/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import componentsSlice from '../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { OutcomesWrapper } from './OutcomesList'

const OutcomesContentBreadcrumbs: Array<PageLink> = [
    {
        title: `Campaign Management`,
        path: '/outcomes/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Outcomes = (props: any) => {
    const dispatch: AppDispatch = useDispatch();

    const { OutcomesListSlice } = useAppSelector(
        (state) => state.outcomes
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={OutcomesContentBreadcrumbs}>{`Outcomes`}</PageTitle>
                            <OutcomesWrapper OutcomesListSlice={OutcomesListSlice} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default Outcomes
