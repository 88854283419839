import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TermsConditionsService from "../../../../services/assetManagement/mobile/termsConditions/TermsConditions.service";


interface InitialStateInterface {
    TermsConditionsListSlice: any
    TermsConditionSlice: any
}

const initialState = {
    TermsConditionsListSlice: null,
    TermsConditionSlice: null
} as InitialStateInterface;

export const getTermsConditionsWithTypeId = createAsyncThunk(
    "getTermsConditionsWithTypeId",
    async (req: any) => {
        try {
            return await TermsConditionsService.getTermsConditionsTypeIdService(req).then(response => response);
        } catch (error) { }
    }
);


export const exportTermsConditionsWithTypeId = createAsyncThunk(
    "exportTermsConditionsWithTypeId",
    async (req: any) => {
        try {
            return await TermsConditionsService.exportTermsConditionsTypeIdService(req).then(response => response);
        } catch (error) { }
    }
);

export const postTermsConditions = createAsyncThunk(
    "postTermsConditions",
    async (req: any, thunkAPI) => {
        try {
            return await TermsConditionsService.postTermsConditionsService(req).then(response => response);
        } catch (error) { }
    }
);

export const putTermsConditions = createAsyncThunk(
    "putTermsConditions",
    async (req: any, thunkAPI) => {
        try {
            return await TermsConditionsService.putTermsConditionsService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteTermsConditionWithId = createAsyncThunk(
    "deleteTermsConditionWithId",
    async (req: any, thunkAPI) => {
        try {
            return await TermsConditionsService.deleteTermsConditionsService(req).then(response => response);
        } catch (error) { }
    }
);

export const getTermsConditionWithId = createAsyncThunk(
    "getTermsConditionWithId",
    async (req: any) => {
        try {
            return await TermsConditionsService.getTermsConditionWithIdService(req).then(response => response);
        } catch (error) { }
    }
);
const termsConditionsSlice = createSlice({
    name: "termsConditionsSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTermsConditionsWithTypeId.fulfilled, (state, action) => {
            state.TermsConditionsListSlice = action.payload?.result ? action.payload.result?.terms_of_conditions : null;
        });
        builder.addCase(getTermsConditionWithId.fulfilled, (state, action) => {
            state.TermsConditionSlice = action.payload?.result ? action.payload.result?.terms_of_conditions : [];
        });
    },
});

export default termsConditionsSlice;
