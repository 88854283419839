/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from 'react'
import CustomDatatable from '../../../components/customDatatable/CustomDatatable'
import { CustomModal } from '../../../components/customModal/CustomModal'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { useDispatch } from 'react-redux'
import componentsSlice from '../../../slices/components/componentsSlice'
import { showToast } from '../../../components/custom-toasts/custom-toasts'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTCard, KTSVG } from '../../../../_metronic/helpers'
import { QueryRequestProvider } from '../../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import { ListViewProvider } from '../../../modules/apps/user-management/users-list/core/ListViewProvider'
import ProductionRequestSlice, {
  exportProductionRequestWithStatus,
  getProductionRequestWithId,
  getProductionRequestWithStatus,
  postSendEmailDirector,
  putProductionRequest,
} from '../../../slices/merchantManagement/productionRequestSlice'
import moment from 'moment'
import Style from '../../../../_metronic/assets/sass/pages/activeMerchants.module.scss'
import CustomTextbox from '../../../components/customTextbox/CustomTextbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getdownloadableLinkWithUrl } from '../../../utils/upload-image-file'
import {
  faBadgeCheck,
  faBuilding,
  faEnvelope,
  faUsers,
  faFile,
  faEllipsisStroke,
} from '@fortawesome/pro-light-svg-icons'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import levenshteinAlgorithm from '../../../utils/levenshtein-algorithm'
import CustomSelect from '../../../components/customModal/CustomSelect'
import { CountryArray } from '../../../utils/constants/Country'

interface Props {
  pendingMerchantList: any
}

export enum SocialMediaEnum {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
}

const PendingMerchantList: FC<Props> = ({ pendingMerchantList }) => {
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const { modalIsOpen } = useAppSelector((state) => state.components)
  const dispatch: AppDispatch = useDispatch()
  const [processName, setprocessName] = useState('Detail')
  const { RequestSlice } = useAppSelector((state) => state.productionRequest)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [filterQuery, setfilterQuery] = useState<any>([])
  const [sortData, setsortData] = useState<any>([])
  const [filterParams, setfilterParams] = useState<any>([])

  let customValidationSchema = Yup.object().shape({
    companyDetail: Yup.boolean().oneOf([true], 'Check Required'),
    registeredDirectors:
      RequestSlice?.production_request?.temp_officers?.length > 0
        ? Yup.boolean().oneOf([true], 'Check Required')
        : Yup.boolean(),
    socialVerification:
      RequestSlice?.production_request?.temp_social_channels?.filter(
        (x: any) => x.social_channel_type !== 4
      )?.length > 0
        ? Yup.boolean().oneOf([true], 'Check Required')
        : Yup.boolean(),
    documentVerification:
      RequestSlice?.production_request?.temp_production_request_files?.length > 0
        ? Yup.boolean().oneOf([true], 'Check Required')
        : Yup.boolean(),
  })

  const formik = useFormik({
    initialValues: {
      id: '',
      companyDetail: false,
      registeredDirectors: false,
      socialVerification: false,
      documentVerification: false,
      others: false,
    },
    validationSchema: customValidationSchema,
    onSubmit: (values, actions) => {
      try {
        createWithRequest(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm()
      }
    },
  })

  useEffect(() => {
    dispatch(ProductionRequestSlice.actions.clearItems({}))
  }, [])

  useEffect(() => {
    setdataTableisBusy(false)
  }, [pendingMerchantList])

  useEffect(() => {
    if (modalIsOpen === undefined) {
      setprocessName('Detail')
      formik.resetForm()
    }
  }, [modalIsOpen])

  //#region DataTable Methods

  const columns = useMemo(
    () => [
      {
        name: 'Country',
        selector: (row: any) => <div>{row?.country}</div>,
        width: '11%',
      },
      {
        name: 'Name',
        selector: (row: any) => <div>{row?.name}</div>,
        width: 'auto',
      },
      {
        name: 'Date Created',
        label: "CreateDateUtc",
        selector: (row: any) => <div>{moment(row.onboarding_date).format('MM/DD/YYYY')}</div>,
        width: '18%',
        sortable: true
      },
      {
        name: 'Action',
        selector: (row: any) => (
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {
                <>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Detail')
                      dispatch(componentsSlice.actions.openModal({}))
                      PendingMerchantWithId(row.id)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/coding/cod002.svg' className='svg-icon-3' />
                  </div>
                </>
              }
            </div>
          </>
        ),
        width: '100px',
      },
    ],
    []
  )

  const customFilter = [
    {
      component: (
        <>
          <label className='fw-bold fs-6 mb-2'>Country</label>
          <CustomSelect
            onChange={(e: any) =>
              e.value !== -1
                ? setfilterQuery([
                  ...filterQuery.filter((x: any) => x.name !== 'TempAddressDefinition.Country'),
                  { name: 'TempAddressDefinition.Country', comparator: 'Contains', value: e.label },
                ])
                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== 'TempAddressDefinition.Country')])
            }
            data={CountryArray}
            isMulti={false}
            selectedValue={
              CountryArray?.find(
                (x: any) => x.label === filterQuery.find((x: any) => x.name === 'TempAddressDefinition.Country')?.value
              ) !== undefined
                ? CountryArray?.find(
                  (x: any) => x.label === filterQuery.find((x: any) => x.name === 'TempAddressDefinition.Country')?.value
                )
                : -1
            }
          ></CustomSelect>
        </>
      ),
    },
    {
      component: (
        <>
          <label className='fw-bold fs-6 mb-2'>Name</label>
          <CustomTextbox
            onChange={(e) =>
              isNotEmpty(e)
                ? setfilterQuery([
                  ...filterQuery.filter((x: any) => x.name !== 'CompanyName'),
                  { name: 'CompanyName', comparator: 'Contains', value: e },
                ])
                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== 'CompanyName')])
            }
            placeHolder={'Type a Name'}
            name='name'
            value={
              filterQuery.find((x: any) => x.name === 'CompanyName') !== undefined
                ? filterQuery.find((x: any) => x.name === 'CompanyName')?.value
                : ''
            }
            customKeypressEvent={() => setfilterParams(filterQuery)}
          ></CustomTextbox>
        </>
      ),
    },

  ]

  const handlePageChange = (page: any) => {
    setdataTableisBusy(true)
    setPage(page - 1)
    setdataTableisBusy(false)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setdataTableisBusy(true)
    setPerPage(newPerPage)
    setdataTableisBusy(false)
  }
  const handleSort = async (column: any, sortDirection: any) => {
    setsortData([{ property: column?.label, direction: sortDirection === 'asc' ? 0 : 1 }])
  }
  useEffect(() => {
    dispatch(
      getProductionRequestWithStatus({
        page_index: page,
        page_size: perPage,
        filters: filterParams,
        sort_filters: sortData,
      })
    )
  }, [page, perPage, sortData, filterParams])

  //#endregion DataTable Methods End

  function PendingMerchantWithId(rowId: any) {
    if (rowId !== undefined) {
      dispatch(getProductionRequestWithId(rowId)).then((s: any) => {
        formik.setValues({
          id: s?.payload?.result?.production_request?.id,
          companyDetail: false,
          documentVerification: false,
          others: false,
          registeredDirectors: false,
          socialVerification: false,
        })
      })
    }
  }

  function createWithRequest(request: any) {
    let req = {
      id: request?.id,
      status: request?.status !== undefined ? request?.status : 1,
    }
    dispatch(putProductionRequest(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(
          getProductionRequestWithStatus({
            page_index: page,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        showToast.success('Item Saved Successfully')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function fileDownload(url: string, name: string) {
    let req = {
      url: url,
      file_name: 'production-request',
    }
    dispatch(getdownloadableLinkWithUrl(req)).then((response) => {
      if (response?.payload?.is_success) {
        let a = document.createElement('a')
        a.href = response?.payload?.result?.original_url
        a.download = name
        a.click()
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function registeredUserEmailFunction(
    registeredUserEmail: string,
    registeredUserName: string,
    registeredUserCompanyName: string
  ) {
    let object = {
      email: registeredUserEmail,
      name: registeredUserName,
      company_name: registeredUserCompanyName,
    }
    dispatch(postSendEmailDirector(object)).then((response) => {
      if (response?.payload?.is_success) {
        showToast.success('E-Mail Sent')
      }
    })
  }

  function redirectSocialMedia(url: string, type: number) {
    if (url.includes('http') || url.includes('https')) {
      window.open(url)
    } else {
      switch (type) {
        case 0:
          window.open(`https://www.facebook.com/${url}`)
          break
        case 1:
          window.open(`https://www.instagram.com/${url}`)
          break
        case 2:
          window.open(`https://twitter.com/${url}`)
          break
        case 3:
          if (url.includes("company/")) {
            window.open(`https://www.linkedin.com/${url}`)
          }
          else {
            window.open(`https://www.linkedin.com/company/${url}`)
          }
          break

        default:
          break
      }
    }
  }

  function findProcessScreen(processName: string) {
    switch (processName) {
      case 'Detail':
        return (
          <>
            <CustomModal
              title={'Merchant Verification Checklist'}
              maxWidth={1100}
              footer={
                <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                  <div className='row processButton'>
                    <div className='text-center pt-2'>
                      <button
                        type='reset'
                        onClick={() => {
                          let req = {
                            id: formik.values.id,
                            status: 0,
                          }
                          createWithRequest(req)
                        }}
                        className='btn btn-danger me-3'
                        data-kt-users-modal-action='cancel'
                      >
                        Reject
                      </button>

                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                      >
                        <span
                          className='indicator-label'
                          onClick={() =>
                            formik.errors.companyDetail !== undefined ||
                              formik.errors.documentVerification !== undefined ||
                              formik.errors.registeredDirectors !== undefined ||
                              formik.errors.socialVerification !== undefined
                              ? showToast.error('Please Check Fields.')
                              : null
                          }
                        >
                          Confirm
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
              }
            >
              <>
                <div className='row'>
                  <div className='col-12 border rounded d-flex justify-content-between'>
                    <div className='row d-flex mx-2' style={{ width: '95%' }}>
                      <div className='col-1 mx-2 d-flex flex-column justify-content-center align-items-center'>
                        <div className='row fs-5 fw-bolder d-flex justify-content-center text-center'>{`Company Detail`}</div>
                        <div className='row d-flex justify-content-center'>
                          <FontAwesomeIcon
                            icon={faBuilding}
                            size='2xl'
                            className='mt-2'
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                      <div className='col-auto d-flex flex-column justify-content-center align-items-center'>
                        <div
                          className='row opacity-50'
                          style={{
                            borderLeft: '2px solid #CCCCCC',
                            height: '80%',
                          }}
                        ></div>
                      </div>
                      <div className='col'>
                        <div className='row d-flex justify-content-start mt-4'>
                          <div className='col-7'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`Country :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    {
                                      RequestSlice?.production_request?.temp_address_definition
                                        ?.country
                                    }
                                  </div>
                                  <div
                                    className='col'
                                    style={{ marginLeft: '0.2rem', paddingLeft: '0.2rem' }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-5'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`Bank Name :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    <div className='row'>
                                      <div
                                        className='col-auto'
                                        style={{
                                          marginRight: '0px',
                                          paddingRight: '0px',
                                        }}
                                      >
                                        {RequestSlice?.production_request?.account_name}
                                      </div>
                                      {levenshteinAlgorithm(
                                        RequestSlice?.production_request?.company_name,
                                        RequestSlice?.production_request?.account_name
                                      ) <= 5 ? (
                                        <div
                                          className='col-1'
                                          style={{ marginLeft: '2px', paddingLeft: '2px' }}
                                        >
                                          <FontAwesomeIcon icon={faBadgeCheck}></FontAwesomeIcon>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row d-flex justify-content-start mt-2'>
                          <div className='col-7'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`Company Name :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    {RequestSlice?.production_request?.company_name}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-5'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`MCC Code :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    {RequestSlice?.production_request?.mcc_code}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row d-flex justify-content-start mt-2'>
                          <div className='col-7'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`Company Number :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    {RequestSlice?.production_request?.company_number}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-5'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`Building name :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    {
                                      RequestSlice?.production_request?.temp_address_definition
                                        ?.building_name
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row d-flex justify-content-start mt-2'>
                          <div className='col-7'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`Company Mobile Number :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    {`+${RequestSlice?.production_request?.company_phone_number}`}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-5'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`Street name :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    {
                                      RequestSlice?.production_request?.temp_address_definition
                                        ?.street_name
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row d-flex justify-content-start mt-2'>
                          <div className='col-7'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`Company Email :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    {RequestSlice?.production_request?.company_email}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-5'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`Postcode :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    {
                                      RequestSlice?.production_request?.temp_address_definition
                                        ?.post_code
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row d-flex justify-content-start mt-2 mb-4'>
                          <div className='col-7'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`Website :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    {
                                      RequestSlice?.production_request?.temp_social_channels?.find(
                                        (x: any) => x.social_channel_type === 4
                                      )?.social_channel_url
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-5'>
                            <div className='row'>
                              <div
                                className='col-auto fs-5 fw-bolder d-flex justify-content-center align-items-center'
                                style={{ marginRight: '0px', paddingRight: '0px' }}
                              >{`City :`}</div>
                              <div className='col-auto fs-5 fw-normal d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                  <div
                                    className='col'
                                    style={{ marginRight: '0px', paddingRight: '0px' }}
                                  >
                                    {
                                      RequestSlice?.production_request?.temp_address_definition
                                        ?.city
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='row d-flex justify-content-center align-items-center'
                      style={{ width: '5%' }}
                    >
                      <div className='form-check form-check-solid'>
                        <input
                          className={`form-check-input ${formik?.touched?.['companyDetail'] &&
                            formik?.errors?.['companyDetail'] &&
                            Style.requiredCheckBox
                            }`}
                          type='checkbox'
                          id='companyDetail'
                          defaultChecked={formik.values.companyDetail}
                          checked={formik.values.companyDetail}
                          onChange={(event) =>
                            formik?.setValues({
                              ...formik?.values,
                              companyDetail: event.target.checked,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {RequestSlice?.production_request?.temp_officers.length > 0 ? (
                  <div className='row mt-5'>
                    <div className='col-12 border rounded d-flex justify-content-between'>
                      <div className='row d-flex mx-2' style={{ width: '95%' }}>
                        <div className='col-1 mx-2 d-flex flex-column justify-content-center align-items-center'>
                          <div className='row fs-5 fw-bolder d-flex justify-content-center text-center'>{`Registered Directors`}</div>
                          <div className='row d-flex justify-content-center'>
                            <FontAwesomeIcon
                              icon={faUsers}
                              size='2xl'
                              className='mt-2'
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                        <div className='col-auto d-flex flex-column justify-content-center align-items-center'>
                          <div
                            className='row opacity-50'
                            style={{
                              borderLeft: '2px solid #CCCCCC',
                              height: '80%',
                            }}
                          ></div>
                        </div>
                        <div className='col'>
                          <table className='table mt-2' style={{ width: '95%' }}>
                            <thead>
                              <tr>
                                <th
                                  scope={`col`}
                                  className={`${Style.merchantsRegistredDirectorsTableHeaderFont}`}
                                >
                                  Name
                                </th>
                                <th
                                  scope='col'
                                  className={`${Style.merchantsRegistredDirectorsTableHeaderFont}`}
                                >
                                  Nationality
                                </th>
                                <th
                                  scope='col'
                                  className={`${Style.merchantsRegistredDirectorsTableHeaderFont}`}
                                >
                                  Occupation
                                </th>
                                <th
                                  scope='col'
                                  className={`${Style.merchantsRegistredDirectorsTableHeaderFont}`}
                                >
                                  E-Mail
                                </th>
                                <th
                                  scope='col'
                                  className={`${Style.merchantsRegistredDirectorsTableHeaderFont}`}
                                >
                                  Mobile Number
                                </th>
                                <th
                                  scope='col'
                                  className={`${Style.merchantsRegistredDirectorsTableHeaderFont}`}
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {RequestSlice?.production_request?.temp_officers?.map(
                                (item: any, index: any) => (
                                  <>
                                    <tr>
                                      <td>{item?.name}</td>
                                      <td>{item?.nationality}</td>
                                      <td>{item?.occupation}</td>
                                      <td>{item?.email}</td>
                                      <td>{`+${item?.phone_number}`}</td>
                                      <td className='d-flex justify-content-center text-center'>
                                        <FontAwesomeIcon
                                          icon={faEnvelope}
                                          size='lg'
                                          style={{ cursor: 'pointer' }}
                                          onClick={() =>
                                            registeredUserEmailFunction(
                                              item?.email,
                                              item?.name,
                                              RequestSlice?.production_request?.company_name
                                            )
                                          }
                                        ></FontAwesomeIcon>
                                      </td>
                                    </tr>
                                  </>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        className='row d-flex justify-content-center align-items-center'
                        style={{ width: '5%' }}
                      >
                        <div className='form-check form-check-solid'>
                          <input
                            className={`form-check-input ${formik?.touched?.['registeredDirectors'] &&
                              formik?.errors?.['registeredDirectors'] &&
                              Style.requiredCheckBox
                              }`}
                            type='checkbox'
                            id='registeredDirectors'
                            defaultChecked={formik.values.registeredDirectors}
                            checked={formik.values.registeredDirectors}
                            onChange={(event) =>
                              formik?.setValues({
                                ...formik?.values,
                                registeredDirectors: event.target.checked,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {RequestSlice?.production_request?.temp_social_channels.filter(
                  (x: any) => x.social_channel_type !== 4
                ).length > 0 ? (
                  <div className='row mt-5' style={{ height: '8rem' }}>
                    <div className='col-12 border rounded d-flex justify-content-between'>
                      <div className='row d-flex mx-2' style={{ width: '95%' }}>
                        <div className='col-1 mx-2 d-flex flex-column justify-content-center align-items-center'>
                          <div className='row fs-5 fw-bolder d-flex justify-content-center text-center'>{`Social Verification`}</div>
                          <div className='row d-flex justify-content-center'>
                            <FontAwesomeIcon
                              icon={faBadgeCheck}
                              size='2xl'
                              className='mt-2'
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                        <div className='col-auto d-flex flex-column justify-content-center align-items-center'>
                          <div
                            className='row opacity-50'
                            style={{
                              borderLeft: '2px solid #CCCCCC',
                              height: '80%',
                            }}
                          ></div>
                        </div>
                        <div className='col-10 align-self-center'>
                          <div className='row d-flex justify-content-start'>
                            {RequestSlice?.production_request?.temp_social_channels?.find(
                              (x: any) => x.social_channel_type === 0
                            ) !== undefined ? (
                              <div className='col-3'>
                                <div className='row d-flex justify-content-start align-items-center'>
                                  <div className='col-auto d-flex justify-content-center align-items-center'>
                                    <FontAwesomeIcon icon={faFacebook} size='3x'></FontAwesomeIcon>
                                  </div>
                                  <div
                                    className={`col-auto mx-1 text-decoration-underline ${Style.pendingSocialMediaFont}`}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <div className='row'>
                                      {RequestSlice?.production_request?.temp_social_channels?.find(
                                        (x: any) => x.social_channel_type === 0
                                      )?.username !== undefined ? (
                                        <>
                                          <div
                                            className={`col-auto ${Style.pendingSocialMediaVerfiedFont}`}
                                            onClick={() =>
                                              redirectSocialMedia(
                                                RequestSlice?.production_request?.temp_social_channels
                                                  ?.find((x: any) => x.social_channel_type === 0)
                                                  ?.username?.split(',')[1],
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 0
                                                )?.social_channel_type
                                              )
                                            }
                                          >
                                            {`/${RequestSlice?.production_request?.temp_social_channels
                                              ?.find((x: any) => x.social_channel_type === 0)
                                              ?.username?.split(',')[0]
                                              }`}
                                          </div>
                                          {RequestSlice?.production_request?.temp_social_channels?.find(
                                            (x: any) => x.social_channel_type === 0
                                          )?.is_verified ? (
                                            <div
                                              className='col-1'
                                              style={{ marginLeft: '2px', paddingLeft: '2px' }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faBadgeCheck}
                                              ></FontAwesomeIcon>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}
                                    </div>
                                    {
                                      RequestSlice?.production_request?.temp_social_channels?.find(
                                        (x: any) => x.social_channel_type === 0
                                      )?.social_channel_url !== undefined ?
                                        <div className={`row`}>
                                          <div className={`col ${Style.treeDotsforSocialMediaUrl}`}
                                            onClick={() =>
                                              redirectSocialMedia(
                                                RequestSlice?.production_request?.temp_social_channels
                                                  ?.find((x: any) => x.social_channel_type === 0)
                                                  ?.social_channel_url,
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 0
                                                )?.social_channel_type
                                              )
                                            }
                                          >
                                            {`/${RequestSlice?.production_request?.temp_social_channels?.find(
                                              (x: any) => x.social_channel_type === 0
                                            )?.social_channel_url
                                              }`}
                                          </div>
                                        </div>
                                        : ''
                                    }

                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {RequestSlice?.production_request?.temp_social_channels?.find(
                              (x: any) => x.social_channel_type === 1
                            ) !== undefined ? (
                              <div className='col-3'>
                                <div className='row d-flex justify-content-start align-items-center'>
                                  <div className='col-4 d-flex justify-content-center align-items-center'>
                                    <FontAwesomeIcon icon={faInstagram} size='3x'></FontAwesomeIcon>
                                  </div>
                                  <div
                                    className={`col-auto mx-1 text-decoration-underline ${Style.pendingSocialMediaFont}`}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <div className='row'>
                                      {RequestSlice?.production_request?.temp_social_channels?.find(
                                        (x: any) => x.social_channel_type === 1
                                      )?.username !== undefined ? (
                                        <>
                                          <div
                                            className={`col-auto ${Style.pendingSocialMediaVerfiedFont}`}
                                            onClick={() =>
                                              redirectSocialMedia(
                                                RequestSlice?.production_request?.temp_social_channels
                                                  ?.find((x: any) => x.social_channel_type === 1)
                                                  ?.username?.split(',')[1] ??
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 1
                                                )?.username,
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 1
                                                )?.social_channel_type
                                              )
                                            }
                                          >
                                            {`/${RequestSlice?.production_request?.temp_social_channels?.find(
                                              (x: any) => x.social_channel_type === 1
                                            )?.username
                                              }`}
                                          </div>
                                          {RequestSlice?.production_request?.temp_social_channels?.find(
                                            (x: any) => x.social_channel_type === 1
                                          )?.is_verified ? (
                                            <div
                                              className='col-1'
                                              style={{ marginLeft: '2px', paddingLeft: '2px' }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faBadgeCheck}
                                              ></FontAwesomeIcon>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}
                                    </div>
                                    {
                                      RequestSlice?.production_request?.temp_social_channels?.find(
                                        (x: any) => x.social_channel_type === 1
                                      )?.social_channel_url !== undefined ?
                                        <div className={`row`}>
                                          <div className={`col ${Style.treeDotsforSocialMediaUrl}`}
                                            onClick={() =>
                                              redirectSocialMedia(
                                                RequestSlice?.production_request?.temp_social_channels
                                                  ?.find((x: any) => x.social_channel_type === 1)
                                                  ?.username?.split(',')[1] ??
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 1
                                                )?.social_channel_url,
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 1
                                                )?.social_channel_type
                                              )
                                            }
                                          >
                                            {`/${RequestSlice?.production_request?.temp_social_channels?.find(
                                              (x: any) => x.social_channel_type === 1
                                            )?.social_channel_url
                                              }`}
                                          </div>
                                        </div>
                                        : ''
                                    }

                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {RequestSlice?.production_request?.temp_social_channels?.find(
                              (x: any) => x.social_channel_type === 3
                            ) !== undefined ? (
                              <div className='col-3'>
                                <div className='row d-flex justify-content-start align-items-center'>
                                  <div className='col-4 d-flex justify-content-center align-items-center'>
                                    <FontAwesomeIcon icon={faLinkedin} size='3x'></FontAwesomeIcon>
                                  </div>
                                  <div
                                    className={`col-auto mx-1 text-decoration-underline ${Style.pendingSocialMediaFont}`}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <div className='row'>
                                      {RequestSlice?.production_request?.temp_social_channels?.find(
                                        (x: any) => x.social_channel_type === 3
                                      )?.username !== undefined ? (
                                        <>
                                          <div
                                            className={`col-auto ${Style.pendingSocialMediaVerfiedFont}`}
                                            onClick={() =>
                                              redirectSocialMedia(
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 3
                                                )?.username,
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 3
                                                )?.social_channel_type
                                              )
                                            }
                                          >
                                            {`/${RequestSlice?.production_request?.temp_social_channels?.find(
                                              (x: any) => x.social_channel_type === 3
                                            )?.username
                                              }`}
                                          </div>
                                          {RequestSlice?.production_request?.temp_social_channels?.find(
                                            (x: any) => x.social_channel_type === 3
                                          )?.is_verified ? (
                                            <div
                                              className='col-1'
                                              style={{ marginLeft: '2px', paddingLeft: '2px' }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faBadgeCheck}
                                              ></FontAwesomeIcon>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}
                                    </div>
                                    {
                                      RequestSlice?.production_request?.temp_social_channels?.find(
                                        (x: any) => x.social_channel_type === 3
                                      )?.social_channel_url !== undefined ?
                                        <div className={`row`}>
                                          <div className={`col ${Style.treeDotsforSocialMediaUrl}`}
                                            onClick={() =>
                                              redirectSocialMedia(
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 3
                                                )?.social_channel_url,
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 3
                                                )?.social_channel_type
                                              )
                                            }
                                          >
                                            {`/${RequestSlice?.production_request?.temp_social_channels?.find(
                                              (x: any) => x.social_channel_type === 3
                                            )?.social_channel_url
                                              }`}
                                          </div>
                                        </div>
                                        : ''
                                    }

                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {RequestSlice?.production_request?.temp_social_channels?.find(
                              (x: any) => x.social_channel_type === 2
                            ) !== undefined ? (
                              <div className='col-3'>
                                <div className='row d-flex justify-content-start align-items-center'>
                                  <div className='col-4 d-flex justify-content-center align-items-center'>
                                    <FontAwesomeIcon icon={faTwitter} size='3x'></FontAwesomeIcon>
                                  </div>
                                  <div
                                    className={`col-auto mx-1 text-decoration-underline ${Style.pendingSocialMediaFont}`}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <div className='row'>
                                      {RequestSlice?.production_request?.temp_social_channels?.find(
                                        (x: any) => x.social_channel_type === 2
                                      )?.username !== undefined ? (
                                        <>
                                          <div
                                            className={`col-auto ${Style.pendingSocialMediaVerfiedFont}`}
                                            onClick={() =>
                                              redirectSocialMedia(
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 2
                                                )?.username,
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 2
                                                )?.social_channel_type
                                              )
                                            }
                                          >
                                            {`/${RequestSlice?.production_request?.temp_social_channels?.find(
                                              (x: any) => x.social_channel_type === 2
                                            )?.username
                                              }`}
                                          </div>
                                          {RequestSlice?.production_request?.temp_social_channels?.find(
                                            (x: any) => x.social_channel_type === 2
                                          )?.is_verified ? (
                                            <div
                                              className='col-1'
                                              style={{ marginLeft: '2px', paddingLeft: '2px' }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faBadgeCheck}
                                              ></FontAwesomeIcon>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}
                                    </div>
                                    {
                                      RequestSlice?.production_request?.temp_social_channels?.find(
                                        (x: any) => x.social_channel_type === 2
                                      )?.social_channel_url !== undefined ?
                                        <div className={`row`}>
                                          <div className={`col ${Style.treeDotsforSocialMediaUrl}`}
                                            onClick={() =>
                                              redirectSocialMedia(
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 2
                                                )?.social_channel_url,
                                                RequestSlice?.production_request?.temp_social_channels?.find(
                                                  (x: any) => x.social_channel_type === 2
                                                )?.social_channel_type
                                              )
                                            }
                                          >
                                            {`/${RequestSlice?.production_request?.temp_social_channels?.find(
                                              (x: any) => x.social_channel_type === 2
                                            )?.social_channel_url
                                              }`}
                                          </div>
                                        </div>
                                        : ''
                                    }

                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div
                        className='row d-flex justify-content-center align-items-center'
                        style={{ width: '5%' }}
                      >
                        <div className='form-check form-check-solid'>
                          <input
                            className={`form-check-input ${formik?.touched?.['socialVerification'] &&
                              formik?.errors?.['socialVerification'] &&
                              Style.requiredCheckBox
                              }`}
                            type='checkbox'
                            id='socialVerification'
                            defaultChecked={formik.values.socialVerification}
                            checked={formik.values.socialVerification}
                            onChange={(event) =>
                              formik?.setValues({
                                ...formik?.values,
                                socialVerification: event.target.checked,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {RequestSlice?.production_request?.temp_production_request_files.length > 0 ? (
                  <div className='row mt-5' style={{ height: '10rem' }}>
                    <div className='col-12 border rounded d-flex justify-content-between'>
                      <div className='row d-flex mx-2' style={{ width: '95%' }}>
                        <div className='col-1 mx-2 d-flex flex-column justify-content-center align-items-center'>
                          <div className='row fs-5 fw-bolder d-flex justify-content-center text-center'>{`Document Verification`}</div>
                          <div className='row d-flex justify-content-center'>
                            <FontAwesomeIcon
                              icon={faFile}
                              size='2xl'
                              className='mt-2'
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                        <div className='col-auto d-flex flex-column justify-content-center align-items-center'>
                          <div
                            className='row opacity-50'
                            style={{
                              borderLeft: '2px solid #CCCCCC',
                              height: '80%',
                            }}
                          ></div>
                        </div>
                        <div className='col-10 align-self-center'>
                          <div className='row d-flex justify-content-start'>
                            {RequestSlice?.production_request?.temp_production_request_files?.find(
                              (x: any) => x.file_type === 0
                            ) !== undefined ? (
                              <div className='col-3'>
                                <div className='row fw-bolder d-flex justify-content-center text-center'>
                                  <div
                                    className={`col fw-bolder d-flex justify-content-center text-center ${Style.pendingSocialMediaFont}`}
                                  >
                                    {`Company Registration`}
                                  </div>
                                </div>
                                <div className='row mt-3 fw-bolder d-flex justify-content-center text-center'>
                                  <FontAwesomeIcon
                                    icon={faFile}
                                    size='2x'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      fileDownload(
                                        RequestSlice?.production_request?.temp_production_request_files?.find(
                                          (x: any) => x.file_type === 0
                                        ).file_url,
                                        'Company Registration'
                                      )
                                    }
                                  ></FontAwesomeIcon>
                                </div>
                                <div className='row mt-3 d-flex justify-content-center text-center'>
                                  <div className={`col-5 ${Style.pendingDocumentVerificationArea}`}>
                                    {
                                      RequestSlice?.production_request?.temp_production_request_files?.find(
                                        (x: any) => x.file_type === 0
                                      ).file_url
                                    }
                                  </div>
                                  <div
                                    className='col-1'
                                    style={{ marginLeft: '0px', paddingLeft: '0px' }}
                                  >{`.pdf`}</div>
                                </div>
                              </div>
                            ) : null}
                            {RequestSlice?.production_request?.temp_production_request_files?.find(
                              (x: any) => x.file_type === 1
                            ) !== undefined ? (
                              <div className='col-3'>
                                <div
                                  className={`col fw-bolder d-flex justify-content-center text-center ${Style.pendingSocialMediaFont}`}
                                >
                                  {`Bank Statement`}
                                </div>
                                <div className='row mt-3 fw-bolder d-flex justify-content-center text-center'>
                                  <FontAwesomeIcon
                                    icon={faFile}
                                    size='2x'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      fileDownload(
                                        RequestSlice?.production_request?.temp_production_request_files?.find(
                                          (x: any) => x.file_type === 1
                                        ).file_url,
                                        'Bank Statement'
                                      )
                                    }
                                  ></FontAwesomeIcon>
                                </div>
                                <div className='row mt-3 fs-6 d-flex justify-content-center text-center'>
                                  <div className={`col-5 ${Style.pendingDocumentVerificationArea}`}>
                                    {
                                      RequestSlice?.production_request?.temp_production_request_files?.find(
                                        (x: any) => x.file_type === 1
                                      ).file_url
                                    }
                                  </div>
                                  <div
                                    className='col-1'
                                    style={{ marginLeft: '0px', paddingLeft: '0px' }}
                                  >{`.pdf`}</div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div
                        className='row d-flex justify-content-center align-items-center'
                        style={{ width: '5%' }}
                      >
                        <div className='form-check form-check-solid'>
                          <input
                            className={`form-check-input ${formik?.touched?.['documentVerification'] &&
                              formik?.errors?.['documentVerification'] &&
                              Style.requiredCheckBox
                              }`}
                            type='checkbox'
                            id='documentVerification'
                            defaultChecked={formik.values.documentVerification}
                            checked={formik.values.documentVerification}
                            onChange={(event) =>
                              formik?.setValues({
                                ...formik?.values,
                                documentVerification: event.target.checked,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {RequestSlice?.production_request?.note !== '' &&
                  RequestSlice?.production_request?.note !== null &&
                  RequestSlice?.production_request?.note !== undefined ? (
                  <div className='row mt-5'>
                    <div className='col-12 border rounded d-flex justify-content-between'>
                      <div className='row d-flex mx-2' style={{ width: '95%' }}>
                        <div className='col-1 mx-2 d-flex flex-column justify-content-center align-items-center'>
                          <div className='row fw-bolder d-flex justify-content-center text-center'>{`Others`}</div>
                          <div className='row d-flex justify-content-center'>
                            <FontAwesomeIcon
                              icon={faEllipsisStroke}
                              size='2xl'
                              className='mt-2'
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                        <div className='col-auto d-flex flex-column justify-content-center align-items-center'>
                          <div
                            className='row opacity-50'
                            style={{
                              borderLeft: '2px solid #CCCCCC',
                              height: '80%',
                            }}
                          ></div>
                        </div>
                        <div className='col-10 align-self-center'>
                          <div className='row mt-3'>
                            <div className='col-2 fw-bolder d-flex justify-content-start text-start'>
                              {`Note :`}
                            </div>
                          </div>
                          <div className='row mt-3 mb-5 d-flex justify-content-center text-center'>
                            <div className='col-11 border rounded d-flex justify-content-start text-start'>
                              {RequestSlice?.production_request?.note}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='row d-flex justify-content-center align-items-center'
                        style={{ width: '5%' }}
                      >
                        <div className='form-check form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='others'
                            defaultChecked={formik.values.others}
                            checked={formik.values.others}
                            onChange={(event) =>
                              formik?.setValues({
                                ...formik?.values,
                                others: event.target.checked,
                              })
                            }
                          />
                          {formik?.touched?.['others'] && formik?.errors?.['others'] && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik?.errors?.['others']}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            </CustomModal>
          </>
        )

      case 'Delete':
        return (
          <>
            <CustomModal title='Are You Sure?'>
              <>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => {
                      dispatch(componentsSlice.actions.closeModal({}))
                    }}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    onClick={() => { }}
                  >
                    <span className='indicator-label'>Delete</span>
                  </button>
                </div>
                {/* end::Actions */}
              </>
            </CustomModal>
          </>
        )

      default:
        return null
    }
  }

  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={pendingMerchantList?.items}
          title={'Pending Merchant List'}
          pagination
          paginationServer
          paginationTotalRows={pendingMerchantList?.total_count}
          isBusy={dataTableisBusy}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          showTitle
          isExport
          onExport={() => {
            dispatch(
              exportProductionRequestWithStatus({
                page_index: 0,
                page_size: 0,
                filters: filterParams,
                sort_filters: sortData,
              })
            )
          }}
          showFilter={true}
          filterOptions={customFilter}
          searchButtonClickEvent={() => setfilterParams(filterQuery)}
          sortHandle={handleSort}
          clearButtonClickEvent={() => {
            setfilterParams([])
            setfilterQuery([])
          }}
        ></CustomDatatable>
      </KTCard>
      {modalIsOpen !== undefined ? findProcessScreen(processName) : null}
    </>
  )
}

const PendingMerchantWrapper: FC<Props> = ({ pendingMerchantList }) => (
  <QueryRequestProvider>
    <ListViewProvider>
      <PendingMerchantList pendingMerchantList={pendingMerchantList} />
    </ListViewProvider>
  </QueryRequestProvider>
)

export { PendingMerchantWrapper }
