import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FaqService from "../../../services/assetManagement/console/Faq.service";


interface InitialStateInterface {
    FaqListSlice: any
    FaqSlice: any[]
}

const initialState = {
    FaqListSlice: null,
    FaqSlice: [],
} as InitialStateInterface;

export const getFaq = createAsyncThunk(
    "getFaqList",
    async (req: any) => {
        try {
            return await FaqService.getFaqService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportFaq = createAsyncThunk(
    "exportFaqList",
    async (req: any) => {
        try {
            return await FaqService.exportFaqService(req).then(response => response);
        } catch (error) { }
    }
);


export const postFaq = createAsyncThunk(
    "postFaq",
    async (req: any, thunkAPI) => {
        try {
            return await FaqService.postFaqService(req).then(response => response);
        } catch (error) { }
    }
);

export const putFaq = createAsyncThunk(
    "putFaq",
    async (req: any, thunkAPI) => {
        try {
            return await FaqService.putFaqService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteFaqWithId = createAsyncThunk(
    "deleteFaq",
    async (req: any, thunkAPI) => {
        try {
            return await FaqService.deleteFaqService(req).then(response => response);
        } catch (error) { }
    }
);

export const getFaqWithId = createAsyncThunk(
    "getFaqWithId",
    async (req: any) => {
        try {
            return await FaqService.getFaqWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const FaqSlice = createSlice({
    name: "FaqSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFaq.fulfilled, (state, action) => {
            state.FaqListSlice = action.payload?.result ? action.payload.result?.fa_qs : null;
        });
        builder.addCase(getFaqWithId.fulfilled, (state, action) => {
            state.FaqSlice = action.payload?.result ? action.payload.result?.faq : [];
        });
    },
});

export default FaqSlice;
