import clsx from "clsx";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Styles from "../../../_metronic/assets/sass/pages/frpPurchaseRateList.module.scss"
interface Props {
    onChange: (e: string) => void;
    country?: string;
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    name?: string;
    formik?: any;
    formikName?: any;
}

const CustomPhoneTextbox = React.forwardRef<HTMLInputElement, Props>(
    (
        {
            onChange,
            country,
            value,
            disabled,
            placeholder,
            formik,
            formikName,
            name
        },
    ) => {
        return (
            <>
                <PhoneInput
                    placeholder={placeholder}
                    country={country}
                    value={value}
                    disabled={disabled}
                    onChange={(event) => {
                        onChange(event);
                        formik?.setValues({
                            ...formik?.values,
                            [formikName]: event
                        })
                    }}
                    inputClass={`${clsx('form-control form-control-solid mb-3 mb-lg-0')} ${Styles.textbox}`}
                />
                {formik?.touched?.[formikName] && formik?.errors?.[formikName] && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik?.errors?.[formikName]}</span>
                        </div>
                    </div>
                )}
            </>
        );
    }
);

CustomPhoneTextbox.defaultProps = {
    disabled: false,
};

export default CustomPhoneTextbox;
