/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import componentsSlice from '../../../slices/components/componentsSlice'
import { getDistributionRules, getIncentive } from '../../../slices/incentiveManagement/IncentiveSlice'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { IncentiveWrapper } from './Incentive'

const IncentivesBreadcrumbs: Array<PageLink> = [
    {
        title: `FRP Management`,
        path: '/Incentive/Main',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const IncentiveMain = () => {
    const dispatch: AppDispatch = useDispatch();

    const { incentive, distiributionRules } = useAppSelector(
        (state) => state.incentive
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getIncentive());
        dispatch(getDistributionRules());
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/Main'
                    element={
                        <>
                            <PageTitle breadcrumbs={IncentivesBreadcrumbs}>{`Incentives`}</PageTitle>
                            <IncentiveWrapper incentive={incentive} distiributionRules={distiributionRules} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default IncentiveMain
