/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import { showToast } from "../../../components/custom-toasts/custom-toasts"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTCard, KTSVG } from "../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { deleteAttributeFormatsWithId, exportAttributeFormat, getAttributeFormat, getAttributeFormatWithId, getExistAttributeFormat, postAttributeFormat, putAttributeFormat } from "../../../slices/credentialManagement/attributeFormat/AttributeFormatSlice"
import CustomSelect from "../../../components/customModal/CustomSelect"
import Style from "../../../../_metronic/assets/sass/components/custom-modal.module.scss"

interface Props {
    attributeFormatList: any
}

const AttributeFormatList: FC<Props> = ({ attributeFormatList }) => {
    const [totalRowCountVal, settotalRowCountVal] = useState(0)
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const { ExistAttributeFormatListSlice } = useAppSelector((state) => state.attributeFormat);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [selectedRowId, setSelectedRowId] = useState(undefined)
    const [attributeFormatDataTypeFiltred, setattributeFormatDataTypeFiltred] = useState<any>([])
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([])
    const [filterParams, setfilterParams] = useState<any>([])

    const formik = useFormik({
        initialValues: {
            id: '',
            type: [],
        },
        validationSchema: Yup.object().shape({
            type: Yup.array()
                .min(1, "Type is required")
                .nullable()
        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateWithId(values)
                }
                else {
                    createTermContiton(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    // This data needs to be pulled by the API
    const AttributeFormatsEnumData = [
        {
            value: 0,
            label: "Decimal"
        },
        {
            value: 1,
            label: "Boolean"
        },
        {
            value: 2,
            label: "Int"
        },
        {
            value: 3,
            label: "Selection"
        },
        {
            value: 4,
            label: "Date"
        },
        {
            value: 5,
            label: "Percentage"
        },
        {
            value: 6,
            label: "Merchant Label Pair"
        },
    ]

    const AttributeFormatsEnumDataForFilter =
        [
            {
                value: -1,
                label: "Select..."
            },
            ...AttributeFormatsEnumData
        ]

    useEffect(() => {
        setdataTableisBusy(false)
        settotalRowCountVal(attributeFormatList?.items?.total_count)

        let filredData: { value: number; label: string }[] = [];
        for (let format of AttributeFormatsEnumData) {
            if (ExistAttributeFormatListSlice?.map((x: any) => x).includes(format.value) === false) {
                filredData.push(format)
            }
        }
        setattributeFormatDataTypeFiltred(filredData)
    }, [attributeFormatList])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(undefined)
            formik.resetForm()
        }
    }, [modalIsOpen])

    useEffect(() => {
        dispatch(getExistAttributeFormat());
    }, []); 

    //#region DataTable Methods

    const columns = useMemo(
        () => [
            {
                name: 'Type',
                selector: (row: any) => <div>{AttributeFormatsEnumData.find(x => x.value === row?.type)?.label}</div>,
                width: "auto",
            },

            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                            <>
                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => {
                                        setprocessName("Insert")
                                        setSelectedRowId(row.id)
                                        dispatch(componentsSlice.actions.openModal({}))
                                        attributeFormatsWithId(row.id, "Insert")
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                </div>
                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => {
                                        setprocessName("Delete")
                                        setSelectedRowId(row.id)
                                        dispatch(componentsSlice.actions.openModal({}))
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                </div>
                            </>

                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const customFilter = [
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Type</label>
                <CustomSelect
                    onChange={
                        (e: any) =>
                            e.value !== -1 ?
                                setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Type"), { "name": "Type", "comparator": "Equal", "value": e.value }])
                                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Type")])
                    }
                    data={AttributeFormatsEnumDataForFilter}
                    isMulti={false}
                    selectedValue={AttributeFormatsEnumDataForFilter?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "Type")?.value) !== undefined ? AttributeFormatsEnumDataForFilter?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "Type")?.value) : -1}
                ></CustomSelect>
            </>
        },
    ]

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.name, "direction": sortDirection === "asc" ? 0 : 1 }])
    };
    useEffect(() => {
        dispatch(getAttributeFormat({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, filterParams])


    //#endregion DataTable Methods End

    function attributeFormatsWithId(rowId: any, process: string) {
        if (rowId !== undefined) {
            dispatch(getAttributeFormatWithId(rowId)).then((s: any) => {
                formik.setValues({
                    id: s?.payload?.result?.attribute_format?.id,
                    type: (AttributeFormatsEnumData.find(x => x.value === s?.payload?.result?.attribute_format?.type) as any),

                })
            })
        }
    }

    function updateWithId(request: any) {
        let req = {
            id: selectedRowId,
            type: request?.type?.value
        }
        dispatch(putAttributeFormat(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getAttributeFormat({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function deleteAttributeFormats() {
        if (selectedRowId !== undefined) {
            dispatch(deleteAttributeFormatsWithId(selectedRowId)).then((s: any) => {
                if (s?.payload?.is_success) {
                    dispatch(getAttributeFormat({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.success("Item successfully deleted")
                    dispatch(componentsSlice.actions.closeModal({}))
                }
                else {
                    dispatch(getAttributeFormat({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.error(s?.payload?.response_exception?.exception_message)
                    dispatch(componentsSlice.actions.closeModal({}))
                }
            })
        }
    }

    function createTermContiton(request: any) {
        let req = {
            type: request?.type?.value,
        }
        dispatch(postAttributeFormat(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getAttributeFormat({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
            }
        })
    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Update Attribute Format" : "Create Attribute Format"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} >
                                <div
                                    className={`d-flex flex-column scroll-y me-n7 pe-7 ${Style.insideDiv}`}
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Type</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={attributeFormatDataTypeFiltred}
                                            isMulti={false}
                                            selectedValue={formik?.values?.type}
                                            formik={formik}
                                            formikName={"type"}
                                        ></CustomSelect>
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteAttributeFormats()
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={attributeFormatList?.items}
                    title={"Attribute Format List"}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRowCountVal}
                    isBusy={dataTableisBusy}
                    showTitle
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    onExport={() => {
                        dispatch(
                            exportAttributeFormat({
                              page_index: 0,
                              page_size: 0,
                              filters: filterParams,
                              sort_filters: sortData,
                            })
                          );
                    }}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    showFilter={true}
                    filterOptions={customFilter}
                    searchButtonClickEvent={() => setfilterParams(filterQuery)}
                    sortHandle={handleSort}
                    clearButtonClickEvent={() => {
                        setfilterParams([])
                        setfilterQuery([])
                    }}
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const AttributeFormatWrapper: FC<Props> = ({ attributeFormatList }) => <QueryRequestProvider>
    <ListViewProvider>
        <AttributeFormatList attributeFormatList={attributeFormatList} />
    </ListViewProvider>
</QueryRequestProvider>

export { AttributeFormatWrapper }
