import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NotificationService from "../../../services/assetManagement/console/Notification.service";


interface InitialStateInterface {
    NotificationListSlice: any
}

const initialState = {
    NotificationListSlice: null,
} as InitialStateInterface;

export const getNotification = createAsyncThunk(
    "getNotificationList",
    async (req: any) => {
        try {
            return await NotificationService.getNotificationService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportNotification = createAsyncThunk(
    "exportNotificationList",
    async (req: any) => {
        try {
            return await NotificationService.exportNotificationService(req).then(response => response);
        } catch (error) { }
    }
);

export const postNotification = createAsyncThunk(
    "postNotification",
    async (req: any, thunkAPI) => {
        try {
            return await NotificationService.postNotificationService(req).then(response => response);
        } catch (error) { }
    }
);

export const putNotification = createAsyncThunk(
    "putNotification",
    async (req: any, thunkAPI) => {
        try {
            return await NotificationService.putNotificationService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteNotificationWithId = createAsyncThunk(
    "deleteNotification",
    async (req: any, thunkAPI) => {
        try {
            return await NotificationService.deleteNotificationService(req).then(response => response);
        } catch (error) { }
    }
);

export const getNotificationWithId = createAsyncThunk(
    "getNotificationWithId",
    async (req: any) => {
        try {
            return await NotificationService.getNotificationWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

export const getMerchants = createAsyncThunk(
    "getMerchants",
    async () => {
        try {
            return await NotificationService.getMerchantsService().then(response => response);
        } catch (error) { }
    }
);

export const postSendNotification = createAsyncThunk(
    "postSendNotification",
    async (req: any) => {
        try {
            return await NotificationService.postSendNotificationService(req).then(response => response);
        } catch (error) { }
    }
);

const NotificationSlice = createSlice({
    name: "NotificationSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNotification.fulfilled, (state, action) => {
            state.NotificationListSlice = action.payload?.result ? action.payload.result?.notifications : null;
        });
    },
});

export default NotificationSlice;
