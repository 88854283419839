/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment"
import { isNotEmpty, KTCard, KTSVG } from "../../../../../_metronic/helpers"
import { ListViewProvider } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC, useEffect, useState } from "react"
import CustomDatatable from "../../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../../store/store"
import { useDispatch } from "react-redux"
import CustomFileUpload from "../../../../components/customFileUpload/CustomImageUpload"
import componentsSlice from "../../../../slices/components/componentsSlice"
import { showToast } from "../../../../components/custom-toasts/custom-toasts"
import { deleteVideoWithId, exportVideo, getVideo, getVideoWithId, putVideo } from "../../../../slices/assetManagement/mobile/introVideos/IntroVideosSlice"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import CustomDate from "../../../../components/custom-date/custom-date"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/pro-solid-svg-icons"
interface Props {
  videoList: any[]
}
const IntroVideosList: FC<Props> = ({ videoList }) => {
  const totalRowCountVal = 0;
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const [resetDataTable, setresetDataTable] = useState(false)
  const { modalIsOpen } = useAppSelector((state) => state.components);
  const dispatch: AppDispatch = useDispatch();
  const [selectedRowId, setSelectedRowId] = useState(null)
  const [processName, setprocessName] = useState("Insert")
  const [validDate, setValidDate] = useState<Date | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [loading, setloading] = useState(false)
  const [tempVideoList, settempVideoList] = useState<any>([])

  const formik = useFormik({
    initialValues: {
      id: "",
      video: "",
      publishedDate: "",
    },
    validationSchema: Yup.object().shape({
      video: Yup.string()
        .required('Video is required'),
      publishedDate: Yup.string()
        .required('Publish Date is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        if (isNotEmpty(values.id)) {
          putFunc(values)
        }
        else {
          postFunc(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        actions.resetForm();
      }
    },
  })

  useEffect(() => {
    setdataTableisBusy(false)
    setresetDataTable(!resetDataTable)
  }, [videoList])

  useEffect(() => {
  }, [validDate])

  useEffect(() => {
    formik.setValues({
      id: formik.values.id,
      video: file !== null ? file?.name : "",
      publishedDate: formik.values.publishedDate
    })
  }, [file])


  const columns = [
    {
      name: 'Date Created',
      selector: (row: any) => <div>{moment(row.date_created).format("MM/DD/YYYY hh:mm a")}</div>,
    },
    {
      name: 'Url',
      selector: (row: any) => <div title={row.url}>{row.url}</div>,
    },
    {
      name: 'Published Date',
      selector: (row: any) => <div>{moment(row.published_date).format("MM/DD/YYYY hh:mm a")}</div>,
    },
    {
      name: 'Status',
      selector: (row: any) => findStatusFromPublishDate(row?.published_date, row?.date_created),
    },
    {
      name: 'Action',
      selector: (row: any) =>
        <>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setprocessName("Update")
                setSelectedRowId(row.id)
                getVideoDetailWithId(row.id)
                dispatch(componentsSlice.actions.openModal({}))
              }}
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </div>
            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setSelectedRowId(row.id)
                setprocessName("Delete")
                dispatch(componentsSlice.actions.openModal({}))
              }
              }
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </div>
          </div>
        </>,
      width: "100px",
    },
  ];

  useEffect(() => {
    //setdataTableisBusy(true) Temp closed until api ready
    //dispatch(getStorage());  Temp closed until api ready
  }, [activePage, pageSize])

  useEffect(() => {
    if (modalIsOpen === undefined) {
      setprocessName("Insert")
      formik.resetForm()
    }
  }, [modalIsOpen])

  const onChangePage = (page: any) => {
    setActivePage(page);
  };
  const onChangeRowsPerPage = (size: any) => {
    setPageSize(size);
  };
  // For DataTable Event End

  function deleteFunc() {
    dispatch(deleteVideoWithId(selectedRowId)).then((response) => {
      if (response?.payload?.is_success) {
        showToast.success("Deleted successfully")
        dispatch(getVideo({ page_index: 0, page_size: 10, filters: [], sort_filters: [] }));
      }
      else {
        showToast.error("Delete process failed")
        dispatch(getVideo({ page_index: 0, page_size: 10, filters: [], sort_filters: [] }));
      }
    })
  }
  
  function postFunc(values: any) {
    let req = {
      url: file?.name,
      container_name: "intro-videos",
      published_date: moment(values.publishedDate).toISOString(true),
      date_created: moment(values.publishedDate).toISOString(true)
    }
    let tempList : any[] = [];
    tempList.push(req)
    settempVideoList(tempList)
    setFile(null)
    showToast.success("Item Saved Successfully")
    dispatch(componentsSlice.actions.closeModal({}))
    
  }

  function putFunc(values: any) {
    setloading(true)
    let req = {
      id: values.id,
      file_stream: file,
      container_name: "intro-videos",
      published_date: moment(values.publishedDate).toISOString(true)
    }
    dispatch(putVideo(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(getVideo({ page_index: 0, page_size: 10, filters: [], sort_filters: [] }));
        setFile(null)
        showToast.success("Item Saved Successfully")
        setloading(false)
        dispatch(componentsSlice.actions.closeModal({}))
      }
      else {
        dispatch(getVideo({ page_index: 0, page_size: 10, filters: [], sort_filters: [] }));
        setFile(null)
        showToast.error("Item Saved Unsuccessfully")
        setloading(false)
        dispatch(componentsSlice.actions.closeModal({}))
      }
    })
  }

  function getVideoDetailWithId(rowId: any) {
    if (rowId !== undefined) {
      dispatch(getVideoWithId(rowId)).then((s: any) => {
        formik.setValues({
          id: s?.payload?.result?.video?.id,
          video: s?.payload?.result?.video?.url,
          publishedDate: s?.payload?.result?.video?.published_date,
        })
      })
    }
  }

  function findStatusFromPublishDate(date: string, date_created: string) {
    if (date < moment().format()) {
      let filteredList = videoList.filter((x: any) => x?.published_date < moment().endOf('day').format()).sort((a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime())
      if (filteredList[0].published_date === date && filteredList[0].date_created === date_created) {
        return (
          <span className='badge badge-light-success fs-5 fw-semibold'>{"Active"}</span>
        )
      }
      else {
        return (
          <span className='badge badge-light-danger fs-5 fw-semibold'>{"Archive"}</span>
        )
      }
    }
    else {
      return (
        <span className='badge badge-light-warning fs-5 fw-semibold'>{"Pending"}</span>
      )
    }
  }

  function downloadVideo(url: string) {
    let alink = document.createElement('a');
    alink.href = url;
    alink.download = 'url';
    alink.click();
  }

  function findProcessScreen(processName: string) {
    switch (processName) {
      case "Insert":
      case "Update":
        return <>
          <CustomModal title={processName === "Insert" ? "New Item" : "Update Item"}>
            <>
              <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                  style={{ height: "520px" }}
                >
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>File</label>
                    <CustomFileUpload
                      uploadFile={(file: FileList) => {
                        setFile(file[0])
                      }}
                      text={formik?.values?.video != null && formik?.values?.video !== "" ? "" : "Upload assets"}
                      hasFile={formik?.values?.video != null && formik?.values?.video !== ""}
                      imageString={undefined}
                      formik={formik}
                      formikName={"video"}
                      accept="video/mp4"
                    ></CustomFileUpload>
                    {
                      processName !== "Insert" && formik?.values?.video ?
                        <div className="row mt-2">
                          <div className="col-10"></div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-auto" style={{ padding: "0px" }}><FontAwesomeIcon icon={faDownload} size={"1x"} onClick={() => downloadVideo(formik?.values?.video)} style={{ cursor: "pointer" }}></FontAwesomeIcon></div>
                              <div className="col-auto" style={{ padding: "0px", marginLeft: "0.3rem", marginTop: "0.1rem" }}>
                                <div onClick={() => downloadVideo(formik?.values?.video)} style={{ color: "black", cursor: "pointer" }}>Download</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        : null
                    }

                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Publish Date</label>
                    <CustomDate
                      minDate={new Date()}
                      {...formik.getFieldProps('publishedDate')}
                      value={null}
                      selected={formik.values.publishedDate !== "" ? new Date(formik.values.publishedDate) : null}
                      placeHolder={"Select Valid Date"}
                      formik={formik}
                      formikName={"publishedDate"}
                      onChange={(event) => setValidDate(event)}
                    />
                  </div>
                </div>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                  >

                    {loading ? (
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    ) :
                      <span className='indicator-label'>Submit</span>
                    }
                  </button>
                </div>
                {/* end::Actions */}
              </form>
            </>
          </CustomModal>
        </>

      case "Delete":
        return <>
          <CustomModal title="Are You Sure?">
            <>
              {/* begin::Actions */}
              <div className='text-center pt-15'>
                <button
                  type='reset'
                  onClick={() => {
                    setprocessName("Insert")
                    dispatch(componentsSlice.actions.closeModal({}))
                  }

                  }
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Discard
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={() => {
                    deleteFunc()
                    dispatch(componentsSlice.actions.closeModal({}))
                    setprocessName("Insert")
                  }
                  }
                >
                  <span className='indicator-label'>Delete</span>
                </button>
              </div>
              {/* end::Actions */}
            </>
          </CustomModal>
        </>

      default: return null;
    }
  }

  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={tempVideoList}
          title={"Intro Video Management"}
          pagination
          paginationServer
          paginationTotalRows={totalRowCountVal}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          isBusy={dataTableisBusy}
          isNewItem
          isNewItemString={"New Item"}
          isExport
          onExport={() => {
            dispatch(exportVideo({ page_index: 0, page_size: 0, filters: [], sort_filters: [] }));
          }}
        ></CustomDatatable>
      </KTCard>
      {modalIsOpen !== undefined ?
        findProcessScreen(processName)
        : null
      }
    </>
  )
}

const IntroVideosListWrapper: FC<Props> = ({ videoList }) => <QueryRequestProvider>
  <ListViewProvider>
    <IntroVideosList videoList={videoList} />
  </ListViewProvider>
</QueryRequestProvider>

export { IntroVideosListWrapper }
