import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const success = (message: any, onCloseProps?: any) => {
    toast.success(message, {
        onClose: onCloseProps,
    });
}

const error = (message: any, onCloseProps?: any) => {
    toast.error(message, {
        onClose: onCloseProps,
    });
}

const warning = (message: any, onCloseProps?: any) => {
    toast.warning(message, {
        onClose: onCloseProps,
    });
}

const info = (message: any, onCloseProps?: any) => {
    toast.info(message, {
        onClose: onCloseProps,
    });
}

export const showToast = {
    success, error, warning, info
}