import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dataSourceIntegrationService from "../services/dataSourceIntegration/dataSourceIntegration.service";


interface InitialStateInterface {
    shoutbackList: any
    credentialList: any[]
    selectedShoutBack: any
}

const initialState = {
    shoutbackList: null,
    credentialList: [],
    selectedShoutBack: null,
} as InitialStateInterface;

export const getShoutback = createAsyncThunk(
    "getShoutback",
    async (req: any) => {
        try {
            return await dataSourceIntegrationService.getDataSourceIntegrationService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportShoutback = createAsyncThunk(
    "exportShoutback",
    async (req: any) => {
        try {
            return await dataSourceIntegrationService.exportDataSourceIntegrationService(req).then(response => response);
        } catch (error) { }
    }
);

export const postShoutback = createAsyncThunk(
    "getStorageDelete",
    async (req: any, thunkAPI) => {
        try {
            return await dataSourceIntegrationService.postDataSourceIntegrationService(req).then(response => response);
        } catch (error) { }
    }
);

export const putShoutback = createAsyncThunk(
    "getStorageDelete",
    async (req: any, thunkAPI) => {
        try {
            return await dataSourceIntegrationService.putDataSourceIntegrationService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteShoutbackWithId = createAsyncThunk(
    "getStorageDelete",
    async (req: any, thunkAPI) => {
        try {
            return await dataSourceIntegrationService.deleteDataSourceIntegrationService(req).then(response => response);
        } catch (error) { }
    }
);

export const getCredentials = createAsyncThunk(
    "getCredentials",
    async (req:any) => {
        try {
            return await dataSourceIntegrationService.getCredentialListService(req).then(response => response);
        } catch (error) { }
    }
);

export const getShoutbackWithId = createAsyncThunk(
    "getShoutbackWithId",
    async (req: any) => {
        try {
            return await dataSourceIntegrationService.getDataSourceIntegrationWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const dataSourceIntegrationSlice = createSlice({
    name: "dataSourceIntegrationSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getShoutback.fulfilled, (state, action) => {
            state.shoutbackList = action.payload?.result ? action.payload.result?.shoutbacks : null;
        });
        builder.addCase(getCredentials.fulfilled, (state, action) => {
            state.credentialList = action.payload?.result ? action.payload.result?.credential_list : [];
        });
        builder.addCase(getShoutbackWithId.fulfilled, (state, action) => {
            state.selectedShoutBack = action.payload?.result ? action.payload.result?.shoutback : [];
        });
    },
});

export default dataSourceIntegrationSlice;
