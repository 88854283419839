/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Style from "../../../_metronic/assets/sass/components/custom-image.module.scss";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { showToast } from "../custom-toasts/custom-toasts";

interface Props {
  text?: string;
  uploadFile: Function;
  className?: string;
  value?: FileList;
  pdfString?: any; 
  formik?: any;
  formikName?: any;
  accept?: string;
  hasFile?: boolean;
}

const CustomPDF: React.FC<Props> = ({ text, uploadFile, className, value, pdfString, formik, formikName, accept, hasFile }) => {
  const fileRef = React.useRef<HTMLInputElement>(null);

  return (
    <div className={className}>
      <a
        onClick={() => {
          if (null !== fileRef.current) {
            fileRef.current?.click();
          }
        }}
      >
        {pdfString ? (
          <div
            className={`container justify-content-center ${Style.imgcontainer}`}
          >
            {/* Adjust the styling as needed */}
            <img
              src={toAbsoluteUrl("/media/icons/pdf-icon.png")} 
              className={Style.pdfIcon}
              alt="pdf"
            />
          </div>
        ) : (
          <div className={`container ${Style.imgcontainer}`}>
            <div className={`container ${Style.containerinside}`}>
              {
                <img src={toAbsoluteUrl("/media/icons/duotune/files/fil022.svg")} style={{ width: '50px' }} />
              }
              <p className={Style.desc}>{text}</p>
            </div>
          </div>
        )}
      </a>

      <input
        ref={fileRef}
        onChange={(e) => {
          if (e.target.files && e.target.files!.length > 0) {
            let ext = e.target.files[0].type.split('/')[0];
            if (!accept?.includes(e.target.files[0].type) && !accept?.includes(ext + "/*")) {
              showToast.error("Unsupported files")
              return false;
            }
            if (e.target.files[0].size > 1000000000) {
              showToast.error("The size of the file must be less than 1 GB")
              return false;
            }
            uploadFile(e.target.files);
            formik?.setValues({
              ...formik?.values,
              [formikName]: e.target.files
            })
          }
        }}
        accept={accept}
        multiple={false}
        type="file"
        hidden
      />
      {formik?.touched?.[formikName] && formik?.errors?.[formikName] && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik?.errors?.[formikName]}</span>
          </div>
        </div>
      )}
    </div>
  );
};

CustomPDF.defaultProps = {
  text: "Upload PDF",
  accept: "application/pdf"
};

export default CustomPDF;
