/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import componentsSlice from '../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { SliderContentListWrapper } from './SliderContentList'

const SliderContentBreadcrumbs: Array<PageLink> = [
    {
        title: `Mobile`,
        path: '/SliderContent/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const SliderContent = () => {
    const dispatch: AppDispatch = useDispatch();

    const { SliderContentListSlice } = useAppSelector(
        (state) => state.sliderContent
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={SliderContentBreadcrumbs}>{`Slider Management`}</PageTitle>
                            <SliderContentListWrapper sliderContentList={SliderContentListSlice} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default SliderContent
