import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import ReferralManagementService from '../../services/referralManagement/ReferralManagement.service'

interface InitialStateInterface {
  ReferralListSlice: any
  ReferralSlice: any[]
  ReferralDetailSlice: any
  ReferralUserSlice: any[]
  ReferralListOnlyIdName: any
}

const initialState = {
  ReferralListSlice: null,
  ReferralSlice: [],
  ReferralDetailSlice: null,
  ReferralUserSlice: [],
  ReferralListOnlyIdName: [],
} as InitialStateInterface

export const getReferral = createAsyncThunk('getReferralList', async (req: any, thunkAPI) => {
  try {
    return await ReferralManagementService.getReferralManagementWithUserTypeService(req).then(
      (response) => response
    )
  } catch (error) {}
})

export const exportReferral = createAsyncThunk('exportReferralList', async (req: any, thunkAPI) => {
  try {
    return await ReferralManagementService.exportReferralManagementWithUserTypeService(req).then(
      (response) => response
    )
  } catch (error) {}
})


export const postReferral = createAsyncThunk('postReferral', async (req: any, thunkAPI) => {
  try {
    return await ReferralManagementService.postReferralManagementService(req).then(
      (response) => response
    )
  } catch (error) {}
})

export const putReferral = createAsyncThunk('putReferral', async (req: any, thunkAPI) => {
  try {
    return await ReferralManagementService.putReferralManagementService(req).then(
      (response) => response
    )
  } catch (error) {}
})

export const getReferralWithId = createAsyncThunk('getReferralWithId', async (req: any) => {
  try {
    return await ReferralManagementService.getReferralManagementWithIdService(req).then(
      (response) => response
    )
  } catch (error) {}
})

export const getReferralDetailWithId = createAsyncThunk(
  'getReferralDetailWithId',
  async (req: any) => {
    try {
      return await ReferralManagementService.getReferralManagementDetailWithIdService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

export const getReferralUsersWithId = createAsyncThunk(
  'getReferralUsersWithId',
  async (req: any) => {
    try {
      return await ReferralManagementService.getReferralUsersWithIdService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

export const postReferralRemittance = createAsyncThunk(
  'postReferralRemittance',
  async (req: any, thunkAPI) => {
    try {
      return await ReferralManagementService.postReferralRemittanceService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

export const getAllReferralUsersWithUserTypeService = createAsyncThunk(
  'getAllReferralUsersWithUserTypeService',
  async (req: any) => {
    try {
      return await ReferralManagementService.getAllReferralUsersWithUserTypeService(req).then(
        (response) => response
      )
    } catch (error) {}
  }
)

const ReferralSlice = createSlice({
  name: 'ReferralSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReferral.fulfilled, (state, action) => {
      state.ReferralListSlice = action.payload?.result ? action.payload.result?.partner_list : null
    })
    builder.addCase(getReferralWithId.fulfilled, (state, action) => {
      state.ReferralSlice = action.payload?.result ? action.payload.result?.referral : []
    })
    builder.addCase(getReferralDetailWithId.fulfilled, (state, action) => {
      state.ReferralDetailSlice = action.payload?.result ? action.payload.result : []
    })
    builder.addCase(getReferralUsersWithId.fulfilled, (state, action) => {
      state.ReferralUserSlice = action.payload?.result
        ? action.payload.result?.users.map((x: any) => ({value: x?.user_id, label: x?.user_name}))
        : []
    })
    builder.addCase(getAllReferralUsersWithUserTypeService.fulfilled, (state, action) => {
      const referral_list = action.payload?.result
        ? action.payload.result?.users?.map((x: any) => ({value: x?.user_id, label: x?.user_name}))
        : []
      referral_list.unshift({value: '-1', label: 'Select...'})
      state.ReferralListOnlyIdName = referral_list
    })
  },
})

export default ReferralSlice
