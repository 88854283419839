/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../../slices/components/componentsSlice"
import { showToast } from "../../../../components/custom-toasts/custom-toasts"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTCard, KTSVG } from "../../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import CustomTextbox from "../../../../components/customTextbox/CustomTextbox"
import { deleteContent, exportContents, getContentWithId, getContents, getExistContentFormat, postContent, putContent } from "../../../../slices/assetManagement/sdk/content/contentSlice"
import Styles from "../../../../../_metronic/assets/sass/pages/videoList.module.scss"
import { uploadPicture } from "../../../../utils/upload-image-file"
import CustomFileUpload from "../../../../components/customFileUpload/CustomImageUpload"
import CustomSelect from "../../../../components/customModal/CustomSelect"

interface Props {
    ContentListSlice: any,
}

export const ContentEnumData = [
    {
        value: 0,
        label: "Credential"
    },
    {
        value: 1,
        label: "LinkedCard"
    },
    {
        value: 2,
        label: "Settings"
    },
]

const ContentEnumDataForFilter = [
    {
        value: -1,
        label: "Select..."
    },
    ...ContentEnumData
]

const ContentList: FC<Props> = ({ ContentListSlice }) => {
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [selectedRowId, setSelectedRowId] = useState(undefined)
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([])
    const [filterParams, setfilterParams] = useState<any>([])
    const [contentIcon, setContentIcon] = useState("")
    const { urlAfterImageUpload } = useAppSelector((state) => state.utils);
    const { ExistContentSlice } = useAppSelector((state) => state.content);
    const [contentDataFiltered, setcontentDataFiltered] = useState<any>([])
    const [calcuteIsBusy, setcalcuteIsBusy] = useState(true)


    const formik = useFormik({
        initialValues: {
            id: '',
            pageType: [],
            contentIconFormik: '',
            title: '',
            description: ''
        },
        validationSchema: Yup.object().shape({
            pageType: Yup.array()
                .min(1, "Type is required")
                .nullable(),
            contentIconFormik: Yup.string()
                .required('Icon is required'),
            title: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(100, 'Maximum 100 symbols')
                .required('Title is required'),
            description: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(300, 'Maximum 300 symbols')
                .required('Description is required'),
        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateWithId(values)
                }
                else {
                    createWithRequest(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    useEffect(() => {
        setcalcuteIsBusy(true)
        dispatch(getExistContentFormat());
    }, []);

    useEffect(() => {
        setcalcuteIsBusy(true)
        let filteredData: { value: number; label: string }[] = [];
        for (let format of ContentEnumData) {
            if (ExistContentSlice?.map((x: any) => x).includes(format.value) === false) {
                filteredData.push(format)
            }
        }
        setcontentDataFiltered(filteredData)
        setcalcuteIsBusy(false)
    }, [ExistContentSlice,ContentListSlice])

    useEffect(() => {
        setContentIcon("")
        if (urlAfterImageUpload !== undefined && urlAfterImageUpload !== "") {
            setContentIcon(urlAfterImageUpload)
        }
    }, [urlAfterImageUpload])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(undefined)
            setContentIcon("")
            formik.resetForm()
        }
    }, [modalIsOpen])

    //#region DataTable Methods

    const columns = useMemo(
        () => [
            {
                name: 'Page Type',
                selector: (row: any) => <div>{ContentEnumData.find(x => x.value === row?.page_type)?.label}</div>,
                // selector: (row: any) => <div>{row?.page_type}</div>,
                width: "8vw",
            },
            {
                name: 'Icon',
                selector: (row: any) => <>
                    <div className={Styles.imageRow}>
                        <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                                <img src={row.icon} alt='' />
                            </div>
                        </div>
                    </div>
                </>,
                width: "7vw",
            },
            {
                name: 'Title',
                selector: (row: any) => <div>{row?.title}</div>,
                maxWidth: "10vw"
            },
            {
                name: 'Description',
                selector: (row: any) => <div>{row?.description}</div>,
            },
            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                            {
                                <>
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setprocessName("Insert")
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                            ContentWithId(row.id)
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                    </div>
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setprocessName("Delete")
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                    </div>
                                </>
                            }

                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const customFilter = [
        {
            component: (
                <>
                    <label className='fw-bold fs-6 mb-2'>Page Type</label>
                    <CustomSelect
                        onChange={(e: any) =>
                            e.value !== -1
                                ? setfilterQuery([
                                    ...filterQuery.filter((x: any) => x.name !== 'PageType'),
                                    { name: 'PageType', comparator: 'Equals', value: e.value },
                                ])
                                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== 'PageType')])
                        }
                        data={ContentEnumDataForFilter}
                        isMulti={false}
                        selectedValue={
                            ContentEnumData?.find(
                                (x: any) => x.value === filterQuery.find((x: any) => x.name === 'PageType')?.value
                            ) !== undefined
                                ? ContentEnumData?.find(
                                    (x: any) => x.value === filterQuery.find((x: any) => x.name === 'PageType')?.value
                                )
                                : -1
                        }
                    ></CustomSelect>
                </>
            ),
        },
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Title</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Title"), { "name": "Title", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Title")])
                    }
                    placeHolder={"Type a Title"}
                    name='Title'
                    value={filterQuery.find((x: any) => x.name === "Title") !== undefined ? filterQuery.find((x: any) => x.name === "Title")?.value : ""}
                ></CustomTextbox>
            </>
        },
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Description</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Description"), { "name": "Description", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Description")])
                    }
                    placeHolder={"Type a Description"}
                    name='Description'
                    value={filterQuery.find((x: any) => x.name === "Description") !== undefined ? filterQuery.find((x: any) => x.name === "Description")?.value : ""}
                ></CustomTextbox>
            </>
        },
    ]

    function postPicture(file: File) {
        dispatch(uploadPicture(file)).then((s: any) => {
        })
    }

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };

    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.name, "direction": sortDirection === "asc" ? 0 : 1 }])
    };

    useEffect(() => {
        dispatch(getContents({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, filterParams])


    //#endregion DataTable Methods End

    function ContentWithId(rowId: any) {
        if (rowId !== undefined) {
            dispatch(getContentWithId(rowId)).then((s: any) => {
                formik.setValues({
                    id: s?.payload?.result?.sdk_content?.id,
                    title: s?.payload?.result?.sdk_content?.title,
                    contentIconFormik: s?.payload?.result?.sdk_content?.icon,
                    description: s?.payload?.result?.sdk_content?.description,
                    pageType: (ContentEnumData.find(c => c.value === s?.payload?.result?.sdk_content?.page_type) as any),
                })
                setContentIcon(s?.payload?.result?.sdk_content?.icon)

            })
        }
    }

    function updateWithId(request: any) {
        let req = {
            id: selectedRowId,
            title: request.title,
            description: request.description,
            page_type: request.pageType.value,
            icon: contentIcon,
        }
        dispatch(putContent(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getContents({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
                dispatch(getExistContentFormat());
                showToast.success("Item Saved Successfully")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function deleteWithId() {
        if (selectedRowId !== undefined) {
            dispatch(deleteContent(selectedRowId)).then((s: any) => {
                if (s?.payload?.is_success) {
                    dispatch(getContents({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
                    dispatch(getExistContentFormat());
                    showToast.success("Item successfully deleted")
                    dispatch(componentsSlice.actions.closeModal({}))
                }
                else {
                    dispatch(getContents({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
                    dispatch(getExistContentFormat());
                    showToast.error(s?.payload?.response_exception?.exception_message)
                    dispatch(componentsSlice.actions.closeModal({}))
                }
            })
        }
    }

    function createWithRequest(request: any) {
        let req = {
            title: request.title,
            description: request.description,
            page_type: request.pageType.value,
            icon: contentIcon,
        }
        dispatch(postContent(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getContents({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
                dispatch(getExistContentFormat());
                showToast.success("Item Saved Successfully")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Update Content" : "Create Content"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >  <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Type</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={contentDataFiltered}
                                            isMulti={false}
                                            selectedValue={formik?.values?.pageType}
                                            formik={formik}
                                            formikName={'pageType'}
                                        ></CustomSelect>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='fw-bold fs-6 mb-2'>Icon</label>
                                        <CustomFileUpload
                                            uploadFile={(file: FileList) => {
                                                postPicture(file?.[0]);
                                            }}
                                            imageString={contentIcon}
                                            formik={formik}
                                            formikName={"contentIconFormik"}
                                        ></CustomFileUpload>
                                    </div>
                                    <div className='fv-row mb-7'>

                                        <label className='required fw-bold fs-6 mb-2'>Title</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('title')}
                                            onChange={() => null}
                                            placeHolder={"Type a Title"}
                                            name='title'
                                            value={formik.values.title}
                                            formik={formik}
                                            formikName={"title"}
                                            type='text'
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Description</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('description')}
                                            onChange={() => null}
                                            placeHolder={"Type a Description"}
                                            name='description'
                                            value={formik.values.description}
                                            formik={formik}
                                            formikName={"description"}
                                            type='text'
                                        ></CustomTextbox>
                                    </div>

                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteWithId()
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                {
                    !calcuteIsBusy ?
                        <CustomDatatable
                            columns={columns}
                            data={ContentListSlice ? ContentListSlice : []}
                            title={"Content List"}
                            pagination
                            paginationServer
                            paginationTotalRows={ContentListSlice?.length}
                            isBusy={dataTableisBusy}
                            isNewItem
                            isNewItemString={"New Item"}
                            isExport
                            onExport={() => {
                                dispatch(exportContents({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
                            }}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            showTitle
                            showFilter={true}
                            filterOptions={customFilter}
                            searchButtonClickEvent={() => setfilterParams(filterQuery)}
                            sortHandle={handleSort}
                            clearButtonClickEvent={() => {
                                setfilterParams([])
                                setfilterQuery([])
                            }}

                        ></CustomDatatable>
                        : null
                }

            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const ContentWrapper: FC<Props> = ({ ContentListSlice }) => <QueryRequestProvider>
    <ListViewProvider>
        <ContentList ContentListSlice={ContentListSlice} />
    </ListViewProvider>
</QueryRequestProvider>

export { ContentWrapper }
