import { KTSVG } from '../../../_metronic/helpers'
import componentsSlice from '../../slices/components/componentsSlice';
import { AppDispatch, useAppDispatch } from '../../store/store';

interface CustomProps {
    title?: string
}

const CustomModalHeader : React.FC<CustomProps> = ({
    title
}) => {
  
    const dispatch: AppDispatch = useAppDispatch();
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{title}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() =>  dispatch(componentsSlice.actions.closeModal({}))}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {CustomModalHeader}
