
import { callApi, HttpMethods } from "../../../utils/api-util";
import { ResponseType } from "../../../utils/enums/ResponseType.enum";

const getOutcomesService = async (req: any) => {
    let _options = {
        controller: "/outcome/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}

const getExistsOutcomesService = async () => {
    let _options = {
        controller: "/outcome/get-exists",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}

const exportOutcomesService = async (req: any) => {
    let _options = {
        controller: "/outcome/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File

    };
    const result = await callApi(_options);
    return result;
}


const postOutcomesService = async (req: any) => {
    let _options = {
        controller: "/outcome",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putOutcomesService = async (req: any) => {
    let _options = {
        controller: "/outcome",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteOutcomesService = async (req: any) => {
    let _options = {
        controller: "/outcome",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getOutcomesWithIdService = async (req:any) => {
    let _options = {
        controller: "/outcome/get-outcome-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const OutcomesService = {
    getOutcomesService,
    exportOutcomesService,
    postOutcomesService,
    putOutcomesService,
    deleteOutcomesService,
    getOutcomesWithIdService,
    getExistsOutcomesService
};

export default OutcomesService;
