import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TenderService from "../../../../services/assetManagement/sdk/tender/Tender.service";


interface InitialStateInterface {
    TenderListSlice: any,
    ExistTenderListSlice: any
}

const initialState = {
    TenderListSlice: null,
    ExistTenderListSlice: null
} as InitialStateInterface;

export const getTender = createAsyncThunk(
    "getTenderList",
    async (req: any) => {
        try {
            return await TenderService.getTenderService(req).then(response => response);
        } catch (error) { }
    }
);

export const getExistTender = createAsyncThunk(
    "getExistTender",
    async () => {
        try {
            return await TenderService.getExistTender().then(response => response);
        } catch (error) { }
    }
);

export const exportTender = createAsyncThunk(
    "exportTenderList",
    async (req: any) => {
        try {
            return await TenderService.exportTenderService(req).then(response => response);
        } catch (error) { }
    }
);

export const postTender = createAsyncThunk(
    "postTender",
    async (req: any, thunkAPI) => {
        try {
            return await TenderService.postTenderService(req).then(response => response);
        } catch (error) { }
    }
);

export const putTender = createAsyncThunk(
    "putTender",
    async (req: any, thunkAPI) => {
        try {
            return await TenderService.putTenderService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteTenderWithId = createAsyncThunk(
    "deleteTender",
    async (req: any, thunkAPI) => {
        try {
            return await TenderService.deleteTenderService(req).then(response => response);
        } catch (error) { }
    }
);

export const getTenderWithId = createAsyncThunk(
    "getTenderWithId",
    async (req: any) => {
        try {
            return await TenderService.getTenderWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const TenderSlice = createSlice({
    name: "TenderSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTender.fulfilled, (state, action) => {
            state.TenderListSlice = action.payload?.result ? action.payload.result?.sdk_tenders : null;
        });
        builder.addCase(getExistTender.fulfilled, (state, action) => {
            state.ExistTenderListSlice = action.payload?.result ? action.payload.result?.sdk_tenders : null;
        });
    },
});

export default TenderSlice;
