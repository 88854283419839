import { callApi, HttpMethods } from "../../utils/api-util";
import { ResponseType } from "../../utils/enums/ResponseType.enum";

const getTransactionsService = async (req: any) => {
    let _options = {
        controller: "/frp/transactions",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const exportTransactionsService = async (req: any) => {
    let _options = {
        controller: "/frp/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    return await callApi(_options);
};

const getUsersService = async () => {
    let _options = {
        controller: "/frp/users",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const getFpBalanceService = async () => {
    let _options = {
        controller: "/frp/get-fp-balance",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const addFpBalanceService = async (req: any) => {
    let _options = {
        controller: "/frp/post-fp-balance",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};


const TransactionService = {
    getTransactionsService,
    exportTransactionsService,
    getUsersService,
    getFpBalanceService,
    addFpBalanceService
};

export default TransactionService;
