import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import OutcomesService from "../../../services/campaignManagament/outcomes/Outcomes.service";


interface InitialStateInterface {
    OutcomesListSlice: any
    ExistsOutcomesListSlice: any
    OutcomeSlice: any[]
}

const initialState = {
    OutcomesListSlice: null,
    ExistsOutcomesListSlice: null,
    OutcomeSlice: [],
} as InitialStateInterface;

export const getOutcomes = createAsyncThunk(
    "getOutcomesList",
    async (req: any) => {
        try {
            return await OutcomesService.getOutcomesService(req).then(response => response);
        } catch (error) { }
    }
);


export const getExistsOutcomes = createAsyncThunk(
    "getExistsOutcomesList",
    async () => {
        try {
            return await OutcomesService.getExistsOutcomesService().then(response => response);
        } catch (error) { }
    }
);


export const exportOutcomes = createAsyncThunk(
    "exportOutcomesList",
    async (req: any) => {
        try {
            return await OutcomesService.exportOutcomesService(req).then(response => response);
        } catch (error) { }
    }
);

export const postOutcomes = createAsyncThunk(
    "postOutcomes",
    async (req: any, thunkAPI) => {
        try {
            return await OutcomesService.postOutcomesService(req).then(response => response);
        } catch (error) { }
    }
);

export const putOutcomes = createAsyncThunk(
    "putOutcomes",
    async (req: any, thunkAPI) => {
        try {
            return await OutcomesService.putOutcomesService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteOutcomesWithId = createAsyncThunk(
    "deleteOutcomes",
    async (req: any, thunkAPI) => {
        try {
            return await OutcomesService.deleteOutcomesService(req).then(response => response);
        } catch (error) { }
    }
);

export const getOutcomesWithId = createAsyncThunk(
    "getOutcomesWithId",
    async (req: any) => {
        try {
            return await OutcomesService.getOutcomesWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const OutcomesSlice = createSlice({
    name: "OutcomesSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOutcomes.fulfilled, (state, action) => {
            state.OutcomesListSlice = action.payload?.result ? action.payload.result?.outcomes : null;
        });
        builder.addCase(getExistsOutcomes.fulfilled, (state, action) => {
            state.ExistsOutcomesListSlice = action.payload?.result ? action.payload.result?.dialog_types : null;
        });
        builder.addCase(getOutcomesWithId.fulfilled, (state, action) => {
            state.OutcomeSlice = action.payload?.result ? action.payload.result?.outcome : [];
        });
    },
});

export default OutcomesSlice;
