/* eslint-disable no-template-curly-in-string */

import { callApi, HttpMethods } from "../../../../utils/api-util";
import { ResponseType } from "../../../../utils/enums/ResponseType.enum";

const getSliderContentService = async (req: any) => {
    let _options = {
        controller: "/slider/get",
        method: HttpMethods.POST,
        data: req,
    };
    const result = await callApi(_options);
    return result;
}

const exportSliderContentService = async (req: any) => {
    let _options = {
        controller: "/slider/export",
        method: HttpMethods.POST,
        data: req,
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}


const getSliderContentWithIdService = async (req: any) => {
    let _options = {
        controller: "/slider/get-slider-by-id/" + req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const postSliderContentService = async (req: any) => {
    let _options = {
        controller: "/slider",
        method: HttpMethods.POST,
        data: req,
        contentType: 'multipart/form-data; boundary=${form._boundary}'
    };
    const result = await callApi(_options);
    return result;
};

const putSliderContentService = async (req: any) => {

    let _options = {
        controller: "/slider",
        method: HttpMethods.PUT,
        data: req,
        contentType: 'multipart/form-data; boundary=${form._boundary}'
    };
    const result = await callApi(_options);
    return result;
};

const deleteSliderContentService = async (req: any) => {
    let _options = {
        controller: "/slider",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const SliderContentService = {
    getSliderContentService,
    exportSliderContentService,
    postSliderContentService,
    putSliderContentService,
    deleteSliderContentService,
    getSliderContentWithIdService,
};

export default SliderContentService;
