/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import componentsSlice from '../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { CredentialMerchantLogoListWrapper } from './CredentialMerchantLogoList'

const CredentialMerchantLogoBreadcrumbs: Array<PageLink> = [
    {
        title: `Credential Management`,
        path: '/CredentialMerchantLogo/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CredentialMerchantLogo = () => {
    const dispatch: AppDispatch = useDispatch();

    const { merchantLogoListSlice } = useAppSelector(
        (state) => state.credentialMerchantLogo
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={CredentialMerchantLogoBreadcrumbs}>{`Credential Merchant Logo`}</PageTitle>
                            <CredentialMerchantLogoListWrapper merchantLogoList={merchantLogoListSlice} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default CredentialMerchantLogo
