/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import componentsSlice from '../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { IZoneSystemMessagesWrapper } from './IZoneSystemMessagesList'

const IZoneSystemMessagesContentBreadcrumbs: Array<PageLink> = [
    {
        title: `System`,
        path: '/IZoneSystemMessages/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const IZoneSystemMessages = (props:any) => {
    const dispatch: AppDispatch = useDispatch();

    const { IZoneSystemMessagesListSlice } = useAppSelector(
        (state) => state.iZoneSystemMessages
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={IZoneSystemMessagesContentBreadcrumbs}>{`iZone System Messages`}</PageTitle>
                            <IZoneSystemMessagesWrapper IZoneSystemMessagesListSlice={IZoneSystemMessagesListSlice} type={props.type} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default IZoneSystemMessages
