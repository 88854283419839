
import { callApi, HttpMethods } from "../../../utils/api-util";
import { ResponseType } from "../../../utils/enums/ResponseType.enum";

const getHelperService = async (req: any) => {
    let _options = {
        controller: "/Helper/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}
const exportHelperService = async (req: any) => {
    let _options = {
        controller: "/Helper/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}


const getPages = async (req: any) => {
    let _options = {
        controller: "/Helper/get-page/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}


const postHelperService = async (req: any) => {
    let _options = {
        controller: "/Helper",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putHelperService = async (req: any) => {
    let _options = {
        controller: "/Helper",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteHelperService = async (req: any) => {
    let _options = {
        controller: "/Helper",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getHelperWithIdService = async (req:any) => {
    let _options = {
        controller: "/Helper/get-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const getHelperContentWithNameAndIdService = async (req:any) => {
    let _options = {
        controller: `/Helper/get-by-name/${req}/1`,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const getHelperContentWithUrlAndIdService = async (req:any) => {
    let _options = {
        controller: `/helper/get-by-url?HelperUrl=${req}&ProjectName=1`,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const HelperService = {
    getHelperService,
    exportHelperService,
    postHelperService,
    putHelperService,
    deleteHelperService,
    getHelperWithIdService,
    getPages,
    getHelperContentWithNameAndIdService,
    getHelperContentWithUrlAndIdService
};

export default HelperService;
