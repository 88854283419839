/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { TransactionWrapper } from './transactionList'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import FrpTransactionSlice, { getFpBalance, getUsers } from '../../../slices/frpTransaction/transactionSlice'

const transactionCrumbs: Array<PageLink> = [
    {
        title: `FRP Management`,
        path: '/Transaction/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Transaction = () => {
    const dispatch: AppDispatch = useDispatch();

    const { TransactionSlice, UserSlice, FpBalance } = useAppSelector(
        (state) => state.transaction
    );

    useEffect(() => {
        dispatch(FrpTransactionSlice.actions.resetData())
        dispatch(getUsers())
        dispatch(getFpBalance())
    }, [])

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={transactionCrumbs}>{`Transactions`}</PageTitle>
                            <TransactionWrapper transactionList={TransactionSlice} userList={UserSlice} fpBalance={FpBalance} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default Transaction
