/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { isNotEmpty, KTCard, KTSVG } from "../../../../_metronic/helpers"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import { showToast } from "../../../components/custom-toasts/custom-toasts"
import clsx from 'clsx'
import moment from "moment"
import { deleteSliderContentWithId, exportSliderContent, getSliderContent, getSliderContentWithId, postSliderContent, putSliderContent } from "../../../slices/assetManagement/mobile/sliderContent/SliderContentSlice"
import CustomSelect from "../../../components/customModal/CustomSelect"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import CustomFileUpload from "../../../components/customFileUpload/CustomImageUpload"
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"
import PreviewPages from "../../../utils/previewScreen/PreviewPages"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/pro-solid-svg-icons"
import CustomRichTextbox from "../../../components/customTextbox/CustomRichTextbox"

interface Props {
    sliderContentList: any
}


export const sliderTypeData = [
    {
        value: 0,
        label: "Shoutback"
    },
    {
        value: 1,
        label: "Credential"
    },
    {
        value: 2,
        label: "Coupon"
    },
    {
        value: 3,
        label: "Invitation"
    },
    {
        value: 4,
        label: "DataSource"
    },
    {
        value: 5,
        label: "FRP"
    },
    {
        value: 6,
        label: "Merchant"
    },
    {
        value: 7,
        label: "Members"
    },
    {
        value: 8,
        label: "Video"
    }
]

export const sliderTypeDataForFilter =
    [
        {
            value: -1,
            label: "Select..."
        },
        ...sliderTypeData
    ]

const SliderContentList: FC<Props> = ({ sliderContentList }) => {
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [selectedRowId, setSelectedRowId] = useState<string | null>(null)
    const [file, setFile] = useState<File | null>(null);
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [loading, setloading] = useState(false)
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([])
    const [filterParams, setfilterParams] = useState<any>([])

    const formik = useFormik({
        initialValues: {
            id: '',
            title: '',
            content: '',
            sliderType: undefined,
            url: '',
            priority: '',
            video: ''
        },
        validationSchema: Yup.object().shape({
            title: Yup.string()
                .min(3, 'Minimum 3 symbols')
                .max(50, 'Maximum 50 symbols')
                .required('Title is required'),
            url: Yup.string()
                .required('Image is required'),
            priority: Yup.string()
                .required('Page Order is required'),
            sliderType: Yup.object().required('Slider Type is required'),
            content:
                Yup.string().when('sliderType', (sliderType, schema) => {
                    if (sliderType?.value !== 8) { return schema.required('Content is required'); }
                    return schema;
                })
                    .min(3, 'Minimum 3 symbols')
                    .max(120, 'A maximum of 120 symbols can be displayed on mobile screens.'),
            video:
                Yup.string().when('sliderType', (sliderType, schema) => {
                    if (sliderType?.value === 8) { return schema.required('Video is required'); }
                    return schema;
                })

        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateWithId(values)
                } else {
                    createSliderContent(values);
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                // dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
                setFile(null)
                setVideoFile(null)
            }
        },
    })

    useEffect(() => {
        setdataTableisBusy(false)
    }, [sliderContentList])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(null)
            formik.resetForm()
        }
    }, [modalIsOpen])

    useEffect(() => {
        if ((formik.values.sliderType as any)?.value !== 8) {
            formik.setFieldValue("video", '')
        }
    }, [formik.values.sliderType])


    //#region DataTable Methods

    const columns = useMemo(
        () => [
            {
                name: 'Title',
                selector: (row: any) => <div>{row?.title}</div>,
                width: "240px",
            },
            {
                name: 'Type',
                selector: (row: any) => <div>{sliderTypeData.find(x => x.value === row?.type)?.label}</div>,
                width: "120px",
            },
            {
                name: 'Page Order',
                label: "Priority",
                selector: (row: any) => <div>{row?.priority}</div>,
                width: "120px",
                sortable: true
            },
            {
                name: 'Url',
                selector: (row: any) => <div>{row?.slider_content_urls?.find((s: any) => s.content_type === 0)?.url}</div>,
           
            },
            {
                name: 'Date Created',
                label: "CreateDateUtc",
                selector: (row: any) => <div>{moment(row.create_date_utc).format("MM/DD/YYYY")}</div>,
                width: "120px",
                sortable: true
            },
            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={() => {
                                    customgetShoutBackWithId(row.id)
                                    setprocessName("Insert")
                                    setSelectedRowId(row.id)
                                    dispatch(componentsSlice.actions.openModal({}))
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </div>
                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={() => {
                                    setprocessName("Delete")
                                    setSelectedRowId(row.id)
                                    dispatch(componentsSlice.actions.openModal({}))
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                            </div>
                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const customFilter = [
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Title</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Title"), { "name": "Title", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Title")])
                    }
                    placeHolder={"Type a Title"}
                    name='Title'
                    value={filterQuery.find((x: any) => x.name === "Title") !== undefined ? filterQuery.find((x: any) => x.name === "Title")?.value : ""}
                    customKeypressEvent={() => setfilterParams(filterQuery)}
                ></CustomTextbox>
            </>
        },
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Type</label>
                <CustomSelect
                    onChange={
                        (e: any) =>
                            e.value !== -1 ?
                                setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Type"), { "name": "Type", "comparator": "==", "value": e.value }])
                                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Type")])
                    }
                    data={sliderTypeDataForFilter}
                    isMulti={false}
                    selectedValue={sliderTypeDataForFilter?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "Type")?.value) !== undefined ? sliderTypeDataForFilter?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "Type")?.value) : -1}
                ></CustomSelect>
            </>
        },
    ]

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.label, "direction": sortDirection === "asc" ? 0 : 1 }])
    };
    useEffect(() => {
        dispatch(getSliderContent({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, filterParams])


    //#endregion DataTable Methods End

    function deleteFunc() {
        dispatch(deleteSliderContentWithId(selectedRowId)).then((response) => {
            if (response?.payload?.is_success) {
                showToast.success("Deleted successfully")
                dispatch(getSliderContent({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
            }
            else {
                showToast.error("Delete process failed")
                dispatch(getSliderContent({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
            }
        })
    }

    function createSliderContent(request: any) {
        setloading(true)
        const formData = new FormData();
        formData.append("ImageFileStream", file !== null ? file : "");
        formData.append("VideoFileStream", videoFile !== null ? videoFile : "");
        formData.append("Title", request?.title);
        formData.append("Content", request?.content);
        formData.append("Type", request?.sliderType?.value);
        formData.append("Priority", request?.priority);

        dispatch(postSliderContent(formData)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                setFile(null)
                dispatch(getSliderContent({ page_index: 0, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
                setloading(false)
            }
        })
    }

    function updateWithId(request: any) {
        setloading(true)
        const formData = new FormData();
        formData.append("ImageFileStream", file !== null ? file : "");
        formData.append("VideoFileStream", videoFile !== null ? videoFile : "");
        formData.append("Title", request?.title);
        formData.append("Content", request?.content !== undefined ? request?.content : "");
        formData.append("Type", request?.sliderType?.value);
        formData.append("Priority", request?.priority);
        formData.append("Id", selectedRowId as string);

        dispatch(putSliderContent(formData)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                setFile(null)
                dispatch(getSliderContent({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
                setloading(false)
            }
        })
    }

    function customgetShoutBackWithId(rowId: any) {
        if (rowId !== undefined) {
            dispatch(getSliderContentWithId(rowId)).then((s: any) => {
                formik.setValues({
                    id: s?.payload?.result?.slider?.id,
                    title: s?.payload?.result?.slider?.title,
                    content: s?.payload?.result?.slider?.content,
                    sliderType: (sliderTypeData.find(x => x.value === s?.payload?.result?.slider?.type) as any),
                    url: s?.payload?.result?.slider?.slider_content_urls?.find((s: any) => s.content_type === 0)?.url,
                    priority: s?.payload?.result?.slider?.priority,
                    video: s?.payload?.result?.slider?.slider_content_urls?.find((s: any) => s.content_type === 1)?.url,
                })
            })
        }
    }

    function downloadVideo(url: string) {
        let alink = document.createElement('a');
        alink.href = url;
        alink.download = 'url';
        alink.click();
    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== null ? "Update Slider Content" : "Create Slider Content"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Type</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={sliderTypeData}
                                            isMulti={false}
                                            selectedValue={formik?.values?.sliderType}
                                            formik={formik}
                                            formikName={"sliderType"}
                                        ></CustomSelect>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Title</label>
                                        <CustomTextbox
                                        {...formik.getFieldProps('title')}
                                        onChange={() => null}
                                        placeHolder={'Type a Title'}
                                        name='title'
                                        value={formik.values.title}
                                        formik={formik}
                                        formikName={'title'}
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className={`${formik.values.sliderType !== null && (formik.values.sliderType as any)?.value === 8 ? "" : "required"} fw-bold fs-6 mb-2`}>Content</label>
                                        <CustomRichTextbox
                                        {...formik.getFieldProps('content')}
                                        onChange={() => null}
                                        placeHolder={'Type a Content'}
                                        name='content'
                                        value={formik.values.content}
                                        formik={formik}
                                        formikName={'content'}
                                        height={'23vh'}
                                        ></CustomRichTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Page Order</label>

                                        <CustomTextbox
                                            {...formik.getFieldProps('priority')}
                                            onChange={() => null}
                                            placeHolder={"Type a Page Order"}
                                            name='queryPollingFrequency'
                                            value={formik.values.priority}
                                            formik={formik}
                                            formikName={"priority"}
                                            type={"number"}
                                            onlyPositive
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Image</label>
                                        <CustomFileUpload
                                            uploadFile={(file: FileList) => {
                                                setFile(file[0])
                                            }}
                                            imageString={formik.values.url}
                                            formik={formik}
                                            formikName={"url"}
                                            accept={"image/*"}
                                        ></CustomFileUpload>
                                    </div>
                                    {(formik.values.sliderType as any)?.value === 8 ?
                                        <div className='fv-row mb-7'>
                                            <label className='required fw-bold fs-6 mb-2'>Video</label>
                                            <CustomFileUpload
                                                uploadFile={(file: FileList) => {
                                                    setVideoFile(file[0])
                                                }}
                                                imageString={formik.values.video}
                                                formik={formik}
                                                formikName={"video"}
                                                accept={"video/mp4"}
                                            ></CustomFileUpload>
                                            {
                                                selectedRowId !== null && (formik.values.sliderType as any)?.value === 8 && formik?.values?.video ?
                                                    <div className="row mt-2">
                                                        <div className="col-10"></div>
                                                        <div className="col-2">
                                                            <div className="row">
                                                                <div className="col-auto" style={{ padding: "0px" }}><FontAwesomeIcon icon={faDownload} size={"1x"} onClick={() => downloadVideo(formik?.values?.video)} style={{ cursor: "pointer" }}></FontAwesomeIcon></div>
                                                                <div className="col-auto" style={{ padding: "0px", marginLeft: "0.3rem", marginTop: "0.1rem" }}>
                                                                    <div onClick={() => downloadVideo(formik?.values?.video)} style={{ color: "black", cursor: "pointer" }}>Download</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div> : ""
                                    }

                                    <div className="row">
                                        <div className="col-9"></div>
                                        <div className="col-3">
                                            <button
                                                onClick={() => { setprocessName("Preview") }}
                                                className='btn btn-primary'
                                                data-kt-users-modal-action='cancel'
                                            >
                                                Preview
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        {loading ? (
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        ) :
                                            <span className='indicator-label'>Submit</span>
                                        }
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Preview":
                return <>
                    <CustomModal title={"Preview"}>
                        <PreviewPages screenName={"SliderContent"} content={formik.values}></PreviewPages>
                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                                onClick={() => { setprocessName("Insert") }}
                            >
                                <span className='indicator-label'>Back</span>
                            </button>
                        </div>
                        {/* end::Actions */}
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteFunc()
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={sliderContentList?.items}
                    title={"Slider Content List"}
                    pagination
                    paginationServer
                    paginationTotalRows={sliderContentList?.total_count}
                    isBusy={dataTableisBusy}
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    onExport={() => {
                        dispatch(exportSliderContent({ page_index: 0, page_size: 0, filters: filterParams, sort_filters: sortData }));

                    }}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    showTitle
                    showFilter={true}
                    filterOptions={customFilter}
                    searchButtonClickEvent={() => setfilterParams(filterQuery)}
                    sortHandle={handleSort}
                    clearButtonClickEvent={() => {
                        setfilterParams([])
                        setfilterQuery([])
                    }}
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const SliderContentListWrapper: FC<Props> = ({ sliderContentList }) => <QueryRequestProvider>
    <ListViewProvider>
        <SliderContentList sliderContentList={sliderContentList} />
    </ListViewProvider>
</QueryRequestProvider>

export { SliderContentListWrapper }
