
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import pictureAndFileService from "../services/upload-image";

interface CustomInitialState {
  urlAfterImageUpload: (string);
}

const initialState = {
  urlAfterImageUpload: '',
} as CustomInitialState;

export const uploadPicture = createAsyncThunk(
  "/postPicture",
  async (file: File, thunkAPI) => {
    try {
      return await pictureAndFileService.postPicture(file).then(response => response);
    } catch (error) { }
  }
);

export const uploadPdf = createAsyncThunk(
  "/postPdfFile",
  async (file: File, thunkAPI) => {
    try {
      return await pictureAndFileService.postPDF(file).then(response => response);
    } catch (error) { }
  }
);

export const uploadSatPicture = createAsyncThunk(
  "/postSatPicture",
  async (file: File, thunkAPI) => {
    try {
      return await pictureAndFileService.postPicture(file,true).then(response => response);
    } catch (error) { }
  }
);

export const getdownloadableLinkWithUrl = createAsyncThunk(
  "getdownloadableLinkWithUrl",
  async (req: any, thunkAPI) => {
    try {
        return await pictureAndFileService.getdownloadableLink(req).then(response => response);
    } catch (error) { }
}
);


const uploadImageAndFileSlice = createSlice({
  name: "uploadImageAndFileSlice",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(uploadPicture.fulfilled, (state, action) => {
      state.urlAfterImageUpload = action.payload?.result?.url
    });
  },
});

export default uploadImageAndFileSlice;