/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useMemo, useState} from 'react'
import CustomDatatable from '../../../components/customDatatable/CustomDatatable'
import {CustomModal} from '../../../components/customModal/CustomModal'
import {AppDispatch, useAppSelector} from '../../../store/store'
import {useDispatch} from 'react-redux'
import componentsSlice from '../../../slices/components/componentsSlice'
import {showToast} from '../../../components/custom-toasts/custom-toasts'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import {QueryRequestProvider} from '../../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import {ListViewProvider} from '../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {deleteContractWithId, exportContractList, getContract, postContract} from '../../../slices/salesAssistant/contract/contractSlice'
import {getProduct} from '../../../slices/salesAssistant/product/productSlice'
import CustomPDFUpload from '../../../components/customFileUpload/CustomPDFUpload'
import {uploadPdf} from '../../../utils/upload-image-file'
import CustomTextbox from '../../../components/customTextbox/CustomTextbox'

interface Props {
  ContractList: any
}

interface Product {
  id: string
  code: string
  name: string
  icon_url: string
  description: string
  party: number[]
  is_coming_soon: boolean
}

interface FormValues {
  id: string
  name: string
  purpose: string
  required: boolean
  products: Product[]
  fpCheck: boolean
  partnerCheck: boolean
  merchantCheck: boolean
  consumerCheck: boolean
  bankMerchantCheck: boolean
  bankRetailCheck: boolean
  contractFileFormik: string
}

const ContractsList: FC<Props> = ({ContractList}) => {
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const {modalIsOpen} = useAppSelector((state) => state.components)
  const dispatch: AppDispatch = useDispatch()
  const [processName, setprocessName] = useState('Insert')
  const [selectedRowId, setSelectedRowId] = useState(undefined)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [filterQuery, setfilterQuery] = useState<any>([])
  const [sortData, setsortData] = useState<any>([])
  const [filterParams, setfilterParams] = useState<any>([])
  const [products, setProducts] = useState<Array<Product>>([])
  const [contractFile, setContractFile] = useState('')

  const formik = useFormik<FormValues>({
    initialValues: {
      id: '',
      name: '',
      required: false,
      products: [],
      fpCheck: false,
      partnerCheck: false,
      merchantCheck: false,
      consumerCheck: false,
      bankMerchantCheck: false,
      bankRetailCheck: false,
      purpose: '',
      contractFileFormik: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(1, 'Minimum 1 symbols').required('Name is required'),
      purpose: Yup.string().min(1, 'Minimum 1 symbols').required('Purpose is required'),
      contractFileFormik: Yup.string().min(1, 'Minimum 1 symbols').required('Icon URL is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        createWithRequest(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm()
      }
    },
  })

  useEffect(() => {
    setdataTableisBusy(false)
  }, [ContractList])

  useEffect(() => {
    if (modalIsOpen === undefined) {
      setContractFile('')
      setprocessName('Insert')
      setSelectedRowId(undefined)
      formik.resetForm()
    }
  }, [modalIsOpen])

  useEffect(() => {
    getProducts()
  }, [])

  //#region DataTable Methods

  async function getProducts() {
    const response = await dispatch(
      getProduct({
        page_index: 0,
        page_size: 100,
        filters: [],
        sort_filters: [],
      })
    )
    const extractedProducts = response.payload.result.products.items;
    setProducts(extractedProducts)
  }

  //#region DataTable Methods

  const columns = useMemo(
    () => [
      {
        name: 'Title',
        selector: (row: any) => <div>{row?.name}</div>,
        width: 'auto',
      },
      {
        name: 'Purpose',
        selector: (row: any) => <div className='text-overflow'>{row?.purpose}</div>,
        width: '25%',
      },
      {
        name: 'Required',
        selector: (row: any) =>
          row?.required ? (
            <span className='badge badge-light-success fs-5 fw-semibold'>
              Yes
            </span>
          ) : (
            <span className='badge badge-light-danger fs-5 fw-semibold'>
             No
            </span>
          ),
        width: '10%',
      },
      {
        name: 'Action',
        selector: (row: any) => (
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {
                <>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Insert')
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                      ContractsWithId(row, 'Insert')
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </div>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Delete')
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </div>
                </>
              }
            </div>
          </>
        ),
        width: '100px',
      },
    ],
    []
  )

  const handlePageChange = (page: any) => {
    setdataTableisBusy(true)
    setPage(page - 1)
    setdataTableisBusy(false)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setdataTableisBusy(true)
    setPerPage(newPerPage)
    setdataTableisBusy(false)
  }
  const handleSort = async (column: any, sortDirection: any) => {
    setsortData([{property: column?.name, direction: sortDirection === 'asc' ? 0 : 1}])
  }
  useEffect(() => {
    dispatch(
      getContract({
        page_index: page,
        page_size: perPage,
        filters: filterParams,
        sort_filters: sortData,
      })
    )
  }, [page, perPage, sortData, filterParams])


  function ContractsWithId(data: any, process: string) {
    if (data !== undefined) {
      formik.setValues({
        id: data.id,
        name: data.name,
        purpose: data.purpose,
        contractFileFormik: data.content_url,
        required: data.required,
        products: data.products,
        fpCheck: data.party_list?.includes(0) ? true : false,
        partnerCheck: data.party_list?.includes(1) ? true : false,
        merchantCheck: data.party_list?.includes(2) ? true : false,
        consumerCheck: data.party_list?.includes(3) ? true : false,
        bankMerchantCheck: data.party_list?.includes(4) ? true : false,
        bankRetailCheck: data.party_list?.includes(5) ? true : false,
      })
      setContractFile(data.content_url)
    }
  }

  function deleteWithId() {
    if (selectedRowId !== undefined) {
      dispatch(deleteContractWithId(selectedRowId)).then((s: any) => {
        dispatch(
          getContract({
            page_index: page,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        if (s?.payload?.is_success) {
          showToast.success('Item Deleted Successfully')
        } else {
          showToast.error(s?.payload?.response_exception?.exception_message)
        }
        dispatch(componentsSlice.actions.closeModal({}))
      })
    }
  }

  function postPicture(file: File) {
    dispatch(uploadPdf(file)).then((s: any) => {
      setContractFile(s?.payload?.result?.url)
      // Upload Picture for sat change
    })
  }

  function createWithRequest(request: any) {
    const partyMapping: {[key: string]: number} = {
      fpCheck: 0,
      partnerCheck: 1,
      merchantCheck: 2,
      consumerCheck: 3,
      bankMerchantCheck: 4,
      bankRetailCheck: 5,
      mobileCheck: 6,
    }

    const partiesFromRequest = Object.keys(partyMapping)
      .filter((check) => request[check])
      .map((check) => partyMapping[check])

    let req = {
      contract: {
        id: request.id !== null ? request.id : undefined,
        name: request.name,
        purpose: request.purpose,
        content_url: contractFile,
        required: request.required,
        party_list: partiesFromRequest,
        products: request.products,
      },
    }
    dispatch(postContract(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(
          getContract({
            page_index: 0,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        showToast.success('Item Saved Successfully')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function renderProductNames() {
    const productsPerRow = 3
    return (
      <div className='fs-6 fw-bold text-gray-400'>
        {Array.from({length: Math.ceil(products.length / productsPerRow)}, (_, rowIndex) => (
          <div className='row d-flex justify-content-between' key={rowIndex}>
            {products
              .slice(rowIndex * productsPerRow, (rowIndex + 1) * productsPerRow)
              .map((product, index) => (
                <div className='col-4' key={product.code}>
                  <div className='form-check form-check-solid'>
                    <input
                      name={`products[${product.code}]`}
                      type='checkbox'
                      id={`products[${product.code}]`}
                      className='form-check-input'
                      value=''
                      checked={Array.isArray(formik.values.products) &&
                        formik.values.products.some(x => x.id === product.id)}
                        onChange={() => {
                          let updatedProducts = Array.isArray(formik.values.products) ? [...formik.values.products] : [];
                          const productIndex = updatedProducts.findIndex(x => x.id === product.id);
                          if (productIndex !== -1) {
                            updatedProducts.splice(productIndex, 1);
                          } else {
                            updatedProducts.push(product);
                          }
                          formik.setFieldValue('products', updatedProducts);
                        }}
                    />
                    <label className='form-check-label ps-2' htmlFor={`product_${product.code}`}>
                      {product.code}
                    </label>
                  </div>
                </div>
              ))}
            {Array.from({length: productsPerRow - (products.length % productsPerRow)}).map(
              (_, emptyIndex) => (
                <div className='col-4 mt-2' key={`empty_${emptyIndex}`}></div>
              )
            )}
          </div>
        ))}
      </div>
    )
  }

  function findProcessScreen(processName: string) {
    switch (processName) {
      case 'Insert':
        return (
          <>
            <CustomModal
              maxWidth={900}
              title={selectedRowId !== undefined ? 'Update' : 'New Item'}
            >
              <>
                <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                  <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                  >
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Title</label>
                      <CustomTextbox
                      {...formik.getFieldProps('name')}
                      onChange={() => null}
                      placeHolder={'Type a Name'}
                      name='name'
                      value={formik.values.name}
                      formik={formik}
                      formikName={'name'}
                      ></CustomTextbox>
                    </div>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Purpose</label>
                      <CustomTextbox
                      {...formik.getFieldProps('purpose')}
                      onChange={() => null}
                      placeHolder={'Type a Purpose'}
                      name='purpose'
                      value={formik.values.purpose}
                      formik={formik}
                      formikName={'purpose'}
                      ></CustomTextbox>
                    </div>
                    <div className='mt-2'>
                      <div className='d-flex flex-stack'>
                        <div className={`list-group-item list-group-item-action my-1`}>
                          <div className='d-flex'>
                            <div className='d-flex flex-column col-12'>
                              <label className='required fw-bold fs-6 mb-2'>
                                Contract Document
                              </label>
                              <CustomPDFUpload
                                uploadFile={(file: FileList) => {
                                  postPicture(file?.[0])
                                }}
                                pdfString={contractFile}
                                formik={formik}
                                formikName={'contractFileFormik'}
                              ></CustomPDFUpload>
                              {formik.touched.contractFileFormik &&
                                formik.errors.contractFileFormik && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{formik.errors.contractFileFormik}</span>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-2'></div>
                    </div>
                    <div className='mt-2'>
                      <div className='d-flex flex-stack'>
                        <div className={`list-group-item list-group-item-action my-1`}>
                          <div className='d-flex'>
                            <div className='d-flex flex-column col-12'>
                              <div className='fs-5 d-flex text-dark fw-bolder'>
                                {`Assosiated Parties`}
                              </div>
                              <div className='fs-6 fw-bold text-gray-400 row d-flex justify-content-between mx-2'>
                                <div className='row d-flex justify-content-between'>
                                  <div className='col-4 mt-2'>
                                    <div className='form-check form-check-solid'>
                                      <input
                                        {...formik.getFieldProps('fpCheck')}
                                        name='fpCheck'
                                        type='checkbox'
                                        id='fpCheck'
                                        className='form-check-input'
                                        value=''
                                        defaultChecked={formik.values.fpCheck}
                                        checked={formik.values.fpCheck}
                                        onChange={(event) =>
                                          formik?.setValues({
                                            ...formik?.values,
                                            fpCheck: event.target.checked,
                                          })
                                        }
                                        //onChange={(event) => setIsPartner(event.target.checked)}
                                      />
                                      <label className='form-check-label mx-2' htmlFor='fpCheck'>
                                        Freedompay
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-4 mt-2'>
                                    <div className='form-check form-check-solid'>
                                      <input
                                        {...formik.getFieldProps('partnerCheck')}
                                        name='partnerCheck'
                                        type='checkbox'
                                        id='partnerCheck'
                                        className='form-check-input'
                                        value=''
                                        defaultChecked={formik.values.partnerCheck}
                                        checked={formik.values.partnerCheck}
                                        onChange={(event) =>
                                          formik?.setValues({
                                            ...formik?.values,
                                            partnerCheck: event.target.checked,
                                          })
                                        }
                                      />
                                      <label
                                        className='form-check-label mx-2'
                                        htmlFor='partnerCheck'
                                      >
                                        Partner
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-4 mt-2'>
                                    <div className='form-check form-check-solid'>
                                      <input
                                        {...formik.getFieldProps('merchantCheck')}
                                        name='merchantCheck'
                                        type='checkbox'
                                        id='merchantCheck'
                                        className='form-check-input'
                                        value=''
                                        defaultChecked={formik.values.merchantCheck}
                                        checked={formik.values.merchantCheck}
                                        onChange={(event) =>
                                          formik?.setValues({
                                            ...formik?.values,
                                            merchantCheck: event.target.checked,
                                          })
                                        }
                                      />
                                      <label
                                        className='form-check-label mx-2'
                                        htmlFor='merchantCheck'
                                      >
                                        Merchant
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className='row d-flex justify-content-between'>
                                  <div className='col-4 mt-2'>
                                    <div className='form-check form-check-solid'>
                                      <input
                                        {...formik.getFieldProps('consumerCheck')}
                                        name='consumerCheck'
                                        type='checkbox'
                                        id='consumerCheck'
                                        className='form-check-input'
                                        value=''
                                        defaultChecked={formik.values.consumerCheck}
                                        checked={formik.values.consumerCheck}
                                        onChange={(event) =>
                                          formik?.setValues({
                                            ...formik?.values,
                                            consumerCheck: event.target.checked,
                                          })
                                        }
                                      />
                                      <label
                                        className='form-check-label mx-2'
                                        htmlFor='consumerCheck'
                                      >
                                        Consumer
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-4 mt-2'>
                                    <div className='form-check form-check-solid'>
                                      <input
                                        {...formik.getFieldProps('bankMerchantCheck')}
                                        name='bankMerchantCheck'
                                        type='checkbox'
                                        id='bankMerchantCheck'
                                        className='form-check-input'
                                        value=''
                                        defaultChecked={formik.values.bankMerchantCheck}
                                        checked={formik.values.bankMerchantCheck}
                                        onChange={(event) =>
                                          formik?.setValues({
                                            ...formik?.values,
                                            bankMerchantCheck: event.target.checked,
                                          })
                                        }
                                      />
                                      <label
                                        className='form-check-label mx-2'
                                        htmlFor='bankMerchantCheck'
                                      >
                                        Bank
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-2'></div>
                    </div>
                    <div className='fv-row mb-7'>
                      <div className='d-flex flex-stack'>
                        <div className={`list-group-item list-group-item-action my-1`}>
                          <div className='d-flex'>
                            <div className='d-flex flex-column col-12'>
                              <div className='fs-5 d-flex text-dark fw-bolder'>
                                {`Assosiated Products`}
                              </div>
                              <div className='fs-6 fw-bold text-gray-400 row d-flex justify-content-between mt-2 mx-2'>
                                {renderProductNames()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-2'></div>
                    </div>
                    <div className='fv-row mb-7'>
                      <div className='form-check form-check-solid'>
                        <input
                          {...formik.getFieldProps('required')}
                          className='form-check-input'
                          name='required'
                          type='checkbox'
                          value=''
                          id='required'
                          defaultChecked={formik.values.required}
                          checked={formik.values.required}
                          onChange={(event) =>
                            formik?.setValues({
                              ...formik?.values,
                              required: event.target.checked,
                            })
                          }
                        />
                        <label
                          className='form-check-label ps-2'
                          htmlFor='kt_settings_notification_phone'
                        >
                          This contract is mandatory
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* begin::Actions */}
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                      className='btn btn-light me-3'
                      data-kt-users-modal-action='cancel'
                    >
                      Discard
                    </button>

                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                    >
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </div>
                  {/* end::Actions */}
                </form>
              </>
            </CustomModal>
          </>
        )

      case 'Delete':
        return (
          <>
            <CustomModal title='Are You Sure?'>
              <>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => {
                      dispatch(componentsSlice.actions.closeModal({}))
                    }}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    onClick={() => {
                      deleteWithId()
                    }}
                  >
                    <span className='indicator-label'>Delete</span>
                  </button>
                </div>
                {/* end::Actions */}
              </>
            </CustomModal>
          </>
        )

      default:
        return null
    }
  }

  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={ContractList?.items}
          title={'Contracts List'}
          pagination
          paginationServer
          paginationTotalRows={ContractList?.total_count}
          isBusy={dataTableisBusy}
          isNewItem
          isNewItemString={'New Item'}
          isExport
          onExport={() => {
            dispatch(
              exportContractList({
                page_index: 0,
                page_size: 0,
                filters: filterParams,
                sort_filters: sortData,
              })
            );
          }}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          showTitle
          //showFilter={true}
          //filterOptions={customFilter}
          searchButtonClickEvent={() => setfilterParams(filterQuery)}
          sortHandle={handleSort}
          clearButtonClickEvent={() => {
            setfilterParams([])
            setfilterQuery([])
          }}
        ></CustomDatatable>
      </KTCard>
      {modalIsOpen !== undefined ? findProcessScreen(processName) : null}
    </>
  )
}

const ContractsWrapper: FC<Props> = ({ContractList}) => (
  <QueryRequestProvider>
    <ListViewProvider>
      <ContractsList ContractList={ContractList} />
    </ListViewProvider>
  </QueryRequestProvider>
)

export {ContractsWrapper}
