import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {useDispatch, TypedUseSelectorHook, useSelector} from 'react-redux'
import sliderContentSlice from '../slices/assetManagement/mobile/sliderContent/SliderContentSlice'
import termsConditionsSlice from '../slices/assetManagement/mobile/termsConditions/TermsConditionsSlice'
import componentsSlice from '../slices/components/componentsSlice'
import credentialMerchantLogoSlice from '../slices/credentialManagement/CredentialMerchantLogoSlice'
import dataSourceIntegrationSlice from '../slices/dataSourceIntegrationSlice'
import frpPurchaseRateSlice from '../slices/frpManagement/FrpPurchaseRateSlice'
import incentiveSlice from '../slices/incentiveManagement/IncentiveSlice'
import videoSlice from '../slices/assetManagement/mobile/introVideos/IntroVideosSlice'
import uploadImageAndFileSlice from '../utils/upload-image-file'
import FaqSlice from '../slices/assetManagement/console/FaqSlice'
import IZoneSystemMessagesSlice from '../slices/campaignManagament/iZoneSystemMessages/iZoneSystemMessagesSlice'
import IZonePollingSettingsSlice from '../slices/campaignManagament/iZonePollingSettings/iZonePollingSettingsSlice'
import BrandAssetSlice from '../slices/assetManagement/system/BrandAssetSlice'
import DataSourcesSlice from '../slices/credentialManagement/dataSources/DataSourcesSlice'
import VeracityContactInformationSlice from '../slices/assetManagement/system/veracityContactInformation/VeracityContactInformationSlice'
import attributeFormatSlice from '../slices/credentialManagement/attributeFormat/AttributeFormatSlice'
import credentialsSlice from '../slices/credentialManagement/credentials/CredentialsSlice'
import OutcomesSlice from '../slices/campaignManagament/outcomes/outcomesSlice'
import HelperSlice from '../slices/assetManagement/console/HelperSlice'
import ProductionRequestSlice from '../slices/merchantManagement/productionRequestSlice'
import FRPRequestSlice from '../slices/frpRequest/frpRequestSlice'
import FPMerchantsSlice from '../slices/fpConnections/fpMerchants/fpMerchantsSlice'
import EndpointsSlice from '../slices/fpConnections/endpoints/endpointsSlice'
import DashboardSlice from '../slices/dashboard/dashboardSlice'
import WelcomeScreenSlice from '../slices/assetManagement/sdk/welcomeScreen/WelcomeScreenSlice'
import TenderSlice from '../slices/assetManagement/sdk/tender/TenderSlice'
import PrivacyPolicySlice from '../slices/assetManagement/system/privacyPolicy/privacyPolicySlice'
import NotificationSlice from '../slices/assetManagement/console/NotificationSlice'
import ContentSlice from '../slices/assetManagement/sdk/content/contentSlice'
import FrpTransactionSlice from '../slices/frpTransaction/transactionSlice'
import ReferralSlice from '../slices/referralManagement/referralManagementSlice'
import UsecasesSlice from '../slices/salesAssistant/useCases/useCasesSlice'
import ContractsSlice from '../slices/salesAssistant/contract/contractSlice'
import PrepareSlice from '../slices/salesAssistant/prepare/prepareSlice'
import ProductSlice from '../slices/salesAssistant/product/productSlice'
import TinkWebHookSlice from '../slices/assetManagement/system/tinkWebHook/tinkWebHookSlice'

const rootReducer = combineReducers({
  video: videoSlice.reducer,
  components: componentsSlice.reducer,
  utils: uploadImageAndFileSlice.reducer,
  dataSourceIntegration: dataSourceIntegrationSlice.reducer,
  credentialMerchantLogo: credentialMerchantLogoSlice.reducer,
  sliderContent: sliderContentSlice.reducer,
  termsConditions: termsConditionsSlice.reducer,
  incentive: incentiveSlice.reducer,
  frpPurchaseRate: frpPurchaseRateSlice.reducer,
  faq: FaqSlice.reducer,
  notification: NotificationSlice.reducer,
  helper: HelperSlice.reducer,
  iZoneSystemMessages: IZoneSystemMessagesSlice.reducer,
  iZonePollingSettings: IZonePollingSettingsSlice.reducer,
  brandAsset: BrandAssetSlice.reducer,
  dataSources: DataSourcesSlice.reducer,
  veracityContactInformation: VeracityContactInformationSlice.reducer,
  attributeFormat: attributeFormatSlice.reducer,
  credentials: credentialsSlice.reducer,
  outcomes: OutcomesSlice.reducer,
  transaction: FrpTransactionSlice.reducer,
  productionRequest: ProductionRequestSlice.reducer,
  frpRequest: FRPRequestSlice.reducer,
  fpMerchantsSlice: FPMerchantsSlice.reducer,
  endpoints: EndpointsSlice.reducer,
  referralManagement: ReferralSlice.reducer,
  dashboardManagement: DashboardSlice.reducer,
  welcomeScreen: WelcomeScreenSlice.reducer,
  tender: TenderSlice.reducer,
  privacyPolicy: PrivacyPolicySlice.reducer,
  content: ContentSlice.reducer,
  useCase: UsecasesSlice.reducer,
  contact: ContractsSlice.reducer,
  prepare: PrepareSlice.reducer,
  product: ProductSlice.reducer,
  tinkWebhook: TinkWebHookSlice.reducer
})

const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
