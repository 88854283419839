/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import { showToast } from "../../../components/custom-toasts/custom-toasts"
import clsx from 'clsx'
import moment from "moment"
import { deleteTermsConditionWithId, exportTermsConditionsWithTypeId, getTermsConditionsWithTypeId, getTermsConditionWithId, postTermsConditions, putTermsConditions } from "../../../slices/assetManagement/mobile/termsConditions/TermsConditionsSlice"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTCard, KTSVG } from "../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import CustomDate from "../../../components/custom-date/custom-date"
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"
import CustomFilterDateRange from "../../../components/customFilter/CustomFilterDateRange"
import CustomRichTextbox from "../../../components/customTextbox/CustomRichTextbox"

interface Props {
    termsConditionsList: any,
    type: any
}

const TermsConditionsList: FC<Props> = ({ termsConditionsList, type }) => {
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [selectedRowId, setSelectedRowId] = useState(undefined)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([])
    const [filterParams, setfilterParams] = useState<any>([])

    const formik = useFormik({
        initialValues: {
            id: '',
            description: '',
            publishedDate: '',
        },
        validationSchema: Yup.object().shape({
            description: Yup.string()
                .min(3, 'Minimum 3 symbols')
                .required('Description is required'),
            publishedDate: Yup.string()
                .required('Publish Date is required'),
        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateWithId(values)
                }
                else {
                    createTermContiton(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    useEffect(() => {
        setdataTableisBusy(false)
    }, [termsConditionsList])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(undefined)
            formik.resetForm()
        }
    }, [modalIsOpen])

    useEffect(() => {
        setfilterParams([])
        setfilterQuery([])
        setsortData([])
    }, [type])
    

    //#region DataTable Methods

    const columns = useMemo(
        () => [
            {
                name: 'Description',
                selector: (row: any) => <div title={row?.description}>{row?.description}</div>,
            },
            {
                name: 'Status',
                selector: (row: any) => row?.status === "active" ? <span className='badge badge-light-success fs-5 fw-semibold'>{"Active"}</span> : row?.status === "archive" ? <span className='badge badge-light-danger fs-5 fw-semibold'>{"Archive"}</span> : <span className='badge badge-light-warning fs-5 fw-semibold'>{"Pending"}</span>,
                width: "120px",
            },
            {
                name: 'Published Date',
                label: "PublishedDate",
                selector: (row: any) => <div>{moment(row.published_date).format("MM/DD/YYYY hh:mm a")}</div>,
                width: "200px",
                sortable: true
            },
            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                            {
                                (new Date(row?.published_date) < new Date())
                                    ?
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                            termsConditionWithId(row.id, "Copy")
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-3' />
                                    </div>
                                    :
                                    <>
                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            onClick={() => {
                                                setprocessName("Insert")
                                                setSelectedRowId(row.id)
                                                dispatch(componentsSlice.actions.openModal({}))
                                                termsConditionWithId(row.id, "Insert")
                                            }}
                                        >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                        </div>
                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            onClick={() => {
                                                setprocessName("Delete")
                                                setSelectedRowId(row.id)
                                                dispatch(componentsSlice.actions.openModal({}))
                                            }}
                                        >
                                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                        </div>
                                    </>
                            }

                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const customFilter = [
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Description</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Description"), { "name": "Description", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Description")])
                    }
                    placeHolder={"Type a Description"}
                    name='Description'
                    value={filterQuery.find((x: any) => x.name === "Description") !== undefined ? filterQuery.find((x: any) => x.name === "Description")?.value : ""}
                    customKeypressEvent={() => setfilterParams(filterQuery)}
                ></CustomTextbox>
            </>
        },
        {
            component: <>
                <CustomFilterDateRange filterName={"PublishedDate"} filterTitle={"Published Date"} customQuery={filterQuery} customFunction={setfilterQuery}></CustomFilterDateRange>
            </>
        },
    ]

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.label, "direction": sortDirection === "asc" ? 0 : 1 }])
    };
    useEffect(() => {
        dispatch(getTermsConditionsWithTypeId({ type: type, page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, type, filterParams])


    //#endregion DataTable Methods End

    function termsConditionWithId(rowId: any, process: string) {
        if (rowId !== undefined) {
            dispatch(getTermsConditionWithId(rowId)).then((s: any) => {
                formik.setValues({
                    id: process !== "Copy" ? s?.payload?.result?.terms_of_conditions?.id : '',
                    description: s?.payload?.result?.terms_of_conditions?.description,
                    publishedDate: process === "Copy" ? (new Date()).toString() : (s?.payload?.result?.terms_of_conditions?.published_date).toString()
                })
            })
        }
    }

    function updateWithId(request: any) {
        let req = {
            id: selectedRowId,
            description: request.description,
            published_date: moment(request.publishedDate).toISOString(true)
        }
        dispatch(putTermsConditions(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getTermsConditionsWithTypeId({ type: type, page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function deleteTermsCondition() {
        if (selectedRowId !== undefined) {
            dispatch(deleteTermsConditionWithId(selectedRowId)).then((s: any) => {
                if (s?.payload?.is_success) {
                    dispatch(getTermsConditionsWithTypeId({ type: type, page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.success("Item successfully deleted")
                    dispatch(componentsSlice.actions.closeModal({}))
                }
                else {
                    dispatch(getTermsConditionsWithTypeId({ type: type, page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.error(s?.payload?.response_exception?.exception_message)
                    dispatch(componentsSlice.actions.closeModal({}))
                }
            })
        }
    }

    function createTermContiton(request: any) {
        let req = {
            id: selectedRowId,
            description: request.description,
            published_date: moment(request.publishedDate).toISOString(true),
            user_type: type
        }
        dispatch(postTermsConditions(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getTermsConditionsWithTypeId({ type: type, page_index: 0, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
            }
        })
    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Update Terms & Conditions" : "Create Terms & Conditions"} >
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Description</label>
                                        <CustomRichTextbox
                                        {...formik.getFieldProps('description')}
                                        onChange={() => null}
                                        placeHolder={'Type a Description'}
                                        name='description'
                                        value={formik.values.description}
                                        formik={formik}
                                        formikName={'description'}
                                        height={'23vh'}
                                        ></CustomRichTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Publish Date</label>
                                        <CustomDate
                                            minDate={new Date()}
                                            {...formik.getFieldProps('publishedDate')}
                                            value={null}
                                            selected={formik.values.publishedDate !== "" ? new Date(formik.values.publishedDate) : null}
                                            placeHolder={"Select Valid Date"}
                                            formik={formik}
                                            formikName={"publishedDate"}
                                            showTimeInput
                                            onChange={undefined}
                                        />
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-5'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Copy":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Terms & Conditions Copy" : "Terms & Conditions"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Description</label>
                                        <textarea
                                            placeholder='Type a Description'
                                            {...formik.getFieldProps('description')}
                                            name='description'
                                            className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                                            autoComplete='off'
                                            data-kt-element='input'
                                            style={{ height: '300px' }}
                                        />
                                        {formik.touched.description && formik.errors.description && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.description}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Publish Date</label>
                                        <CustomDate
                                            minDate={new Date()}
                                            {...formik.getFieldProps('publishedDate')}
                                            value={formik.values.publishedDate !== undefined ? new Date(formik.values.publishedDate) : null}
                                            selected={null}
                                            placeHolder={"Select Valid Date"}
                                            formik={formik}
                                            formikName={"publishedDate"}
                                            onChange={undefined}
                                        />
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteTermsCondition()
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={termsConditionsList?.items}
                    title={"Terms & Conditions List"}
                    pagination
                    paginationServer
                    paginationTotalRows={termsConditionsList?.total_count}
                    isBusy={dataTableisBusy}
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    onExport={() => {
                        dispatch(exportTermsConditionsWithTypeId({ type: type, page_index: 0, page_size: 0, filters: filterParams, sort_filters: sortData }));

                    }}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    showTitle
                    showFilter={true}
                    filterOptions={customFilter}
                    searchButtonClickEvent={() => setfilterParams(filterQuery)}
                    sortHandle={handleSort}
                    clearButtonClickEvent={() => {
                        setfilterParams([])
                        setfilterQuery([])
                    }}
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const TermsConditionsWrapper: FC<Props> = ({ termsConditionsList, type }) => <QueryRequestProvider>
    <ListViewProvider>
        <TermsConditionsList termsConditionsList={termsConditionsList} type={type} />
    </ListViewProvider>
</QueryRequestProvider>

export { TermsConditionsWrapper }
