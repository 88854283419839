/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../../slices/components/componentsSlice"
import { showToast } from "../../../../components/custom-toasts/custom-toasts"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTCard, KTSVG } from "../../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { deleteTinkWebHookWithId, exportTinkWebHook, getTinkWebHook, postTinkWebHook, putTinkWebHook } from "../../../../slices/assetManagement/system/tinkWebHook/tinkWebHookSlice"
import CustomTextbox from "../../../../components/customTextbox/CustomTextbox"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import Styles from "../../../../../_metronic/assets/sass/pages/outCome.module.scss"
import moment from "moment"

interface Props {
    TinkWebHookWrapperListSlice: any,
    type: any
}

const TinkWebHookList: FC<Props> = ({ TinkWebHookWrapperListSlice, type }) => {
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [selectedRowId, setSelectedRowId] = useState(undefined)
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([])
    const [filterParams, setfilterParams] = useState<any>([])
    const { TinkWebHookListSlice } = useAppSelector((state) => state.tinkWebhook);
    const [resultScret, setResultScret] = useState("")

    const formik = useFormik({
        initialValues: {
            id: '',
            description: '',
            enabled_events: '',
            disabled: false,
            url: '',
        },
        validationSchema: Yup.object().shape({
            description: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(1000, 'Maximum 1000 symbols')
                .required('Description is required'),
            enabled_events: Yup.string()
                .required('Enabled Events are required'),
            url: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(1000, 'Maximum 1000 symbols')
                .required('URL is required'),
        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateWithId(values)
                }
                else {
                    createWithRequest(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    useEffect(() => {
        setdataTableisBusy(false)
    }, [TinkWebHookWrapperListSlice])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(undefined)
            formik.resetForm()
        }
    }, [modalIsOpen])


    useEffect(() => {
        TinkWebHookWithId(selectedRowId)
    }, [selectedRowId])


    //#region DataTable Methods

    const columns = useMemo(
        () => [
            {
                name: 'Description',
                selector: (row: any) => <div>{row?.description}</div>,
            },
            {
                name: 'Enabled Events',
                selector: (row: any) => <div style={{ whiteSpace: "pre" }}>{row?.enabled_events.join("\n")}</div>
            },
            {
                name: 'Is Disabled',
                selector: (row: any) => row?.disabled ?
                    <span className='badge badge-light-success fs-5 fw-semibold'>{row?.disabled.toString()}</span> :
                    <span className='badge badge-light-danger fs-5 fw-semibold'>{row?.disabled.toString()}</span>
                ,
            },
            {
                name: 'Date Created',
                label: "CreateDateUtc",
                selector: (row: any) => <div>{moment(row.created_at).format("MM/DD/YYYY")}</div>,
                width: "auto",
                sortable: true
            },
            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                            {
                                <>
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setprocessName("Insert")
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                    </div>
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setprocessName("Delete")
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                    </div>
                                </>
                            }

                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.name, "direction": sortDirection === "asc" ? 0 : 1 }])
    };
    useEffect(() => {
        setdataTableisBusy(true)
        dispatch(getTinkWebHook({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, filterParams])


    //#endregion DataTable Methods End

    function TinkWebHookWithId(rowId: any) {
        if (rowId !== undefined) {
            let sliceList = TinkWebHookListSlice;
            let Webhook = sliceList.find((x: any) => x.id === rowId)
            formik.setValues({
                id: Webhook?.id,
                description: Webhook?.description,
                enabled_events: Webhook?.enabled_events.join(),
                disabled: Webhook?.disabled,
                url: Webhook?.url,
            })
        }
    }

    function updateWithId(request: any) {
        let req = {
            id: request.id,
            description: request.description,
            enabled_events: request.enabled_events.split(","),
            disabled: request.disabled,
            url: request.url,
        }
        dispatch(putTinkWebHook(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getTinkWebHook({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
            }
            else {
                //showToast.error(response?.payload?.response_exception?.exception_message) Api tarafından gelen response içeriği genel ile aynı olamdıgı için kontrol edilecek
                showToast.error("Bad Request")
            }
        })
    }

    function deleteWithId() {
        if (selectedRowId !== undefined) {
            dispatch(deleteTinkWebHookWithId(selectedRowId)).then((s: any) => {
                if (s?.payload?.is_success) {
                    dispatch(getTinkWebHook({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.success("Item successfully deleted")
                    dispatch(componentsSlice.actions.closeModal({}))
                }
                else {
                    dispatch(getTinkWebHook({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.error(s?.payload?.response_exception?.exception_message)
                    dispatch(componentsSlice.actions.closeModal({}))
                }
            })
        }
    }

    function createWithRequest(request: any) {
        let req = {
            description: request.description,
            enabled_events: request.enabled_events.split(","),
            disabled: request.disabled,
            url: request.url,
        }
        dispatch(postTinkWebHook(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getTinkWebHook({ page_index: 0, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
                setprocessName("ShowScret")
                setResultScret(response?.payload?.result?.secret)
                dispatch(componentsSlice.actions.openModal({}))
            }
            else {
                //showToast.error(response?.payload?.response_exception?.exception_message) Api tarafından gelen response içeriği genel ile aynı olamdıgı için kontrol edilecek
                showToast.error("Bad Request")
            }
        })
    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Update Tink Webhook" : "Create Tink Webhook"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Description</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('description')}
                                            onChange={() => null}
                                            placeHolder={"Type a Description"}
                                            name='description'
                                            value={formik.values.description}
                                            formik={formik}
                                            formikName={"description"}
                                            type='text'
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <div className="row d-flex justify-content-start">
                                            <label className='required fw-bold fs-6 mb-2 col-auto'>Enabled Events</label>
                                            <div className={`col-auto ${Styles.tooltip} px-0`}>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                <span className={Styles.tooltiptext}>Use "comma(,)" separator for each event</span>
                                            </div>
                                        </div>
                                        <CustomTextbox
                                            {...formik.getFieldProps('enabled_events')}
                                            onChange={() => null}
                                            placeHolder={"Type Enabled Events"}
                                            name='enabled_events'
                                            value={formik.values.enabled_events}
                                            formik={formik}
                                            formikName={"enabled_events"}
                                            type='text'
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='fw-bold fs-6 mb-2 required'>URL</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('url')}
                                            onChange={() => null}
                                            placeHolder={"Type an URL"}
                                            name='url'
                                            value={formik.values.url}
                                            formik={formik}
                                            formikName={"url"}
                                            type='text'
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <div className='form-check form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value=''
                                                id='kt_settings_notification_phone'
                                                defaultChecked={formik.values.disabled}
                                                checked={formik.values.disabled}
                                                onChange={(event) =>
                                                    formik?.setValues({
                                                        ...formik?.values,
                                                        disabled: event.target.checked
                                                    })
                                                }
                                            />
                                            <label
                                                className='form-check-label ps-2'
                                                htmlFor='kt_settings_notification_phone'
                                            >
                                                Is Disabled
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteWithId()
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            case "ShowScret":
                return <>
                    <CustomModal title="Secret warning">
                        <>
                            <div
                                className='d-flex flex-column scroll-y me-n7 pe-7'
                                id='kt_modal_add_user_scroll'
                                data-kt-scroll='true'
                                data-kt-scroll-activate='{default: false, lg: true}'
                                data-kt-scroll-max-height='auto'
                                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                data-kt-scroll-offset='300px'
                            >
                                <div className="row d-flex justify-content-center">
                                    <div className="col fs-2 fw-bold text-danger">
                                        {"You should change wallet secret with this secret."}
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center mt-3">
                                    <div className="col">
                                        {resultScret}
                                    </div>
                                </div>
                            </div>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Close
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => { navigator.clipboard.writeText(resultScret) }}
                                >
                                    <span className='indicator-label'>Copy</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>


            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={TinkWebHookWrapperListSlice !== null ? TinkWebHookWrapperListSlice : []}
                    title={"Tink Webhook List"}
                    pagination
                    paginationServer
                    paginationTotalRows={TinkWebHookWrapperListSlice?.total_count}
                    isBusy={dataTableisBusy}
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    onExport={() => {
                        dispatch(exportTinkWebHook({ page_index: 0, page_size: 0, filters: filterParams, sort_filters: sortData }));

                    }}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    showTitle
                    showFilter={false}
                    searchButtonClickEvent={() => setfilterParams(filterQuery)}
                    sortHandle={handleSort}
                    clearButtonClickEvent={() => {
                        setfilterParams([])
                        setfilterQuery([])
                    }}
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const TinkWebHookWrapper: FC<Props> = ({ TinkWebHookWrapperListSlice, type }) => <QueryRequestProvider>
    <ListViewProvider>
        <TinkWebHookList TinkWebHookWrapperListSlice={TinkWebHookWrapperListSlice} type={type} />
    </ListViewProvider>
</QueryRequestProvider>

export { TinkWebHookWrapper }
