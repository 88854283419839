import { createSlice } from "@reduxjs/toolkit";


interface InitialStateInterface {
    modalIsOpen: boolean | undefined;
}

const initialState = {
    modalIsOpen: undefined,
} as InitialStateInterface;


const componentsSlice = createSlice({
    name: "componentsSlice",
    initialState,
    reducers: {
        openModal: (state, action) => {
            state.modalIsOpen = true
        },
        closeModal: (state, action) => {
            state.modalIsOpen = undefined
        }
    },
    extraReducers: (builder) => {
    },
});

export default componentsSlice;
