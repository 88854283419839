import { HttpMethods, callApi } from "../../utils/api-util";
import { ResponseType } from "../../utils/enums/ResponseType.enum";


const getUseCaseService = async (req: any) => {
    let _options = {
        controller: "/sat-content/usecase-list",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}

const exportUseCases = async (req: any) => {
    let _options = {
        controller: "/sat-content/usecase-list-export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}
const exportPrepareMeetingList = async (req: any) => {
    let _options = {
        controller: "/sat-content/export-prepare-list",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}

const exportProductList = async (req: any) => {
    let _options = {
        controller: "/sat-content/export-product-list",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}

const exportContractList = async (req: any) => {
    let _options = {
        controller: "/sat-content/export-contract-list",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}




const postUseCaseService = async (req: any) => {
    let _options = {
        controller: "/sat-content/usecases",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const getProductService = async (req: any) => {
    let _options = {
        controller: "/sat-content/product-list",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}

const postProductService = async (req: any) => {
    let _options = {
        controller: "/sat-content/products",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};


const postProductFBService = async (req: any) => {
    let _options = {
        controller: "/sat-content/products/fb",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const postProductVideoService = async (req: any) => {
    let _options = {
        controller: "/sat-content/products/video",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const postProductTsService = async (req: any) => {
    let _options = {
        controller: "/sat-content/products/ts",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const getProductWithIdService = async (req:any) => {
    let _options = {
        controller: "/sat-content/product/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const getContractService = async (req: any) => {
    let _options = {
        controller: "/sat-content/contract-list",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}

const postContractService = async (req: any) => {
    let _options = {
        controller: "/sat-content/contracts",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const getPrepareService = async (req: any) => {
    let _options = {
        controller: "/sat-content/prepare-list",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}

const postPrepareService = async (req: any) => {
    let _options = {
        controller: "/sat-content/prepare",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const postPrepareDetailService = async (req: any) => {
    let _options = {
        controller: "/sat-content/prepare-detail",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteUseCaseService = async (req: any) => {
    let _options = {
        controller: "/sat-content/usecases",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const deletePrepareService = async (req: any) => {
    let _options = {
        controller: "/sat-content/prepare",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const deletePrepareDetailService = async (req: any) => {
    let _options = {
        controller: "/sat-content/prepare-detail",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const deleteContractService = async (req: any) => {
    let _options = {
        controller: "/sat-content/contracts",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const deleteProductService = async (req: any) => {
    let _options = {
        controller: "/sat-content/products",
        method: HttpMethods.DELETE,
        data: { product_id: req }
    };
    return await callApi(_options);
};

const deleteProductFBService = async (req: any) => {
    let _options = {
        controller: "/sat-content/products/fb",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const deleteProductTSService = async (req: any) => {
    let _options = {
        controller: "/sat-content/products/ts",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const deleteProductVideoService = async (req: any) => {
    let _options = {
        controller: "/sat-content/products/video",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const SalesAssistantService = {
    getUseCaseService,
    postUseCaseService,
    getContractService,
    postContractService,
    getPrepareService,
    postPrepareService,
    postPrepareDetailService,
    postProductService,
    getProductService,
    getProductWithIdService,
    postProductFBService,
    postProductVideoService,
    postProductTsService,
    deleteUseCaseService,
    deletePrepareService,
    deletePrepareDetailService,
    deleteContractService,
    deleteProductService,
    deleteProductFBService,
    deleteProductTSService,
    deleteProductVideoService,
    exportUseCases,
    exportPrepareMeetingList,
    exportProductList,
    exportContractList
};

export default SalesAssistantService;
