/* eslint-disable react-hooks/exhaustive-deps */
// import { useEffect } from 'react'
// import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
// import { getCalculationMethods, getShoutback } from '../../slices/dataSourceIntegrationSlice'
// import { AppDispatch, useAppSelector } from '../../store/store'
import { CalculationMethodsListWrapper } from './CalculationMethodsList'

const CalculationMethodsBreadcrumbs: Array<PageLink> = [
    {
        title: `Credential Managament`,
        path: '/CalculationMethods/CalculationMethods',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CalculationMethods = () => {

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={CalculationMethodsBreadcrumbs}>{`Calculation Methods`}</PageTitle>
                            <CalculationMethodsListWrapper calculationMethodsList={
                                [
                                    { "name": "Lifetime Value", "detail": "Vodafone, EE, H3G", "status": "Active" },
                                    { "name": "Avarage Monthly Spend", "detail": "Between Values, Equal to or grather than, Equal to or less than ", "status": "Active"  },
                                    { "name": "Monthly Spend Rate", "detail": "Between Values, Equal to or grather than, Equal to or less than ", "status": "Active"  },
                                    { "name": "Is Over 18", "detail": "Vodafone, EE, H3G", "status": "Active" },
                                    { "name": "Account Balance", "detail": "Vodafone, EE, H3G", "status": "Active" },
                                    { "name": "Merchant", "detail": "Vodafone, EE, H3G", "status": "Active" },
                                    { "name": "Merchant First Bill Date", "detail": "Between Values, Equal to or grather than, Equal to or less than ", },
                                    { "name": "Score", "detail": "Between Values, Equal to or grather than, Equal to or less than ", "status": "Active" },
                                    { "name": "History", "detail": "Between Values, Equal to or grather than, Equal to or less than ", "status": "Active" },
                                    { "name": "Last Payment Date", "detail": "Between Values, Equal to or grather than, Equal to or less than ", },
                                ]
                                } />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default CalculationMethods
