/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../../store/store'
import {
  getHelperWithId,
  postHelper,
  putHelper,
} from '../../../../slices/assetManagement/console/HelperSlice'
import { isNotEmpty, KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { showToast } from '../../../../components/custom-toasts/custom-toasts'
import CustomSelect from '../../../../components/customModal/CustomSelect'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { uploadPicture } from '../../../../utils/upload-image-file'
import { projectList } from './HelperList'
import CustomTextbox from '../../../../components/customTextbox/CustomTextbox'

const CreateHelperContentBreadcrumbs: Array<PageLink> = [
  {
    title: `User Manual`,
    path: '/Helper/List',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function CreateHelper() {
  const dispatch: AppDispatch = useAppDispatch()
  const { selectedId } = useAppSelector((state) => state.helper)
  const [filteredProjectList, setFilteredProjectList] = useState([] as any)
  const navigate = useNavigate()

  const quillRef = React.useRef<ReactQuill>(null)

  const formik = useFormik({
    initialValues: {
      id: '',
      helperPageUrl: '',
      name: '',
      projectName: undefined,
      content: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(100, 'Maximum 100 symbols')
        .required('Page Title is required'),
      helperPageUrl: Yup.string()
        .max(400, 'Maximum 400 symbols')
        .required('Page Url is required'),
      content: Yup.string()
        .min(1, 'Minimum 1 symbols')
        .required('Content is required')
        .nullable(false),
      projectName: Yup.object().required('Project is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        if (isNotEmpty(values.id)) {
          updateWithId(values)
        } else {
          createWithRequest(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
    
      }
    },
  })  

  useEffect(() => {
    if (isNotEmpty(selectedId) && selectedId !== undefined) {
      dispatch(getHelperWithId(selectedId)).then((res) => {
        let helper = res.payload?.result?.helper
        if (helper !== undefined && helper !== null) {
          setFilteredProjectList(projectList.filter(x => x.value === helper.project_name))
          formik.setValues({
            id: helper.id,
            name: helper?.name,
            helperPageUrl: helper?.helper_url,
            content: helper.content,
            projectName: projectList.find(x => x.value === helper.project_name) as any
          })
        }
      })
    } else {
      setFilteredProjectList(projectList)
    }
  }, [])

  function updateWithId(request: any) {
    let req = {
      id: selectedId,
      helper_url: request.helperPageUrl,
      name: request.name,
      content: request?.content,
      project_name: request?.projectName?.value
    }
    dispatch(putHelper(req)).then((response) => {
      if (response?.payload?.is_success) {
        navigate('/Helper/List')
        showToast.success('Item Updated Successfully')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function createWithRequest(request: any) {
    let content = request.content as string
    content = content.replaceAll('<p>', '')
    content = content.replaceAll('</p>', '')
    content = content.replaceAll('</br>', '')
    content = content.replaceAll('<br>', '')
    content = content.replaceAll(' ', '')
    if (content === '') {
      showToast.error('Content is required.')
    } else {
      let req = {
        project_name: request?.projectName?.value,
        name: request?.name,
        helper_url: request.helperPageUrl,
        content: request.content,
      }
      dispatch(postHelper(req)).then((response) => {
        if (response?.payload?.is_success) {
          showToast.success('Item Saved Successfully')
          navigate('/Helper/List')
        } else {
          showToast.error(response?.payload?.response_exception?.exception_message)
        }
      })
    }
  }

  const imageHandler = () => {
    if (quillRef && quillRef.current) {
      const quill = quillRef.current
      const editor = quill.getEditor()

      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        if (input && input.files) {
          const file = input.files[0]
          dispatch(uploadPicture(file)).then((url: any) => {
            try {
              if (editor && editor.getSelection() != null) {
                editor.insertEmbed(editor.getSelection()!.index, 'image', url?.payload?.result?.url)
              }
            } catch (error) { }
          })
        }
      }
    }
  }

  let modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }, 'bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
          [{ color: [] }, { background: [] }, 'link', 'image'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  )

  return (
    <>
      <PageTitle breadcrumbs={CreateHelperContentBreadcrumbs}>
        {selectedId ? `Update User Manual` : `Create User Manual`}
      </PageTitle>
      <KTCard>
        <KTCardBody>
          <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
            <div>
              <div className='row'>
                <div className='col-6'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Project</label>
                    <CustomSelect
                      onChange={() => null}
                      data={filteredProjectList}
                      isMulti={false}
                      selectedValue={formik?.values?.projectName}
                      formik={formik}
                      formikName={'projectName'}
                    ></CustomSelect>
                  </div>
                  <hr></hr>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Page Url</label>
                    <CustomTextbox
                      {...formik.getFieldProps('helperPageUrl')}
                      onChange={() => null}
                      placeHolder={"{env}/Helper/CreateHelper"}
                      name='helperPageUrl'
                      value={formik.values.helperPageUrl}
                      formik={formik}
                      formikName={"helperPageUrl"}
                    ></CustomTextbox>

                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Page Title</label>
                    <CustomTextbox
                      {...formik.getFieldProps('name')}
                      onChange={() => null}
                      placeHolder={"Page Title"}
                      name='name'
                      value={formik.values.name}
                      formik={formik}
                      formikName={"name"}
                    ></CustomTextbox>
                  </div>
                  <div className='fv-col pb-4'>
                    <label className='required fw-bold fs-6 mb-2'>Content</label>
                    <div className='row'>
                      {formik?.touched?.content && formik?.errors?.content && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik?.errors?.content}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='row'>
                      <ReactQuill
                        theme='snow'
                        ref={quillRef}
                        modules={modules}
                        preserveWhitespace
                        value={formik?.values?.content}
                        onChange={(i: any) => {
                          formik.setFieldValue('content', i)
                        }}
                        style={{ minHeight: "20rem", width:"100%"}}
                      />
                    </div>
                    <div className='row' style={{ marginTop: '90px' }}>
                      <div className='col'>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-users-modal-action='submit'
                        >
                          <span className='indicator-label'>Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </KTCardBody>
      </KTCard>
    </>
  )
}

export default CreateHelper
