import { callApi, HttpMethods } from "../../utils/api-util";

const getActiveCountsService = async () => {
    let _options = {
        controller: "/dashboard/active-counts",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}
const getFrpDataService = async () => {
    let _options = {
        controller: "/dashboard/frp",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}

const getHealthyStatusService = async () => {
    let _options = {
        controller: "/health?level=readiness",
        method: HttpMethods.GET,
        forHealty: true
    };
    const result = await callApi(_options);
    return result;
}

const getHybridHealthyStatusService = async () => {
    let _options = {
        controller: "/dashboard/health",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}

const DashboardService = {
    getActiveCountsService,
    getFrpDataService,
    getHealthyStatusService,
    getHybridHealthyStatusService
};

export default DashboardService;
