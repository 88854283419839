import Select, { GetOptionLabel } from 'react-select';

interface CustomProps {
    data?: any[];
    onChange: Function;
    defaultValue?: any[];
    isMulti?: boolean;
    selectedValue?: any;
    formik?: any;
    formikName?: any;
    disable?: boolean;
    getOptionLabel?: GetOptionLabel<any>;
    getOptionValue?: GetOptionLabel<any>;
}

const CustomSelect: React.FC<CustomProps> = ({
    data,
    onChange,
    defaultValue,
    isMulti,
    selectedValue,
    formik,
    formikName,
    disable,
    getOptionLabel,
    getOptionValue
}) => {
    const handleChange = (event: any) => {
        if (!isMulti) {
            onChange(event)
            formik?.setValues({
                ...formik?.values,
                [formikName]: data?.find((item) => item.value === event?.value)
            })
        }
        else {
            onChange(event)
            formik?.setValues({
                ...formik?.values,
                [formikName]: event
            })
        }
    };
    
    return (
        <>
            <Select
                defaultValue={defaultValue}
                onChange={handleChange}
                isMulti={isMulti}
                name="reactSelect"
                options={data}
                value={selectedValue}
                isDisabled={disable}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
            />
            {formik?.touched?.[formikName] && formik?.errors?.[formikName] && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert'>{formik?.errors?.[formikName]}</span>
                    </div>
                </div>
            )}
        </>

    )
}

export default CustomSelect