/* eslint-disable react-hooks/exhaustive-deps */
import {useFormik} from 'formik'
import React, {useEffect, useMemo, useState} from 'react'
import {KTCard, KTCardBody, KTSVG} from '../../../../_metronic/helpers'
import {PageLink} from '../../../../_metronic/layout/core'
import * as Yup from 'yup'
import {Tab, Tabs} from 'react-bootstrap'
import {AppDispatch, useAppDispatch, useAppSelector} from '../../../store/store'
import {useNavigate} from 'react-router-dom'
import {showToast} from '../../../components/custom-toasts/custom-toasts'
import clsx from 'clsx'
import ReactQuill from 'react-quill'
import componentsSlice from '../../../slices/components/componentsSlice'
import {CustomModal} from '../../../components/customModal/CustomModal'
import {deletePrepareDetailWithId, postPrepare, postPrepareDetail} from '../../../slices/salesAssistant/prepare/prepareSlice'
import CustomDatatable from '../../../components/customDatatable/CustomDatatable'
import CustomTextbox from '../../../components/customTextbox/CustomTextbox'

const PrepareContentBreadcrumbs: Array<PageLink> = [
  {
    title: `Sales Asistant Management`,
    path: '/PrepareMeeting/PrepareDetail',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function PrepareDetail() {
  const dispatch: AppDispatch = useAppDispatch()
  const {selectedPrepare} = useAppSelector((state) => state.prepare)
  const navigate = useNavigate()
  const [detailList, setDetailList] = useState<any>([])
  const [processName, setprocessName] = useState('Insert')
  const {modalIsOpen} = useAppSelector((state) => state.components)
  const [contentTitle, setContentTitle] = useState('')
  const [contentText, setContentText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRowIndex, setselectedRowIndex] = useState<number>()
  const [selectedRowId, setselectedRowId] = useState('')

  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [filterQuery, setfilterQuery] = useState<any>([])
  const [sortData, setsortData] = useState<any>([])
  const [filterParams, setfilterParams] = useState<any>([])
  const [dataTableisBusy, setdataTableisBusy] = useState(false)

  useEffect(() => {
    setdataTableisBusy(false)
    //setDetailList([])
  }, [detailList])

  const columns = useMemo(
    () => [
      {
        name: 'Title',
        selector: (row: any) => <div>{row?.title}</div>,
        width: "auto",
      },
      {
        name: 'Content', 
        selector: (row: any) => <div>{row?.content}</div>,
        width: "750px",
      },
      {
        name: 'Action',
        selector: (row: any, index: number) => (
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {
                <>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      handleRowSelect(row, index)
                      setselectedRowId(row.id)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </div>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                        setprocessName('Delete')
                        setselectedRowId(row.id)
                        setselectedRowIndex(index)
                        dispatch(componentsSlice.actions.openModal({}))
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </div>
                </>
              }
            </div>
          </>
        ),
        width: '100px',
      },
    ],
    []
  )

  const handlePageChange = (page: any) => {
    setdataTableisBusy(true)
    setPage(page - 1)
    setdataTableisBusy(false)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setdataTableisBusy(true)
    setPerPage(newPerPage)
    setdataTableisBusy(false)
  }
  const handleSort = async (column: any, sortDirection: any) => {
    setsortData([{property: column?.name, direction: sortDirection === 'asc' ? 0 : 1}])
  }

  const handleRowSelect = (row: any, index:any) => {
    setContentTitle(row.title)
    setContentText(row.content)
    setselectedRowIndex(index)
    setprocessName('Content')
    dispatch(componentsSlice.actions.openModal({}))
  }

  const quillRef = React.useRef<ReactQuill>(null)
  let modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{header: [1, 2, false]}, 'bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}, {align: []}],
          [{color: []}, {background: []}, 'link'],
        ],
      },
    }),
    []
  )

  const formik = useFormik({
    initialValues: {
      id: '',
      title: '',
      content: '',
      detail: [{title: '', content: ''}],
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().min(1, 'Minimum 1 symbols').required('Title is required'),
    }),
    onSubmit: async (values, actions) => {
      try {
        await createWithRequest(values)
      } catch (ex) {
        console.error(ex)
        navigate('/PrepareMeeting/List')
        showToast.error('Something Went Wrong!')
      } finally {
        setIsLoading(false)
        actions.resetForm()
        //dispatch(componentsSlice.actions.closeModal({}))
      }
    },
  })

  useEffect(() => {
    if (selectedPrepare != null) {
      PrepareWithId(selectedPrepare)
    } else {
      formik.resetForm()
    }
  }, [selectedPrepare])

  function PrepareWithId(data: any) {
    setDetailList([])
    if (data !== undefined) {
      formik.setValues({
        id: data.id,
        title: data.title,
        content: data.content,
        detail: data.detail,
      })
      if (data.detail != undefined) {
        data.detail.forEach((item: any) => {
          addDetailItem(item.id, item.title, item.content)
        })
      }
    }
  }

  async function createWithRequest(request: any) {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    let req = {
      prepare_meeting: {
        id: request.id !== null ? request.id : undefined,
        title: request.title,
        content: request.content,
        detail: detailList,
      },
    }
    try {
      const response = await dispatch(postPrepare(req)) // Await dispatch action
      if (response?.payload?.is_success) {
        showToast.success('Item Saved Successfully')
        navigate('/PrepareMeeting/List')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    } catch (error) {
      showToast.error('Something Went Wrong!')
      console.error(error)
    }
  }

  async function updateDetailRequest() {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    let req = {
      prepare_meeting_detail: {
        id: selectedRowId,
        title: contentTitle,
        content: contentText,
      },
    }
    try {
      const response = await dispatch(postPrepareDetail(req)) // Await dispatch action
      if (response?.payload?.is_success) {
        showToast.success('Item Saved Successfully')
        setIsLoading(false);
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
        setIsLoading(false);
      }
    } catch (error) {
      showToast.error('Something Went Wrong!')
      console.error(error)
    }
  }

  function deleteWithId() {
    if (selectedRowId !== undefined || selectedRowId !== '') {
      dispatch(deletePrepareDetailWithId(selectedRowId)).then((s: any) => {
        if (s?.payload?.is_success) {
          detailList.splice(selectedRowIndex, 1);
          showToast.success('Item Deleted Successfully')
        } else {
          showToast.error(s?.payload?.response_exception?.exception_message || "Error")
        }
        dispatch(componentsSlice.actions.closeModal({}))
      })
    }
  }

  function addDetailItem(id?: string, title?: string, content?: string) {
    const newDetail = {
      id: id ? id ?? '' : '',
      title: id ? title ?? '' : '',
      content: id ? content ?? '' : '',
    }
    if (id != null) {
      setDetailList((prevList: any) => [...prevList, newDetail])
    } else {
      formik.setFieldValue('detail', [...(formik.values.detail || []), newDetail])
    }
  }

  function findProcessScreen(processName: string) {
    switch (processName) {
      case 'Content':
        return (
          <>
            <CustomModal title='Add Tab Content'>
              <>
                <div className='d-flex flex-column align-items-center'>
                  <div className='fv-row mb-7 w-100 mx-2'>
                    <label className='required fw-bold fs-6 mb-2'>Content Title</label>
                    <input
                      placeholder='Type a Content Title'
                      type='text'
                      name={`detailTitle`}
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      autoComplete='off'
                      value={contentTitle.trimStart()}
                      onChange={(e) => {
                        setContentTitle(e.target.value)
                      }}
                    />
                  </div>
                  <div className='fv-row mb-7 w-100 mx-2'>
                    <label className='required fw-bold fs-6 mb-3 '>Content Text</label>
                    <textarea
                      className='form-control form-control-solid mb-3'
                      rows={4}
                      data-kt-element='input'
                      placeholder='Type a Text'
                      value={contentText.trimStart()}
                      onChange={(e) => setContentText(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => {
                      dispatch(componentsSlice.actions.closeModal({}))
                    }}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    onClick={() => {
                      if (selectedRowIndex !== undefined) {
                        const updatedDetailList = detailList.map((item: any, index: any) =>
                          index === selectedRowIndex
                            ? {...item, title: contentTitle, content: contentText, id:item.id}
                            : item
                        )
                        setDetailList(updatedDetailList)
                        setselectedRowIndex(undefined)
                        dispatch(componentsSlice.actions.closeModal({}))
                        updateDetailRequest();
                        //add update detail function here
                      } else {
                        const newDetail = {title: contentTitle, content: contentText}
                        setDetailList([...detailList, newDetail])
                        dispatch(componentsSlice.actions.closeModal({}))
                      }
                    }}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className='spinner-border spinner-border-sm me-2'
                          role='status'
                          aria-hidden='true'
                        ></span>
                        <span className='visually-hidden'>Loading...</span>
                      </>
                    ) : (
                      <span className='indicator-label'>Submit</span>
                    )}{' '}
                  </button>
                </div>
                {/* end::Actions */}
              </>
            </CustomModal>
          </>
        )
        case 'Delete':
        return (
          <>
            <CustomModal title='Are You Sure?'>
              <>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => {
                      dispatch(componentsSlice.actions.closeModal({}))
                    }}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    onClick={() => {
                        deleteWithId()
                    }}
                  >
                    <span className='indicator-label'>Delete</span>
                  </button>
                </div>
                {/* end::Actions */}
              </>
            </CustomModal>
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <KTCard>
        <KTCardBody>
          <>
            <Tabs defaultActiveKey='pfmTab' id='tab' className='mb-3'>
              <Tab eventKey='pfmTab' title='Tab General'>
                <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                  <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                  >
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Tab Title</label>
                      <CustomTextbox
                      {...formik.getFieldProps('title')}
                      onChange={() => null}
                      placeHolder={'Type a Title'}
                      name='title'
                      value={formik.values.title}
                      formik={formik}
                      formikName={'title'}
                      ></CustomTextbox>
                    </div>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Tab Content</label>
                      <div className='row mb-15'>
                        <ReactQuill
                          theme='snow'
                          ref={quillRef}
                          modules={modules}
                          className='h-200px'
                          preserveWhitespace
                          value={formik?.values?.content}
                          onChange={(i: any) => {
                            formik.setFieldValue('content', i)
                          }}
                        />
                      </div>
                      {formik.touched.content && formik.errors.content && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.content}</span>
                          </div>
                        </div>
                      )}
                      <hr className='hr' />
                    </div>
                  </div>
                  {/* begin::Actions */}
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      onClick={() => {
                        formik.resetForm()
                        navigate('/PrepareMeeting/List')
                        //setDetailList([])
                        //dispatch(componentsSlice.actions.closeModal({}))
                      }}
                      className='btn btn-light me-3'
                      data-kt-users-modal-action='cancel'
                    >
                      Discard
                    </button>

                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                    >
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </div>
                  {/* end::Actions */}
                </form>
              </Tab>
              <Tab eventKey='content' title='Tab Content'>
                <CustomDatatable
                  columns={columns}
                  data={detailList}
                  title={'Prepare For Meeting List'}
                  pagination
                  paginationServer
                  paginationTotalRows={detailList?.total_count}
                  isBusy={dataTableisBusy}
                  isNewItem
                  isNewItemString={'New Item'}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  showTitle
                  //showFilter={true}
                  //filterOptions={customFilter}
                  searchButtonClickEvent={() => setfilterParams(filterQuery)}
                  sortHandle={handleSort}
                  clearButtonClickEvent={() => {
                    setfilterParams([])
                    setfilterQuery([])
                  }}
                  newItemClickEvent={() => {
                    setContentTitle('')
                    setContentText('')
                    addDetailItem()
                    setprocessName('Content')
                    dispatch(componentsSlice.actions.openModal({}))
                  }}
                ></CustomDatatable>
              </Tab>
            </Tabs>
          </>
        </KTCardBody>
      </KTCard>
      {modalIsOpen !== undefined ? findProcessScreen(processName) : null}
    </>
  )
}

export default PrepareDetail
