
import { callApi, HttpMethods } from "../../utils/api-util";
import { ResponseType } from "../../utils/enums/ResponseType.enum";

const getFrpPurchaseRateWithIdService = async (req:any) => {
    let _options = {
        controller: "/frp-price-country?country="+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const exportFrpTransactionService = async (req:any) => {
    let _options = {
        controller: "/frp-price-country/export?country="+ req,
        method: HttpMethods.GET,
        responseType: ResponseType.File

    };
    const result = await callApi(_options);
    return result;
};

const postFrpPurchaseRateService = async (req: any) => {
    let _options = {
        controller: "/frp-price-country",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putFrpPurchaseRateService = async (req: any) => {
    let _options = {
        controller: "/frp-price-country",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};



const postFrpPriceService = async (req: any) => {
    let _options = {
        controller: "/frp-price-country/frp-price",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putFrpPriceService = async (req: any) => {
    let _options = {
        controller: "/frp-price-country/frp-price",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteFrpPriceService = async (req: any) => {
    let _options = {
        controller: "/frp-price-country/frp-price",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const FrpPurchaseRateService = {
    getFrpPurchaseRateWithIdService,
    exportFrpTransactionService,
    postFrpPurchaseRateService,
    putFrpPurchaseRateService,
    postFrpPriceService,
    putFrpPriceService,
    deleteFrpPriceService
};

export default FrpPurchaseRateService;
