
import { callApi, HttpMethods } from "../../../utils/api-util";
import { ResponseType } from "../../../utils/enums/ResponseType.enum";

const getCredentialsService = async (req: any) => {
    let _options = {
        controller: "/credential/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
};


const exportCredentialsService = async (req: any) => {
    let _options = {
        controller: "/credential/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
};
const getCalculationFormula = async () => {
    let _options = {
        controller: "/calculation-formula",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const postCredentialsService = async (req: any) => {
    let _options = {
        controller: "/credential",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putCredentialsService = async (req: any) => {
    let _options = {
        controller: "/credential",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteCredentialsService = async (req: any) => {
    let _options = {
        controller: "/credential",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getCredentialsWithIdService = async (req:any) => {
    let _options = {
        controller: "/credential/get-credential-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const getSchemaJsonWithReqService = async (req:any) => {
    let _options = {
        controller: "/credential/get-json",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const saveSchemaJson = async () => {
    let _options = {
        controller: "/credential/save-json-ld",
        method: HttpMethods.POST,
    };
    return await callApi(_options);
};

const publishCDS = async () => {
    let _options = {
        controller: "/credential/publish-cds",
        method: HttpMethods.POST,
    };
    return await callApi(_options);
};

const getCategoryOfTinkService = async () => {
    let _options = {
        controller: "/tink-category",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const CredentialsService = {
    getCredentialsService,
    exportCredentialsService,
    putCredentialsService,
    getCredentialsWithIdService,
    postCredentialsService,
    deleteCredentialsService,
    getSchemaJsonWithReqService,
    saveSchemaJson,
    publishCDS,
    getCalculationFormula,
    getCategoryOfTinkService
};

export default CredentialsService;
