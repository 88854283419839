/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useMemo, useState} from 'react'
import CustomDatatable from '../../../components/customDatatable/CustomDatatable'
import {CustomModal} from '../../../components/customModal/CustomModal'
import {AppDispatch, useAppSelector} from '../../../store/store'
import {useDispatch} from 'react-redux'
import componentsSlice from '../../../slices/components/componentsSlice'
import {showToast} from '../../../components/custom-toasts/custom-toasts'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import {QueryRequestProvider} from '../../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import {ListViewProvider} from '../../../modules/apps/user-management/users-list/core/ListViewProvider'
import PrepareSlice, {deletePrepareWithId, exportPrepareMeetingList, getPrepare, postPrepare} from '../../../slices/salesAssistant/prepare/prepareSlice'
import {Tab, Tabs} from 'react-bootstrap'
import ReactQuill from 'react-quill'
import { useNavigate } from 'react-router-dom'

interface Props {
  PrepareList: any
}

const PreparesList: FC<Props> = ({PrepareList}) => {
  const navigate = useNavigate();
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const {modalIsOpen} = useAppSelector((state) => state.components)
  const dispatch: AppDispatch = useDispatch()
  const [processName, setprocessName] = useState('Insert')
  const [selectedRowId, setSelectedRowId] = useState(undefined)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [filterQuery, setfilterQuery] = useState<any>([])
  const [sortData, setsortData] = useState<any>([])
  const [filterParams, setfilterParams] = useState<any>([])
  const [detailList, setDetailList] = useState<any>([])
  const [contentTitle, setContentTitle] = useState('')
  const [contentText, setContentText] = useState('')
  const [defaultActiveKey, setDefaultActiveKey] = useState("pfmTab");

  const quillRef = React.useRef<ReactQuill>(null)
  let modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{header: [1, 2, false]}, 'bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}, {align: []}],
          [{color: []}, {background: []}, 'link'],
        ],
      },
    }),
    []
  )

  const formik = useFormik({
    initialValues: {
      id: '',
      title: '',
      content: '',
      detail: [{title: '', content: ''}],
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().min(1, 'Minimum 1 symbols').required('Title is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        createWithRequest(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm()
      }
    },
  })

  useEffect(() => {
    setdataTableisBusy(false)
    setDetailList([])
  }, [PrepareList])

  useEffect(() => {
    if (modalIsOpen === undefined) {
      setprocessName('Insert')
      setSelectedRowId(undefined)
      setDetailList([]);
      setDefaultActiveKey("pfmTab");
      formik.resetForm()
    }
  }, [modalIsOpen])

  //#region DataTable Methods

  const columns = useMemo(
    () => [
      {
        name: 'Tab Title',
        selector: (row: any) => <div>{row?.title}</div>,
        width: 'auto',
      },
      {
        name: 'Action',
        selector: (row: any) => (
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {
                <>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      navigate("/PrepareMeeting/PrepareDetail")
                      dispatch(PrepareSlice.actions.changeSelectedPrepareId({ prepareData: row }))
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </div>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Delete')
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </div>
                </>
              }
            </div>
          </>
        ),
        width: '100px',
      },
    ],
    []
  )


  const handlePageChange = (page: any) => {
    setdataTableisBusy(true)
    setPage(page - 1)
    setdataTableisBusy(false)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setdataTableisBusy(true)
    setPerPage(newPerPage)
    setdataTableisBusy(false)
  }
  const handleSort = async (column: any, sortDirection: any) => {
    setsortData([{property: column?.name, direction: sortDirection === 'asc' ? 0 : 1}])
  }
  useEffect(() => {
    dispatch(
      getPrepare({
        page_index: page,
        page_size: perPage,
        filters: filterParams,
        sort_filters: sortData,
      })
    )
  }, [page, perPage, sortData, filterParams])

  //#endregion DataTable Methods End

  function PrepareWithId(data: any, process: string) {
    setDetailList([])
    if (data !== undefined) {
        formik.setValues({
          id: data.id,
          title: data.title,
          content: data.content,
          detail: data.detail,
        })
        data.detail.forEach((item:any)=>{
          addDetailItem(item.id,item.title,item.content);
        })
    }
  } 

  function addDetailItem(id?: string, title?:string,content?:string){
    const newDetail = {
      id: id ? id ?? "" : "",
      title: id ? title ?? "" : "",
      content: id ? content ?? "" : "",
    };
    if(id != null){
      setDetailList((prevList: any) => [...prevList, newDetail]);
    }else{
      formik.setFieldValue('detail', [...(formik.values.detail || []), newDetail]);
    }
  }

  function deleteWithId() {
    if (selectedRowId !== undefined) {
      dispatch(deletePrepareWithId(selectedRowId)).then((s: any) => {
        dispatch(
          getPrepare({
            page_index: page,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        if (s?.payload?.is_success) {
          showToast.success('Item Deleted Successfully')
        } else {
          showToast.error(s?.payload?.response_exception?.exception_message)
        }
        dispatch(componentsSlice.actions.closeModal({}))
      })
    }
  }

  function createWithRequest(request: any) {
    let req = {
      prepare_meeting: {
        id: request.id !== null ? request.id : undefined,
        title: request.title,
        content: request.content,
        detail: detailList,
      },
    }
    dispatch(postPrepare(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(
          getPrepare({
            page_index: 0,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        showToast.success('Item Saved Successfully')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function findProcessScreen(processName: string) {
    switch (processName) {
      case 'Insert':
        return (
          <>
            <CustomModal
              maxWidth={1100}
              title={selectedRowId !== undefined ? 'Update Message' : 'Create Tab'}
            >
              <>
                <Tabs defaultActiveKey={defaultActiveKey} id='tab' className='mb-3'>
                  <Tab eventKey='pfmTab' title='Tab General'>
                    <form
                      id='kt_modal_add_user_form'
                      className='form'
                      onSubmit={formik.handleSubmit}
                    >
                      <div
                        className='d-flex flex-column scroll-y me-n7 pe-7'
                        id='kt_modal_add_user_scroll'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                        data-kt-scroll-offset='300px'
                      >
                        <div className='fv-row mb-7'>
                          <label className='required fw-bold fs-6 mb-2'>Tab Title</label>
                          <input
                            placeholder='Type a Title'
                            type='text'
                            {...formik.getFieldProps('title')}
                            name='title'
                            className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                            autoComplete='off'
                          />
                          {formik.touched.title && formik.errors.title && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.title}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='fv-row mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Tab Content</label>
                          <div className='row mb-15'>
                            <ReactQuill
                              theme='snow'
                              ref={quillRef}
                              modules={modules}
                              className='h-200px'
                              preserveWhitespace
                              value={formik?.values?.content}
                              onChange={(i: any) => {
                                formik.setFieldValue('content', i)
                              }}
                            />
                          </div>
                          {formik.touched.content && formik.errors.content && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.content}</span>
                              </div>
                            </div>
                          )}
                          <hr className='hr' />
                        </div>
                      </div>
                      {/* begin::Actions */}
                      <div className='text-center pt-15'>
                        <button
                          type='reset'
                          onClick={() => {
                            setDetailList([])
                            dispatch(componentsSlice.actions.closeModal({}))
                          }}
                          className='btn btn-light me-3'
                          data-kt-users-modal-action='cancel'
                        >
                          Discard
                        </button>

                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-users-modal-action='submit'
                        >
                          <span className='indicator-label'>Submit</span>
                        </button>
                      </div>
                      {/* end::Actions */}
                    </form>
                  </Tab>
                  <Tab eventKey='content' title='Tab Content'>
                    {detailList.map((detailItem: any, index: any) => (
                      <div className='mt-2' key={detailItem?.id}>
                        <div className='d-flex flex-stack'>
                          <div className={`list-group-item list-group-item-action my-1`}>
                            <div className='d-flex'>
                              <div className='d-flex flex-column'>
                                <div className='fs-5 text-dark fw-bolder'>{detailItem.title}</div>
                                <div className='fs-6 fw-bold text-gray-400'>
                                  {detailItem.content}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='separator separator-dashed my-2'></div>
                      </div>
                    ))}
                    <div className='fv-row mb-7'>
                      <button
                        className='btn btn-primary'
                        type='button'
                        onClick={() => {
                          addDetailItem()
                          setprocessName('Content')
                          dispatch(componentsSlice.actions.openModal({}))
                        }}
                      >
                        <span className='indicator-label'>Add</span>
                      </button>
                    </div>
                  </Tab>
                </Tabs>
              </>
            </CustomModal>
          </>
        )

      case 'Delete':
        return (
          <>
            <CustomModal title='Are You Sure?'>
              <>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => {
                      dispatch(componentsSlice.actions.closeModal({}))
                    }}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    onClick={() => {
                      deleteWithId()
                    }}
                  >
                    <span className='indicator-label'>Delete</span>
                  </button>
                </div>
                {/* end::Actions */}
              </>
            </CustomModal>
          </>
        )
      case 'Content':
        return (
          <>
            <CustomModal title='Are You Sure?'>
              <>
                <div className='d-flex flex-column align-items-center'>
                  <div className='fv-row mb-7 w-100 mx-2'>
                    <label className='required fw-bold fs-6 mb-2'>Content Title</label>
                    <input
                      placeholder='Type a Content Title'
                      type='text'
                      name={`detailTitle`}
                      className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                      autoComplete='off'
                      onChange={(e) => {
                        setContentTitle(e.target.value)
                      }}
                    />
                  </div>
                  <div className='fv-row mb-7 w-100 mx-2'>
                    <label className='required fw-bold fs-6 mb-3 '>Content Text</label>
                    <textarea
                      className='form-control form-control-solid mb-3'
                      rows={4}
                      data-kt-element='input'
                      placeholder='Type a Text'
                      value={contentText}
                      onChange={(e) => setContentText(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => {
                      dispatch(componentsSlice.actions.closeModal({}))
                    }}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    onClick={() => {
                      const newDetail = {title: contentTitle, content: contentText}
                      setDetailList([...detailList, newDetail])
                      setDefaultActiveKey("content")
                      setprocessName('Insert')
                      dispatch(componentsSlice.actions.openModal({}))
                    }}
                  >
                    <span className='indicator-label'>Submit</span>
                  </button>
                </div>
                {/* end::Actions */}
              </>
            </CustomModal>
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={PrepareList?.items}
          title={'Prepare For Meeting List'}
          pagination
          paginationServer
          paginationTotalRows={PrepareList?.total_count}
          isBusy={dataTableisBusy}
          isNewItem
          isNewItemString={'New Item'}
          isExport
          onExport={() => {
            dispatch(
              exportPrepareMeetingList({
                page_index: 0,
                page_size: 0,
                filters: filterParams,
                sort_filters: sortData,
              })
            );
          }}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          showTitle
          //showFilter={true}
          //filterOptions={customFilter}
          searchButtonClickEvent={() => setfilterParams(filterQuery)}
          sortHandle={handleSort}
          clearButtonClickEvent={() => {
            setfilterParams([])
            setfilterQuery([])
          }}
          newItemClickEvent={() => {
            navigate("/PrepareMeeting/PrepareDetail")
            dispatch(PrepareSlice.actions.clearItems({}))
          }}
        ></CustomDatatable>
      </KTCard>
      {modalIsOpen !== undefined ? findProcessScreen(processName) : null}
    </>
  )
}

const PreparesWrapper: FC<Props> = ({PrepareList}) => (
  <QueryRequestProvider>
    <ListViewProvider>
      <PreparesList PrepareList={PrepareList} />
    </ListViewProvider>
  </QueryRequestProvider>
)

export {PreparesWrapper}
