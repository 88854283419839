import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import accountService from "../services/account/account.service";

interface InitialStateInterface {
    error: string;
    loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState = {
    loading: "idle",
    error: "",
} as InitialStateInterface;

export const customLoginSlice = createAsyncThunk("account/login",
    async (user: any, thunkAPI) => {
        const { username, password } = user;
        try {
            const data = await accountService.customLoginService(username, password);
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue("");
        }
    }
);


const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
    },
});

export default accountSlice;
