/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import componentsSlice from '../../slices/components/componentsSlice'
import {
  getAllReferralUsersWithUserTypeService,
  getReferralUsersWithId,
} from '../../slices/referralManagement/referralManagementSlice'
import {AppDispatch, useAppSelector} from '../../store/store'
import {ClientUserTypeEnum} from '../../utils/enums/ClientUserTypeEnum.enum'
import {ReferralManagementWrapper} from './ReferralManagementList'

const ReferralManagementContentBreadcrumbs: Array<PageLink> = [
  {
    title: `Referral Management`,
    path: '/ReferralManagement/List',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReferralManagement = () => {
  const dispatch: AppDispatch = useDispatch()

  const {ReferralListSlice} = useAppSelector((state) => state.referralManagement)

  useEffect(() => {
    dispatch(componentsSlice.actions.closeModal({}))
    dispatch(getReferralUsersWithId(ClientUserTypeEnum.Merchant))
    dispatch(getAllReferralUsersWithUserTypeService(ClientUserTypeEnum.Merchant))
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/List'
          element={
            <>
              <PageTitle
                breadcrumbs={ReferralManagementContentBreadcrumbs}
              >{`Referral Management`}</PageTitle>
              <ReferralManagementWrapper ReferralManagementListSlice={ReferralListSlice} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ReferralManagement
