import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EndpointsService from "../../../services/fpConnections/endpoints/Endpoints.service";


interface InitialStateInterface {
    EndpointsListSlice: any
    EndpointSlice: any[]
}

const initialState = {
    EndpointsListSlice: null,
    EndpointSlice: [],
} as InitialStateInterface;

export const getEndpoints = createAsyncThunk(
    "getEndpointsList",
    async (req: any) => {
        try {
            return await EndpointsService.getEndpointsService(req).then(response => response);
        } catch (error) { }
    }
);

export const postEndpoints = createAsyncThunk(
    "postEndpoints",
    async (req: any, thunkAPI) => {
        try {
            return await EndpointsService.postEndpointsService(req).then(response => response);
        } catch (error) { }
    }
);

export const putEndpoints = createAsyncThunk(
    "putEndpoints",
    async (req: any, thunkAPI) => {
        try {
            return await EndpointsService.putEndpointsService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteEndpointsWithId = createAsyncThunk(
    "deleteEndpoints",
    async (req: any, thunkAPI) => {
        try {
            return await EndpointsService.deleteEndpointsService(req).then(response => response);
        } catch (error) { }
    }
);

export const getEndpointsWithId = createAsyncThunk(
    "getEndpointsWithId",
    async (req: any) => {
        try {
            return await EndpointsService.getEndpointsWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const EndpointsSlice = createSlice({
    name: "EndpointsSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEndpoints.fulfilled, (state, action) => {
            state.EndpointsListSlice = action.payload?.result ? action.payload.result?.endpoints : null;
        });
        builder.addCase(getEndpointsWithId.fulfilled, (state, action) => {
            state.EndpointSlice = action.payload?.result ? action.payload.result?.endpoint : [];
        });
    },
});

export default EndpointsSlice;
