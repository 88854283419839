
import { callApi, HttpMethods } from "../../../../utils/api-util";
import { ResponseType } from "../../../../utils/enums/ResponseType.enum";

const getTermsConditionsTypeIdService = async (req:any) => {
    let _options = {
        controller: "/terms-of-condition/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
};

const exportTermsConditionsTypeIdService = async (req:any) => {
    let _options = {
        controller: "/terms-of-condition/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
};



const postTermsConditionsService = async (req: any) => {
    let _options = {
        controller: "/terms-of-condition",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putTermsConditionsService = async (req: any) => {
    let _options = {
        controller: "/terms-of-condition",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteTermsConditionsService = async (req: any) => {
    let _options = {
        controller: "/terms-of-condition",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getTermsConditionWithIdService = async (req:any) => {
    let _options = {
        controller: "/terms-of-condition/get-terms-condition-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const TermsConditionsService = {
    getTermsConditionsTypeIdService,
    exportTermsConditionsTypeIdService,
    putTermsConditionsService,
    getTermsConditionWithIdService,
    postTermsConditionsService,
    deleteTermsConditionsService
};

export default TermsConditionsService;
