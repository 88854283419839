/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import componentsSlice from '../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { PendingMerchantWrapper } from './PendingMerchantList'

const PendingMerchantContentBreadcrumbs: Array<PageLink> = [
    {
        title: `Merchant Management`,
        path: '/pendingMerchant/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const PendingMerchant = () => {
    const dispatch: AppDispatch = useDispatch();

    const { ProductionRequestListSlice } = useAppSelector(
        (state) => state.productionRequest
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={PendingMerchantContentBreadcrumbs}>{`Pending Merchants`}</PageTitle>
                            <PendingMerchantWrapper pendingMerchantList={ProductionRequestListSlice} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default PendingMerchant
