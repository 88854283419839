import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AttributeFormatService from "../../../services/credentialManagement/attributeFormat/AttributeFormat.service";


interface InitialStateInterface {
    AttributeFormatListSlice: any
    ExistAttributeFormatListSlice: any
    AttributeFormatSlice: any
}

const initialState = {
    AttributeFormatListSlice: null,
    ExistAttributeFormatListSlice: null,
    AttributeFormatSlice: null
} as InitialStateInterface;

export const getAttributeFormat = createAsyncThunk(
    "getAttributeFormat",
    async (req: any) => {
        try {
            return await AttributeFormatService.getAttributeFormatService(req).then(response => response);
        } catch (error) { }
    }
);

export const getExistAttributeFormat = createAsyncThunk(
    "getExistAttributeFormat",
    async () => {
        try {
            return await AttributeFormatService.getExistAttributeFormatService().then(response => response);
        } catch (error) { }
    }
);


export const exportAttributeFormat = createAsyncThunk(
    "exportAttributeFormat",
    async (req: any) => {
        try {
            return await AttributeFormatService.exportAttributeFormatService(req).then(response => response);
        } catch (error) { }
    }
);

export const postAttributeFormat = createAsyncThunk(
    "postAttributeFormat",
    async (req: any, thunkAPI) => {
        try {
            return await AttributeFormatService.postAttributeFormatService(req).then(response => response);
        } catch (error) { }
    }
);

export const putAttributeFormat = createAsyncThunk(
    "putAttributeFormat",
    async (req: any, thunkAPI) => {
        try {
            return await AttributeFormatService.putAttributeFormatService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteAttributeFormatsWithId = createAsyncThunk(
    "deleteAttributeFormatsWithId",
    async (req: any, thunkAPI) => {
        try {
            return await AttributeFormatService.deleteAttributeFormatService(req).then(response => response);
        } catch (error) { }
    }
);

export const getAttributeFormatWithId = createAsyncThunk(
    "getAttributeFormatWithId",
    async (req: any) => {
        try {
            return await AttributeFormatService.getAttributeFormatWithIdService(req).then(response => response);
        } catch (error) { }
    }
);
const attributeFormatSlice = createSlice({
    name: "attributeFormatSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAttributeFormat.fulfilled, (state, action) => {
            state.AttributeFormatListSlice = action.payload?.result ? action.payload.result?.attribute_formats : null;
        });
        builder.addCase(getAttributeFormatWithId.fulfilled, (state, action) => {
            state.AttributeFormatSlice = action.payload?.result ? action.payload.result?.attribute_format : [];
        });
        builder.addCase(getExistAttributeFormat.fulfilled, (state, action) => {
            state.ExistAttributeFormatListSlice = action.payload?.result ? action.payload.result?.attribute_formats : null;
        });
    },
});

export default attributeFormatSlice;
