import clsx from 'clsx'
import { KTSVG } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons'
import { Link, useLocation } from 'react-router-dom'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'


const Navbar = () => {
  const {pathname} = useLocation()
  
  return (
    <div className='app-navbar flex-shrink-0'>
      <Link className={clsx('app-navbar-item', itemClass)} to={`/UserManual${pathname}`} target='_blank' style={{opacity:"85%"}}>
        <FontAwesomeIcon icon={faCircleQuestion} size='3x' color='white'></FontAwesomeIcon>
      </Link>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <KTSVG path="/media/icons/duotune/communication/com006.svg" className="svg-icon-muted svg-icon-3hx" />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export { Navbar }
