
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { getBrandAsset } from '../../../slices/assetManagement/system/BrandAssetSlice'
import { AppDispatch, useAppSelector } from '../../../store/store'
import { BrandAssetWrapper } from './BrandAssetList'

const BrandAssetContentBreadcrumbs: Array<PageLink> = [
    {
        title: `Asset Management`,
        path: '/BrandAsset/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const BrandAsset = () => {
    const dispatch: AppDispatch = useDispatch();

    const { BrandAssetSliceList } = useAppSelector(
        (state) => state.brandAsset
    );

    useEffect(() => {
        dispatch(getBrandAsset());
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={BrandAssetContentBreadcrumbs}>{`Brand Assets`}</PageTitle>
                            <BrandAssetWrapper BrandAssetSlice={BrandAssetSliceList} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default BrandAsset
