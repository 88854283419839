/* eslint-disable no-template-curly-in-string */

import { callApi, HttpMethods } from "../../../../utils/api-util";

const getWelcomeScreenService = async (req: any) => {
    let _options = {
        controller: "/sdk-intro/get",
        method: HttpMethods.POST,
        data: req,
    };
    const result = await callApi(_options);
    return result;
}

const getWelcomeScreenWithIdService = async (req: any) => {
    let _options = {
        controller: "/sdk-intro/get-by-id/" + req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const postWelcomeScreenService = async (req: any) => {
    let _options = {
        controller: "/sdk-intro",
        method: HttpMethods.POST,
        data: req,
        contentType: 'multipart/form-data; boundary=${form._boundary}'
    };
    const result = await callApi(_options);
    return result;
};

const putWelcomeScreenService = async (req: any) => {

    let _options = {
        controller: "/sdk-intro",
        method: HttpMethods.PUT,
        data: req,
        contentType: 'multipart/form-data; boundary=${form._boundary}'
    };
    const result = await callApi(_options);
    return result;
};

const deleteWelcomeScreenService = async (req: any) => {
    let _options = {
        controller: "/sdk-intro",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const WelcomeScreenService = {
    getWelcomeScreenService,
    postWelcomeScreenService,
    putWelcomeScreenService,
    deleteWelcomeScreenService,
    getWelcomeScreenWithIdService,
};

export default WelcomeScreenService;
