/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { AppDispatch, useAppDispatch } from '../../store/store'
import { getHelperContentWithUrlAndId } from '../../slices/assetManagement/console/HelperSlice'
import { useLocation } from 'react-router-dom'

function UserManual() {
  const { pathname } = useLocation()
  const dispatch: AppDispatch = useAppDispatch()
  const [pageName, setpageName] = useState<any>()
  const [pageContent, setPageContent] = useState<any>()

  useEffect(() => {
    let getPathName = window.location.href.substring(window.location.href.indexOf("/UserManual") + 11)
    dispatch(getHelperContentWithUrlAndId(getPathName)).then((resp) => {
      setpageName(resp?.payload?.result?.helper?.name)
      setPageContent(resp.payload?.result?.helper?.content)
    })
  }, [pathname])

  return (
    <div className="row mx-2 bg-white rounded d-flex justify-content-center align-content-start" style={{ height: "75vh", overflow: "auto" }}>
      <div className="row mt-3" style={{ height: "fit-content" }}>
        <div className='row d-flex justify-content-start mt-5 mx-2 mb-2' style={{ fontSize: "2rem", fontWeight: "bold" }}> {pageName}</div>
        <hr></hr>
      </div>
      <div className='row d-flex justify-content-center'>
        <div style={{ whiteSpace: "break-spaces" }} dangerouslySetInnerHTML={{ __html: pageContent }} />
      </div>
    </div >
  )
}

export default UserManual