/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { isNotEmpty, KTCard, KTSVG } from "../../../../_metronic/helpers"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC, useEffect, useState } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import { showToast } from "../../../components/custom-toasts/custom-toasts"
import Styles from "../../../../_metronic/assets/sass/pages/frpPurchaseRateList.module.scss"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { deleteFrpPriceWithId, exportFrpTransaction, getFrpPurchaseRateWithCountryId, postFrpPrice, postFrpPurchaseRate, putFrpPrice, putFrpPurchaseRate } from "../../../slices/frpManagement/FrpPurchaseRateSlice"
import CustomSelect from "../../../components/customModal/CustomSelect"
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"

interface Props {
  frpPurchaseList: any[]
}
const FrpPurchaseRateList: FC<Props> = ({ frpPurchaseList }) => {
  const [totalRowCountVal, settotalRowCountVal] = useState(0)
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const [resetDataTable, setresetDataTable] = useState(false)
  const { modalIsOpen } = useAppSelector((state) => state.components);
  const dispatch: AppDispatch = useDispatch();
  const [processName, setprocessName] = useState("Insert")

  const [selectedRowId, setSelectedRowId] = useState(undefined)
  const [countryofoperation, setcountryofoperation] = useState<any>();

  const CountryArray = [
    {
      value: 0,
      label: "UK"
    },
    {
      value: 1,
      label: "USA"
    },
  ]

  const CurrencyArray = [
    {
      value: 0,
      label: "£"
    },
    {
      value: 1,
      label: "$"
    },
  ]

  const PaymentOptionEnum = [
    {
      value: 0,
      label: "Credit / Debit"
    },
    // {
    //   value: 1,
    //   label: "Open Banking"
    // },
    // {
    //   value: 2,
    //   label: "Purchase Order"
    // },
  ]

  const formikForPurchaseRate = useFormik({
    initialValues: {
      id: '',
      country: {
        value: 0,
        label: "UK"
      },
      currency: {
        value: 0,
        label: "£"
      },
      vat: '',
      paymentOptions: []
    },
    validationSchema: Yup.object().shape({
      country: Yup.object()
        .required('Country is required'),
      currency: Yup.object()
        .required('Currency is required'),
      vat: Yup.string()
        .required('Vat is required'),
      paymentOptions: Yup.array()
        .min(1, "Payment Options is required")
        .required('Payment Options is required')
    }),
    onSubmit: (values, actions) => {
      try {
        if (isNotEmpty(values.id)) {
          updateFRPPurchaseWithCountryId(values)
        } else {
          postFRPPurchase(values);
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm();
      }
    },
  })

  const formikForFrpPurchaseRateList = useFormik({
    initialValues: {
      id: '',
      totalPrice: '',
      frp: '',
    },
    validationSchema: Yup.object().shape({
      totalPrice: Yup.string()
        .max(50, 'Maximum 50 symbols')
        .required('Total Price is required'),
      frp: Yup.string()
        .max(50, 'Maximum 50 symbols')
        .required('FRP Amount is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        if (isNotEmpty(values.id)) {
          UpdateFrpPurchaseRateList(values)
        } else {
          let isExist = frpPurchaseList[0].frp_prices.find((x: any) => x.frp === values.frp)
          if (isExist != null) {
            showToast.error("This FRP Amount has total price. Please enter different FRP Amount")
            return;
          }
          else {
            SaveFrpPurchaseRateList(values);
          }

        }
      } catch (ex) {
        console.error(ex)
      } finally {
        dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm();
      }
    },
  })

  useEffect(() => {
    setdataTableisBusy(false)
    settotalRowCountVal(frpPurchaseList?.[0]?.frp_prices?.length)
    setresetDataTable(!resetDataTable)
    if (frpPurchaseList.length > 0) {
      let paymentOptionArray: any[] = [];
      frpPurchaseList?.[0]?.payment_options.forEach((element: any) => {
        paymentOptionArray.push(PaymentOptionEnum?.find(x => x.value === element.payment_option))
      });
      formikForPurchaseRate.setValues({
        id: frpPurchaseList?.[0]?.id,
        country: CountryArray.find(x => x.value === frpPurchaseList?.[0]?.country)!,
        currency: CurrencyArray.find(x => x.value === frpPurchaseList?.[0]?.currency)!,
        vat: frpPurchaseList?.[0]?.vat,
        paymentOptions: (paymentOptionArray as any),
      })
    }
    else {
      formikForPurchaseRate.setValues({
        id: '',
        country: formikForPurchaseRate.values.country,
        currency: { value: 0, label: "£" },
        vat: '',
        paymentOptions: [],
      })
    }
  }, [frpPurchaseList])

  useEffect(() => {
    dispatch(getFrpPurchaseRateWithCountryId(formikForPurchaseRate.values.country.value));
  }, [countryofoperation])

  useEffect(() => {
    if (modalIsOpen === undefined) {
      setprocessName("Insert")
      setSelectedRowId(undefined)
      formikForFrpPurchaseRateList.resetForm()
    }
  }, [modalIsOpen])


  const columns = [
    {
      name: 'FRP Amount',
      selector: (row: any) => <div>{Number(row?.frp).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>,
      width: "auto",
      right: true
    },
    {
      name: 'Total Price ' + (formikForPurchaseRate?.values?.country?.value === 0 ? "(£)" : "($)"),
      selector: (row: any) =>
        <div>
          {Number(row?.total_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </div>,
      width: "auto",
      right: true
    },
    { width: "auto" },
    {
      name: 'Action',
      selector: (row: any) =>
        <>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setprocessName("Insert")
                setSelectedRowId(row.id)
                dispatch(componentsSlice.actions.openModal({}))
                GetFrpPurchaseRate(row.id)
              }}
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </div>
            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setprocessName("Delete")
                setSelectedRowId(row.id)
                dispatch(componentsSlice.actions.openModal({}))
              }}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </div>
          </div>
        </>
      , width: "150px"
    },
  ];

  function postFRPPurchase(values: any) {
    let req = {
      country: values.country?.value,
      currency: values.currency?.value,
      vat: parseFloat(values.vat),
      payment_options: values.paymentOptions?.map((x: any) => ({ 'payment_option': x.value }))
    }
    dispatch(postFrpPurchaseRate(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getFrpPurchaseRateWithCountryId(formikForPurchaseRate.values.country.value))
        showToast.success("Item Saved Successfully")
        formikForFrpPurchaseRateList.resetForm()
        formikForPurchaseRate.resetForm()
      }
    })
  }

  function updateFRPPurchaseWithCountryId(values: any) {
    let req = {
      id: values.id,
      country: values.country?.value,
      currency: values.currency?.value,
      vat: parseFloat(values.vat),
      payment_options: values.paymentOptions?.map((x: any) => ({ 'payment_option': x.value }))
    }
    dispatch(putFrpPurchaseRate(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getFrpPurchaseRateWithCountryId(formikForPurchaseRate.values.country.value))
        showToast.success("Item Saved Successfully")
        formikForFrpPurchaseRateList.resetForm()
        formikForPurchaseRate.resetForm()
      }
    })
  }


  function GetFrpPurchaseRate(rowId: any) {
    let frpPurchaseRate = frpPurchaseList[0]?.frp_prices?.find((x: any) => x.id === rowId)
    formikForFrpPurchaseRateList.setValues({
      id: frpPurchaseRate?.id,
      frp: frpPurchaseRate?.frp,
      totalPrice: frpPurchaseRate?.total_price
    })
  }

  function SaveFrpPurchaseRateList(values: any) {
    let req = {
      frp_price_country_id: formikForPurchaseRate.values.id,
      total_price: values.totalPrice,
      frp: values.frp,
    }
    dispatch(postFrpPrice(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getFrpPurchaseRateWithCountryId(formikForPurchaseRate.values.country.value))
        showToast.success("Item Saved Successfully")
        formikForFrpPurchaseRateList.resetForm()
        formikForPurchaseRate.resetForm()
      }
      else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function UpdateFrpPurchaseRateList(values: any) {
    let req = {
      id: values.id,
      total_price: values.totalPrice,
      frp: values.frp,
    }
    dispatch(putFrpPrice(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getFrpPurchaseRateWithCountryId(formikForPurchaseRate.values.country.value))
        showToast.success("Item Saved Successfully")
        formikForFrpPurchaseRateList.resetForm()
        formikForPurchaseRate.resetForm()
      }
      else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function DeleteFunc() {
    dispatch(deleteFrpPriceWithId(selectedRowId)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(getFrpPurchaseRateWithCountryId(formikForPurchaseRate.values.country.value));
        showToast.success("Deleted successfully")
        formikForFrpPurchaseRateList.resetForm()
        formikForPurchaseRate.resetForm()
        dispatch(componentsSlice.actions.closeModal({}))
      }
      else {
        showToast.error("Delete process failed")
        dispatch(getFrpPurchaseRateWithCountryId(formikForPurchaseRate.values.country.value));
        formikForFrpPurchaseRateList.resetForm()
        formikForPurchaseRate.resetForm()
        dispatch(componentsSlice.actions.closeModal({}))
      }
    })
  }

  function findProcessScreen(processName: string) {
    switch (processName) {
      case "Insert":
        return <>
          <CustomModal title={selectedRowId !== undefined ? "Update Bundle Price" : "Create Bundle Price"}>
            <>
              <form id='kt_modal_add_user_form' className='form' onSubmit={formikForFrpPurchaseRateList.handleSubmit}>
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >

                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>FRP Amount</label>
                    <CustomTextbox
                      {...formikForFrpPurchaseRateList.getFieldProps('frp')}
                      onChange={() => null}
                      placeHolder='FRP Amount'
                      name='frp'
                      value={formikForFrpPurchaseRateList.values.frp}
                      formik={formikForFrpPurchaseRateList}
                      formikName={"frp"}
                      type={"number"}
                      onlyPositive
                    ></CustomTextbox>
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Total Price</label>

                    <CustomTextbox
                      {...formikForFrpPurchaseRateList.getFieldProps('totalPrice')}
                      onChange={() => null}
                      placeHolder='Total Price'
                      name='totalPrice'
                      value={formikForFrpPurchaseRateList.values.totalPrice}
                      formik={formikForFrpPurchaseRateList}
                      formikName={"totalPrice"}
                      type={"number"}
                      onlyPositive
                    ></CustomTextbox>
                  </div>
                </div>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                  >
                    <span className='indicator-label'>Submit</span>
                  </button>
                </div>
                {/* end::Actions */}
              </form>
            </>
          </CustomModal>
        </>

      case "Update":
        return <>

        </>

      case "Delete":
        return <>
          <CustomModal title="Are You Sure?">
            <>
              {/* begin::Actions */}
              <div className='text-center pt-15'>
                <button
                  type='reset'
                  onClick={() => {
                    dispatch(componentsSlice.actions.closeModal({}))
                  }

                  }
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Discard
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={() => {
                    DeleteFunc()
                  }
                  }
                >
                  <span className='indicator-label'>Delete</span>
                </button>
              </div>
              {/* end::Actions */}
            </>
          </CustomModal>
        </>

      default: return null;
    }
  }

  return (
    <>
      <KTCard>
        <form id='kt_modal_add_user_form' className='form' onSubmit={formikForPurchaseRate.handleSubmit}>

          <div className={`${Styles.container}`}>
            <div className={`${Styles.title}`}>{'Bundle Prices'}</div>
            <div className="row">
              <div className="col">
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Country</label>
                  <CustomSelect
                    onChange={setcountryofoperation}
                    data={CountryArray}
                    isMulti={false}
                    formik={formikForPurchaseRate}
                    formikName={"country"}
                    selectedValue={
                      formikForPurchaseRate?.values?.country === undefined ?
                        {
                          value: 0,
                          label: "UK"
                        } : formikForPurchaseRate?.values?.country
                    }
                  ></CustomSelect>
                </div>
              </div>
              <div className="col"></div>
              <div className="col"></div>
            </div>
            <div className="row">
              <div className="col-2">
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Currency</label>
                  <CustomSelect
                    onChange={() => null}
                    data={CurrencyArray}
                    isMulti={false}
                    formik={formikForPurchaseRate}
                    formikName={"currency"}
                    selectedValue={formikForPurchaseRate?.values?.currency}
                  ></CustomSelect>
                </div>
              </div>
              <div className="col-2">
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Vat</label>
                  <CustomTextbox
                    {...formikForPurchaseRate.getFieldProps('vat')}
                    onChange={() => null}
                    placeHolder={"vat %"}
                    name='vat'
                    value={formikForPurchaseRate.values.vat}
                    formik={formikForPurchaseRate}
                    formikName={"vat"}
                    mask={'%99'}
                    prefix={"%"}
                  ></CustomTextbox>
                </div>
              </div>
              <div className="col-3">
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Payment Options</label>
                  <CustomSelect
                    onChange={() => null}
                    data={PaymentOptionEnum}
                    isMulti={true}
                    formik={formikForPurchaseRate}
                    formikName={"paymentOptions"}
                    selectedValue={formikForPurchaseRate?.values?.paymentOptions}
                  ></CustomSelect>
                </div>
              </div>
              <div className="col-2">
                <button
                  type='submit'
                  className={`btn btn-primary ${Styles.saveButton}`}
                  data-kt-users-modal-action='submit'
                  onClick={() => { }}
                >
                  <span className='indicator-label'>Save</span>
                </button>
              </div>
              <div className="col"></div>
            </div>
          </div>
        </form>
      </KTCard>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={frpPurchaseList?.[0]?.frp_prices}
          title={"Bundle Prices List"}
          paginationTotalRows={totalRowCountVal}
          isBusy={dataTableisBusy}
          showTitle
          isNewItem
          isNewItemString={"New Item"}
          isExport
          onExport={() => {
            dispatch(exportFrpTransaction(formikForPurchaseRate.values.country.value))

          }}

        ></CustomDatatable>
      </KTCard>
      {modalIsOpen !== undefined ?
        findProcessScreen(processName)
        : null
      }
    </>
  )
}

const FrpPurchaseRateListWrapper: FC<Props> = ({ frpPurchaseList }) => <QueryRequestProvider>
  <ListViewProvider>
    <FrpPurchaseRateList frpPurchaseList={frpPurchaseList} />
  </ListViewProvider>
</QueryRequestProvider>

export { FrpPurchaseRateListWrapper }
