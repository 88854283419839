/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { VasEpConfigurationWrapper } from './vasEpConfigurationList'

const vasEpConfigurationCrumbs: Array<PageLink> = [
    {
        title: `Endpoint`,
        path: '/Endpoint/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const VasEpConfiguration = () => {

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={vasEpConfigurationCrumbs}>{`VAS/EP Configurations`}</PageTitle>
                            <VasEpConfigurationWrapper vasEpConfigurationList={
                                [
                                    { "name": "PROD Test", "pos_template" :"Category-Based POS", "pos_system": "Micros Sim v2", "product_lists": "PROD Store List", "last_updated": "11/15/2022" },
                                    { "name": "WEB10939_1", "pos_template" :"SKU-Based POS", "pos_system": "Infrasys", "product_lists": "PB02122019", "last_updated": "07/13/2022" },
                                    { "name": "WEB10939_2", "pos_template" :"SKU-Based POS", "pos_system": "Infrasys", "product_lists": "PB02122019", "last_updated": "07/13/2022" },
                                    { "name": "2217 Regression POS Config", "pos_template" :"SKU-Based POS", "pos_system": "Micro Sim v2", "product_lists": "012", "last_updated": "05/31/2022" },
                                    { "name": "Shiji Infrasys", "pos_template" :"SKU-Based POS", "pos_system": "Infrasys", "product_lists": "PB02122019", "last_updated": "05/31/2022" },
                                    { "name": "SHIJIFlagOnConfig", "pos_template" :"Category-Based POS", "pos_system": "Infrasys", "product_lists": "test", "last_updated": "05/13/2022" },
                                    { "name": "22.14 Regression POS Config", "pos_template" :"Category-Based POS", "pos_system": "VPOS", "product_lists": "Marriott Products", "last_updated": "03/31/2022" },
                                    { "name": "22.05 Regression", "pos_template" :"Category-Based POS", "pos_system": "Micros Sim v2", "product_lists": "PB02122019", "last_updated": "01/28/2022" },
                                    { "name": "New POS config UAT", "pos_template" :"Category-Based POS", "pos_system": "Micros Sim v2", "product_lists": "PB02122019", "last_updated": "12/30/2021" },
                                ]
                                }  />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default VasEpConfiguration
