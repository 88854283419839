import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HelperService from "../../../services/assetManagement/console/Helper.service";


interface InitialStateInterface {
    HelperListSlice: any
    selectedId: string
    PageList: any[]
}

const initialState = {
    HelperListSlice: null,
    selectedId: "",
    PageList: [],
} as InitialStateInterface;

export const getHelper = createAsyncThunk(
    "getHelperList",
    async (req: any) => {
        try {
            return await HelperService.getHelperService(req).then(response => response);
        } catch (error) { }
    }
);


export const exportHelper = createAsyncThunk(
    "exportHelperList",
    async (req: any) => {
        try {
            return await HelperService.exportHelperService(req).then(response => response);
        } catch (error) { }
    }
);


export const getPages = createAsyncThunk(
    "getPages",
    async (req: any) => {
        try {
            return await HelperService.getPages(req).then(response => response);
        } catch (error) { }
    }
);

export const postHelper = createAsyncThunk(
    "postHelper",
    async (req: any, thunkAPI) => {
        try {
            return await HelperService.postHelperService(req).then(response => response);
        } catch (error) { }
    }
);

export const putHelper = createAsyncThunk(
    "putHelper",
    async (req: any, thunkAPI) => {
        try {
            return await HelperService.putHelperService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteHelperWithId = createAsyncThunk(
    "deleteHelper",
    async (req: any, thunkAPI) => {
        try {
            return await HelperService.deleteHelperService(req).then(response => response);
        } catch (error) { }
    }
);

export const getHelperWithId = createAsyncThunk(
    "getHelperWithId",
    async (req: any) => {
        try {
            return await HelperService.getHelperWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

export const getHelperContentWithNameAndId = createAsyncThunk(
    "getHelperContentWithNameAndId",
    async (req: any) => {
        try {
            return await HelperService.getHelperContentWithNameAndIdService(req).then(response => response);
        } catch (error) { }
    }
);

export const getHelperContentWithUrlAndId = createAsyncThunk(
    "getHelperContentWithNameAndId",
    async (req: any) => {
        try {
            return await HelperService.getHelperContentWithUrlAndIdService(req).then(response => response);
        } catch (error) { }
    }
);

const HelperSlice = createSlice({
    name: "HelperSlice",
    initialState,
    reducers: {
        changeSelectedId: (state, action) => {
            state.selectedId = action.payload?.id
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getHelper.fulfilled, (state, action) => {
            state.HelperListSlice = action.payload?.result ? action.payload.result?.helper_list : null;
        });

        builder.addCase(getPages.fulfilled, (state, action) => {
            state.PageList = action.payload?.result ? action.payload.result?.pages : null;
        });
    },
});

export default HelperSlice;
