
import { callApi, HttpMethods } from "../../../utils/api-util";
import { ResponseType } from "../../../utils/enums/ResponseType.enum";

const getNotificationService = async (req: any) => {
    let _options = {
        controller: "/Notification/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}

const exportNotificationService = async (req: any) => {
    let _options = {
        controller: "/Notification/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}

const postNotificationService = async (req: any) => {
    let _options = {
        controller: "/Notification/add",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const postSendNotificationService = async (req: any) => {
    let _options = {
        controller: "/Notification/send",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putNotificationService = async (req: any) => {
    let _options = {
        controller: "/Notification",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};


const deleteNotificationService = async (req: any) => {
    let _options = {
        controller: "/Notification",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getNotificationWithIdService = async (req:any) => {
    let _options = {
        controller: "/Notification/get-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const getMerchantsService = async () => {
    let _options = {
        controller: "/Notification/merchants",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const NotificationService = {
    getNotificationService,
    exportNotificationService,
    postNotificationService,
    putNotificationService,
    deleteNotificationService,
    getNotificationWithIdService,
    postSendNotificationService,
    getMerchantsService
};

export default NotificationService;
