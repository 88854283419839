/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { isNotEmpty } from '../../../_metronic/helpers'
import CustomTextbox from '../customTextbox/CustomTextbox';

interface CustomProps {
    filterName: string;
    filterTitle: string;
    customQuery: any;
    customFunction: any;
    onlyPositive?: boolean;
}
const CustomFilterNumberRange: React.FC<CustomProps> = ({
    filterName,
    filterTitle,
    customQuery,
    customFunction,
    onlyPositive = true
}) => {

    const [firstNumber, setfirstNumber] = useState<any>("")
    const [secondNumber, setsecondNumber] = useState<any>("")

    useEffect(() => {
        if ((!isNaN(firstNumber) && firstNumber !== null && firstNumber !== undefined && firstNumber !== "") && (!isNaN(secondNumber) && secondNumber !== null && secondNumber !== undefined && secondNumber !== "")) {
            customFunction(
                [...customQuery.filter((x: any) => x.name !== filterName)
                    , { "name": filterName, "comparator": "InRange", "value": firstNumber, "second_value": secondNumber }])
        }
        else if ((!isNaN(firstNumber) && firstNumber !== null && firstNumber !== undefined && firstNumber !== "") && (isNaN(secondNumber) || secondNumber === null || secondNumber === undefined || secondNumber === "")) {
            customFunction(
                [...customQuery.filter((x: any) => x.name !== filterName)
                    , { "name": filterName, "comparator": "LessOrEqual", "value": firstNumber }])
        }
        else if ((isNaN(firstNumber) || firstNumber === null || firstNumber === undefined || firstNumber === "") && (!isNaN(secondNumber) && secondNumber !== null && secondNumber !== undefined && secondNumber !== "")) {
            customFunction(
                [...customQuery.filter((x: any) => x.name !== filterName)
                    , { "name": filterName, "comparator": "GreaterOrEqual", "value": secondNumber }])
        }
        else {
            customFunction(
                [...customQuery.filter((x: any) => x.name !== filterName)])
        }
    }, [firstNumber, secondNumber])

    useEffect(() => {
        if (customQuery.length === 0) {
            setfirstNumber("")
            setsecondNumber("")
        }
    }, [customQuery])



    return (
        <>
            <label className='fw-bold fs-6 mb-2'>{filterTitle}</label>
            <div className="row">
                <div className="col">
                    <CustomTextbox
                        onChange={
                            (e) => isNotEmpty(e) ?
                                setfirstNumber(e)
                                : setfirstNumber(null)
                        }
                        placeHolder={`Type  ${['a', 'e', 'i', 'o', 'u'].includes(filterTitle[0].toLowerCase()) ? "an" : "a"} ${filterTitle}`}
                        name={filterName}
                        value={firstNumber}
                        type={"number"}
                        onlyPositive={onlyPositive}
                    ></CustomTextbox>
                </div>
                <div className="col">
                    <CustomTextbox
                        onChange={
                            (e) => isNotEmpty(e) ?
                                setsecondNumber(e)
                                : setsecondNumber(null)
                        }
                        placeHolder={`Type  ${['a', 'e', 'i', 'o', 'u'].includes(filterTitle[0].toLowerCase()) ? "an" : "a"} ${filterTitle}`}
                        name={filterName}
                        value={secondNumber}
                        type={"number"}
                        onlyPositive={onlyPositive}
                    ></CustomTextbox>
                </div>
            </div>
        </>
    )
}

export default CustomFilterNumberRange