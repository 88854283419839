import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ContentService from "../../../../services/assetManagement/sdk/content/Content.service";


interface InitialStateInterface {
    ContentListSlice: any
    ContentSlice: any[]
    ExistContentSlice: any[]
}

const initialState = {
    ContentListSlice: null,
    ContentSlice: [],
    ExistContentSlice: []
} as InitialStateInterface;

export const getContents = createAsyncThunk(
    "getContentList",
    async (req: any) => {
        try {
            return await ContentService.getContentService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportContents = createAsyncThunk(
    "exportContentList",
    async (req: any) => {
        try {
            return await ContentService.exportContentService(req).then(response => response);
        } catch (error) { }
    }
);

export const getExistContentFormat = createAsyncThunk(
    "getExistContentFormat",
    async () => {
        try {
            return await ContentService.getExistContentFormat().then(response => response);
        } catch (error) { }
    }
);

export const postContent = createAsyncThunk(
    "postContent",
    async (req: any, thunkAPI) => {
        try {
            return await ContentService.postContentService(req).then(response => response);
        } catch (error) { }
    }
);

export const putContent = createAsyncThunk(
    "putContent",
    async (req: any, thunkAPI) => {
        try {
            return await ContentService.putContentService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteContent = createAsyncThunk(
    "deleteContent",
    async (req: any, thunkAPI) => {
        try {
            return await ContentService.deleteContentService(req).then(response => response);
        } catch (error) { }
    }
);

export const getContentWithId = createAsyncThunk(
    "getContentWithId",
    async (req: any) => {
        try {
            return await ContentService.getContentWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const ContentSlice = createSlice({
    name: "ContentSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getContents.fulfilled, (state, action) => {
            state.ContentListSlice = action.payload?.result ? action.payload.result?.contents : null;
        });
        builder.addCase(getContentWithId.fulfilled, (state, action) => {
            state.ContentSlice = action.payload?.result ? action.payload.result?.content : [];
        });
        builder.addCase(getExistContentFormat.fulfilled, (state, action) => {
            state.ExistContentSlice = action.payload?.result ? action.payload.result?.sdk_page_types : [];
        });
        
    },
});

export default ContentSlice;
