/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { isNotEmpty, KTCard, KTSVG } from "../../../../../_metronic/helpers"
import { ListViewProvider } from "../../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../../slices/components/componentsSlice"
import { showToast } from "../../../../components/custom-toasts/custom-toasts"
import clsx from 'clsx'
import moment from "moment"
import CustomSelect from "../../../../components/customModal/CustomSelect"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import CustomFileUpload from "../../../../components/customFileUpload/CustomImageUpload"
import CustomTextbox from "../../../../components/customTextbox/CustomTextbox"
import PreviewPages from "../../../../utils/previewScreen/PreviewPages"
import { deleteWelcomeScreenWithId, getWelcomeScreen, getWelcomeScreenWithId, postWelcomeScreen, putWelcomeScreen } from "../../../../slices/assetManagement/sdk/welcomeScreen/WelcomeScreenSlice"

interface Props {
    welcomeScreenList: any
}


export const sdkIntroContentTypeData = [
    {
        value: 0,
        label: "Image"
    },
    {
        value: 1,
        label: "Video"
    },
]

export const sdkIntroContentTypeDataForFilter =
    [
        {
            value: -1,
            label: "Select..."
        },
        ...sdkIntroContentTypeData
    ]

const WelcomeScreenList: FC<Props> = ({ welcomeScreenList }) => {
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { modalIsOpen } = useAppSelector((state: any) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [selectedRowId, setSelectedRowId] = useState<string | null>(null)
    const [file, setFile] = useState<File | null>(null);
    const [loading, setloading] = useState(false)
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([])
    const [filterParams, setfilterParams] = useState<any>([])

    const formik = useFormik({
        initialValues: {
            id: '',
            title: '',
            content: '',
            sdkIntroPriority: '',
            sdkIntroType: undefined,
            fileUrl: '',
        },
        validationSchema: Yup.object().shape({
            sdkIntroType: Yup.object().required('Type is required'),
            title:
                Yup.string().when('sdkIntroType', (sdkIntroType, schema) => {
                    if (sdkIntroType?.value !== 1) { return schema.required('Title is required'); }
                    return schema;
                })
                    .min(3, 'Minimum 3 symbols')
                    .max(50, 'Maximum 50 symbols'),
            content:
                Yup.string().when('sdkIntroType', (sdkIntroType, schema) => {
                    if (sdkIntroType?.value !== 1) { return schema.required('Content is required'); }
                    return schema;
                })
                    .min(3, 'Minimum 3 symbols')
                    .max(250, 'Maximum 250 symbols'),
            sdkIntroPriority: Yup.string()
                .required('Page Order is required'),
            fileUrl: Yup.string()
                .required('File is required'),

        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateWithId(values)
                } else {
                    createWelcomeScreen(values);
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                // dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
                setFile(null)
            }
        },
    })

    useEffect(() => {
        setdataTableisBusy(false)
    }, [welcomeScreenList])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(null)
            formik.resetForm()
        }
    }, [modalIsOpen])

    useEffect(() => {
        if (typeof formik.values.fileUrl === "string") {
            if ((formik.values.sdkIntroType as any)?.value === 0 && (formik.values.fileUrl.endsWith('.mp4') || formik.values.fileUrl === "")) {
                setFile(null)
                formik.setFieldValue("fileUrl", '')
            }

            if ((formik.values.sdkIntroType as any)?.value === 1 && (formik.values.fileUrl.endsWith('.png') || formik.values.fileUrl.endsWith('.jpg') || formik.values.fileUrl.endsWith('.svg'))) {
                setFile(null)
                formik.setFieldValue("fileUrl", '')
            }
        }
        else {
            if ((formik.values.sdkIntroType as any)?.value === 0 && (formik.values.fileUrl as any)[0].type === "video/mp4") {
                setFile(null)
                formik.setFieldValue("fileUrl", '')
            }

            if ((formik.values.sdkIntroType as any)?.value === 1 && ((formik.values.fileUrl as any)[0].type === "image/png")) {
                setFile(null)
                formik.setFieldValue("fileUrl", '')
            }
        }

    }, [formik.values.sdkIntroType])


    //#region DataTable Methods

    const columns = useMemo(
        () => [
            {
                name: 'Type',
                selector: (row: any) => <div>{sdkIntroContentTypeData.find(x => x.value === row?.sdk_intro_type)?.label}</div>,
                width: "100px",
            },
            {
                name: 'Page Order',
                selector: (row: any) => <div>{row?.sdk_intro_priority}</div>,
                width: "130px",
            },
            {
                name: 'Title',
                selector: (row: any) => <div>{row?.title}</div>,
                width: "auto",
            },
            {
                name: 'Content',
                selector: (row: any) => <div>{row?.content}</div>,
                width: "750px",
            },
            {
                name: 'Date Created',
                selector: (row: any) => <div>{moment(row.create_date_utc).format("MM/DD/YYYY")}</div>,
                width: "150px",
            },
            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={() => {
                                    customgetWelcomeScreenWithId(row.id)
                                    setprocessName("Insert")
                                    setSelectedRowId(row.id)
                                    dispatch(componentsSlice.actions.openModal({}))
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </div>
                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={() => {
                                    setprocessName("Delete")
                                    setSelectedRowId(row.id)
                                    dispatch(componentsSlice.actions.openModal({}))
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                            </div>
                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const customFilter = [
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Title</label>
                <CustomTextbox
                    onChange={
                        (e: any) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Title"), { "name": "Title", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Title")])
                    }
                    placeHolder={"Type a Title"}
                    name='Title'
                    value={filterQuery.find((x: any) => x.name === "Title") !== undefined ? filterQuery.find((x: any) => x.name === "Title")?.value : ""}
                    customKeypressEvent={() => setfilterParams(filterQuery)}
                ></CustomTextbox>
            </>
        },
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Type</label>
                <CustomSelect
                    onChange={
                        (e: any) =>
                            e.value !== -1 ?
                                setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Type"), { "name": "Type", "comparator": "==", "value": e.value }])
                                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Type")])
                    }
                    data={sdkIntroContentTypeDataForFilter}
                    isMulti={false}
                    selectedValue={sdkIntroContentTypeDataForFilter?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "Type")?.value) !== undefined ? sdkIntroContentTypeDataForFilter?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "Type")?.value) : -1}
                ></CustomSelect>
            </>
        },
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Content</label>
                <CustomTextbox
                    onChange={
                        (e: any) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Content"), { "name": "Content", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Content")])
                    }
                    placeHolder={"Type a Content"}
                    name='Content'
                    value={filterQuery.find((x: any) => x.name === "Content") !== undefined ? filterQuery.find((x: any) => x.name === "Content")?.value : ""}
                ></CustomTextbox>
            </>
        },
    ]

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.name, "direction": sortDirection === "asc" ? 0 : 1 }])
    };
    useEffect(() => {
        dispatch(getWelcomeScreen({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, filterParams])


    //#endregion DataTable Methods End

    function deleteFunc() {
        dispatch(deleteWelcomeScreenWithId(selectedRowId)).then((response: any) => {
            if (response?.payload?.is_success) {
                showToast.success("Deleted successfully")
                dispatch(getWelcomeScreen({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
            }
            else {
                showToast.error("Delete process failed")
                dispatch(getWelcomeScreen({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
            }
        })
    }

    function createWelcomeScreen(request: any) {
        setloading(true)
        const formData = new FormData();
        formData.append("Title", request?.title !== undefined ? request?.title : "");
        formData.append("Content", request?.content !== undefined ? request?.content : "");
        formData.append("FileStream", file !== null ? file : "");
        formData.append("SdkIntroType", request?.sdkIntroType?.value);
        formData.append("SdkIntroPriority", request?.sdkIntroPriority);

        dispatch(postWelcomeScreen(formData)).then((response: any) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                setFile(null)
                dispatch(getWelcomeScreen({ page_index: 0, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
                setloading(false)
            }
            else {
                formik.resetForm();
                showToast.error("A problem has occurred. Try again")
                setloading(false)
                dispatch(componentsSlice.actions.closeModal({}))
            }
        })
    }

    function updateWithId(request: any) {
        setloading(true)
        const formData = new FormData();
        formData.append("Id", request?.id);
        formData.append("Title", request?.title !== undefined ? request?.title : "");
        formData.append("Content", request?.content !== undefined ? request?.content : "");
        formData.append("FileStream", file !== null ? file : "");
        formData.append("SdkIntroType", request?.sdkIntroType?.value);
        formData.append("SdkIntroPriority", request?.sdkIntroPriority);

        dispatch(putWelcomeScreen(formData)).then((response: any) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                setFile(null)
                dispatch(getWelcomeScreen({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
                setloading(false)
            }
            else {
                setloading(false)
            }
        })
    }

    function customgetWelcomeScreenWithId(rowId: any) {
        if (rowId !== undefined) {
            dispatch(getWelcomeScreenWithId(rowId)).then((s: any) => {
                formik.setValues({
                    id: s?.payload?.result?.sdk_intro_content?.id,
                    title: s?.payload?.result?.sdk_intro_content?.title,
                    content: s?.payload?.result?.sdk_intro_content?.content,
                    sdkIntroPriority: s?.payload?.result?.sdk_intro_content?.sdk_intro_priority,
                    sdkIntroType: (sdkIntroContentTypeData.find(x => x.value === s?.payload?.result?.sdk_intro_content?.sdk_intro_type) as any),
                    fileUrl: s?.payload?.result?.sdk_intro_content?.sdk_intro_url,
                })
            })
        }
    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== null ? "Update Welcome Screen" : "Create Welcome Screen"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Type</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={sdkIntroContentTypeData}
                                            isMulti={false}
                                            selectedValue={formik?.values?.sdkIntroType}
                                            formik={formik}
                                            formikName={"sdkIntroType"}
                                        ></CustomSelect>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className={`${formik.values.sdkIntroType !== null && (formik.values.sdkIntroType as any)?.value === 1 ? "" : "required"} fw-bold fs-6 mb-2`}>Title</label>
                                        <input
                                            placeholder='Type a Title'
                                            type='text'
                                            {...formik.getFieldProps('title')}
                                            name='title'
                                            className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                                            autoComplete='off'
                                        />
                                        {formik.touched.title && formik.errors.title && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.title}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className={`${formik.values.sdkIntroType !== null && (formik.values.sdkIntroType as any)?.value === 1 ? "" : "required"} fw-bold fs-6 mb-2`}>Content</label>
                                        <textarea
                                            placeholder='Type a Content'
                                            {...formik.getFieldProps('content')}
                                            name='content'
                                            className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                                            autoComplete='off'
                                            data-kt-element='input'
                                            style={{ height: "100px" }}
                                        />
                                        {formik.touched.content && formik.errors.content && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.content}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Page Order</label>

                                        <CustomTextbox
                                            {...formik.getFieldProps('sdkIntroPriority')}
                                            onChange={() => null}
                                            placeHolder={"Type a Page Order"}
                                            name='queryPollingFrequency'
                                            value={formik.values.sdkIntroPriority}
                                            formik={formik}
                                            formikName={"sdkIntroPriority"}
                                            type={"number"}
                                            onlyPositive
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>File</label>
                                        <CustomFileUpload
                                            uploadFile={(file: FileList) => {
                                                setFile(file[0])
                                            }}
                                            imageString={formik.values.fileUrl}
                                            formik={formik}
                                            formikName={"fileUrl"}
                                            accept={(formik.values.sdkIntroType as any)?.value === 1 ? "video/mp4" : "image/*"}
                                        ></CustomFileUpload>
                                    </div>
                                    <div className="row">
                                        <div className="col-9"></div>
                                        <div className="col-3">
                                            <button
                                                onClick={() => { setprocessName("Preview") }}
                                                className='btn btn-primary'
                                                data-kt-users-modal-action='cancel'
                                                disabled={formik.values.sdkIntroType === undefined || formik.values.fileUrl === ''}
                                            >
                                                Preview
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        {loading ? (
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        ) :
                                            <span className='indicator-label'>Submit</span>
                                        }
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Preview":
                return <>
                    <CustomModal title={"Preview"}>
                        <PreviewPages screenName={"SdkWelcomeScreen"} content={formik.values}></PreviewPages>
                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                                onClick={() => { setprocessName("Insert") }}
                            >
                                <span className='indicator-label'>Back</span>
                            </button>
                        </div>
                        {/* end::Actions */}
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteFunc()
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={welcomeScreenList?.items}
                    title={"Welcome Screen List"}
                    pagination
                    paginationServer
                    paginationTotalRows={welcomeScreenList?.total_count}
                    isBusy={dataTableisBusy}
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    showTitle
                    showFilter={true}
                    filterOptions={customFilter}
                    searchButtonClickEvent={() => setfilterParams(filterQuery)}
                    sortHandle={handleSort}
                    clearButtonClickEvent={() => {
                        setfilterParams([])
                        setfilterQuery([])
                    }}
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const WelcomeScreenListWrapper: FC<Props> = ({ welcomeScreenList }) => <QueryRequestProvider>
    <ListViewProvider>
        <WelcomeScreenList welcomeScreenList={welcomeScreenList} />
    </ListViewProvider>
</QueryRequestProvider>

export { WelcomeScreenListWrapper }
