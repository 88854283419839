/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../../../_metronic/helpers'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state)

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  const value = useMemo(() => ({state, updateState}), []);

  return (
    <QueryRequestContext.Provider value={value}>
      {children}
    </QueryRequestContext.Provider>
  )
} 

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
