/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    faCheckCircle,
    faExclamationCircle,
    IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import Styles from "../../../_metronic/assets/sass/pages/frpPurchaseRateList.module.scss"

interface Props {
    onChange: (e: string) => void;
    placeHolder?: string;
    value?: string;
    className?: string;
    disabled?: boolean;
    icon?: IconDefinition;
    iconStyle?: any;
    name?: any;
    formik?: any;
    formikName?: any;
}

const CustomEmailTextbox = React.forwardRef<HTMLInputElement, Props>(
    (
        {
            onChange,
            placeHolder,
            value,
            disabled,
            icon,
            className,
            iconStyle,
            name,
            formik,
            formikName
        },
        ref
    ) => {
        const [emailValue, setemailValue] = useState("");
        const [emailStatus, setemailStatus] = useState(false);

        useEffect(() => {
            setemailValue(value !== undefined ? value.trimStart() : "");
        }, []);

        useEffect(() => {
            setemailStatus(emailValidation());
            if (emailValidation()) {
                onChange(emailValue);
            } else {
                onChange("");
            }
        }, [emailValue]);

        useEffect(() => {
            if (value === undefined) {
                setemailValue("")
            }
            else {
                setemailValue(value !== undefined ? value : "")
            }
        }, [value])


        function emailValidation() {
            const regex =
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            return !(!emailValue || regex.test(emailValue) === false);
        }

        return icon ? (
            <>
                <div className={`${Styles.inner_addon} ${Styles.left_addon}`}>
                    {
                        <FontAwesomeIcon
                            icon={icon}
                            className={`${Styles.glyphicon} ${iconStyle}`}
                        ></FontAwesomeIcon>
                    }
                    <input
                        name={name}
                        ref={ref}
                        type={"email"}
                        placeholder={placeHolder}
                        onChange={(event) => {
                            setemailValue(event.target.value.trimStart());
                            formik?.setValues({
                                ...formik?.values,
                                [formikName]: event.target.value.trimStart()
                            })
                        }}
                        //defaultValue={emailValue}
                        value={emailValue}
                        disabled={disabled}
                        className={`${clsx('form-control form-control-solid mb-3 mb-lg-0')} ${Styles.textbox}`}
                    ></input>
                </div>
                {formik?.touched?.[formikName] && formik?.errors?.[formikName] && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik?.errors?.[formikName]}</span>
                        </div>
                    </div>
                )}
            </>
        ) : (
            <>
                <div className={`${Styles.inner_addon} ${Styles.right_addon}`}>
                    {emailStatus ? (
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            color={"#6EE1AA"}
                            className={`${Styles.glyphicon}`}
                        ></FontAwesomeIcon>
                    ) : (
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            color={"#EB4E56"}
                            className={`${Styles.glyphicon}`}
                        ></FontAwesomeIcon>
                    )}
                    <input
                        name={name}
                        ref={ref}
                        type={"email"}
                        placeholder={placeHolder}
                        onChange={(event) => {
                            setemailValue(event.target.value);
                            formik?.setValues({
                                ...formik?.values,
                                [formikName]: event.target.value
                            })
                        }}
                        //defaultValue={emailValue}
                        value={emailValue}
                        disabled={disabled}
                        className={`${clsx('form-control form-control-solid mb-3 mb-lg-0')} ${Styles.textbox}`}
                    ></input>
                </div>
                {formik?.touched?.[formikName] && formik?.errors?.[formikName] && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik?.errors?.[formikName]}</span>
                        </div>
                    </div>
                )}
            </>
        );
    }
);

CustomEmailTextbox.defaultProps = {
    placeHolder: "Type your Email",
    disabled: false,
};

export default CustomEmailTextbox;
