import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IZoneSystemMessagesService from "../../../services/campaignManagament/iZoneSystemMessages/iZoneSystemMessages.service";


interface InitialStateInterface {
    IZoneSystemMessagesListSlice: any
    IZoneSystemMessagesSlice: any[]
}

const initialState = {
    IZoneSystemMessagesListSlice: null,
    IZoneSystemMessagesSlice: [],
} as InitialStateInterface;

export const getIZoneSystemMessages = createAsyncThunk(
    "getIZoneSystemMessagesList",
    async (req: any) => {
        try {
            return await IZoneSystemMessagesService.getIZoneSystemMessagesService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportIZoneSystemMessages = createAsyncThunk(
    "exportIZoneSystemMessagesList",
    async (req: any) => {
        try {
            return await IZoneSystemMessagesService.exportIZoneSystemMessagesService(req).then(response => response);
        } catch (error) { }
    }
);
export const postIZoneSystemMessages = createAsyncThunk(
    "postIZoneSystemMessages",
    async (req: any, thunkAPI) => {
        try {
            return await IZoneSystemMessagesService.postIZoneSystemMessagesService(req).then(response => response);
        } catch (error) { }
    }
);

export const putIZoneSystemMessages = createAsyncThunk(
    "putIZoneSystemMessages",
    async (req: any, thunkAPI) => {
        try {
            return await IZoneSystemMessagesService.putIZoneSystemMessagesService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteIZoneSystemMessagesWithId = createAsyncThunk(
    "deleteIZoneSystemMessages",
    async (req: any, thunkAPI) => {
        try {
            return await IZoneSystemMessagesService.deleteIZoneSystemMessagesService(req).then(response => response);
        } catch (error) { }
    }
);

export const getIZoneSystemMessagesWithId = createAsyncThunk(
    "getIZoneSystemMessagesWithId",
    async (req: any) => {
        try {
            return await IZoneSystemMessagesService.getIZoneSystemMessagesWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const IZoneSystemMessagesSlice = createSlice({
    name: "IZoneSystemMessagesSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getIZoneSystemMessages.fulfilled, (state, action) => {
            state.IZoneSystemMessagesListSlice = action.payload?.result ? action.payload.result?.i_zone_system_messages : null;
        });
        builder.addCase(getIZoneSystemMessagesWithId.fulfilled, (state, action) => {
            state.IZoneSystemMessagesSlice = action.payload?.result ? action.payload.result?.i_zone_system_message : [];
        });
    },
});

export default IZoneSystemMessagesSlice;
