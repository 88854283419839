import { callApi, HttpMethods } from "../../utils/api-util-auth";

const customLoginService = async (username: any, password: any) => {
    let jsonData = new URLSearchParams({
        client_id: 'backoffice',
        client_secret: '6e6c2fdb-c17e-4696-9f9d-f50cea916fbb',
        grant_type: 'password',
        username: username,
        password: password
    })
    let _options = {
        controller: "/connect/token",
        method: HttpMethods.POST,
        apiUrl: "auth",
        data: jsonData
    };
    return await callApi(_options);
  }

const accountService = {
    customLoginService
};

export default accountService;
