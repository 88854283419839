/* eslint-disable jsx-a11y/alt-text */
import { faCog } from '@fortawesome/pro-light-svg-icons'
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import Style from '../../../_metronic/assets/sass/pages/sliderContentPreview.module.scss'
import { SliderType } from '../enums/SliderTypeEnum';

interface Props {
    content?: any;
}

const SliderContentPreview: FC<Props> = ({ content }) => {

    function findUrl(url: any) {
        if (typeof url === "object") {
            return URL.createObjectURL(url[0])
        }
        else {
            return url
        }
    }
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className={`${Style.titleBar} row`}>
                        <div className='col-2'><img src='/media/logos/veracity-white.png' className={`${Style.veracityLogo}`}></img></div>
                        <div className='col-8'>
                            <div className='row'>
                                <div className='col-4'></div>
                                <div className={`${Style.whiteFont} col-2`}>FRP</div>
                                <div className={`${Style.whiteFont} col-2`}>100</div>
                                <div className='col-4'></div>
                            </div>
                        </div>
                        <div className='col-2'>
                            <FontAwesomeIcon icon={faCog} className={Style.FontAwesomeIcon} color={"white"}></FontAwesomeIcon>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className={`row`}>
                        <img src='/phoneImages/sliderManagement-1.png' className={`${Style.contentProfile}`}></img>
                    </div>
                    <div className={`${Style.sliderArea} row`}>
                        <div className={`${Style.sliderBorder} row`} style={{ backgroundImage: content?.url !== "" ? `url(${findUrl(content.url)})` : "" }}>
                            {
                                content?.sliderType?.value !== SliderType.Video ?
                                    <>
                                        <div className={`${Style.sliderTitle} row`}>{content?.title}</div>
                                        <div className={`${Style.sliderContent} row`}>{content?.content}</div>
                                        <div className={`${Style.sliderButtonDiv} row`}>
                                            <button
                                                onClick={() => null}
                                                className={`${Style.sliderButton}`}
                                                data-kt-users-modal-action='cancel'
                                                disabled
                                            >
                                                {content?.sliderType?.label}
                                            </button>
                                        </div> </> :
                                    <>
                                        <div className={`${Style.videoPlayIcon} row`}>
                                            <FontAwesomeIcon icon={faPlay} size={"4x"} color={"white"}></FontAwesomeIcon>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <img src='/phoneImages/Navigation-Genreral.png' className={`${Style.footer}`}></img>
                </div>
            </div>
        </>
    )
}

export default SliderContentPreview