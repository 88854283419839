import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { ResponseType } from "./enums/ResponseType.enum";

const axiosOptions: AxiosRequestConfig = {
    responseEncoding: "",
    url: "",
    data: "",
    params: "",
    method: undefined,
};

export const HttpMethods = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
};

function axiosOptionBuilder(options: any) {
    let URL = ""
    if (options?.forHealty) {
        URL = window.__RUNTIME_CONFIG__.REACT_APP_RESOURCE_API_URL;
    } else {
        URL = `${window.__RUNTIME_CONFIG__.REACT_APP_RESOURCE_API_URL}${window.__RUNTIME_CONFIG__.REACT_APP_RESOURCE_API_PATH}`;
    }
    try {
        const _url =
            options.url === undefined ||
                options.url === "" ||
                options.url === "" ||
                options.url === null
                ? URL
                : options.url;
        axiosOptions.url = _url + options.controller;
        axiosOptions.method = options.method;
        axiosOptions.data = options.data;
        axiosOptions.params = options.params;

        let token = null;
        token = localStorage.getItem("kt-auth-react-v")
        token = token == null ? "" : JSON.parse(token)
        let header: AxiosRequestHeaders = {
            "Authorization": "Bearer " + (token?.api_token),
            "Content-Type": options.contentType ?? "application/json",
        };
        axiosOptions.headers = header;
        return axiosOptions;
    } catch (error) {
        throw new Error();
    }
}

export const callApi = async (options: any) => {
    let apiOptions = axiosOptionBuilder(options);

    const resp = await axios(apiOptions)
        .then(function (response) {
            if (response.status === 200) {
                if (options.responseType === ResponseType.File) {
                    let fileName = 'downloaded_file';
                    const contentDisposition = response.headers['content-disposition'];
                    if (contentDisposition) {
                        const matches = contentDisposition.match(/filename="(.+)"/);
                        if (matches && matches.length > 1) {
                            fileName = matches[1];
                        }
                    }
                    let link = window.document.createElement("a");
                    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(response.data));
                    link.download = fileName;
                    link.click();
                }
                else if (response.data?.type === undefined) {
                    return response.data;
                } else {
                    return response;
                }

            } else if (response.status === 401) {
                localStorage.clear()
                window.location.reload();
                return response;
            } else if (response.status === 403 || response.status === 500 || response.status === 503) {
                return response;
            } else {
                return response.statusText;
            }
        })
        .catch(function (response) {
            if (response?.response?.data?.entries) {
                return response?.response?.data;
            }
            if (response?.message === "Request failed with status code 401") {
                localStorage.clear()
                window.location.reload();
            }
        });
    return resp;
};

const apiUtils = {
    callApi,
};
export default apiUtils;
