import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import credentialMerchantLogoService from "../../services/credentialManagement/CredentialMerchantLogo.service";


interface InitialStateInterface {
    merchantLogoListSlice: any
}

const initialState = {
    merchantLogoListSlice: null,
} as InitialStateInterface;

export const getMerchantLogo = createAsyncThunk(
    "getMerchantLogoList",
    async (req: any) => {
        try {
            return await credentialMerchantLogoService.getCredentialMerchantLogoService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportMerchantLogo = createAsyncThunk(
    "exportMerchantLogo",
    async (req: any) => {
        try {
            return await credentialMerchantLogoService.exportCredentialMerchantLogoService(req).then(response => response);
        } catch (error) { }
    }
);

export const postMerchantLogo = createAsyncThunk(
    "postMerchantLogo",
    async (req: any, thunkAPI) => {
        try {
            return await credentialMerchantLogoService.postCredentialMerchantLogoService(req).then(response => response);
        } catch (error) { }
    }
);

export const putMerchantLogo = createAsyncThunk(
    "putMerchantLogo",
    async (req: any, thunkAPI) => {
        try {
            return await credentialMerchantLogoService.putCredentialMerchantLogoService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteMerchantLogoWithId = createAsyncThunk(
    "deleteMerchantLogo",
    async (req: any, thunkAPI) => {
        try {
            return await credentialMerchantLogoService.deleteCredentialMerchantLogoService(req).then(response => response);
        } catch (error) { }
    }
);

export const getMerchantLogoWithId = createAsyncThunk(
    "getMerchantLogoWithId",
    async (req: any) => {
        try {
            return await credentialMerchantLogoService.getCredentialMerchantLogoWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const credentialMerchantLogoSlice = createSlice({
    name: "credentialMerchantLogoSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMerchantLogo.fulfilled, (state, action) => {
            state.merchantLogoListSlice = action.payload?.result ? action.payload.result?.credential_merchant_logos : null;
        });
    },
});

export default credentialMerchantLogoSlice;
