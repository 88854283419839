export enum SliderType {
    Shoutback = 0,
    Credential = 1,
    Coupon = 2,
    Invitation = 3,
    DataSource = 4,
    FRP = 5,
    Merchant = 6,
    Members = 7,
    Video = 8,
}

