
import { callApi, HttpMethods } from "../../../../utils/api-util";
import { ResponseType } from "../../../../utils/enums/ResponseType.enum";

const getContentService = async (req:any) => {
    let _options = {
        controller: "/sdk-content/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}

const exportContentService = async (req:any) => {
    let _options = {
        controller: "/sdk-content/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}

const getExistContentFormat = async () => {
    let _options = {
        controller: "/sdk-content/get-exist",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}




const postContentService = async (req: any) => {
    let _options = {
        controller: "/sdk-content",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putContentService = async (req: any) => {
    let _options = {
        controller: "/sdk-content",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteContentService = async (req: any) => {
    let _options = {
        controller: "/sdk-content",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getContentWithIdService = async (req:any) => {
    let _options = {
        controller: "/sdk-content/get-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const ContentService = {
    getContentService,
    exportContentService,
    postContentService,
    putContentService,
    deleteContentService,
    getContentWithIdService,
    getExistContentFormat
};

export default ContentService;
