/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItem } from './SidebarMenuItem'
import { AppDispatch, useAppDispatch } from '../../../../../app/store/store'
import { getPages } from '../../../../../app/slices/assetManagement/console/HelperSlice'

const SidebarMenuHelper = () => {
  const intl = useIntl()
  const dispatch: AppDispatch = useAppDispatch()
  const [menuList, setmenuList] = useState<any>()
  useEffect(() => {
    dispatch(getPages("1")).then((resp) => {
      setmenuList(resp?.payload?.result?.pages)
    })
  }, [])
  
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>User Manual List</span>
        </div>
      </div>
      {
        menuList?.map((x:any) => {
          return (
            <SidebarMenuItem
              to={`/UserManual${x?.helper_url}`}
              title={`${x?.name}`}
              icon='/media/icons/duotune/files/fil024.svg'
              key={x.id}
            ></SidebarMenuItem>
          )
        }
        )

      }
    </>
  )
}

export { SidebarMenuHelper }
