import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SalesAssistantService from "../../../services/salesAssistant/SalesAssistant.service";


interface InitialStateInterface {
    ContractListSlice: any
    ContractSlice: any[]
}

const initialState = {
    ContractListSlice: null,
    ContractSlice: [],
} as InitialStateInterface;


export const postContract = createAsyncThunk(
    "postContract",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.postContractService(req).then(response => response);
        } catch (error) { }
    }
);

export const getContract = createAsyncThunk(
    "getContractList",
    async (req: any) => {
        try {
            return await SalesAssistantService.getContractService(req).then(response => response);
        } catch (error) { }
    }
);


export const exportContractList = createAsyncThunk(
    "getContractList",
    async (req: any) => {
        try {
            return await SalesAssistantService.exportContractList(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteContractWithId = createAsyncThunk(
    "deleteContract",
    async (req: any, thunkAPI) => {
        try {
            return await SalesAssistantService.deleteContractService(req).then(response => response);
        } catch (error) { }
    }
);


const ContractsSlice = createSlice({
    name: "ContractSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getContract.fulfilled, (state, action) => {
            state.ContractListSlice = action.payload?.result ? action.payload.result?.contract : null;
        });
    },
});

export default ContractsSlice;
