/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTCard, KTCardBody } from "../../../../_metronic/helpers"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC, useEffect } from "react"
import { AppDispatch, useAppSelector } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import Styles from "../../../../_metronic/assets/sass/pages/frpPurchaseRateList.module.scss"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getIncentive, putIncentive, postIncentive, postDistributionRules, getDistributionRules, putDistributionRules } from "../../../slices/incentiveManagement/IncentiveSlice"
import { showToast } from "../../../components/custom-toasts/custom-toasts"
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"
import { Tab, Tabs } from "react-bootstrap"
import CustomPercentageCard from "../../../components/custom-percentage-card/custom-percentage-card"
import { CustomParentPercentageCard } from "../../../components/custom-parent-percentage-card/custom-parent-percentage-card"
import { Doughnut } from "react-chartjs-2"
import { Chart as ChartJs, ArcElement, Tooltip, Legend, ChartType } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"

ChartJs.register(ArcElement, Tooltip, Legend, ChartDataLabels);


interface Props {
  incentive: any
  distiributionRules: any
}
const Incentive: FC<Props> = ({ incentive, distiributionRules }) => {
  const dispatch: AppDispatch = useDispatch();


  const formik = useFormik({
    initialValues: {
      id: '',
      memberRegistrationBonus: '',
      businessRegistrationBonus: '',
      referralGetMemberBonus: '',
    },
    validationSchema: Yup.object().shape({
      memberRegistrationBonus: Yup.number()
        .required('Member Registration Bonus is required'),
      businessRegistrationBonus: Yup.number()
        .required('Business Registration Bonus is required'),
      referralGetMemberBonus: Yup.number()
        .required('Referral Get Member Bonus is required'),
    }),
    onSubmit: (values, actions) => {
      try {
        if (formik.values.id) {
          updateWithId(values)
        }
        else {
          createWithRequest(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm();
      }
    },
  })


  const rulesFormik = useFormik({
    initialValues: {
      id: '',
      consumer: '',
      consumersParent: '',
      partner: '',
    },
    onSubmit: (values, actions) => {

    },
  })


  const data = {
    datasets: [
      {
        data: [rulesFormik.values.consumer, 100 - +rulesFormik.values.consumer],
        backgroundColor: ['#FBE38EB2', '#31638A'],
        cutout: '50%', // Larger cutout for inner chart
      },
      {
        data: [rulesFormik.values.consumer, (((100 - rulesFormik.getFieldProps('consumer').value) - (((100 - rulesFormik.getFieldProps('consumer').value) * (rulesFormik.getFieldProps('consumersParent').value)) / 100) - (((100 - rulesFormik.getFieldProps('consumer').value) * (rulesFormik.getFieldProps('partner').value)) / 100))).toFixed(2), ((100 - rulesFormik.getFieldProps('consumer').value) * (rulesFormik.getFieldProps('consumersParent').value)) / 100, ((100 - rulesFormik.getFieldProps('consumer').value) * (rulesFormik.getFieldProps('partner').value)) / 100],
        backgroundColor: ['#FBE38EB2', '#31638A', '#4BC0C0', '#FF9F40'],
        cutout: '15%',
      },
    ],
  };


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 32,
    },
    plugins: {
      datalabels: {
        color: (context: any) => {
          const index = context.dataIndex; 
          const colors = ['black', 'white', 'black', 'black']; 

          return colors[index % colors.length];
        },
        padding: 1,
        formatter: (value: number) => '%' + +value,
        offset: 2,
        font: {
          weight: 450,
          size: 13,
          family: 'Gentona'

        }
      },
    },
  };


  function saveorUpdateRules() {
    try {
      if (rulesFormik.values.id) {
        updateDistributionRules(rulesFormik.values)
      }
      else {
        createDistributionRules(rulesFormik.values)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
    }

  }

  useEffect(() => {
    if (incentive && Object.keys(incentive).length > 0) {
      formik.setValues({
        id: incentive?.id,
        businessRegistrationBonus: Number(incentive?.business_registration_bonus).toFixed(2),
        memberRegistrationBonus: Number(incentive?.member_registration_bonus).toFixed(2),
        referralGetMemberBonus: Number(incentive?.referral_get_member_bonus).toFixed(2)
      })
    }
  }, [incentive])

  useEffect(() => {

    if (distiributionRules && Object.keys(distiributionRules).length > 0) {
      rulesFormik.setValues({
        id: distiributionRules?.id,
        consumer: Number(distiributionRules?.consumer).toFixed(2),
        consumersParent: Number(distiributionRules?.consumers_parent).toFixed(2),
        partner: Number(distiributionRules?.partner).toFixed(2)
      })
    }
  }, [distiributionRules])



  function updateWithId(request: any) {
    let req = {
      id: request.id,
      member_registration_bonus: request.memberRegistrationBonus,
      business_registration_bonus: request.businessRegistrationBonus,
      referral_get_member_bonus: request.referralGetMemberBonus
    }
    dispatch(putIncentive(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getIncentive())
        showToast.success("Item Updated Successfully")
      }
      else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function createWithRequest(request: any) {
    let req = {
      member_registration_bonus: request.memberRegistrationBonus,
      business_registration_bonus: request.businessRegistrationBonus,
      referral_get_member_bonus: request.referralGetMemberBonus
    }
    dispatch(postIncentive(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getIncentive())
        showToast.success("Item Saved Successfully")
      }
    })
  }



  function createDistributionRules(request: any) {
    let req = {
      consumer: request.consumer,
      consumers_parent: request.consumersParent,
      partner: request.partner
    }
    dispatch(postDistributionRules(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getDistributionRules())
        showToast.success("Item Saved Successfully")
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function updateDistributionRules(request: any) {
    let req = {
      id: request.id,
      consumer: request.consumer,
      consumers_parent: request.consumersParent,
      partner: request.partner
    }
    dispatch(putDistributionRules(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getDistributionRules())
        showToast.success("Item Updated Successfully")
      }
      else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  return (
    <>
      <Tabs defaultActiveKey='referrals' id='tab' className='mb-3'>
        <Tab eventKey='referrals' title='Referrals'>
          <KTCard>
            <KTCardBody>
              <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                <div className={`${Styles.container}`}>
                  <div className="row">
                    <div className="col-3">
                      <label className='required fw-bold fs-6 mb-2'>Sign up bonus merchants</label>
                      <CustomTextbox
                        {...formik.getFieldProps('businessRegistrationBonus')}
                        onChange={() => null}
                        placeHolder={"Sign up bonus merchants"}
                        name='businessRegistrationBonus'
                        formik={formik}
                        formikName={"businessRegistrationBonus"}
                        onlyPositive
                        type={"number"}
                      ></CustomTextbox>
                    </div>
                    <div className="col-1" style={{ padding: 0, margin: 0 }}>
                      <label className='fw-bold fs-6 mt-12'>FRP</label>
                    </div>


                  </div>
                  <div className="row mt-2">
                    <div className="col-3">
                      <label className='required fw-bold fs-6 mb-2'>Sign up bonus consumers</label>
                      <CustomTextbox
                        {...formik.getFieldProps('memberRegistrationBonus')}
                        onChange={() => null}
                        placeHolder={"Sign up bonus consumers"}
                        name='memberRegistrationBonus'
                        formik={formik}
                        formikName={"memberRegistrationBonus"}
                        onlyPositive
                        type={"number"}
                      ></CustomTextbox>
                    </div>
                    <div className="col-1" style={{ padding: 0, margin: 0 }}>
                      <label className='fw-bold fs-6 mt-12'>FRP</label>
                    </div>


                  </div>
                  <div className="row mt-2">
                    <div className="col-3">
                      <div className='fv-row'>
                        <label className='required fw-bold fs-6 mb-2'>Referral Get Member Bonus</label>
                        <CustomTextbox
                          {...formik.getFieldProps('referralGetMemberBonus')}
                          onChange={() => null}
                          placeHolder={"Referral Get Member Bonus"}
                          name='referralGetMemberBonus'
                          formik={formik}
                          formikName={"referralGetMemberBonus"}
                          onlyPositive
                          type={"number"}
                        ></CustomTextbox>
                      </div>
                    </div>
                    <div className="col-1" style={{ padding: 0, margin: 0 }}>
                      <label className='fw-bold fs-6 mt-12'>FRP</label>
                    </div>


                  </div>

                  <div className="row">
                    <div className="col-7" style={{ textAlign: "right" }}>
                      <button
                        type='submit'
                        className={`btn btn-primary ${Styles.saveButton}`}
                        data-kt-users-modal-action='submit'
                        onClick={() => { }}
                      >
                        <span className='indicator-label'>Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </KTCardBody>
          </KTCard>
        </Tab>
        <Tab eventKey='rules' title='Distribution Rules'>

          <KTCard>
            <KTCardBody>
              <form id='kt_modal_add_user_form' className='form' onSubmit={(e => e.preventDefault())}>
                <div className={`${Styles.container}`}>
                  <div className={`${Styles.ruleContainer}`}>
                    <div className={`${Styles.ruleLeftColumn}`}>
                      <CustomPercentageCard name="Consumer" value={rulesFormik.values.consumer} formik={rulesFormik} formikName={"consumer"} subName="%s for consumer" isButtonVisible={true} isTitleBorderVisible={true}></CustomPercentageCard>

                      <CustomPercentageCard name="FreedomPay" value={100 - rulesFormik.getFieldProps('consumer').value} subName="FreedomPay" color="#31638A" textColor="#FFFFFF" isTitleBorderVisible={true} ></CustomPercentageCard>
                    </div>
                    <div className={`${Styles.donutChart}`}>

                    <Doughnut data={data} options={options} />
                    </div>
                    <div className={`${Styles.ruleRightColumn}`}>
                      <CustomParentPercentageCard
                        title="FreedomPay"
                        children={

                          <div>
                            <CustomPercentageCard subName="Consumer's Parent" value={rulesFormik.getFieldProps('consumersParent').value} leftValue={((100 - rulesFormik.getFieldProps('consumer').value) * (rulesFormik.getFieldProps('consumersParent').value)) / 100} formik={rulesFormik} formikName={"consumersParent"} color="#4BC0C0" textColor="#000000" height="94px" isLeftButtonVisible={true} isButtonVisible={true} ></CustomPercentageCard>

                            <CustomPercentageCard subName="Partner" value={rulesFormik.getFieldProps('partner').value} leftValue={((100 - rulesFormik.getFieldProps('consumer').value) * (rulesFormik.getFieldProps('partner').value)) / 100} formik={rulesFormik} formikName={"partner"} color="#FF9F40" textColor="#000000" height="94px" isLeftButtonVisible={true} isButtonVisible={true} ></CustomPercentageCard>

                            <CustomPercentageCard subName="FreedomPay [visobl]" value={(100 - rulesFormik.getFieldProps('consumersParent').value) - rulesFormik.getFieldProps('partner').value} leftValue={(((100 - rulesFormik.getFieldProps('consumer').value) - (((100 - rulesFormik.getFieldProps('consumer').value) * (rulesFormik.getFieldProps('consumersParent').value)) / 100) - (((100 - rulesFormik.getFieldProps('consumer').value) * (rulesFormik.getFieldProps('partner').value)) / 100))).toFixed(2)} color="#31638A" textColor="#FFFFFF" height="94px" isLeftButtonVisible={true}></CustomPercentageCard>

                          </div>

                        }
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12" style={{ textAlign: "right" }}>
                      <button
                        className={`btn btn-primary ${Styles.saveButton}`}
                        onClick={() => { saveorUpdateRules() }}
                      >
                        <span className='indicator-label'>Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </KTCardBody>
          </KTCard>
        </Tab>
      </Tabs>
    </>
  )
}

const IncentiveWrapper: FC<Props> = ({ incentive, distiributionRules }) => <QueryRequestProvider>
  <ListViewProvider>
    <Incentive incentive={incentive} distiributionRules={distiributionRules} />
  </ListViewProvider>
</QueryRequestProvider>

export { IncentiveWrapper }
