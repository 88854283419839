import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircle} from '@fortawesome/pro-solid-svg-icons'
import Style from '../../../_metronic/assets/sass/components/custom-accordion.module.scss'

interface Props {
  list: Array<ListItem>
  isFlush?: boolean
  isAlwaysOpen?: boolean
  hasIcon?: boolean
  colorControl?: Array<string>
  customHeaderStyle?: string
  noAccordionHeaderStyle?: boolean
  customId?: string
  isOpen?: boolean
}

export type ListItem = {
  id:string,
  header: string
  content: any
  icon?: any
}

const CustomAccordion: React.FC<Props> = ({
  list,
  isFlush,
  isAlwaysOpen,
  hasIcon,
  colorControl,
  customHeaderStyle,
  noAccordionHeaderStyle,
  customId,
  isOpen,
}) => {
  return isAlwaysOpen ? (
    <div
      className={`accordion  ${isFlush ? 'accordion-flush' : ''}`}
      id={`${customId !== undefined ? customId + '-' : ''}customAccordion`}
    >
      {list?.map((item: ListItem, index: number) => (
        <div className={Style.accordionItem + ' accordion-item mb-1'} key={item.id}>
          <h2
            className={`accordion-header ${
              noAccordionHeaderStyle === true ? '' : Style.accordionHeader
            } `}
            id={`${customId !== undefined ? customId + '-' : ''}heading${index.toString()}`}
          >
            <button
              className={`accordion-button collapsed ${Style.button}`}
              type='button'
              data-bs-toggle='collapse'
              data-bs-target={`#${
                customId !== undefined ? customId + '-' : ''
              }collapse${index.toString()}`}
              aria-expanded='false'
              aria-controls={`${
                customId !== undefined ? customId + '-' : ''
              }collapse${index.toString()}`}
            >
              <div className={` ${Style.header}  ${customHeaderStyle}`}>{item.header}</div>
              {hasIcon ? (
                item?.icon === undefined ? (
                  <FontAwesomeIcon
                    style={{
                      color: colorControl ? colorControl[index] : 'inherit',
                    }}
                    className={Style.icon}
                    icon={faCircle}
                  />
                ) : (
                  item?.icon
                )
              ) : (
                <></>
              )}
            </button>
          </h2>

          <div
            id={`${customId !== undefined ? customId + '-' : ''}collapse${index.toString()}`}
            className={isOpen ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'}
            aria-labelledby={`${
              customId !== undefined ? customId + '-' : ''
            }heading${index.toString()}`}
            data-bs-parent={`#${customId !== undefined ? customId + '-' : ''}customAccordion`}
          >
            <div className={'accordion-body ' + Style.accBody}>{item.content}</div>
          </div>
        </div>
      ))}
    </div>
  ) : null
}

CustomAccordion.defaultProps = {
  isFlush: true,
  isAlwaysOpen: true,
  hasIcon: false,
}

export default CustomAccordion
