/* eslint-disable no-template-curly-in-string */

import { callApi, HttpMethods } from "../../../../utils/api-util";

const getVeracityContactInformationService = async () => {
    let _options = {
        controller: "/veracity-contact-information",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}

const postVeracityContactInformationService = async (req: any) => {
    let _options = {
        controller: "/veracity-contact-information",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putVeracityContactInformationService = async (req: any) => {
    let _options = {
        controller: "/veracity-contact-information",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const VeracityContactInformationService = {
    getVeracityContactInformationService,
    postVeracityContactInformationService,
    putVeracityContactInformationService,
};

export default VeracityContactInformationService;
