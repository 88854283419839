/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTCard, KTSVG } from "../../../../_metronic/helpers"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"

interface Props {
    tplConfigurationList: any[]
}
const TplConfiguration: FC<Props> = ({ tplConfigurationList }) => {
  let tplConfigurationListTemp = [{ "merchant": "Starbucks", "loyalty": "Star" },{ "merchant": "Mariott Bonvoy", "loyalty": "Bonvoy" }]




  const columns = [
    {
      name: 'Merchant',
      selector: (row: any) => <div>{row?.merchant}</div>,
    },
    {
      name: 'Loyalty',
      selector: (row: any) => <div>{row?.loyalty}</div>,
    },
    {
      name: 'Edit',
      selector: (row: any) =>
          <>
              <div className='btn btn-icon btn-bg-light btn-pending-color-primary btn-sm me-1'
                  
              >
                  <KTSVG path='/media/icons/duotune/abstract/abs007.svg' className='svg-icon-3' />
              </div>
          </>
      ,
      width: "100px",
  },
  {
      name: 'FRP Exchange Rate',
      selector: (row: any) =>
          <>
              <div className='btn btn-icon btn-bg-light btn-pending-color-primary btn-sm me-1'
                  onClick={() => {
                  }}
              >
                  <KTSVG path='/media/icons/duotune/coding/cod002.svg' className='svg-icon-3' />
              </div>
          </>
      ,
      width: "100px",
  },
  ];
  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={tplConfigurationListTemp}
          pagination
          showFilter={false}
          isExport
        ></CustomDatatable>
      </KTCard>
    </>
  )
}

const TplConfigurationWrapper: FC<Props> = ({tplConfigurationList}) => <QueryRequestProvider>
  <ListViewProvider>
    <TplConfiguration tplConfigurationList={tplConfigurationList} />
  </ListViewProvider>
</QueryRequestProvider>

export { TplConfigurationWrapper }
