
import { callApi, HttpMethods } from "../../../utils/api-util";
import { ResponseType } from "../../../utils/enums/ResponseType.enum";

const getAttributeFormatService = async (req: any) => {
    let _options = {
        controller: "/attribute-format/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
        
    };
    const result = await callApi(_options);
    return result;
};


const getExistAttributeFormatService = async () => {
    let _options = {
        controller: "/attribute-format/get-exist",
        method: HttpMethods.GET,
        
    };
    const result = await callApi(_options);
    return result;
};
const exportAttributeFormatService = async (req: any) => {
    let _options = {
        controller: "/attribute-format/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
        
    };
    const result = await callApi(_options);
    return result;
};

const postAttributeFormatService = async (req: any) => {
    let _options = {
        controller: "/attribute-format",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putAttributeFormatService = async (req: any) => {
    let _options = {
        controller: "/attribute-format",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteAttributeFormatService = async (req: any) => {
    let _options = {
        controller: "/attribute-format",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getAttributeFormatWithIdService = async (req:any) => {
    let _options = {
        controller: "/attribute-format/get-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const AttributeFormatService = {
    getAttributeFormatService,
    exportAttributeFormatService,
    putAttributeFormatService,
    getAttributeFormatWithIdService,
    postAttributeFormatService,
    deleteAttributeFormatService,
    getExistAttributeFormatService
};

export default AttributeFormatService;
