import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FRPRequestService from "../../services/frpRequest/frpRequest.service";

interface InitialStateInterface {
}

const initialState = {
} as InitialStateInterface;

export const postFRPRequest = createAsyncThunk(
    "postFRPRequest",
    async (req: any, thunkAPI) => {
        try {
            return await FRPRequestService.postFRPRequestService(req).then(response => response);
        } catch (error) { }
    }
);

const FRPRequestSlice = createSlice({
    name: "FRPRequestSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
    },
});

export default FRPRequestSlice;
