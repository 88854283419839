
import { callApi, HttpMethods } from "../../../../utils/api-util";
import { ResponseType } from "../../../../utils/enums/ResponseType.enum";

const getTenderService = async (req: any) => {
    let _options = {
        controller: "/sdk-tender/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}
const exportTenderService = async (req: any) => {
    let _options = {
        controller: "/sdk-tender/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}


const postTenderService = async (req: any) => {
    let _options = {
        controller: "/sdk-tender",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putTenderService = async (req: any) => {
    let _options = {
        controller: "/sdk-tender",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteTenderService = async (req: any) => {
    let _options = {
        controller: "/sdk-tender",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getTenderWithIdService = async (req:any) => {
    let _options = {
        controller: "/sdk-tender/get-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const getExistTender = async () => {
    let _options = {
        controller: "/sdk-tender/get-exist",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const TenderService = {
    getTenderService,
    exportTenderService,
    postTenderService,
    putTenderService,
    deleteTenderService,
    getTenderWithIdService,
    getExistTender
};

export default TenderService;
