/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../../slices/components/componentsSlice"
import { showToast } from "../../../components/custom-toasts/custom-toasts"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, KTCard, KTSVG } from "../../../../_metronic/helpers"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { deleteDataSourcesWithId, exportDataSources, getDataProviderType, getDataSources, getDataSourcesWithId, postDataSources, putDataSources } from "../../../slices/credentialManagement/dataSources/DataSourcesSlice"
import Styles from "../../../../_metronic/assets/sass/pages/videoList.module.scss"
import CustomSelect from "../../../components/customModal/CustomSelect"
import CustomTextbox from "../../../components/customTextbox/CustomTextbox"
import CustomFileUpload from "../../../components/customFileUpload/CustomImageUpload"

interface Props {
    DataSourcesListSlice: any
}

const DataSourcesList: FC<Props> = ({ DataSourcesListSlice }) => {
    const [types, setTypes] = useState([] as any[])
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { DataProviderTypes } = useAppSelector((state) => state.dataSources);
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [selectedRowId, setSelectedRowId] = useState(undefined)
    const [file, setFile] = useState<File | null>(null);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([])
    const [filterParams, setfilterParams] = useState<any>([])

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            credentialExpireInDays: '',
            dataProviderType: [],
            icon: '',
            refreshPeriod: '',
            hybridRefreshPeriod: '',
            isActive: false,
            isActiveOnHybridService: false,
        },
        validationSchema: Yup.object().shape({

            name: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(300, 'Maximum 300 symbols')
                .required('Name is required'),
            credentialExpireInDays: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Credential Expire In Days is required'),
            dataProviderType: Yup.array()
                .min(1, "Type is required")
                .nullable(),
            icon: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .max(400, 'Maximum 400 symbols')
                .required('Icon is required'),
            refreshPeriod: Yup.string()
                .min(1, 'Minimum 1 symbols')
                .required('Refresh Period is required'),
        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.id)) {
                    updateWithId(values)
                }
                else {
                    createWithRequest(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
                setFile(null)
            }
        },
    })

    useEffect(() => {
        setdataTableisBusy(false)
        dispatch(getDataProviderType());
    }, [DataSourcesListSlice])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(undefined)
            formik.resetForm()
        }
    }, [modalIsOpen])

    const DataProviderTypesEnum = [
        {
            value: -1,
            label: "Select..."
        },
        {
            value: 0,
            label: "OpenBanking"
        },
        {
            value: 1,
            label: "Facebook"
        },
        {
            value: 2,
            label: "Instagram"
        },
        {
            value: 3,
            label: "LinkedIn"
        },
        {
            value: 4,
            label: "Device"
        },
        {
            value: 5,
            label: "None"
        },
        {
            value: 6,
            label: "FreedomPay BI"
        },
        {
            value: 7,
            label: "X"
        },
    ]

    //#region DataTable Methods

    const columns = useMemo(
        () => [
            {
                name: 'Icon',
                selector: (row: any) => <>
                    <div className={Styles.imageRow}>
                        <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                                <img src={row.icon} alt='' />
                            </div>
                        </div>
                    </div>
                </>,
                width: "150px",
            },
            {
                name: 'Name',
                selector: (row: any) => <div>{row?.name}</div>,
                width: "auto",
                maxWidth: "300px"
            },
            {
                name: 'Data Provider Type',
                selector: (row: any) => <div>{DataProviderTypesEnum.find(x => x.value === row?.data_provider_type)?.label}</div>,
                width: "auto",
                maxWidth: "300px"
            },
            {
                name: 'Refresh Period (Hours)',
                label: "RefreshPeriod",
                selector: (row: any) => <div>{row?.refresh_period}</div>,
                width: "auto",
                maxWidth: "300px",
                sortable: true

            },
            {
                name: 'Hybrid Refresh Period (Hours)',
                label: "HybridRefreshPeriod",
                selector: (row: any) => <div>{row?.hybrid_refresh_period}</div>,
                width: "auto",
                maxWidth: "300px",
                sortable: true
            },
            {
                name: 'Credential Expire In Days',
                label: "CredentialExpireInDays",
                selector: (row: any) => <div>{row?.credential_expire_in_days}</div>,
                width: "auto",
                maxWidth: "300px",
                sortable: true
            },
            {
                name: 'Status',
                selector: (row: any) => row?.is_active ?
                    <span className='badge badge-light-success fs-5 fw-semibold'>{row?.is_active.toString()}</span> :
                    <span className='badge badge-light-danger fs-5 fw-semibold'>{row?.is_active.toString()}</span>
                ,
                width: "auto",
            },
            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                            {
                                <>
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => {
                                            setTypes(DataProviderTypesEnum.filter(x => x.value === row.data_provider_type))
                                            setprocessName("Insert")
                                            setSelectedRowId(row.id)
                                            dispatch(componentsSlice.actions.openModal({}))
                                            DataSourcesWithId(row.id)
                                        }}
                                    >
                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                    </div>
                                </>
                            }

                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const customFilter = [
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Name</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Name"), { "name": "Name", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "Name")])
                    }
                    placeHolder={"Type a Name"}
                    name='name'
                    value={filterQuery.find((x: any) => x.name === "Name") !== undefined ? filterQuery.find((x: any) => x.name === "Name")?.value : ""}
                    customKeypressEvent={() => setfilterParams(filterQuery)}
                ></CustomTextbox>
            </>
        },
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Data Provider Type</label>
                <CustomSelect
                    onChange={
                        (e: any) =>
                            e.value !== -1 ?
                                setfilterQuery([...filterQuery.filter((x: any) => x.name !== "DataProviderType"), { "name": "DataProviderType", "comparator": "Equal", "value": e.value }])
                                : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "DataProviderType")])
                    }
                    data={DataProviderTypesEnum}
                    isMulti={false}
                    selectedValue={DataProviderTypesEnum?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "DataProviderType")?.value) !== undefined ? DataProviderTypesEnum?.find((x: any) => x.value === filterQuery.find((x: any) => x.name === "DataProviderType")?.value) : -1}
                ></CustomSelect>
            </>
        },
        {
            component: <>
                <div className='form-check form-check-solid' style={{ marginTop: "2.9rem" }}>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        id='is_active_filter'
                        checked={filterQuery.find((x: any) => x.name === "IsActive") !== undefined ? filterQuery.find((x: any) => x.name === "IsActive")?.value : false}
                        onChange={(e) => setfilterQuery([...filterQuery.filter((x: any) => x.name !== "IsActive"), { "name": "IsActive", "comparator": "Equal", "value": e.target.checked }])}
                    />
                    <label
                        className='form-check-label ps-2'
                        htmlFor='is_active_filter'
                    >
                        Is Active
                    </label>
                </div>
            </>
        },
    ]

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.label, "direction": sortDirection === "asc" ? 0 : 1 }])
    };
    useEffect(() => {
        dispatch(getDataSources({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, filterParams])


    //#endregion DataTable Methods End

    function DataSourcesWithId(rowId: any) {
        if (rowId !== undefined) {
            dispatch(getDataSourcesWithId(rowId)).then((s: any) => {
                formik.setValues({
                    id: s?.payload?.result?.data_provider?.id,
                    name: s?.payload?.result?.data_provider?.name,
                    credentialExpireInDays: s?.payload?.result?.data_provider?.credential_expire_in_days,
                    dataProviderType: (DataProviderTypesEnum.find(x => x.value === s?.payload?.result?.data_provider?.data_provider_type) as any),
                    icon: s?.payload?.result?.data_provider?.icon,
                    refreshPeriod: s?.payload?.result?.data_provider?.refresh_period,
                    hybridRefreshPeriod: s?.payload?.result?.data_provider?.hybrid_refresh_period,
                    isActive: s?.payload?.result?.data_provider?.is_active,
                    isActiveOnHybridService: s?.payload?.result?.data_provider?.is_active_on_hybrid_service,
                })
            })
        }
    }

    function updateWithId(request: any) {

        const formData = new FormData();
        formData.append("Id", request?.id);
        formData.append("Name", request?.name);
        formData.append("CredentialExpireInDays", request?.credentialExpireInDays);
        formData.append("DataProviderType", request?.dataProviderType?.value);
        formData.append("IconFile", file !== null ? file : "");
        formData.append("RefreshPeriod", request?.refreshPeriod);
        formData.append("HybridRefreshPeriod", request?.hybridRefreshPeriod ?? "");
        formData.append("IsActive", request?.isActive);
        formData.append("IsActiveOnHybridService", request?.isActiveOnHybridService);
        dispatch(putDataSources(formData)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getDataSources({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
                setFile(null)
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function deleteWithId() {
        if (selectedRowId !== undefined) {
            dispatch(deleteDataSourcesWithId(selectedRowId)).then((s: any) => {
                if (s?.payload?.is_success) {
                    dispatch(getDataSources({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.success("Item successfully deleted")
                    dispatch(componentsSlice.actions.closeModal({}))
                }
                else {
                    dispatch(getDataSources({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                    showToast.error(s?.payload?.response_exception?.exception_message)
                    dispatch(componentsSlice.actions.closeModal({}))
                }
            })
        }
    }

    function createWithRequest(request: any) {
        const formData = new FormData();
        formData.append("Id", request?.id);
        formData.append("Name", request?.name);
        formData.append("CredentialExpireInDays", request?.credentialExpireInDays);
        formData.append("DataProviderType", request?.dataProviderType?.value);
        formData.append("IconFile", file !== null ? file : "");
        formData.append("RefreshPeriod", request?.refreshPeriod);
        formData.append("HybridRefreshPeriod", request?.hybridRefreshPeriod ?? "");
        formData.append("IsActive", request?.isActive);
        formData.append("IsActiveOnHybridService", request?.isActiveOnHybridService);
        dispatch(postDataSources(formData)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getDataSources({ page_index: 0, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
                setFile(null)
            }
            else {
                showToast.error(response?.payload?.response_exception?.exception_message)
            }
        })
    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Update Data Source" : "Create Data Source"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Name</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('name')}
                                            onChange={() => null}
                                            placeHolder={"Type a Name"}
                                            name='name'
                                            value={formik.values.name}
                                            formik={formik}
                                            formikName={"name"}
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Type</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={types}
                                            isMulti={false}
                                            selectedValue={formik?.values?.dataProviderType}
                                            formik={formik}
                                            formikName={"dataProviderType"}
                                        ></CustomSelect>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Credential Expire In Days</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('credentialExpireInDays')}
                                            onChange={() => null}
                                            placeHolder={"Type a Credential Expire In Days"}
                                            name='credentialExpireInDays'
                                            value={formik.values.credentialExpireInDays}
                                            formik={formik}
                                            formikName={"credentialExpireInDays"}
                                            type={"number"}
                                            onlyPositive
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Icon File</label>
                                        <CustomFileUpload
                                            uploadFile={(file: FileList) => {
                                                setFile(file[0])
                                            }}
                                            imageString={formik.values.icon}
                                            formik={formik}
                                            formikName={"icon"}
                                            accept={"image/*"}
                                            text="Upload Icon File"
                                        ></CustomFileUpload>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Refresh Period(Hours)</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('refreshPeriod')}
                                            onChange={() => null}
                                            placeHolder={"Type a Refresh Period"}
                                            name='refreshPeriod'
                                            value={formik.values.refreshPeriod}
                                            formik={formik}
                                            formikName={"refreshPeriod"}
                                            onlyPositive
                                            type={"number"}
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='fw-bold fs-6 mb-2'>Hybrid Refresh Period(Hours)</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('hybridRefreshPeriod')}
                                            onChange={() => null}
                                            placeHolder={"Type a Hybrid Refresh Period"}
                                            name='refreshPeriod'
                                            value={formik.values.hybridRefreshPeriod}
                                            formik={formik}
                                            formikName={"hybridRefreshPeriod"}
                                            onlyPositive
                                            type={"number"}
                                        ></CustomTextbox>
                                    </div>

                                    <div className='fv-row mb-7 d-flex' >
                                        <div className='col-3'>
                                            <div className='form-check form-check-solid'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='is_active'
                                                    defaultChecked={formik.values.isActive}
                                                    checked={formik.values.isActive}
                                                    onChange={(event) =>
                                                        formik?.setValues({
                                                            ...formik?.values,
                                                            isActive: event.target.checked
                                                        })
                                                    }
                                                />
                                                <label
                                                    className='form-check-label ps-2'
                                                    htmlFor='is_active'
                                                >
                                                    Is Active
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-check form-check-solid' >
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='is_active_hybrid'
                                                    defaultChecked={formik.values.isActiveOnHybridService}
                                                    checked={formik.values.isActiveOnHybridService}
                                                    onChange={(event) =>
                                                        formik?.setValues({
                                                            ...formik?.values,
                                                            isActiveOnHybridService: event.target.checked
                                                        })
                                                    }
                                                />
                                                <label
                                                    className='form-check-label ps-2'
                                                    htmlFor='is_active_hybrid'
                                                >
                                                    Is Active on Hybrid Service
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteWithId()
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={DataSourcesListSlice?.items}
                    title={"Data Source List"}
                    pagination
                    paginationServer
                    paginationTotalRows={DataSourcesListSlice?.total_count}
                    isBusy={dataTableisBusy}
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    onExport={() => {
                        dispatch(exportDataSources({ page_index: 0, page_size: 0, filters: filterParams, sort_filters: sortData }));

                    }}
                    newItemClickEvent=
                    {() => {
                        setTypes(DataProviderTypes?.map((x: any) => ({ value: x, label: DataProviderTypesEnum.find(y => y.value === x)?.label })))
                        dispatch(componentsSlice.actions.openModal({})
                        )
                    }}

                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    showTitle
                    showFilter={true}
                    filterOptions={customFilter}
                    searchButtonClickEvent={() => setfilterParams(filterQuery)}
                    sortHandle={handleSort}
                    clearButtonClickEvent={() => {
                        setfilterParams([])
                        setfilterQuery([])
                    }}
                ></CustomDatatable>
            </KTCard>
            {
                modalIsOpen !== undefined ?
                    findProcessScreen(processName)
                    : null
            }
        </>
    )
}

const DataSourcesWrapper: FC<Props> = ({ DataSourcesListSlice }) => <QueryRequestProvider>
    <ListViewProvider>
        <DataSourcesList DataSourcesListSlice={DataSourcesListSlice} />
    </ListViewProvider>
</QueryRequestProvider>

export { DataSourcesWrapper }
