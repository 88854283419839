/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTCard } from "../../../../_metronic/helpers"
import { ListViewProvider } from "../../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC } from "react"
import CustomDatatable from "../../../components/customDatatable/CustomDatatable"

interface Props {
  reportList: any[]
}
const Report: FC<Props> = ({ reportList }) => {
  let reportListTemp = [{ "operation": "Init Payment", "call_count": "2432" }]



  const columns = [
    {
      name: 'Operation',
      selector: (row: any) => <div>{row?.operation}</div>,
    },
    {
      name: 'Call Count',
      selector: (row: any) => <div>{row?.call_count}</div>,
    },
  ];
  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={reportListTemp}
          pagination
          showFilter={false}
          isExport
        ></CustomDatatable>
      </KTCard>
    </>
  )
}

const ReportWrapper: FC<Props> = ({ reportList }) => <QueryRequestProvider>
  <ListViewProvider>
    <Report reportList={reportList} />
  </ListViewProvider>
</QueryRequestProvider>

export { ReportWrapper }
