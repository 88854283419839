
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { getVeracityContactInformation } from '../../../../slices/assetManagement/system/veracityContactInformation/VeracityContactInformationSlice'
import componentsSlice from '../../../../slices/components/componentsSlice'
import { AppDispatch, useAppSelector } from '../../../../store/store'
import { VeracityContactInformationWrapper } from './VeracityContactInformationList'

const VeracityContactInformationBreadcrumbs: Array<PageLink> = [
    {
        title: `System`,
        path: '/VeracityContactInformation/List',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const VeracityContactInformation = () => {
    const dispatch: AppDispatch = useDispatch();

    const { VeracityContactInformationSlice } = useAppSelector(
        (state) => state.veracityContactInformation
    );

    useEffect(() => {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(getVeracityContactInformation());
    }, [])



    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='/List'
                    element={
                        <>
                            <PageTitle breadcrumbs={VeracityContactInformationBreadcrumbs}>{`Veracity Contact Information`}</PageTitle>
                            <VeracityContactInformationWrapper VeracityContactInformationSlice={VeracityContactInformationSlice} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default VeracityContactInformation
