import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import introVideoService from "../../../../services/assetManagement/mobile/introVideo/video.service";


interface InitialStateInterface {
    videoList: any
    error: string;
    loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState = {
    videoList: null,
    loading: "idle",
    error: "",
} as InitialStateInterface;

export const getVideo = createAsyncThunk(
    "getVideo",
    async (req: any) => {
        try {
            return await introVideoService.getVideoService(req).then(response => response);
        } catch (error) { }
    }
);

export const exportVideo = createAsyncThunk(
    "exportVideo",
    async (req: any) => {
        try {
            return await introVideoService.exportVideoService(req).then(response => response);
        } catch (error) { }
    }
);

export const postVideo = createAsyncThunk(
    "postVideo",
    async (req: any, thunkAPI) => {
        try {
            return await introVideoService.postVideoService(req).then(response => response);
        } catch (error) { }
    }
);

export const putVideo = createAsyncThunk(
    "putVideo",
    async (req: any, thunkAPI) => {
        try {
            return await introVideoService.putVideoService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteVideoWithId = createAsyncThunk(
    "deleteVideoWithId",
    async (req: any, thunkAPI) => {
        try {
            return await introVideoService.deleteVideoService(req).then(response => response);
        } catch (error) { }
    }
);

export const getVideoWithId = createAsyncThunk(
    "getVideoWithId",
    async (req: any) => {
        try {
            return await introVideoService.getVideoWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const videoSlice = createSlice({
    name: "videoSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVideo.fulfilled, (state, action) => {
            state.videoList = action.payload?.result ? action.payload.result?.video_list : null;
        });
    },
});

export default videoSlice;
