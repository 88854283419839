
import { callApi, HttpMethods } from "../../../../utils/api-util";
import { ResponseType } from "../../../../utils/enums/ResponseType.enum";

const getTinkWebHookService = async (req: any) => {
    let _options = {
        controller: "/tink-webhook",
        method: HttpMethods.GET,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}
const exportTinkWebHookService = async (req: any) => {
    let _options = {
        controller: "/tink-webhook/export",
        method: HttpMethods.POST,
        data: JSON.stringify(req),
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}


const postTinkWebHookService = async (req: any) => {
    let _options = {
        controller: "/tink-webhook",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putTinkWebHookService = async (req: any) => {
    let _options = {
        controller: "/tink-webhook",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteTinkWebHookService = async (req: any) => {
    let _options = {
        controller: "/tink-webhook",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const TinkWebHookService = {
    getTinkWebHookService,
    exportTinkWebHookService,
    postTinkWebHookService,
    putTinkWebHookService,
    deleteTinkWebHookService
};

export default TinkWebHookService;
