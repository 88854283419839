/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { isNotEmpty, KTCard, KTSVG } from "../../../_metronic/helpers"
import { ListViewProvider } from "../../modules/apps/user-management/users-list/core/ListViewProvider"
import { QueryRequestProvider } from "../../modules/apps/user-management/users-list/core/QueryRequestProvider"
import { FC, useEffect, useMemo, useState } from "react"
import CustomDatatable from "../../components/customDatatable/CustomDatatable"
import { CustomModal } from "../../components/customModal/CustomModal"
import { AppDispatch, useAppSelector } from "../../store/store"
import { useDispatch } from "react-redux"
import componentsSlice from "../../slices/components/componentsSlice"
import { showToast } from "../../components/custom-toasts/custom-toasts"
import { deleteShoutbackWithId, exportShoutback, getShoutback, getShoutbackWithId, postShoutback, putShoutback } from "../../slices/dataSourceIntegrationSlice"
import clsx from 'clsx'
import CustomSelect from "../../components/customModal/CustomSelect"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import fontAwesomeHelper from "../../utils/font-awesome-helper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/free-brands-svg-icons"
import { faX } from "@fortawesome/pro-light-svg-icons"
import CustomTextbox from "../../components/customTextbox/CustomTextbox"

interface Props {
    shoutbackList: any
    credentialList: any
}
const DataSourceIntegrationList: FC<Props> = ({ shoutbackList, credentialList }) => {
    const [dataTableisBusy, setdataTableisBusy] = useState(false)
    const { modalIsOpen } = useAppSelector((state) => state.components);
    const dispatch: AppDispatch = useDispatch();
    const [processName, setprocessName] = useState("Insert")
    const [credentialNamesCore, setcredentialNamesCore] = useState<any[]>()
    const [selectedRowId, setSelectedRowId] = useState(undefined)
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterQuery, setfilterQuery] = useState<any>([])
    const [sortData, setsortData] = useState<any>([])
    const [filterParams, setfilterParams] = useState<any>([])

    const formik = useFormik({
        initialValues: {
            shoutbackId: '',
            name: '',
            iconString: '',
            shortDescription: '',
            type: [],
            queryId: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(3, 'Minimum 3 symbols')
                .max(300, 'Maximum 300 symbols')
                .required('Name is required'),
            iconString: Yup.string()
                .min(3, 'Minimum 3 symbols')
                .max(50, 'Maximum 50 symbols')
                .required('Icon is required'),
            shortDescription: Yup.string()
                .min(3, 'Minimum 3 symbols')
                .max(300, 'Maximum 300 symbols')
                .required('Short Description is required'),
            type: Yup.array()
                .min(1, "Shoutback Criterias is required")
                .required('Shoutback Criterias is required')
        }),
        onSubmit: (values, actions) => {
            try {
                if (isNotEmpty(values.shoutbackId)) {
                    updateWithId(values)
                } else {
                    createShoutBack(values);
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                dispatch(componentsSlice.actions.closeModal({}))
                actions.resetForm();
            }
        },
    })

    useEffect(() => {
        setdataTableisBusy(false)
    }, [shoutbackList])

    useEffect(() => {
        let array: any[];
        array = [];
        credentialList?.items?.map((x: any) => {
            let optionsData = {
                value: x.id,
                label: x.name,
                isFixed: true
            }
            array.push(optionsData)
        }
        )

        setcredentialNamesCore(array)
    }, [credentialList])

    useEffect(() => { if (selectedRowId !== undefined) { customgetShoutBackWithId() } }, [selectedRowId])

    useEffect(() => {
        if (modalIsOpen === undefined) {
            setprocessName("Insert")
            setSelectedRowId(undefined)
            formik.resetForm()
        }
    }, [modalIsOpen])

    //#region DataTable Methods

    const columns = useMemo(
        () => [
            {
                name: 'Icon',
                selector: (row: any) => <div>
                    <FontAwesomeIcon
                        icon={row?.icon !== undefined ? fontAwesomeHelper(row?.icon) as IconDefinition : faX} size={"1x"}
                    ></FontAwesomeIcon>

                </div>,
                width: "90px",
            },
            {
                name: 'Criterias',
                selector: (row: any) => <div>{row?.bo_query?.query_criterias.flatMap((x: any) => x.credential_definition).map((x: any) => x.name).join()}</div>,
                width: "150px",
            },
            {
                name: 'Name',
                selector: (row: any) => <div>{row?.bo_query?.campaign_name}</div>,
                width: "auto",
            },
            {
                name: 'Description',
                selector: (row: any) => <div>{row.short_desc}</div>,
                width: "auto",
            },
            {
                name: 'Action',
                selector: (row: any) =>
                    <>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={() => {
                                    setprocessName("Insert")
                                    setSelectedRowId(row.id)
                                    dispatch(componentsSlice.actions.openModal({}))
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </div>
                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={() => {
                                    setprocessName("Delete")
                                    setSelectedRowId(row.id)
                                    dispatch(componentsSlice.actions.openModal({}))
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                            </div>
                        </div>
                    </>
                ,
                width: "100px",
            },
        ], []
    )

    const customFilter = [
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Name</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "BoQuery.CampaignName"), { "name": "BoQuery.CampaignName", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "BoQuery.CampaignName")])
                    }
                    placeHolder={"Type a Name"}
                    name='Name'
                    value={filterQuery.find((x: any) => x.name === "BoQuery.CampaignName") !== undefined ? filterQuery.find((x: any) => x.name === "BoQuery.CampaignName")?.value : ""}
                    customKeypressEvent={() => setfilterParams(filterQuery)}
                ></CustomTextbox>
            </>
        },
        {
            component: <>
                <label className='fw-bold fs-6 mb-2'>Short Desc</label>
                <CustomTextbox
                    onChange={
                        (e) => isNotEmpty(e) ?
                            setfilterQuery([...filterQuery.filter((x: any) => x.name !== "ShortDesc"), { "name": "ShortDesc", "comparator": "Contains", "value": e }])
                            : setfilterQuery([...filterQuery.filter((x: any) => x.name !== "ShortDesc")])
                    }
                    placeHolder={"Type a Short Desc"}
                    name='ShortDesc'
                    value={filterQuery.find((x: any) => x.name === "ShortDesc") !== undefined ? filterQuery.find((x: any) => x.name === "ShortDesc")?.value : ""}
                    customKeypressEvent={() => setfilterParams(filterQuery)}
                ></CustomTextbox>
            </>
        },
    ]

    const handlePageChange = (page: any) => {
        setdataTableisBusy(true);
        setPage(page - 1)
        setdataTableisBusy(false);
    };
    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setdataTableisBusy(true);
        setPerPage(newPerPage);
        setdataTableisBusy(false);
    };
    const handleSort = async (column: any, sortDirection: any) => {
        setsortData([{ "property": column?.name, "direction": sortDirection === "asc" ? 0 : 1 }])
    };
    useEffect(() => {
        dispatch(getShoutback({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
    }, [page, perPage, sortData, filterParams])


    //#endregion DataTable Methods End

    function deleteFunc() {
        dispatch(deleteShoutbackWithId(selectedRowId)).then((response) => {
            if (response?.payload?.is_success) {
                showToast.success("Deleted successfully")
                dispatch(getShoutback({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
            }
            else {
                showToast.error("Delete process failed")
                dispatch(getShoutback({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }));
            }
        })
    }

    function createShoutBack(values: any) {
        let req = {
            campaign_name: values.name,
            template_type: 1,
            shoutback_criteria: values?.type?.map((x: any) => ({ 'credential_id': x.value })),
            shoutback: {
                icon: values.iconString,
                short_desc: values.shortDescription
            }
        }
        dispatch(postShoutback(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getShoutback({ page_index: 0, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
            }
        })
    }

    function customgetShoutBackWithId() {
        if (selectedRowId !== undefined) {
            dispatch(getShoutbackWithId(selectedRowId)).then((s: any) => {
                let credentialArray: any[] = [];
                s?.payload?.result?.shoutback?.bo_query?.query_criterias.forEach((element: any) => {
                    credentialArray.push(credentialNamesCore?.find(x => x.value === element.credential_definition_id))
                });
                formik.setValues({
                    shoutbackId: s?.payload?.result?.shoutback?.id,
                    name: s?.payload?.result?.shoutback?.bo_query?.campaign_name,
                    iconString: s?.payload?.result?.shoutback?.icon,
                    shortDescription: s?.payload?.result?.shoutback?.short_desc,
                    type: (credentialArray as any),
                    queryId: s?.payload?.result?.shoutback?.bo_query?.id
                })
            })
        }
    }

    function updateWithId(values: any) {
        let req = {
            id: values.queryId,
            campaign_name: values.name,
            template_type: 1,
            shoutback_criteria: values?.type?.map((x: any) => ({ 'credential_id': x.value })),
            shoutback: {
                id: values.shoutbackId,
                icon: values.iconString,
                short_desc: values.shortDescription
            }
        }
        dispatch(putShoutback(req)).then((response) => {
            if (response?.payload?.is_success) {
                dispatch(componentsSlice.actions.closeModal({}))
                dispatch(getShoutback({ page_index: page, page_size: perPage, filters: filterParams, sort_filters: sortData }))
                showToast.success("Item Saved Successfully")
            }
        })

    }

    function findProcessScreen(processName: string) {
        switch (processName) {
            case "Insert":
                return <>
                    <CustomModal title={selectedRowId !== undefined ? "Update Shoutback" : "Create Shoutback"}>
                        <>
                            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Name</label>
                                        <CustomTextbox
                                            {...formik.getFieldProps('name')}
                                            onChange={() => null}
                                            placeHolder={"Type a Name"}
                                            name='name'
                                            value={formik.values.name}
                                            formik={formik}
                                            formikName={"name"}
                                            type='text'
                                        ></CustomTextbox>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Shoutback Request</label>
                                        <div className="row">
                                            <div className="col-11">
                                            <CustomTextbox
                                            {...formik.getFieldProps('iconString')}
                                            onChange={() => null}
                                            placeHolder={"Type an Icon"}
                                            name='iconString'
                                            value={formik.values.iconString}
                                            formik={formik}
                                            formikName={"iconString"}
                                            type='text'
                                            ></CustomTextbox>
                                            </div>
                                            <div className="col-1">
                                                <FontAwesomeIcon
                                                    icon={formik.values.iconString !== undefined ? fontAwesomeHelper(formik.values.iconString) as IconDefinition : faX} size={"2x"} style={{ marginTop: "0.5rem" }}
                                                ></FontAwesomeIcon>
                                            </div>
                                        </div>

                                        <div className=" mt-4">
                                        <CustomTextbox
                                            {...formik.getFieldProps('shortDescription')}
                                            onChange={() => null}
                                            placeHolder={"Type a Short Description"}
                                            name='shortDescription'
                                            value={formik.values.shortDescription}
                                            formik={formik}
                                            formikName={"shortDescription"}
                                            type='text'
                                            ></CustomTextbox>
                                        </div>
                                    </div>
                                    <div className='fv-row mb-7'>
                                        <label className='required fw-bold fs-6 mb-2'>Shoutback Criterias</label>
                                        <CustomSelect
                                            onChange={() => null}
                                            data={credentialNamesCore}
                                            isMulti={true}
                                            formik={formik}
                                            formikName={"type"}
                                            selectedValue={formik?.values?.type}
                                        ></CustomSelect>
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                    >
                                        Discard
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                    >
                                        <span className='indicator-label'>Submit</span>
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </>
                    </CustomModal>
                </>

            case "Update":
                return <>

                </>

            case "Delete":
                return <>
                    <CustomModal title="Are You Sure?">
                        <>
                            {/* begin::Actions */}
                            <div className='text-center pt-15'>
                                <button
                                    type='reset'
                                    onClick={() => {
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }

                                    }
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Discard
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    onClick={() => {
                                        deleteFunc()
                                        dispatch(componentsSlice.actions.closeModal({}))
                                    }
                                    }
                                >
                                    <span className='indicator-label'>Delete</span>
                                </button>
                            </div>
                            {/* end::Actions */}
                        </>
                    </CustomModal>
                </>

            default: return null;
        }
    }

    return (
        <>
            <KTCard>
                <CustomDatatable
                    columns={columns}
                    data={shoutbackList?.items}
                    title={"Shoutback List"}
                    pagination
                    paginationServer
                    paginationTotalRows={shoutbackList?.total_count}
                    isBusy={dataTableisBusy}
                    isNewItem
                    isNewItemString={"New Item"}
                    isExport
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    showTitle
                    showFilter={true}
                    filterOptions={customFilter}
                    searchButtonClickEvent={() => setfilterParams(filterQuery)}
                    sortHandle={handleSort}
                    onExport={() => {
                        dispatch(
                            exportShoutback({
                              page_index: 0,
                              page_size: 0,
                              filters: filterParams,
                              sort_filters: sortData,
                            })
                          );
                    }}
                    clearButtonClickEvent={() => {
                        setfilterParams([])
                        setfilterQuery([])
                    }}
                ></CustomDatatable>
            </KTCard>
            {modalIsOpen !== undefined ?
                findProcessScreen(processName)
                : null
            }
        </>
    )
}

const DataSourceIntegrationListWrapper: FC<Props> = ({ shoutbackList, credentialList }) => <QueryRequestProvider>
    <ListViewProvider>
        <DataSourceIntegrationList shoutbackList={shoutbackList} credentialList={credentialList} />
    </ListViewProvider>
</QueryRequestProvider>

export { DataSourceIntegrationListWrapper }
