
import { callApi, HttpMethods } from "../../../utils/api-util";

const getEndpointsService = async (req: any) => {
    let _options = {
        controller: "/endpoint/get",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    const result = await callApi(_options);
    return result;
}

const postEndpointsService = async (req: any) => {
    let _options = {
        controller: "/endpoint",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const putEndpointsService = async (req: any) => {
    let _options = {
        controller: "/endpoint",
        method: HttpMethods.PUT,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const deleteEndpointsService = async (req: any) => {
    let _options = {
        controller: "/endpoint",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getEndpointsWithIdService = async (req:any) => {
    let _options = {
        controller: "/endpoint/get-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const EndpointsService = {
    getEndpointsService,
    postEndpointsService,
    putEndpointsService,
    deleteEndpointsService,
    getEndpointsWithIdService
};

export default EndpointsService;
