import { callApi, HttpMethods } from "../../utils/api-util";

const postFRPRequestService = async (req: any) => {
    let _options = {
        controller: "/frp/purchase",
        method: HttpMethods.POST,
        data: JSON.stringify(req)
    };
    return await callApi(_options);
};

const FRPRequestService = {
    postFRPRequestService,
};

export default FRPRequestService;
