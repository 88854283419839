/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useMemo, useState} from 'react'
import CustomDatatable from '../../../components/customDatatable/CustomDatatable'
import {CustomModal} from '../../../components/customModal/CustomModal'
import {AppDispatch, useAppSelector} from '../../../store/store'
import {useDispatch} from 'react-redux'
import componentsSlice from '../../../slices/components/componentsSlice'
import {showToast} from '../../../components/custom-toasts/custom-toasts'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import {QueryRequestProvider} from '../../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import {ListViewProvider} from '../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {deleteUsecaseWithId, exportUseCases, getUsecase, postUsecase} from '../../../slices/salesAssistant/useCases/useCasesSlice'
import CustomTextbox from '../../../components/customTextbox/CustomTextbox'

interface Props {
    UsecaseList: any
}

const UseCasesList: FC<Props> = ({UsecaseList}) => {
  const [dataTableisBusy, setdataTableisBusy] = useState(false)
  const {modalIsOpen} = useAppSelector((state) => state.components)
  const dispatch: AppDispatch = useDispatch()
  const [processName, setprocessName] = useState('Insert')
  const [selectedRowId, setSelectedRowId] = useState(undefined)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [filterQuery, setfilterQuery] = useState<any>([])
  const [sortData, setsortData] = useState<any>([])
  const [filterParams, setfilterParams] = useState<any>([])

  const validationSchema = Yup.object().shape({
    title: Yup.string().min(1, 'Minimum 1 symbol').required('Title is required'),
    video_url: Yup.string().test('video-or-image-url', 'Either Video URL or Image URL is required', function(value) {
      const image_url = this.parent.image_url;
      if (!value && !image_url) {
        return false;
      }
      return true;
    }).min(1, 'Minimum 1 symbol'),
    image_url: Yup.string().test('video-or-image-url', 'Either Video URL or Image URL is required', function(value) {
      const video_url = this.parent.video_url;
      if (!value && !video_url) {
        return false;
      }
      return true;
    }).min(1, 'Minimum 1 symbol'),
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      title: '',
      video_url: '',
      image_url: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      try {
        createWithRequest(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        dispatch(componentsSlice.actions.closeModal({}))
        actions.resetForm()
      }
    },
  })

  useEffect(() => {
    setdataTableisBusy(false)
  }, [UsecaseList])

  useEffect(() => {
    if (modalIsOpen === undefined) {
      setprocessName('Insert')
      setSelectedRowId(undefined)
      formik.resetForm()
    }
  }, [modalIsOpen])

  //#region DataTable Methods

  const columns = useMemo(
    () => [
      {
        name: 'Title',
        selector: (row: any) => <div>{row?.title}</div>,
        width: 'auto',
      },
      {
        name: 'Video URL',
        selector: (row: any) => <div>{row?.video_url}</div>,
        width: '%30',
      },
      {
        name: 'Image URL',
        selector: (row: any) => <div>{row?.image_url}</div>,
        width: '%30',
      },
      {
        name: 'Action',
        selector: (row: any) => (
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {
                <>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Insert')
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                      UseCaseWithId(row, 'Insert')
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </div>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      setprocessName('Delete')
                      setSelectedRowId(row.id)
                      dispatch(componentsSlice.actions.openModal({}))
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </div>
                </>
              }
            </div>
          </>
        ),
        width: '100px',
      },
    ],
    []
  )

  const handlePageChange = (page: any) => {
    setdataTableisBusy(true)
    setPage(page - 1)
    setdataTableisBusy(false)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setdataTableisBusy(true)
    setPerPage(newPerPage)
    setdataTableisBusy(false)
  }
  const handleSort = async (column: any, sortDirection: any) => {
    setsortData([{property: column?.title, direction: sortDirection === 'asc' ? 0 : 1}])
  }
  useEffect(() => {
    dispatch(
      getUsecase({
        page_index: page,
        page_size: perPage,
        filters: filterParams,
        sort_filters: sortData,
      })
    )
  }, [page, perPage, sortData, filterParams])

  //#endregion DataTable Methods End

  function UseCaseWithId(data: any, process: string) {
    if (data !== undefined) {
      /*dispatch(getOutcomesWithId(rowId)).then((s: any) => {
        
      })*/
      formik.setValues({
        id: data.id,
        title: data.title,
        video_url: data.video_url,
        image_url: data.image_url,
      })
    }
  }


  function deleteWithId() {
    if (selectedRowId !== undefined) {
      dispatch(deleteUsecaseWithId(selectedRowId)).then((s: any) => {
        dispatch(
            getUsecase({
            page_index: page,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        if (s?.payload?.is_success) {
          showToast.success('Item Deleted Successfully')
        } else {
          showToast.error(s?.payload?.response_exception?.exception_message)
        }
        dispatch(componentsSlice.actions.closeModal({}))
      })
    }
  }

  function createWithRequest(request: any) {
    let req = {
      use_case: {
        id: request.id !== null ? request.id : undefined,
        title: request.title,
        video_url: request.video_url == "" ? null : request.video_url,
        image_url: request.image_url == "" ? null : request.image_url,
      },
    }
    dispatch(postUsecase(req)).then((response) => {
      if (response?.payload?.is_success) {
        dispatch(componentsSlice.actions.closeModal({}))
        dispatch(
            getUsecase({
            page_index: 0,
            page_size: perPage,
            filters: filterParams,
            sort_filters: sortData,
          })
        )
        showToast.success('Item Saved Successfully')
      } else {
        showToast.error(response?.payload?.response_exception?.exception_message)
      }
    })
  }

  function findProcessScreen(processName: string) {
    switch (processName) {
      case 'Insert':
        return (
          <>
            <CustomModal title={selectedRowId !== undefined ? 'Update Use Case' : 'Create Use Case'}>
              <>
                <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit}>
                  <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                  >
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Title</label>
                      <CustomTextbox
                      {...formik.getFieldProps('title')}
                      onChange={() => null}
                      placeHolder={'Type a Title'}
                      name='title'
                      value={formik.values.title}
                      formik={formik}
                      formikName={'title'}
                      ></CustomTextbox>
                    </div>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Video URL</label>
                      <CustomTextbox
                      {...formik.getFieldProps('video_url')}
                      onChange={() => null}
                      placeHolder={'Type an URL'}
                      name='video_url'
                      value={formik.values.video_url}
                      formik={formik}
                      formikName={'video_url'}
                      ></CustomTextbox>
                    </div>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Image URL</label>
                      <CustomTextbox
                      {...formik.getFieldProps('image_url')}
                      onChange={() => null}
                      placeHolder={'Type an URL'}
                      name='image_url'
                      value={formik.values.image_url}
                      formik={formik}
                      formikName={'image_url'}
                      ></CustomTextbox>
                    </div>
                  </div>
                  {/* begin::Actions */}
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      onClick={() => dispatch(componentsSlice.actions.closeModal({}))}
                      className='btn btn-light me-3'
                      data-kt-users-modal-action='cancel'
                    >
                      Discard
                    </button>

                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                    >
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </div>
                  {/* end::Actions */}
                </form>
              </>
            </CustomModal>
          </>
        )

      case 'Delete':
        return (
          <>
            <CustomModal title='Are You Sure?'>
              <>
                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => {
                      dispatch(componentsSlice.actions.closeModal({}))
                    }}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    onClick={() => {
                      deleteWithId()
                    }}
                  >
                    <span className='indicator-label'>Delete</span>
                  </button>
                </div>
                {/* end::Actions */}
              </>
            </CustomModal>
          </>
        )

      default:
        return null
    }
  }

  return (
    <>
      <KTCard>
        <CustomDatatable
          columns={columns}
          data={UsecaseList?.items}
          title={'Use Case List'}
          pagination
          paginationServer
          paginationTotalRows={UsecaseList?.total_count}
          isBusy={dataTableisBusy}
          isNewItem
          isNewItemString={'New Item'}
          isExport
          onExport={() => {
            dispatch(
              exportUseCases({
                page_index: 0,
                page_size: 0,
                filters: filterParams,
                sort_filters: sortData,
              })
            );
          }}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          showTitle
          //showFilter={true}
          //filterOptions={customFilter}
          searchButtonClickEvent={() => setfilterParams(filterQuery)}
          sortHandle={handleSort}
          clearButtonClickEvent={() => {
            setfilterParams([])
            setfilterQuery([])
          }}
        ></CustomDatatable>
      </KTCard>
      {modalIsOpen !== undefined ? findProcessScreen(processName) : null}
    </>
  )
}

const UseCasesWrapper: FC<Props> = ({UsecaseList}) => (
  <QueryRequestProvider>
    <ListViewProvider>
      <UseCasesList UsecaseList={UsecaseList} />
    </ListViewProvider>
  </QueryRequestProvider> 
)

export {UseCasesWrapper}
