import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WelcomeScreenService from "../../../../services/assetManagement/sdk/welcomeScreen/WelcomeScreen.service";


interface InitialStateInterface {
    WelcomeScreenListSlice: any
}

const initialState = {
    WelcomeScreenListSlice: null,
} as InitialStateInterface;

export const getWelcomeScreen = createAsyncThunk(
    "getWelcomeScreenList",
    async (req: any) => {
        try {
            return await WelcomeScreenService.getWelcomeScreenService(req).then(response => response);
        } catch (error) { }
    }
);

export const postWelcomeScreen = createAsyncThunk(
    "postWelcomeScreen",
    async (req: any, thunkAPI) => {
        try {
            return await WelcomeScreenService.postWelcomeScreenService(req).then(response => response);
        } catch (error) { }
    }
);

export const putWelcomeScreen = createAsyncThunk(
    "putWelcomeScreen",
    async (req: any, thunkAPI) => {
        try {
            return await WelcomeScreenService.putWelcomeScreenService(req).then(response => response);
        } catch (error) { }
    }
);

export const deleteWelcomeScreenWithId = createAsyncThunk(
    "deleteWelcomeScreen",
    async (req: any, thunkAPI) => {
        try {
            return await WelcomeScreenService.deleteWelcomeScreenService(req).then(response => response);
        } catch (error) { }
    }
);

export const getWelcomeScreenWithId = createAsyncThunk(
    "getWelcomeScreenWithId",
    async (req: any) => {
        try {
            return await WelcomeScreenService.getWelcomeScreenWithIdService(req).then(response => response);
        } catch (error) { }
    }
);

const WelcomeScreenSlice = createSlice({
    name: "WelcomeScreenSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getWelcomeScreen.fulfilled, (state, action) => {
            state.WelcomeScreenListSlice = action.payload?.result ? action.payload.result?.sdk_intro_contents : null;
        });
    },
});

export default WelcomeScreenSlice;
