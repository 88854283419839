/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { isNotEmpty } from '../../../_metronic/helpers'
import CustomDate from '../custom-date/custom-date'

interface CustomProps {
    filterName: string;
    filterTitle: string;
    customQuery: any;
    customFunction: any;
}
const CustomFilterDateRange: React.FC<CustomProps> = ({
    filterName,
    filterTitle,
    customQuery,
    customFunction
}) => {

    const [firstDate, setfirstDate] = useState<any>()
    const [secondDate, setsecondDate] = useState<any>()

    useEffect(() => {
        if ((firstDate !== null && firstDate !== undefined) && (secondDate !== null && secondDate !== undefined)) {
            customFunction(
                [...customQuery.filter((x: any) => x.name !== filterName)
                    , { "name": filterName, "comparator": "InRange", "value": firstDate, "second_value": secondDate }])
        }
        else if ((firstDate !== null && firstDate !== undefined) && (secondDate === null || secondDate === undefined)) {
            customFunction(
                [...customQuery.filter((x: any) => x.name !== filterName)
                    , { "name": filterName, "comparator": "LessOrEqual", "value": firstDate }])
        }
        else if ((firstDate === null || firstDate === undefined) && (secondDate !== null && secondDate !== undefined)) {
            customFunction(
                [...customQuery.filter((x: any) => x.name !== filterName)
                    , { "name": filterName, "comparator": "GreaterOrEqual", "value": secondDate }])
        }
        else {
            customFunction(
                [...customQuery.filter((x: any) => x.name !== filterName)])
        }
    }, [firstDate, secondDate])

    useEffect(() => {
        if (customQuery.length === 0) {
            setfirstDate(null)
            setsecondDate(null)
        }
    }, [customQuery])

    return (
        <>
            <label className='fw-bold fs-6 mb-2'>{filterTitle}</label>
            <div className="row">
                <div className="col">
                    <CustomDate
                        value={null}
                        selected={firstDate ?? null}
                        placeHolder={`Select ${filterTitle}`}
                        onChange={
                            (e) => isNotEmpty(e) ?
                                setfirstDate(e)
                                : setfirstDate(null)
                        }
                        showTimeInput
                    />
                </div>
                <div className="col">
                    <CustomDate
                        value={null}
                        selected={secondDate ?? null}
                        placeHolder={`Select ${filterTitle}`}
                        onChange={
                            (e) => isNotEmpty(e) ?
                                setsecondDate(e)
                                : setsecondDate(null)
                        }
                        showTimeInput
                    />
                </div>
            </div>
        </>
    )
}

export default CustomFilterDateRange