/* eslint-disable no-template-curly-in-string */

import { callApi, HttpMethods } from "../../../utils/api-util";
import { ResponseType } from "../../../utils/enums/ResponseType.enum";

const getDataSourcesService = async (req: any) => {
    let _options = {
        controller: "/data-provider/get",
        method: HttpMethods.POST,
        data: req,
    };
    const result = await callApi(_options);
    return result;
}

const getDataProviderType = async () => {
    let _options = {
        controller: "/data-provider/get-data-provider-types",
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
}

const exportDataSourcesService = async (req: any) => {
    let _options = {
        controller: "/data-provider/export",
        method: HttpMethods.POST,
        data: req,
        responseType: ResponseType.File
    };
    const result = await callApi(_options);
    return result;
}
const postDataSourcesService = async (req: any) => {
    let _options = {
        controller: "/data-provider",
        method: HttpMethods.POST,
        data: req,
        contentType: 'multipart/form-data; boundary=${form._boundary}'
    };
    return await callApi(_options);
};

const putDataSourcesService = async (req: any) => {
    let _options = {
        controller: "/data-provider",
        method: HttpMethods.PUT,
        data: req,
        contentType: 'multipart/form-data; boundary=${form._boundary}'
    };
    return await callApi(_options);
};

const deleteDataSourcesService = async (req: any) => {
    let _options = {
        controller: "/data-provider",
        method: HttpMethods.DELETE,
        data: { Id: req }
    };
    return await callApi(_options);
};

const getDataSourcesWithIdService = async (req:any) => {
    let _options = {
        controller: "/data-provider/get-data-provider-by-id/"+ req,
        method: HttpMethods.GET,
    };
    const result = await callApi(_options);
    return result;
};

const DataSourcesService = {
    getDataSourcesService,
    exportDataSourcesService,
    postDataSourcesService,
    putDataSourcesService,
    deleteDataSourcesService,
    getDataSourcesWithIdService,
    getDataProviderType
};

export default DataSourcesService;
