import {callApi, HttpMethods} from '../../utils/api-util'
import { ResponseType } from '../../utils/enums/ResponseType.enum'

const getProductionRequestWithStatusService = async (req: any) => {
  let _options = {
    controller: `/production-request/get-requests`,
    method: HttpMethods.POST,
    data: JSON.stringify(req),
  }
  const result = await callApi(_options)
  return result
}

const exportProductionRequestWithStatusService = async (req: any) => {
  let _options = {
    controller: `/production-request/export-production-request`,
    method: HttpMethods.POST,
    data: JSON.stringify(req),
    responseType: ResponseType.File
  }
  const result = await callApi(_options)
  return result
}

const getProductionRequestWithIdService = async (req: any) => {
  let _options = {
    controller: '/production-request/get-production-request-by-id/' + req,
    method: HttpMethods.GET,
  }
  const result = await callApi(_options)
  return result
}

const postProductionRequestService = async (req: any) => {
  let _options = {
    controller: '/production-request',
    method: HttpMethods.POST,
    data: JSON.stringify(req),
  }
  return await callApi(_options)
}

const putProductionRequestService = async (req: any) => {
  let _options = {
    controller: '/production-request/update-status',
    method: HttpMethods.PUT,
    data: JSON.stringify(req),
  }
  return await callApi(_options)
}

const deleteProductionRequestService = async (req: any) => {
  let _options = {
    controller: '/production-request',
    method: HttpMethods.DELETE,
    data: {Id: req},
  }
  return await callApi(_options)
}

const getActiveMerchantsService = async (req: any) => {
  let _options = {
    controller: '/production-request/get-merchants',
    method: HttpMethods.POST,
    data: JSON.stringify(req),
  }
  const result = await callApi(_options)
  return result
}

const exportActiveMerchants = async (req: any) => {
  let _options = {
    controller: '/production-request/export-active-merchants',
    method: HttpMethods.POST,
    data: JSON.stringify(req),
    responseType: ResponseType.File
  }
  const result = await callApi(_options)
  return result
}

const getActiveMerchantWithIdService = async (req: any) => {
  let _options = {
    controller: '/production-request/get-active-merchant-by-id/' + req,
    method: HttpMethods.GET,
  }
  const result = await callApi(_options)
  return result
}
const putPartnerService = async (req: any) => {
  let _options = {
    controller: '/production-request/update-partners',
    method: HttpMethods.PUT,
    data: JSON.stringify(req),
  }
  const result = await callApi(_options)
  return result
}

const postProductionRequestSendEmailToAdmin = async (req: any) => {
  let _options = {
    controller: '/production-request/send-email-to-admin',
    method: HttpMethods.POST,
    data: JSON.stringify(req),
  }
  return await callApi(_options)
}

const postSendEmailDirectorService = async (req: any) => {
  let _options = {
    controller: '/production-request/send-director',
    method: HttpMethods.POST,
    data: JSON.stringify(req),
  }
  return await callApi(_options)
}

const ProductionRequestService = {
  getProductionRequestWithStatusService,
  exportProductionRequestWithStatusService,
  postProductionRequestService,
  putProductionRequestService,
  deleteProductionRequestService,
  getProductionRequestWithIdService,
  getActiveMerchantsService,
  exportActiveMerchants,
  getActiveMerchantWithIdService,
  postProductionRequestSendEmailToAdmin,
  putPartnerService,
  postSendEmailDirectorService,
}

export default ProductionRequestService
